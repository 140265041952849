<div class="bg"></div>
<div class="bg-mobile"></div>

<header>
  <img src="../assets/img/logo-novae.svg" alt="">
</header>

<div class="element"><img src="../assets/img/element.png" alt=""></div>
<div class="element-mobile"><img src="../assets/img/element-mobile.png" alt=""></div>

<form [formGroup]="activateForm">
  <div class="container">
    <div class="b-password" *ngIf="showForm">
      <div class="b-hi">
        <h1>{{ 'RECOVERY_PASS.hi2' | translate }}</h1>
        <h2>{{ 'RECOVERY_PASS.activateCard' | translate }}</h2>
        <p> {{ 'RECOVERY_PASS.descriptionActivateCard1' | translate }}
          <strong>{{ 'RECOVERY_PASS.descriptionActivateCard2' | translate :'{appName:\'' + appName + '\'}' }}</strong>
          {{ 'RECOVERY_PASS.descriptionActivateCard3' | translate }}
          <strong>{{ 'RECOVERY_PASS.descriptionActivateCard4' | translate }} </strong>
          {{ 'RECOVERY_PASS.descriptionActivateCard5' | translate }}
          <strong>{{ 'descriptionActivateCard6' | translate }} </strong> </p>
      </div>

      <!-- <div class="box intro">
                <div class="bot">
                    <figure class="icon-alle"> </figure>
                    <div class="bubble">
                        <p>{{'descriptionActivateCard'|translate}}</p>
                    </div>
                </div>
            </div> -->

      <div class="b-input">

        <div class="box">
          <div class="bot alert" *ngIf="errorServiceMessage">
            <p> {{ message }} </p>
          </div>
        </div>

        <div class="box">
          <div class="bot">
            <figure class="icon-alle"> </figure>
            <div class="bubble">
              <p>{{'RECOVERY_PASS.enterEmail' | translate}}</p>
            </div>
          </div>
        </div>

        <div class="box">
          <div class="user">
            <figure class="icon-user"> </figure>
            <div class="bubble" [ngClass]="{'input-alert': classPwd1}">
              <input type="text" formControlName="email" name="firtValue"
                placeholder="{{'RECOVERY_PASS.email' | translate}}" (keyup)="cleanErros()">
            </div>
          </div>
        </div>

        <div class="box">
          <div class="bot">
            <figure class="icon-alle"> </figure>
            <div class="bubble">
              <p>{{'RECOVERY_PASS.enterBin1' | translate}}
                <strong>{{'RECOVERY_PASS.enterBin2' | translate :'{appName:\'' + appName + '\'}' }}</strong>
                {{'RECOVERY_PASS.enterBin3' | translate}}</p>
            </div>
          </div>
        </div>

        <div class="box">
          <div class="user">
            <figure class="icon-user"> </figure>
            <div class="bubble" [ngClass]="{'input-alert': classPwd2}">
              <input type="text" formControlName="bin" name="secondValue"
                placeholder="{{'RECOVERY_PASS.cardBin' | translate}}">
            </div>
          </div>
        </div>


        <button type="button" class="btn" *ngIf="activateForm.valid" (click)="activateCard()">
          {{'RECOVERY_PASS.activateNowButton' | translate}}
        </button>

      </div>

    </div>

    <div class="b-password" *ngIf="!showForm">
      <div class="b-hi">
        <h1>HTTP 400 </h1>
        <h2>{{'RECOVERY_PASS.badRequest' |translate}}</h2>
      </div>
    </div>
  </div>

</form>
