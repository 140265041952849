<section class="s-flex morepower">
    <div class="container">
        <h2 class="text-gradient" innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.title' | translate}}"></h2>
        <p class="text-center" innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.desc_1' | translate}}"></p>
        <!-- <p class="text-center" *ngIf="brand === 'credomatic'" innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.desc_2' | translate}}"></p> -->
        <div class="bl-row">
            <div class="item">
                <div class="block">
                    <img src="../../../../assets/images/{{brand}}-miles/icon-flight.svg" alt="">
                    <p><strong>Viaja flex</strong>, sin costo adicional por cambios en tus reservas. </p>
                </div>
                <div class="block">
                    <img src="../../../../assets/images/{{brand}}-miles/icon-protection.svg" alt="">
                    <p><strong>Recibe protección médica. <br/>Incluye  COVID-19.</strong></p>
                </div>
                <p><strong>¡Gana <span class="italic">Miles</span> siempre!</strong><br> Aún cuando pagas <br>con <span class="italic">Miles</span>.</p>
                <div class="rewards">
                    <div class="reward">
                        <img src="../../../../assets/images/{{brand}}-miles/1x.svg" alt="">
                        <p>en vuelos.</p>
                    </div>
                    <div class="reward">
                        <img src="../../../../assets/images/{{brand}}-miles/2X.svg" alt="">
                        <p>en alquiler de autos.</p>
                    </div>
                    <div class="reward">
                        <img src="../../../../assets/images/{{brand}}-miles/3X.svg" alt="">
                        <p>en hoteles.</p>
                    </div>
                </div>
                <p><strong>¡Todo incluido, todo <br> al instante!</strong></p>
            </div>
            <div class="item phone">
                <img src="../../../../assets/images/{{brand}}-miles/phone-trip.png" alt="">
                <p><strong>Todos tus programas de lealtad en una misma billetera: <span class="italic">Miles</span>.</strong></p>
                <p>Paga tus viajes de manera parcial o total con <span class="italic">Miles</span> y combínalas con tus tarjetas <strong>BAC Credomatic.</strong> </p>
            </div>
            <div class="item phone">
                <p>Paga tus viajes <br> en cuotas con <br> <strong>0% de interés.</strong> </p>
                <img src="../../../../assets/images/{{brand}}-miles/phone-trip2.png" alt="">
                <span>Aplican condiciones.</span>
            </div>
        </div>
        <button class="reserve" (click)="goToTravel()">Reserva ahora</button>

    </div>
</section>

<section class="flex">
    <div class="container">
        <div class="bl-row">
            <div class="bl-left">
                <h2>Viaja con la flexibilidad <span>que mereces.</span> </h2>
                <p><strong>Tus reservaciones son 100% modificables, sin costo adicional por cambios*.</strong> Además, te damos opciones de cancelación e interrupción de viaje para una experiencia <strong>libre de preocupaciones.</strong></p>

                <a href="https://bac-tc.milesbynovae.com/#/bac?lang=es" target="_blank"><p class="text-small">Ver Términos y Condiciones.</p></a>
            </div>
            <div class="bl-right">
                <img alt="" src="../../../../assets/images/{{brand}}-miles/flex.png">
            </div>
        </div>
    </div>
</section>

<section class="change-reservation">
    <div class="container">
        <div class="bl-row">
            <div class="bl-left">
                <h3><strong>Cambia tus <br> reservas</strong> de <br> manera fácil <br> y sencilla. </h3>
            </div>
            <div class="bl-right">
                <p>Modifica todas tus reservas <strong>sin costo adicional.</strong></p>
                <p><strong>Recupera el 100% de tus gastos</strong> si cancelas el viaje por enfermedad tuya o de tu acompañante y hasta el <strong>75%</strong> sin razón específica.</p>
                <p>En caso de que debas interrumpir tu viaje, nos encargamos de la <strong>logística de regreso.</strong></p>
                <a (click)="goToTyC()">Ver Términos y Condiciones.</a>
            </div>
        </div>
    </div>
</section>
