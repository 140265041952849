import { FiltersModule } from './../shared/filters/filters.module';
import { MainFormModule } from './../main-form/main-form.module';
import { BenefitModule } from './../benefit-card/benefit.module';
import { MatRadioModule } from '@angular/material/radio';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityListComponent } from './activity-list/activity-list.component';
import { ActivityDetailComponent } from './activity-detail/activity-detail.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from './../shared/shared.module';
import { DetailHeaderComponent } from './activity-detail/detail-header/detail-header.component';
import { DetailGalleryComponent } from './activity-detail/detail-gallery/detail-gallery.component';
import { DetailSelectionComponent } from './activity-detail/detail-selection/detail-selection.component';
import { ActivitySliderComponent } from './activity-detail/detail-gallery/activity-slider/activity-slider.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { ActivityDetailCardComponent } from './activity-detail/activity-detail-card/activity-detail-card.component';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ActivityBodyComponent } from './activity-body.component';



@NgModule({
  declarations: [
    ActivityListComponent,
    ActivityDetailComponent,
    DetailHeaderComponent,
    DetailGalleryComponent,
    DetailSelectionComponent,
    ActivitySliderComponent,
    ActivityDetailCardComponent,
    ActivityBodyComponent
  ],
  exports: [
    ActivityListComponent,
    ActivityDetailComponent,
    ActivityBodyComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SwiperModule,
    TranslateModule.forChild(),
    MatRadioModule,
    FormsModule,
    BenefitModule,
    PipesModule,
    MainFormModule,
    FiltersModule,
    PipesModule
  ],
  entryComponents: [ActivityDetailComponent]
})
export class ActivityModule { }
