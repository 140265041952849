/**
 * muestra el login cuando se hace un request y retorna 401
 * la lógica esta explicada (con un ejemplo diferente) en este artículo
 * https://medium.com/@this.is.samy/angular-auto-session-recovery-interceptor-42e8233cfa23
 */
import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpErrorResponse
} from '@angular/common/http';
import {
    Observable,
    Subject,
    throwError
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { LoginComponent } from '../container/shared/login/login.component';
import { MatDialog } from '@angular/material/dialog';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { keysUniversal } from '../resources/keys.resources';
import { DataStoreService } from '../services/data-store.service';

@Injectable()
export class LoginInterceptor implements HttpInterceptor {

    keyURL = sessionStorage.getItem('key');
    middlewareUrl: string;
    consolidAuth: string;

    /**
     * código de error, usuario no autorizado para reliazar el request
     */
    readonly TOKEN_UNAUTHORIZED = 401;

    /**
     * formulario de login subject
     */
    private loginSubject: Subject<any> = new Subject<any>();

    constructor(
        private dialog: MatDialog,
        private dataStore: DataStoreService,
        public storeServiceLibrary: StoreServiceLibrary
    ) {
        if (this.keyURL === 'localhost') {
            if (this.dataStore.brand !== 'lifeMilesTravel') {
                this.middlewareUrl = '/api/';
            } else {
                this.middlewareUrl = '/nothing/';
            }
        } else {
            this.middlewareUrl = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.middleware_url);
        }

        this.consolidAuth = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.consolid_url);
    }

    /**
     * retorna true si es un request al middleware
     */
    private isRequestToMiddleware(url: string = ''): boolean {
        return this.middlewareUrl !== '' && this.middlewareUrl !== undefined &&
            url.indexOf(this.middlewareUrl) !== -1 && url.indexOf('apib') === -1;
    }

    /**
     * retorna true si el token de usuario es inválido en el middleware
     */
    private isInvalidUserToken(error): boolean {
        return this.isRequestToMiddleware(error.url)
            && error instanceof HttpErrorResponse
            && error.status === this.TOKEN_UNAUTHORIZED;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // si el request es al middleware se añaden los headers
        let requestWithHeaders = request;
        if (this.isRequestToMiddleware(request.url)) {
            let headers;
            headers = requestWithHeaders.headers
                .set(
                    'Authorization',
                    this.consolidAuth
                );
            requestWithHeaders = request.clone({
                headers: headers
            });
        }

        return next.handle(requestWithHeaders)
            .pipe(
                /**
                 * pipe - catchError
                 * -----------------------------------------------------------------------------
                 * si retorna "error", el request queda atrapado.
                 * si retorna "caught", el request se reintentará ejecutar nuevamente.
                 * ya sea que se retorne "error" o "caught" coninuará la cadena de interceptores.
                 */
                catchError((error, caught) => {
                    // si el middleware retorna usuario no autorizado
                    if (this.isInvalidUserToken(error)) {
                        // mostramos el formulario de login
                        return this.requestRelogin(caught);
                    } else {
                        return throwError(error.message);
                    }
                })
            );
    }

    /**
     * solicita al usuario que vuelva a ingresar al sistema
     * (se utiliza cuando el backen retorna 401 "unauthorized")
     */
    private requestRelogin(caught: Observable<HttpEvent<any>>) {
        this.loginSubject.subscribe({
            next: (isUserLoged) => {
                if (isUserLoged) {
                    // el usuario se logueo, reintentar el request
                    caught.toPromise().then(() => { });
                }
            },
            complete: () => {
                // si nos logueamos, entonces, reiniciamos el loginSubject.
                // esto sirve para que no queden observadores obsoletos mirando si nos logueamos.
                this.loginSubject = new Subject<any>();
            }
        });

        if (this.loginSubject.observers.length === 1) {
            // al primer request, que no pudo efectuarse por unathorized, solicitamos login
            // (ya no volvemos a solicitar login, para otros request)

            // mostrar formulario de login
            (new Observable((observer) => {
                const dialogRef = this.dialog.open(LoginComponent, {
                    panelClass: 'login-dialog'
                });
                dialogRef.afterClosed().subscribe(isUserLoged => {
                    observer.next(isUserLoged);
                    observer.complete();
                });
            }).subscribe(this.loginSubject));

        }

        return this.loginSubject;
    }
}
