<div class="datos-col row">
  <!-- <div class="col-6">&nbsp;</div> -->

  <!--
  <div class="col-6"><strong>ID Purchase:</strong> # {{ confirmationData.transactionData.transactionNumber }}</div>
  -->
  <!-- <div class="col-6"><strong innerHTML="{{ 'CONFIRMATION.BENEFIT.benefit' | translate }}">Benefits:</strong> {{ dataStore.selectedBenefit.benefitGroups.length }}</div> -->
  <div class="col-6">
    <strong>{{ dataStore.selectedBenefit.productCode }} -
      {{  dataStore.selectedBenefit.productName }} </strong>
    <!-- <strong>{{ dataStore.selectedBenefit.productCurrency }} </strong><strong>{{ dataStore.selectedBenefit.price.totalPrice }} </strong> -->
  </div>
  <div class="col-6">
    <b innerHTML="{{ 'CONFIRMATION.BENEFIT.start' | translate }}">
      Start:&nbsp;
    </b>
    {{ startDate  }}
    &nbsp;-&nbsp;
    <b innerHTML="{{ 'CONFIRMATION.BENEFIT.end' | translate }}">
      Finish:&nbsp;
    </b>
    {{ endDate }}
  </div>

</div>