import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TravellerFormComponent } from './traveller-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { MatSelectModule } from '@angular/material/select';


@NgModule({
  declarations: [
    TravellerFormComponent
  ],
  exports: [
    TravellerFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxDaterangepickerMd.forRoot(),
    TranslateModule.forChild(),
    DirectivesModule,
    MatSelectModule,
  ]
})
export class TravellerFormModule { }
