<section class="section-cero gray">
    <div class="container">
        <div class="bl-coming section-friday">
            <div class="bl-center">

                <!--se saca por seguridad 
                    <app-black-friday></app-black-friday> -->

                <h1>
                    <strong innerHTML="{{'LANDING.'+brand+'.SECTION_FIRST.SECTION0.say_hello_to' | translate}}"></strong><br>
                    <strong innerHTML="{{'LANDING.'+brand+'.SECTION_FIRST.SECTION0.title_app_name' | translate}}"></strong>
                </h1>

                <h3 innerHTML="{{'LANDING.'+brand+'.SECTION_FIRST.SECTION0.description_1' | translate }}"></h3>


                <div class="store">
                    <span innerHTML="{{'LANDING.'+brand+'.SECTION_FIRST.SECTION0.download_app' | translate }}"></span>
                    <app-download-store [class]="'logo'"></app-download-store>
                </div>

                <a href="#/travel"><img class="img-coming" [src]="imagesBgIphone1" alt="mr"></a>


            </div>
        </div>
    </div>
</section>
