import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-benefits-upperclub',
  templateUrl: './benefits-upperclub.component.html',
  styleUrls: ['./benefits-upperclub.component.css']
})
export class BenefitsUpperclubComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
