const keys = {
    user: 'ALLE_2020_USER',
    product: 'ALLE_2020_PRODUCT',
    travel_flights: 'ALLE_2020_FLIGHTS_DATA',
    travel_drive: 'ALLE_2020_DRIVE_DATA',
    travel_hotels: 'ALLE_2020_HOTELS_DATA',
    travel_go_out: 'ALLE_2020_GO_OUT_DATA',
    purchase: 'ALLE_2020_PURCHASE',
    productRequest: 'ALLE_2020_PRODUCT_REQUEST',
    detailTransaction: 'ALLE_2020_DETAIL_TRANSACTION',
    card_data: 'ALLE_2020_DATA',
    point: 'ALLE_2020_PONT',

    actionsMessage: 'ALLE_2020_YES-OF-COURSE',

    notification: 'ALLE_2020_NOTIFICATION',

    //  Universal
    universalProperties: 'ALLE_2020_UNIVERSAL_PROPERTIES',

    //  Flights (Round Trip or One Way)
    roundTripOrOneWay: 'roundTripOrOneWay',
    travelClass: 'travelClass',

    myImages: 'Alle_2020_MY_IMAGES',
    urlImagesProfile: 'ALLE_2020_URL_IMAGE_PROFILE',
    URL_LOGIN_LIFEMILES: '_URL_LOGIN_LIFEMILES',
    JSON_CATEGORY_BENFITS: '_JSON_CATEGORY_BENFITS',
    CONFIG_FIREBASE: '_CONFIG_FIREBASE',
    EXTRA_CURRENCY_DATA: '_EXTRA_CURRENCY_DATA',
    ORIBI_ANALYTICS: '_ORIBI_ANALYTICS',
    URL_TRAVEL_JUNIPER: '_URL_TRAVEL_JUNIPER',
    JSON_SLIDERS_BANNER: '_JSON_SLIDERS_BANNER',
    LOAD_BLACK_FRIDAY: '_LOAD_BLACK_FRIDAY',
    DEFAULT_REDIRECT_WEB: '_DEFAULT_REDIRECT_WEB',

    HOTJAR: '_BITACORA_HOTJAR'
};

/**
 * General keys
 */
const generalKeys = {
    keyStateApp: {
        webLogin: 'WEB_LOGIN'
    },
    keyTypePostMessage: {
        errorReceivedPostMessage: 'ERROR_RECEIVED_POST_MESSAGE',
        finishedProcessingLastMessage: 'FINISHED_PROCESSING_LAST_MESSAGE',
        // SIGNUP
        initSignUpPage: 'INIT_SIGNUP_PAGE',
        tokenLogin: 'TOKEN_LOGIN',
        // PREFERENCE PAYMENT
        initPPaymentPage: 'INIT_PREFERENCE_PAYMENT_PAGE',
        receivedTokenPPayment: 'TOKEN_PREFERENCE_PAYMENT',
        receivedUpdateInfoPPayment: 'UPDATE_INFO_PREFERENCE_PAYMENT',
        sliderDragEndPPaymentPage: 'SLIDER_DRAGEND_PAYMENT_PAGE',
        // OP
        initOPPage: 'INIT_ONEPOCKET_PAGE',
        termConsitionOPPage: 'TERM_AND_CONDITION_ONEPOCKET',
        paymentResponseOPPage: 'PAYMENT_RESPONSE_ONEPOCKET',
        receivedTokenOPPage: 'TOKEN_ONEPOCKET',
        receivedIdPurchaseOPPage: 'ID_PURCHASE_ONEPOCKET',
        purchaseIdInvalid: 'PURCHASE_ID_INVALID',
        paymentGoToResumeOPPage: 'GO_TO_RESUME_ONEPOCKET',
        initBitacoraPageFinder: 'INIT_BITACORA_PAGE',
        bitacoraReturnPurchaseId: 'BITACORA_PURCHASE_ID',
        detailPaymentOPPage: 'DETAIL_PAYMENT_ONEPOCKET',
        // My Account
        initMyAccount: 'INIT_MY_ACCOUNT_PAGE',
        receivedTokenMyAccountPage: 'TOKEN_MY_ACCOUNT',
        // Web view
        webViewReturn: 'WEB_VIEW_RETURN',
        // MILES
        initSignUpMilesPayPage: 'INIT_SING_UP_MILES_PAY_PAGE',
        successPaymentLandingMiles: 'SUCCESS_PAYMENT_LANDING_MILES',
        declinedPaymentLandingMiles: 'DECLINED_PAYMENT_LANDING_MILES',
        cancelPaymentLandingMiles: 'CANCEL_PAYMENT_LANDING_MILES',
        // TERM_AND_CONDITIONS
        showTermsAndConditions: 'SHOW_TERMS_AND_CONDITIONS',
        // Amex Change
        typeCreditCardDefault: 'TYPE_CREDIT_CARD_DEFUALT',
        // USER
        errorTokenSessionUser: 'TOKEN_SESSION_USER_ERROR',
        // LOG_OUT
        userTokenLogOut: 'USER_LOG_OUT',
        // GO_TO_HOME
        goToHome: 'GO_TO_HOME',
        openWhatsApp: 'OPEN_WHATSAPP_APP'
    },

    lpPushInformation: '_LP_PUSH_INFORMATION'
};

const keysCodeError = {
    creditcard: {
        userTokenIsNotvalid: '625'
    },
    uniqueTransaction: {
        userTokenIsNotvalid: '220'
    },
    getAccount: {
        userTokenIsNotvalid: '220'
    }
};

/**
 * General keys
 */
const keysPurchases = {
    statusPurchase: {
        pedding: 1,
        payment: 2
    }
};

/**
 * Franchise tyoe
 */
const franchiseType = {
    TYPE_AMERICAN_EXPRESS: 'AMERICAN_EXPRESS',
    TYPE_VISA: 'VISA',
    TYPE_DISCOVERY: 'DISCOVERY',
    TYPE_MASTERCAR: 'MASTERCAR',
};
const keysUniversal = {

    //  CREDIT_CARD_WS
    creditcard_api_basic_auth_token: '_CREDITCARD_API_BASIC_AUTH_TOKEN',
    creditcard_api_url_add_credit_card_alle: '_CREDITCARD_API_URL_ADD_CREDIT_CARD_ALLE',
    creditcard_api_url_get_credit_card_alle: '_CREDITCARD_API_URL_GET_CREDIT_CARD_ALLE',
    creditcard_api_url_update_credit_card_alle: '_CREDITCARD_API_URL_UPDATE_CREDIT_CARD_ALLE',
    //  UNIVERSAL_WS
    universal_api_basic_auth_token: '_UNIVERSAL_API_BASIC_AUTH_TOKEN',
    universal_api_url_get_countries: '_UNIVERSAL_API_URL_GET_COUNTRIES',
    universal_api_url_get_city_by_country_id: '_UNIVERSAL_API_URL_GET_CITY_BY_COUNTRY_ID',
    //  AUTH-API_WS
    auth_api_url_register: '_AUTH_API_URL_REGISTER',
    auth_api_url_reset_pw: '_AUTH_API_URL_RESET_PW',
    auth_api_url_forgot_pw: '_AUTH_API_URL_FORGOT_PW',
    auth_api_url_get_account_2: '_AUTH_API_URL_GET_ACCOUNT_2',
    auth_api_url_login: '_AUTH_API_URL_LOGIN',
    auth_api_url_update_account: '_AUTH_API_URL_UPDATE_ACCOUNT',
    auth_api_url_image_profile: '_AUTH_API_URL_IMAGE_PROFILE',
    //  ALLE-API_WS
    alle_api_url_register: '_ALLE_API_URL_REGISTER',
    alle_api_url_assign_product: '_ALLE_API_URL_ASSIGN_PRODUCT',
    alle_api_url_get_product_user: '_ALLE_API_URL_GET_PRODUCT_USER',
    alle_api_url_get_products: '_ALLE_API_URL_GET_PRODUCTS',
    alle_api_url_oauth2_token: '_ALLE_API_URL_OAUTH2_TOKEN',
    alle_api_url_add_purchase_alle: '_ALLE_API_URL_ADD_PURCHASE_ALLE',
    alle_api_header_oauth2_pw: '_ALLE_API_HEADER_OAUTH2_PW',
    alle_api_header_oauth2_us: '_ALLE_API_HEADER_OAUTH2_US',
    alle_api_body_oauth2_pw: '_ALLE_API_BODY_OAUTH2_PW',
    alle_api_body_oauth2_us: '_ALLE_API_BODY_OAUTH2_US',
    //  LIVEPERSON
    live_person_account: '_LIVE_PERSON_ACCOUNT',
    APP_LIVE_PERSON_CAMPAING_ID: '_APP_LIVE_PERSON_CAMPAING_ID',
    APP_LIVE_PERSON_ENGAGEMENT_ID: '_APP_LIVE_PERSON_ENGAGEMENT_ID',
    LIVE_PERSON_API_SEND_ENGAGEMENT_ATTRIBUT: '_LIVE_PERSON_API_SEND_ENGAGEMENT_ATTRIBUT',
    APP_LIVE_PERSON_APLICATTION_ID: '_APP_LIVE_PERSON_APLICATTION_ID',
    //  VIAJES_WS
    viajes_api_url_get_jwt_token: '_VIAJES_API_URL_GET_JWT_TOKEN',
    viajes_api_url_get_travel_filter_information: '_VIAJES_API_URL_GET_TRAVEL_FILTER_INFORMATION',
    viajes_api_url_get_nationality: '_VIAJES_API_URL_GET_NATIONALITY',
    viajes_listener_url_travel_proxy_request: '_VIAJES_LISTENER_URL_TRAVEL_PROXY_REQUEST',
    // PURCHASE_WS
    purchase_api_basic_auth_token: '_PURCHASE_API_BASIC_AUTH_TOKEN',
    purchase_api_url_get_purchase: '_PURCHASE_API_URL_GET_PURCHASE',
    purchase_api_url_add_purchase: '_PURCHASE_API_URL_ADD_PURCHASE',
    // CYBERSOURCE
    cybersource_tokenize_url: '_CYBERSOURCE_TOKENIZE_URL',
    cybersource_tokenize_access_key: '_CYBERSOURCE_TOKENIZE_ACCESS_KEY',
    cybersource_tokenize_unsigned_field_names: '_CYBERSOURCE_TOKENIZE_UNSIGNED_FIELD_NAMES',
    cybersource_tokenize_signed_field_names: '_CYBERSOURCE_TOKENIZE_SIGNED_FIELD_NAMES',
    cybersource_tokenize_transaction_type: '_CYBERSOURCE_TOKENIZE_TRANSACTION_TYPE',
    cybersource_tokenize_profile_id: '_CYBERSOURCE_TOKENIZE_PROFILE_ID',
    cybersource_tokenize_secret_key: '_CYBERSOURCE_TOKENIZE_SECRET_KEY',
    // CARDHOLDER-API_WS
    cardholder_api_basic_auth_token: '_CARDHOLDER_API_BASIC_AUTH_TOKEN',
    cardholder_api_url_points_cards: '_CARDHOLDER_API_URL_POINTS_CARDS',
    // UNIQUETRANSACTION_WS
    uniquetransaction_api_url_uniquetransaction: '_UNIQUETRANSACTION_API_URL_UNIQUETRANSACTION',
    // BITACORA
    bitacora_travel_flights_url: '_BITACORA_TRAVEL_FLIGHTS_URL',
    bitacora_travel_hotels_url: '_BITACORA_TRAVEL_HOTELS_URL',
    // OTROS
    app_default_product_id: '_APP_DEFAULT_PRODUCT_ID',
    app_default_language: '_APP_DEFAULT_LANGUAGE',
    web_url_terms_and_conditions_es: '_WEB_URL_TERMS_AND_CONDITIONS_ES',
    web_url_terms_and_conditions_en: '_WEB_URL_TERMS_AND_CONDITIONS_EN',

    app_url_terms_and_conditions_m4m_es: '_APP_URL_TERMS_AND_CONDITIONS_M4M_ES',
    app_url_terms_and_conditions_m4m_en: '_APP_URL_TERMS_AND_CONDITIONS_M4M_EN',

    app_default_currency: '_APP_DEFAULT_CURRENCY',
    IATAI_UNIVERSAL_WS_SALT: '_IATAI_UNIVERSAL_WS_SALT',

    consolid_url: '_CONSOLID_URL',
    middleware_url: '_MIDDELWARE_URL',
    middleware_benefits_url: '_MIDDELWARE_BENEFITS_URL',
    benefits_url: '_BENEFITS_URL',
    activities_url: '_ACTIVITIES_URL',
    consolid_auth: '_CONSOLID_AUTH',
    getLocations_uri: '_GET_LOCATIONS_URL',
    onePocket_url: '_ONEPOCKET_URL',
    TYPE_FIXED_RATES: '_TYPE_FIXED_RATES',
    USE_SEARCH_IFRAME: '_USE_SEARCH_IFRAME',

    URL_JUNIPER_DEFAULT: '_URL_JUNIPER_DEFAULT',
    URL_TRAVEL_JUNIPER: '_URL_TRAVEL_JUNIPER',
    URL_TRAVEL_FIXED_RATES: '_URL_TRAVEL_FIXED_RATES',

    // LM TRAVEL
    URL_TRAVEL_JUNIPER_LM: '_URL_TRAVEL_JUNIPER_LM',
    URL_LOGIN_LIFEMILES: '_URL_LOGIN_LIFEMILES',

    // PROMOS
    GET_PROMOS_DATABASE: '_GET_PROMOS_DATABASE',


    // Countries

    JSON_COUNTRIES_ALLETRAVEL: '_JSON_COUNTRIES_ALLETRAVEL',
    URL_REDIRECT_LIFEMILES_PAGE: '_URL_REDIRECT_LIFEMILES_PAGE',
    ORIBI_ANALYTICS: '_ORIBI_ANALYTICS',
    DEFAULT_CONVERSION_FACTOR: '_DEFAULT_CONVERSION_FACTOR',
    DEFAULT_FIXED_RATE: '_DEFAULT_FIXED_RATES',
    GET_URL_PROGRAM: '_GET_URL_PROGRAM_FOOTER',
    ADD_KPI_WEB: '_ADD_KPI_WEB',
    NEWS_CARDS: '_NEWS_CARDS',
    TUTORIALS_DATA: '_TUTORIALS_DATA',

    contentful: '_CONTENTFUL',

    web_show_code_promotion: '_WEB_SHOW_CODE_PROMOTION',
    app_channel_id: '_APP_CHANNEL_ID',

    app_web_show_benefits_component: '_APP_WEB_SHOW_BENEFITS_COMPONENT',

    filterToLimitPrice: '_JUNIPER_FILTER_TO_LIMIT_PRICE',
    filterToLimitPriceToFixedRates: '_JUNIPER_FILTER_TO_LIMIT_PRICE_TO_FIXED_RATES',

    onePocketBenefits_url: '_ONEPOCKET_BENEFITS_URL'
};

const flightsClass = {
    economy: { keyJuniper: 'Economy', keyLanguage: 'novaeTravelEconomy' },
    business: { keyJuniper: 'Business', keyLanguage: 'novaeTravelBusiness' },
    firstClass: { keyJuniper: 'First', keyLanguage: 'novaeTravelFirst' }
};

const typeService = {
    flights: 'flights',
    hotels: 'hotels',
    cars: 'cars',
    activities: 'activities',
    vacationHomes: 'vacationHomes',
    transfers: 'transfers',
  };

const roundTripOrOneWay = {
    roundTrip: { keyJuniper: 'roundTrip', keyLanguage: 'novaeTravelRoundTrip' },
    oneWay: { keyJuniper: 'oneway', keyLanguage: 'novaeTravelOneWay' }
};

const merchants = {
    visa_hoteles: {
        id: 2
    },
    visa_vuelos: {
        id: 3
    },
    visa_automoviles: {
        id: 6
    },
};

const codeTypePreferences = {
    flight_origin: 'VUEORI',
    flight_destiny: 'VUEDES',
    flight_class: 'CLASS',
    hotels_minimumHotelStarts: 'HOTELES',
    hotels_destiny: 'HOTELDES',
    car_TypeCar: 'CARRO',
    viajero_frecuente: 'VIAJEFREC',
    codigo_programa: 'CODIGOPROG'
};

const livePersonStore = {
    lpAtributesInfo: 'lpAtributesInfo',
    lpVisitorId: 'lpVisitorId',
    lpSessionId: 'lpSessionId'

};

const keyJsonFixedRates = {
    json_fixed_rates: 'JSON_FIXED_RATES',
    fixed_rates_selected: 'FIXED_RATES_SELECTED'
};

const keysPostMessage = {
    tokenUser: 'TOKEN_USER',
    showJuniperExtraElements: 'SHOW_JUNIPER_EXTRA_ELEMENTS',
    initData: 'INIT_DATA',
    idPurchase: 'ID_PURCHASE',
    idPurchaseOnePocket: 'ID_PURCHASE_ONEPOCKET',
    tokenOnePocket: 'TOKEN_ONEPOCKET',
    tokenMyAccount: 'TOKEN_MY_ACCOUNT',
    tokenAuth: 'TOKEN_AUTH',
    updateInfoPreferencePayment: 'UPDATE_INFO_PREFERENCE_PAYMENT',
    tokenPreferencePayment: 'TOKEN_PREFERENCE_PAYMENT',
    changeCredomaticCity: 'CHANGE_CREDOMATIC_CITY',
    changeInfoPromotion: 'CHANGE_INFO_PROMOTION',
};

enum TypePromotions {
    system = "SYSTEM",
    code = "CODE"
};

export { keys, TypePromotions, typeService, keysUniversal, keysPostMessage, keyJsonFixedRates, livePersonStore, generalKeys, keysPurchases, merchants, codeTypePreferences, flightsClass, roundTripOrOneWay, franchiseType, keysCodeError };
// export { keys, keysUniversal, merchants, codeTypePreferences };
