<h3 class="registro-persona">
    {{car.name}}
</h3>

<div class="registro-persona">
    <h6 innerHTML="{{'PURCHASE_SUMARY.CAR.vehicle_details' | translate}}">
        Detalles del vehículo</h6>
    <p [innerHTML]="car.description"></p>
    <ng-container *ngFor="let acrissdetail of ACRISS.value">
        <li class="acriss">
            <strong> {{ 'LAYOUT_TWO.'+acrissdetail.type | translate}}:</strong> {{acrissdetail.value}}
        </li>
    </ng-container>
</div>

<ng-container *ngIf="isSamePlaceWithdrawalAndReturn; else lugaresDiferentes">
    <div class="registro-persona d-flex flex-wrap">
        <h6 innerHTML="{{'PURCHASE_SUMARY.CAR.pick_drop_office' | translate}}">
            Oficina de recogida y devolución</h6>
        <i class="icon-car align-self-center"></i>
        <div class="item-detail">
            <!-- <p innerHTML="{{'PURCHASE_SUMARY.CAR.pick_up_office' | translate: '{value:\'' + car.dropOff + '\'}'}}">
                Lugar:&nbsp;{{car.dropOff}}
            </p> -->
            <p>
                <strong>{{'PURCHASE_SUMARY.CAR.pick_up' | translate}}:</strong>
                <br> {{car.puckUp}}
                <br> {{pickUpDate}} {{pickUpHour}}&nbsp;hs
            </p>
            <p>
                <strong> {{'PURCHASE_SUMARY.CAR.drop_off' | translate}}:</strong>
                <br> {{car.dropOff}}<br> {{dropOffDate}} {{dropOffHour}}&nbsp;hs
            </p>
        </div>
    </div>
</ng-container>
<ng-template #lugaresDiferentes>
    <div class="registro-persona d-flex flex-wrap">
        <h6 innerHTML="{{'PURCHASE_SUMARY.CAR.pick_up_office' | translate}}">
            Oficina de recogida</h6>
        <i class="icon-car align-self-center"></i>
        <div class="item-detail">
            <p innerHTML="{{'PURCHASE_SUMARY.CAR.place' | translate: '{value:\'' + car.originZoneName + '\'}'}}">
                Lugar: {{car.originZoneName}}</p>
            <p class="date-time">
                <strong>{{pickUpDate}}</strong> <strong class="ml-3">{{pickUpHour}} hs</strong>
            </p>
        </div>
    </div>
    <div class="registro-persona d-flex flex-wrap">
        <h6 innerHTML="{{'PURCHASE_SUMARY.CAR.drop_off_office' | translate}}">
            Oficina de devolución</h6>
        <i class="icon-car-devolucion align-self-center"></i>
        <div class="item-detail">
            <p innerHTML="{{'PURCHASE_SUMARY.CAR.place' | translate: '{value:\'' + car.destinationZoneName + '\'}'}}">
                Lugar: {{car.destinationZoneName}}</p>
            <p class="date-time">
                <strong>{{dropOffDate}}</strong> <strong class="ml-3">{{dropOffHour}}hs</strong>
            </p>
        </div>
    </div>
</ng-template>

<div class="registro-persona cancellationPolicies">
    <h6 innerHTML="{{'PURCHASE_SUMARY.CAR.cancellation_policies' | translate}}">
        Políticas de cancelación</h6>
    <span class="no-cancellationPolicies" *ngIf="!cancellationPolicies || !cancellationPolicies.length; else mostrarPoliticas">
        <i class="icon-reembolsable"></i>
        {{ tranlationsCar['no_cancellation_policies'] }}
    </span>
    <ng-template #mostrarPoliticas>
        <div class="with-cancellationPolicies" *ngFor="let cancellationPolicyOption of cancellationPolicies">
            <i class="icon-reembolsable"></i>
            <strong>{{ tranlationsCar['refund_conditions'] }}</strong>
            <ul>
                <li *ngFor="let value of cancellationPolicyOption.cancellationPolicy">
                    <span>
                        <strong innerHTML="{{ 'PURCHASE_SUMARY.cancellation_from' | translate:'{date:\'' + (fromDate(value)) + '\'}' }}">{{ value.from | date: translationDate['pipe_date'] }}</strong>
                    </span>
                    <br>
                    <span *ngIf="value.percent === '0.0'" innerHTML="{{ 'PURCHASE_SUMARY.penalty' | translate: '{amount:\'' + currency + (value.amount | milesFormat) + '\'}'}}"></span>
                    <span *ngIf="+value.amount === 0 && value.percent !== '0.0'" innerHTML="{{ 'PURCHASE_SUMARY.penalty_percent' | translate: '{amount:\'' + value.percent + '\'}'}}"></span>
                </li>
            </ul>
        </div>
    </ng-template>
</div>