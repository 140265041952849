<div class="datos-col row">
    <div class="col-12 datos-destacado">
        <div class="destacado">{{ activity.name }}</div>
    </div>
</div>
<div class="datos-col row">
    <div class="col-12" innerHTML="{{ trans['CONFIRMATION']['ACTIVITY']['transaction_number'] }}">
        <strong>Numero de Transaccion:</strong> # {{ transactionNumber }}
    </div>
    <div class="col-6">
        <strong innerHTML="{{ trans['CONFIRMATION']['ACTIVITY']['activity'] }}">Actividad:</strong> {{ activity.selectedOption.name }}
    </div>
    <div class="col-6">
        <strong innerHTML="{{ trans['CONFIRMATION']['ACTIVITY']['activity'] }}">Fecha:</strong> {{ startDate }}
    </div>
    <div class="col-6" *ngIf="activity.selectedOption.selectedMeetingPoint">
        <strong innerHTML="{{ trans['CONFIRMATION']['ACTIVITY']['meeting_point'] }}">Punto de encuentro:</strong> {{ activity.selectedOption.selectedMeetingPoint.name }}
    </div>
    <div class="col-6" *ngIf="activity.selectedOption.selectedMeetingPoint">
        <strong innerHTML="{{ trans['CONFIRMATION']['ACTIVITY']['meeting_datetime'] }}">Fecha y Hora de encuentro:</strong> {{ datetime }}
    </div>
    <!-- <div class="col-6">
        <strong innerHTML="{{ trans['CONFIRMATION.ACTIVITY']['passenger'] }}">Pasajero:</strong>
        {{ travellers }}
    </div> -->
</div>