import { Component, OnInit, HostListener } from '@angular/core';
import { DataService } from '../../services/data.service';
import { DataStoreService } from '../../services/data-store.service';
import { SmartProfileService } from '../../services/smart-profile.service';
import { ActivatedRoute, Router } from '@angular/router';

import { OcultadorService } from '../home/ocultador.service';
import { InitService } from '../../services/init.service';
import { ActionEventService } from '../../services/action-event.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { EProduct } from '../../component/searched/models/searched.model';
import { environment } from '../../../environments/environment';
import { LoginComponent } from '../../container/shared/login/login.component';
import { LanguageService } from 'src/app/component/language/language.service';
import data from 'src/app/resources/screenAndEventsTracking.json';
import { LoginService } from '../../container/shared/login/login.service';
import { GoogleAnalytics } from '../../resources/GoogleAnalytics.resource';

declare var window: any;
declare var navigator: any;

@Component({
  selector: 'app-landing-scotia',
  templateUrl: './landing-scotia.component.html',
  styleUrls: ['./landing-scotia.component.scss']
})
export class LandingScotiaComponent implements OnInit {
  get brand() {
    return this.dataStoreService.brand;
  }

  public fixed: boolean;

  constructor(private dataService: DataService,
    private dataStoreService: DataStoreService,
    private smartProfile: SmartProfileService,
    private route: ActivatedRoute,
    private languageService: LanguageService,
    public ocultadorService: OcultadorService,
    private initService: InitService,
    private actionEventService: ActionEventService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private store: StoreServiceLibrary,
    private router: Router,
    private loginService: LoginService
  ) {

  }

  ngOnInit() {
    // this.trackingManager.setCurrentScreen('/home', data.WELCOME.welcomeScreen);

    this.getBrandRoute();
    this.aboutLoadNav();
    // this.dataService.productSelected = EProduct.FLIGHTS;
    // this.dataStoreService.preloaderImg = EProduct.FLIGHTS;
    this.initService.isInitializedBrand.subscribe((isInitialized) => {
      if (isInitialized) {
        let langDefault = environment.brands[this.dataStoreService.brand].defaultLanguaje;
        if (environment.brands[this.dataStoreService.brand].config.isLangNavigator) {

          let defaultLang;

          if (window.navigator.language) {
            defaultLang = window.navigator.language;
          } else if (navigator.language) {
            defaultLang = window.language;
          } else if (navigator.userLanguage) {
            defaultLang = window.userLanguage;
          }

          const splitLanguaje = defaultLang.split('-');
          if (splitLanguaje[0]) {
            defaultLang = splitLanguaje[0];
          }

          if (defaultLang === 'es' || defaultLang === 'en') {
            langDefault = defaultLang;
          }

          this.translate.use(langDefault);
          this.translate.setDefaultLang(langDefault);
          this.languageService.setSystemLanguaje(langDefault);
          this.store.setItemInStorage('lang', langDefault);

        } else {

          const lang = this.store.getItemFromStorage('lang') || 'en';
          this.translate.use(lang);
          this.translate.setDefaultLang(lang);
          this.languageService.setSystemLanguaje(lang);
          this.store.setItemInStorage('lang', lang);

        }

        this.validateRegisterParams();
        setTimeout(() => {
          this.checkGetUserOnUrl();
        });
        if (this.dataService.token) {
          this.smartProfile.getAccountMethod(this.dataService.token);
        }
        GoogleAnalytics.setCurrentScreen('/home');
      }
      switch (this.brand) {
        case 'ncb':
          this.actionEventService.setItemActiveTab(EProduct.REWARDS);
          this.dataService.productSelected = EProduct.REWARDS;
          this.dataStoreService.preloaderImg = EProduct.GENERAL;
          break;
          case 'firstcitizens':
          this.actionEventService.setItemActiveTab(EProduct.HOME);
          this.dataService.productSelected = EProduct.HOME;
          this.dataStoreService.preloaderImg = EProduct.GENERAL;
          break;
        case 'myrewards':
          this.actionEventService.setItemActiveTab(EProduct.HOME);
          this.dataService.productSelected = EProduct.HOME;
          this.dataStoreService.preloaderImg = EProduct.GENERAL;
          break;
        case 'cibc':
          this.actionEventService.setItemActiveTab(EProduct.HOME);
          this.dataService.productSelected = EProduct.HOME;
          this.dataStoreService.preloaderImg = EProduct.GENERAL;
          break;
        case 'scotia':
          this.actionEventService.setItemActiveTab(EProduct.HOME);
          this.dataService.productSelected = EProduct.HOME;
          this.dataStoreService.preloaderImg = EProduct.GENERAL;
          break;
        case 'credomatic':
          this.actionEventService.setItemActiveTab(EProduct.HOME);
          this.dataService.productSelected = EProduct.HOME;
          this.dataStoreService.preloaderImg = EProduct.GENERAL;
          break;
      }
    });
  }

   @HostListener('body:scroll', ['$event'])
  onScrollingEvent($event): void {
    let scrollOffset = $event.target.scrollTop;
    if (scrollOffset > 0) {
      this.fixed = true;
    } else {
      this.fixed = false;
    }
  }

  /**
   * verificar si el usuario llegó en la url
   */
  private checkGetUserOnUrl() {
    this.route.queryParams.subscribe((params) => {
      if (params.tokenAuth) {
        this.actionEventService.sendCollapseApp(true);
        const tokn = params.tokenAuth.replace(/ /g, '+');
        this.dataService.token = encodeURI(tokn);
        this.dataService.idApplication = params.idApplication;
        this.dataStoreService.channelID = params.idChannel;
        this.languageService.setSystemLanguaje(params.language);
        this.smartProfile.getAccountMethod(this.dataService.token);

        // como abrieron bitacora, ya logueados
        this.ocultadorService.ocultar = true;
        this.router.navigateByUrl('travel');
      }
    });
  }

  /**
 * @method getBrandRoute optiene el brand por parametro para su configuracion
 */

  getBrandRoute() {
    this.route.queryParams.subscribe((params: any) => {
      if (params.brand) {
        this.dataService.saveBrand(params.brand);
        this.actionEventService.sendBrandDynamic(params.brand);
        this.dataService.productSelected = EProduct.HOME;
        this.dataStoreService.preloaderImg = EProduct.GENERAL;
      }
    });
  }

  /**
  * @param EProduct.HOME es para colocar el active en el producto seleccionado
  * @param EProduct.GENERAL imagen que se le settea a el loaderService
  */

  aboutLoadNav() {
    this.actionEventService.isAboutLoad.subscribe((isAbout) => {
      this.dataService.productSelected = EProduct.HOME;
      this.dataStoreService.preloaderImg = EProduct.GENERAL;
    });
  }

  /**
     * @method validateRegisterParams
     * le envia el obj de registro a LoginComponent para su registro.
     */
  validateRegisterParams() {
    this.loginService.validateRegisterParams();
  }

}
