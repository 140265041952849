import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TermsAndConditionsComponent implements OnInit {

  iframeURL = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TermsAndConditionsComponent>
  ) {
    this.iframeURL = data.iframeURL;
  }

  ngOnInit() { }

  close() {
    this.dialogRef.close();
  }
}
