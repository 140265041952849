import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-gallery-carousel',
  templateUrl: './gallery-carousel.component.html',
  styleUrls: ['./gallery-carousel.component.scss'],
  host: {'class': 'cmp-tag'}
})
export class GalleryCarouselComponent implements OnInit {

  @Input() images = [];
  mainImageSrc: string;

  constructor() { }

  ngOnInit() {
    if (this.images === null || this.images.length === 0) {
      this.images = [
        {
          imgURL: './assets/images/empty_room.png',
          label: ''
        }
      ];
    }
    this.mainImageSrc = this.images[0].imgURL;
  }

  clickedImgSrcListener(src) {
    this.mainImageSrc = src;
  }

  onErrorFn() {
    this.mainImageSrc = this.images[0].imgURL;
  }

}
