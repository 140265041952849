<div class="box" *ngIf="isTranslationsLoaded">
    <div class="title">
        <p innerHTML="{{ 'PURCHASE_SUMARY.details' | translate }}">Detalles</p>
    </div>
    <div class="bl-general main-content" [ngSwitch]="config.type">
        <app-flight-summary *ngSwitchCase="EProduct.FLIGHTS">
        </app-flight-summary>
        <app-hotel-summary *ngSwitchCase="EProduct.HOTELS">
        </app-hotel-summary>
        <app-car-summary *ngSwitchCase="EProduct.CARS" [car]="registrationService.selectedProduct"></app-car-summary>
        <app-benefit-summary *ngSwitchCase="EProduct.BENEFITS" [benefit]="purchaseSummaryService.benefit"></app-benefit-summary>
        <app-activity-summary *ngSwitchCase="EProduct.ACTIVITIES" [activity]="purchaseSummaryService.activity"></app-activity-summary>
    </div>

    <div class="buttons">
        <div class="sumary-button" *ngIf="config.paymentButton">
            <button (click)="paymentButton()" [class.disabled]="config.paymentButtonClass === 'disabled'" [class.hidden]="config.paymentButtonClass === 'hidden'" innerHTML="{{ 'PURCHASE_SUMARY.pay' | translate }}">Pagar</button>
        </div>
        <div class="sumary-button" *ngIf="config.modifyButton">
            <button (click)="modifyButton()" [class.hidden]="config.modifyButtonClass === 'hidden'" innerHTML="{{ 'PURCHASE_SUMARY.modify' | translate }}">Modificar</button>
        </div>
    </div>
</div>