import { DataService } from 'src/app/services/data.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertSessionService {

  constructor(
    private dataService: DataService
  ) { }

  postMessage(message) {
    this.dataService.sendPostMessage(JSON.stringify(message));
  }
}
