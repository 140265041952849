<div class="menu-search" [ngClass]="{'showOnTop':ocultadorService.ocultar}">
  <button aria-controls="vuelos" (click)="productSelect(EProduct.FLIGHTS)"
          [ngClass]="{active: productSelected === EProduct.FLIGHTS}" class="vuelos">
    <span class="back"></span>
    <span innerHTML="{{'SEARCH.flights'|translate}}">
          Flights
        </span>
  </button>
  <button aria-controls="hoteles" (click)="productSelect(EProduct.HOTELS)"
          [ngClass]="{active: productSelected === EProduct.HOTELS}" class="hoteles">
        <span innerHTML="{{'SEARCH.hotels'|translate}}">
          Hotels
        </span>
  </button>
  <button aria-controls="autos" (click)="productSelect(EProduct.CARS)"
          [ngClass]="{active: productSelected === EProduct.CARS}" class="autos">
        <span innerHTML="{{'SEARCH.cars'|translate}}">
          Cars
        </span>
  </button>
  <button aria-controls="actividades" (click)="productSelect(EProduct.ACTIVITIES)"
          [ngClass]="{active: productSelected === EProduct.ACTIVITIES}" class="actividades">
        <span innerHTML="{{'SEARCH.activities'|translate}}">
          Activities
        </span>
  </button>
  <button aria-controls="beneficios" (click)="productSelect(EProduct.BENEFITS)"
          [ngClass]="{active: productSelected === EProduct.BENEFITS}" class="beneficios">
        <span innerHTML="{{'SEARCH.benefits'|translate}}">
          Benefits
        </span>
  </button>
</div>
