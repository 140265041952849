import { Injectable, Inject } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { DataStoreService } from '../services/data-store.service';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeGuard implements CanActivate {
  domain = '';

  constructor(
    @Inject(DOCUMENT) private _DOCUMENT,
    private dataStore: DataStoreService,
    private router: Router) {
  }

  canActivate() {
    let hostname: string = this._DOCUMENT.location.host.toLowerCase();
    let separtorHost = hostname.split('.');
    let route = window.location.hash;
    this.domain = environment.brands[this.dataStore.brand].config.hasLandingSale || undefined;
    if (this.dataStore.isAlleMobile) {
      this.router.navigateByUrl('travel');
      return false;
    } else if (this._DOCUMENT.location.host.toLowerCase().indexOf(this.domain) > -1) {
      this.router.navigateByUrl('myrewards-salesdeck');
    } else if (this.dataStore.brand === 'lifeMilesTravel') {
      const param = window.location.search;
      this.router.navigateByUrl(`lifemiles-travel${param}`);
      return false;
    } else if (separtorHost[0] == 'mtravel') {
      const paramTravel = window.location.search;
      this.router.navigateByUrl(`travel-app${paramTravel}`);
      return false;
    } else if (route == '#/travel-app') {
      const paramTravel = window.location.search;
      this.router.navigateByUrl(`travel-app${paramTravel}`);
      return false;
    } else {
      return true;
    }
  }

}
