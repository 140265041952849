<div class="menu-nav" id="menuNav" *ngIf="showTabs">
  <div class="container">
    <div [id]="i+'service'" *ngFor="let itemTab of typeExternalUrls; let i = index" class="item"
      [ngClass]="{'active': itemTab.name == selectedTab }" (click)="changeLoadIframe(itemTab.name)">{{
      itemTab.lang[languageSend] }}</div>
  </div>
</div>
<div class="tab-changeable" *ngIf="!showBenefits && isFixedRates">
  <div class="container">
    <div class="bloque">
      <div class="left">
        <h2 innerHTML="{{'LANDING.scotia.ONEPOCKETPAY.booking_benefits_title_desc'|translate}}">100% changeable and
          refundable
          <span innerHTML="{{'LANDING.scotia.ONEPOCKETPAY.booking_benefits_desc'|translate}}">reservations,
            all included in your purchase</span>
        </h2>
      </div>
      <div class="right">
        <div class="button" (click)="showBenefitsButton()"
          innerHTML="{{'LANDING.scotia.ONEPOCKETPAY.booking_benefits_btn'|translate}}">
          Check all included benefits
        </div>
      </div>
    </div>
  </div>
</div>
<iframe allow="clipboard-read; clipboard-write" id="iframe_juniper_app" [src]="urlSearchIframe | srcSanitizer"
  *ngIf="useSearchIframe" class="iframe-content"
  [ngStyle]="{height: iframeScrollHeight, width: iframeScrollWidth, border: border}">
</iframe>
<app-promociones id="promotionsTravelApp" *ngIf="showPromotions"></app-promociones>
<div [ngStyle]="{display: showBenefits? 'inline' : 'none'}">
  <app-benefits id="benefitsTravelApp"></app-benefits>
</div>
<app-footer-all [brand]="brand" *ngIf="showBenefits" [ngClass]="{'min-footer': !showBenefits}"></app-footer-all>