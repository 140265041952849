<div class="col-md-12 col-xs-12 travel-box">
  <div class="col-md-12 col-xs-12 hotel hidden-xs clearfix">
    <div class="col-md-12 col-sm-6 hotel_info ps-0">
      <div attr.class="special-tag text-center {{ (style==2) ? 'two' : '' }}">
        <p>Excelente Oferta</p>
      </div>
      <div *ngIf="style == 1">
        <div class="corner-selected"></div>
        <div class="input-selector selected"></div>
      </div>

      <app-hotel-card-header></app-hotel-card-header>

      <app-hotel-image [ngClass]="infoContainerClass + ' col-xs-6 thumbnail-container ps-0'"></app-hotel-image>

      <div [ngClass]="infoContainerClass + 'col-xs-6'">
        <app-hotel-detail [style]="style"></app-hotel-detail>
        <div class="clearfix"></div>
        <div class="text-center" *ngIf="style == 2">
          <app-hotel-price></app-hotel-price>
        </div>
      </div>

      <div class="col-md-4 col-sm-4 col-xs-6 text-center price-container" *ngIf="style == 1">
        <app-hotel-price></app-hotel-price>
      </div>
    </div>
  </div>
</div>

