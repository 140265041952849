import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { keysUniversal, StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { InitService } from 'src/app/services/init.service';


declare var window: any;

@Component({
  selector: 'app-mr-not-found',
  templateUrl: './app-not-found.component.html',
  styleUrls: ['./app-not-found.component.scss']
})
export class AppNotFoundComponent implements OnInit {
  type = '';
  app_name = '';
  url_app_download_ios = '';
  url_app_download_android = '';


  constructor(
    private router: Router,
    private initService: InitService,
    private activatedRoute: ActivatedRoute,
    private storeServiceLibrary: StoreServiceLibrary
  ) {
  }
  
  ngOnInit(): void {
    const that = this;
    this.activatedRoute.queryParams.subscribe(params => {
      that.type = params.type
      if (params.type == 'wave') {
        that.app_name = 'LANDING_ALLO.name_app_external';
      } else if (params.type == 'alle') {
        that.app_name = 'LANDING_ALLO.app_name';
      }
      that.loadUrls(that);
    });
    this.initService.isInitializedBrand.subscribe((isInitialized) => {
      if (isInitialized) {
        that.loadUrls(that);
      }
    });
  }
  
  private loadUrls(that: this) {
    if (that.type == 'wave') {
      that.url_app_download_ios = that.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.external_app_download_ios);
      that.url_app_download_android = that.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.external_app_download_android);
    } else if (that.type == 'alle') {
      that.url_app_download_ios = that.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.ios_url_store);
      that.url_app_download_android = that.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.android_url_store);
    }
  }

  goToApp(appName: string) {
    if (appName == 'android') {
      window.open(this.url_app_download_android, '_blank');
    } else if (appName == 'ios') {
      window.open(this.url_app_download_ios, '_blank');
    }
  }

  goHome() {
    this.router.navigate(['home']);
  }

}
