import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataStoreService } from '../../services/data-store.service';
import { InitService } from '../../services/init.service';
import { ActionEventService } from '../../services/action-event.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() product: any;
  @Input() selected: boolean = false;
  @Output() productSelected = new EventEmitter();
  class = '';
  private messageListenerFn = (event) => this.receiveMessage(event);
  constructor(private dataStore: DataStoreService,
    private initService: InitService,
    private actionService: ActionEventService) {
  }
  get brand() {
    return this.dataStore.brand;
  }

  ngOnInit() {
    window.addEventListener('message', this.messageListenerFn, false);

    if (this.brand === 'scotia' && this.dataStore.creditCardDefult === 'amex') {
      this.actionService.setSubBrand('amex');
      this.class = 'scotia-amex';
    } else {
      this.class = 'scotia-default';
    }
  }

  receiveMessage(event) {
    if (event.data.typeMessage === 'TYPE_CREDIT_CARD_DEFUALT') {
    }

  }
}
