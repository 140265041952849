<div>
    <swiper *ngIf="type == 'component' && show"
            class="swiper-container"
            fxFlex="auto"
            [config]="config"
            (indexChange)="onIndexChange($event)">
        <div *ngFor="let slide of slides; let i = index" class="swiper-slide">
        
            <img [src]="slide.imgURL | srcSanitizer" (click)="updateIndex(i)" alt="" (error)="onErrorFn(i)">
        
        </div>
    </swiper>
    <div class="swiper-button-prev" (click)="onPrev()"></div>
    <div class="swiper-button-next" (click)="onNext()"></div>
</div>
