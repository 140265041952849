import { Component, OnInit, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { OcultadorService } from '../../home/ocultador.service';
import { StoreServiceLibrary, OnePocketService } from '@wearenovae/novae-core-services';
import data from '../../../resources/screenAndEventsTracking.json';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../../../services/data.service';
import { DataStoreService } from '../../../services/data-store.service';
import { SmartProfileService } from '../../../services/smart-profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '../../../component/language/language.service';
import { InitService } from '../../../services/init.service';
import { ActionEventService } from '../../../services/action-event.service';
import { MatDialog } from '@angular/material/dialog';
import { EProduct, IRoomPaxes } from '../../../component/searched/models/searched.model';
import { LoginComponent } from '../../../container/shared/login/login.component';
import { SearchedService } from '../../../component/searched/searched.service';
import { formatDate } from '@angular/common';
import { locale } from 'moment';
import { SearchedFlight } from '../../../component/searched/models/searched-flight.model';
import { SearchedHotel } from '../../../component/searched/models/searched-hotel.model';
import { SearchedCar } from '../../../component/searched/models/searched-car.model';
import { ICarInfo } from '../../home/search/search-cars/search-cars.component';
import moment from 'moment';
import { IActivityRequestParameters } from '../../../models/activities/response/activity-availability.model';
import { SearchedActivity } from '../../../component/searched/models/searched-activity.model';
import { LoginService } from '../../../container/shared/login/login.service';
import { GoogleAnalytics } from '../../../resources/GoogleAnalytics.resource';
import { keysUniversal, typeService } from 'src/app/resources/keys.resources';
import { JuniperService } from '../../../services/juniper.service';
import { UtilsResource } from 'src/app/resources/UtilsResource.resource';

@Component({
  selector: 'app-landing-travel',
  templateUrl: './landing-travel.component.html',
  styleUrls: ['./landing-travel.component.scss']
})
export class LandingTravelComponent implements OnInit, AfterViewInit, OnDestroy {
  showForm: boolean;
  translations = [];
  textWithdrawalReturnIn: string;
  textWithdrawalIn: any;
  textReturnIn: any;
  textRental: any;
  textReturnDate: any;
  paxes = [];
  showSlider: boolean = true;
  useContentful: boolean = false;
  ///showMenu: boolean = true;
  showPromotions: boolean = true;
  sbs: any;

  showBenefits: boolean = false;
  showFooter: boolean = true;

  selectedTab = '';
  typeService = typeService;
  showTabs: boolean = false;

  objSearchFlights = {
    originID: '',
    destinationID: '',
    destinationIATA: '',
    originIATA: '',
    startDate: '',
    endDate: '',
    adt: '',
    chd: '',
    inf: '',
    ages: '',
    urlMultiDestino: '',
    itineraryType: '',
    flightClass: ''
  };

  objSearchHotels = {
    destinationID: '',
    destinationIATA: '',
    startDate: '',
    endDate: '',
    adt: '',
    chd: '',
    inf: '',
    ages: ''
  };

  objSearchHomeVacations = {
    destinationID: '',
    destinationIATA: '',
    startDate: '',
    endDate: '',
    adt: '',
    chd: '',
    inf: '',
    ages: ''
  };

  objSearchCars = {
    originID: '',
    startDate: '',
    endDate: '',
    startHour: '',
    endHour: '',
    nationality: '',
    dirverAge: ''
  };

  objActivatesTabs = {
    activateFligths: false,
    activateHotels: false,
    activateCars: false,
    activateHouseVacations: false
  };

  typeExternalUrls = [];
  configOptionsTravel: any;

  get language(): string {
    if (this.languageService.systemLanguajeChange.value) {
      return this.languageService.systemLanguajeChange.value.toLowerCase();
    }
    return '';
  }

  get brand() {
    return this.dataStoreService.brand;
  }

  constructor(
    private dataService: DataService,
    private dataStoreService: DataStoreService,
    private smartProfile: SmartProfileService,
    private route: ActivatedRoute,
    private languageService: LanguageService,
    public ocultadorService: OcultadorService,
    private initService: InitService,
    private actionEventService: ActionEventService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private store: StoreServiceLibrary,
    private _storeLibrary: StoreServiceLibrary,
    private onePocketService: OnePocketService,
    private searchedService: SearchedService,
    private router: Router,
    private loginService: LoginService,
    private render: Renderer2,
    private juniperService: JuniperService
  ) {
    this.getBrandRoute();
  }

  ngOnInit() {
    this.getTranslations();
    this.aboutLoadNav();

    this.dataService.productSelected = EProduct.TRAVEL;
    this.dataStoreService.preloaderImg = EProduct.TRAVEL;
    this.initService.isInitializedBrand.subscribe((isInitialized) => {
      if (isInitialized) {
        const lang = this._storeLibrary.getItemFromStorage('lang') || 'en';
        this.translate.use(lang);
        this.translate.setDefaultLang(lang);
        this.languageService.setSystemLanguaje(lang);
        this.validateRegisterParams();

        let externalUrls = JSON.parse(this._storeLibrary.getJustOneUniversalPropertie('_TYPE_URLS_EXTERNAL_TRAVEL') || 'false');
        if (externalUrls) {
          this.typeExternalUrls = externalUrls.filter(typeService => typeService?.active == true);
          this.selectedTab = typeService.flights;
          UtilsResource.NSMarkLog('CONFIGURATIONS TO SHOW: ', this.typeExternalUrls);
        }

        let configOptionsTravel = this.dataService.getConfigPropertiesTravel();
        UtilsResource.NSMarkLog('CONFIGURATIONS TO SHOW: ', configOptionsTravel);

        if (configOptionsTravel) {
          this.configOptionsTravel = configOptionsTravel;
          this.showTabs = this.configOptionsTravel.showTabs;
          this.showPromotions = this.configOptionsTravel.showHighlights;
          this.showBenefits = this.configOptionsTravel.showBenefitsInfo;
        }

        let contentful = this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.contentful);
        if (contentful != '') {
          this.useContentful = true;
          this.showSlider = false;
        }

        this.actionEventService.setItemActiveTab(EProduct.FLIGHTS);
        /*
        if (this.brand === 'credomatic') {
          this.actionEventService.setItemActiveTab(EProduct.FLIGHTS);
        } else {
          this.actionEventService.setItemActiveTab(EProduct.TRAVEL);
        }
        //*/

        setTimeout(() => {
          // this.checkGetUserOnUrl();
          this.setInformationTravelFromApp();
          this.actionEventService.haveLoadUrlTravelIframe.subscribe((resp) => {
            this.activateTabs();
            this.getParamsService();
            this.getParamsRoute();
          });
          this.activateTabs();
          this.getParamsService();
          this.getParamsRoute();
        });

        if (this.dataService.token) {
          this.smartProfile.getAccountMethod(this.dataService.token);
        }

        this.route.data.subscribe((data) => {
          if (data['openRegister']) {
            this.openRegister();
          }
        });
        GoogleAnalytics.setCurrentScreen('/travel');
      }
    });

    this.actionEventService.hideItemsPage.subscribe((show) => {
      let contentful = this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.contentful);
      if (contentful != '') {
        this.useContentful = show;
        this.showSlider = false;
      } else {
        this.showSlider = show;
      }

      let configOptionsTravel = this.dataService.getConfigPropertiesTravel();
      UtilsResource.NSMarkLog('CONFIGURATIONS TO SHOW: ', configOptionsTravel);

      if (configOptionsTravel) {
        this.configOptionsTravel = configOptionsTravel;
        if (this.configOptionsTravel.showHighlights) {
          this.showPromotions = show;
        } else {
          this.showPromotions = false;
        }
        if (this.configOptionsTravel.showBenefitsInfo) {
          this.showBenefits = show;
        } else {
          this.showBenefits = false;
        }
      }

      this.showFooter = show;
      ///this.showMenu = show;

      if (this.showTabs) {
        const elementMenu = document.getElementById('menuNav');
        this.render.setStyle(elementMenu, 'display', show ? 'block' : 'none');
      }
    });
    this.showForm = true;
  }

  ngAfterViewInit() {
  }

  getParamsService() {
    this.route.paramMap.subscribe((param) => {
      const typeService = param['params'].service;
      if (typeService) {
        this.chnageLoadIframeByService(typeService);
      } else {
        this.loadDefaultItem();
      }
    });
  }

  getParamsRoute() {
    this.route.queryParams.subscribe((params) => {
      if (params['service']) {
        switch (params['service']) {
          case 'searchflights':
            this.validateTypeItinerary(params['itineraryType'], params);
            break;
          case 'searchhotels':
            this.validateTypeHotelsSearch(params);
            break;
          case 'searchhomes':
            this.validateTypeHomeVacationSearch(params);
            break;
          case 'searchcars':
            this.validateTypeCarsSearch(params);
            break;
          default:
            break;
        }
        this.router.navigateByUrl('travel');
      }
    });
  }

  validateTypeCarsSearch(params) {
    const elementService0 = document.getElementById('0service');
    const elementService1 = document.getElementById('1service');
    const elementService3 = document.getElementById('3service');

    this.render.removeClass(elementService1, 'active');
    this.render.removeClass(elementService0, 'active');
    this.render.addClass(elementService3, 'active');

    this.actionEventService.setItemActiveTab(EProduct.CARS);
    this.dataService.productSelected = EProduct.CARS;
    this.dataStoreService.preloaderImg = EProduct.CARS;

    this.objSearchCars.originID = params['originID'];
    this.objSearchCars.startDate = params['startDate'];
    this.objSearchCars.endDate = params['endDate'];
    this.objSearchCars.nationality = params['nationality'];
    this.objSearchCars.startHour = params['startHour'];
    this.objSearchCars.endHour = params['endHour'];
    this.objSearchCars.dirverAge = params['dirverAge'];

    const urlToChangeCars = this.juniperService.searchCarsJuniper('searchcars', this.objSearchCars);
    this.actionEventService.setChangeUrlJuniper(urlToChangeCars, false);
  }

  validateTypeHotelsSearch(params) {
    const elementService0 = document.getElementById('0service');
    const elementService1 = document.getElementById('1service');
    const elementService3 = document.getElementById('3service');

    this.render.removeClass(elementService0, 'active');
    this.render.removeClass(elementService3, 'active');
    this.render.addClass(elementService1, 'active');

    this.actionEventService.setItemActiveTab(EProduct.HOTELS);
    this.dataService.productSelected = EProduct.HOTELS;
    this.dataStoreService.preloaderImg = EProduct.HOTELS;

    this.objSearchHotels.destinationID = params['destinationID'];
    this.objSearchHotels.destinationIATA = params['destinationIATA'];
    this.objSearchHotels.startDate = params['startDate'];
    this.objSearchHotels.endDate = params['endDate'];
    this.objSearchHotels.adt = params['adt'] || '0';
    this.objSearchHotels.chd = params['chd'] || '0';
    this.objSearchHotels.inf = params['inf'] || '0';
    this.objSearchHotels.ages = '';

    if (params['inf']) {
      this.objSearchHotels.chd = `${Number(params['chd']) + Number(params['inf'])}`;
    }

    this.proccesAges(params, 'hotels');

    const urlToChangeHotels = this.juniperService.searchHotelsJuniperAllParameters('searchhotels', this.objSearchHotels);
    this.actionEventService.setChangeUrlJuniper(urlToChangeHotels, false);
  }

  validateTypeHomeVacationSearch(params) {
    const elementService0 = document.getElementById('0service');
    const elementService1 = document.getElementById('1service');
    const elementService3 = document.getElementById('3service');

    this.render.removeClass(elementService0, 'active');
    this.render.removeClass(elementService3, 'active');
    this.render.addClass(elementService1, 'active');

    this.actionEventService.setItemActiveTab(EProduct.HOTELS);
    this.dataService.productSelected = EProduct.HOTELS;
    this.dataStoreService.preloaderImg = EProduct.HOTELS;

    this.objSearchHomeVacations.destinationID = params['destinationID'];
    this.objSearchHomeVacations.destinationIATA = params['destinationIATA'];
    this.objSearchHomeVacations.startDate = params['startDate'];
    this.objSearchHomeVacations.endDate = params['endDate'];
    this.objSearchHomeVacations.adt = params['adt'] || '0';
    this.objSearchHomeVacations.chd = params['chd'] || '0';
    this.objSearchHomeVacations.inf = params['inf'] || '0';
    this.objSearchHomeVacations.ages = '';

    if (params['inf']) {
      this.objSearchHomeVacations.chd = `${Number(params['chd']) + Number(params['inf'])}`;
    }

    this.proccesAges(params, 'homes');

    const urlToChangeHomeVacations = this.juniperService.searchHomeVacationsJuniperAllParameters('searchhomes', this.objSearchHomeVacations);
    this.actionEventService.setChangeUrlJuniper(urlToChangeHomeVacations, false);
  }

  validateTypeItinerary(itineraryType, params) {
    switch (itineraryType) {
      case 'OW': {
        const elementService0 = document.getElementById('0service');
        const elementService1 = document.getElementById('1service');
        const elementService3 = document.getElementById('3service');

        this.render.removeClass(elementService1, 'active');
        this.render.removeClass(elementService3, 'active');
        this.render.addClass(elementService0, 'active');

        this.actionEventService.setItemActiveTab(EProduct.FLIGHTS);
        this.dataService.productSelected = EProduct.FLIGHTS;
        this.dataStoreService.preloaderImg = EProduct.FLIGHTS;

        this.objSearchFlights.itineraryType = 'oneway';
        this.objSearchFlights.originIATA = params['originIATA'];
        this.objSearchFlights.destinationIATA = params['destinationIATA'];
        this.objSearchFlights.originID = params['originID'];
        this.objSearchFlights.destinationID = params['destinationID'];
        this.objSearchFlights.startDate = params['startDate'];
        this.objSearchFlights.adt = params['adt'] || '0';
        this.objSearchFlights.chd = params['chd'] || '0';
        this.objSearchFlights.inf = params['inf'] || '0';
        this.objSearchFlights.flightClass = params['flightClass'];
        this.objSearchFlights.ages = '';

        if (params['inf']) {
          this.objSearchFlights.chd = `${Number(params['chd']) + Number(params['inf'])}`;
        }

        this.proccesAges(params, 'flights');

        const urlToChangeFligths = this.juniperService.searchFlightsJuniperAllParameters('searchflights', this.objSearchFlights);
        this.actionEventService.setChangeUrlJuniper(urlToChangeFligths, false);
      }
        break;
      case 'RT': {
        const elementService0 = document.getElementById('0service');
        const elementService1 = document.getElementById('1service');
        const elementService3 = document.getElementById('3service');

        this.render.removeClass(elementService1, 'active');
        this.render.removeClass(elementService3, 'active');
        this.render.addClass(elementService0, 'active');

        this.actionEventService.setItemActiveTab(EProduct.FLIGHTS);
        this.dataService.productSelected = EProduct.FLIGHTS;
        this.dataStoreService.preloaderImg = EProduct.FLIGHTS;

        this.objSearchFlights.itineraryType = 'roundtrip';
        this.objSearchFlights.originIATA = params['originIATA'];
        this.objSearchFlights.destinationIATA = params['destinationIATA'];
        this.objSearchFlights.originID = params['originID'];
        this.objSearchFlights.destinationID = params['destinationID'];
        this.objSearchFlights.startDate = params['startDate'];
        this.objSearchFlights.endDate = params['endDate'];
        this.objSearchFlights.adt = params['adt'] || '0';
        this.objSearchFlights.chd = params['chd'] || '0';
        this.objSearchFlights.inf = params['inf'] || '0';
        this.objSearchFlights.flightClass = params['flightClass'];
        this.objSearchFlights.ages = '';

        if (params['inf']) {
          this.objSearchFlights.chd = `${Number(params['chd']) + Number(params['inf'])}`;
        }

        this.proccesAges(params, 'flights');

        const urlToChangeFligths = this.juniperService.searchFlightsJuniperAllParameters('searchflights', this.objSearchFlights);
        this.actionEventService.setChangeUrlJuniper(urlToChangeFligths, false);
      }
        break;
      default:
        const elementService0 = document.getElementById('0service');
        const elementService1 = document.getElementById('1service');
        const elementService3 = document.getElementById('3service');

        this.render.removeClass(elementService1, 'active');
        this.render.removeClass(elementService3, 'active');
        this.render.addClass(elementService0, 'active');

        this.actionEventService.setItemActiveTab(EProduct.FLIGHTS);
        this.dataService.productSelected = EProduct.FLIGHTS;
        this.dataStoreService.preloaderImg = EProduct.FLIGHTS;

        if (params['endDate']) {
          this.objSearchFlights.itineraryType = 'roundtrip';
          this.objSearchFlights.endDate = params['endDate'];
        } else {
          this.objSearchFlights.itineraryType = 'oneway';
        }

        this.objSearchFlights.originIATA = params['originIATA'];
        this.objSearchFlights.destinationIATA = params['destinationIATA'];
        this.objSearchFlights.originID = params['originID'];
        this.objSearchFlights.destinationID = params['destinationID'];
        this.objSearchFlights.startDate = params['startDate'];
        this.objSearchFlights.adt = params['adt'] || '0';
        this.objSearchFlights.chd = params['chd'] || '0';
        this.objSearchFlights.inf = params['inf'] || '0';
        this.objSearchFlights.flightClass = params['flightClass'];
        this.objSearchFlights.ages = '';

        if (params['inf']) {
          this.objSearchFlights.chd = `${Number(params['chd']) + Number(params['inf'])}`;
        }

        this.proccesAges(params, 'flights');

        const urlToChangeFligths = this.juniperService.searchFlightsJuniperAllParameters('searchflights', this.objSearchFlights);
        this.actionEventService.setChangeUrlJuniper(urlToChangeFligths, false);
        break;
    }
  }

  proccesAges(info, service) {
    switch (service) {
      case 'flights':
        if (info['chd']) {
          let chdAges = '08';
          for (let index = 0; index < info['chd']; index++) {
            this.objSearchFlights.ages += chdAges;
          }
        }

        if (info['inf']) {
          let infAges = '01';
          for (let index = 0; index < info['inf']; index++) {
            this.objSearchFlights.ages += infAges;
          }
        }
        break;
      case 'hotels':
        if (info['chd']) {
          let chdAges = '08';
          for (let index = 0; index < info['chd']; index++) {
            this.objSearchHotels.ages += chdAges;
          }
        }

        if (info['inf']) {
          let infAges = '01';
          for (let index = 0; index < info['inf']; index++) {
            this.objSearchHotels.ages += infAges;
          }
        }
        break;
      case 'homes':
        if (info['chd']) {
          let chdAges = '08';
          for (let index = 0; index < info['chd']; index++) {
            this.objSearchHomeVacations.ages += chdAges;
          }
        }

        if (info['inf']) {
          let infAges = '01';
          for (let index = 0; index < info['inf']; index++) {
            this.objSearchHomeVacations.ages += infAges;
          }
        }
        break;

      default:
        break;
    }

  }

  private getTranslations() {
    this.sbs = this.translate.get([
      'DATE',
      'SEARCH_HOTELS'
    ]).subscribe((trans) => {
      this.translations = trans;

      this.textWithdrawalReturnIn = trans['SEARCH_CARS.withdrawal_return_in'];
      this.textWithdrawalIn = trans['SEARCH_CARS.withdrawal_in'];
      this.textReturnIn = trans['SEARCH_CARS.return_in'];
      this.textRental = trans['SEARCH_CARS.rental'];
      this.textReturnDate = trans['SEARCH_CARS.return_date'];
      locale(this.languageService.systemLanguajeChange.value);
    });
  }

  /**
   * verificar si el usuario llegó en la url
   */
  private checkGetUserOnUrl() {
    this.route.queryParams.subscribe((params) => {
      if (params.tokenAuth) {
        this.actionEventService.sendCollapseApp(true);
        this.dataStoreService.isAlleMobile = true;
        this.ocultadorService.ocultar = true;
        const tokn = params.tokenAuth.replace(/ /g, '+');
        this.dataService.token = encodeURI(tokn);
        this.dataService.idApplication = params.idApplication;
        this.dataStoreService.channelID = params.idChannel;
        this.smartProfile.getAccountMethod(this.dataService.token);
        const brand = this.dataStoreService.brand;
        if (params.defaultCard !== '') {
          this.sendBrandLoad(Number(params.defaultCard));
        } else {
          this.actionEventService.sendBrandDynamic(brand);
        }

        this.languageService.setSystemLanguaje(params.language);

        // como abrieron bitacora, ya logueados
      }
    });
  }

  /**
   * metodo para buscar travel desde la app
   */
  setInformationTravelFromApp() {
    this.route.queryParams.subscribe((params) => {
      if (params.tokenAuth) {
        this.actionEventService.sendCollapseApp(true);
        this.dataStoreService.isAlleMobile = true;
        this.ocultadorService.ocultar = true;
        const tokn = params.tokenAuth.replace(/ /g, '+');
        this.dataService.token = encodeURI(tokn);
        this.dataService.idApplication = params.idApplication;
        this.dataStoreService.channelID = params.idChannel;
        this.smartProfile.getAccountMethod(this.dataService.token);
        const brand = this.dataStoreService.brand;
        if (params.defaultCard !== '') {
          this.sendBrandLoad(Number(params.defaultCard));
        } else {
          this.actionEventService.sendBrandDynamic(brand);
        }

        this.languageService.setSystemLanguaje(params.language);

        // como abrieron bitacora, ya logueados
      }

      switch (params.product) {
        case EProduct.HOTELS:
          this.paxes = [{
            'id': 1,
            'age': 21
          }];

          const searchHotels = {
            checkIn: params.checkIn, // "2020-03-02",
            checkOut: params.checkOut, // "2020-03-03",
            destinyCode: params.destinyCode, // "46786",
            totalNights: params.totalNights, // "1",
            destinyNameIata: params.destinyNameIata, // "MAD",
            destinyName: params.destinyName, // "Madrid",
            qPasangersB: JSON.parse(params.qPasangersB).length !== 0 ? JSON.parse(params.qPasangersB) : this.paxes
          };
          // this.trackingManager.trackEventString(data.HOTELS.searchHotelsButton, data.WELCOME.welcomeScreen, '', JSON.stringify(searchHotels));
          GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.HOTELS.searchHotelsButton, JSON.stringify(searchHotels));
          this.searchHotels(searchHotels);
          break;
        case EProduct.FLIGHTS:
          const searchFlight = {
            departureAirportCityText: params.departureAirportCityText, // 'San Jose',
            arrivalAirportCityText: params.arrivalAirportCityText, // 'Madrid',
            departureAirportCity: params.departureAirportCity, // 'SJO',
            arrivalAirportCity: params.arrivalAirportCity, // 'MAD',
            qAdultos: params.qAdultos, // '1',
            startDate: params.startDate, // '2020-05-12',
            endDate: params.endDate, // '2020-05-19',
            qNinos: params.qNinos,
            qBebes: params.qBebes,
            cabinClasses: params.cabinClasses,
            tramo: params.tramo
          };
          // this.trackingManager.trackEventString(data.FLIGHTS.searchFlightButton, data.WELCOME.welcomeScreen, '', JSON.stringify(searchFlight));
          GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.FLIGHTS.searchFlightButton, JSON.stringify(searchFlight));
          this.searchFlight(searchFlight);
          break;
        case EProduct.CARS:
          const searchCar = {
            start: params.dateStart, // "2020-09-24",
            end: params.dateEnd, // "2020-09-24",
            hour: params.hourStart, // "08:43:21",
            hourEnd: params.hourEnd, // "08:43:21",
            originZone: params.originZone, // 42075,
            destinationZone: params.destinationZone, // 48964,
            ageDriver: params.ageDriver,
            pickUpIata: params.pickUpIata,
            pickUpName: params.pickUpName,
            dropOffIata: params.dropOffIata,
            dropOffName: params.dropOffName, // 19,
          };
          // this.trackingManager.trackEventString(data.CARS.searchCarsButton, data.WELCOME.welcomeScreen, '', JSON.stringify(searchCar));
          GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.CARS.searchCarsButton, JSON.stringify(searchCar));
          this.searchCar(searchCar);
          break;
        case EProduct.ACTIVITIES:
          this.paxes = [{
            'id': 1,
            'age': 18
          }];
          const searActivities = {
            destinationZone: params.destinationZone,
            startDate: params.startDate,
            endDate: params.endDate,
            qPasangersB: JSON.parse(params.qPasangersB).length !== 0 ? JSON.parse(params.qPasangersB) : this.paxes
          };
          // this.trackingManager.trackEventString(data.ACTIVITIES.searchActivitiesButton, data.WELCOME.welcomeScreen, '', JSON.stringify(searActivities));
          GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.ACTIVITIES.searchActivitiesButton, JSON.stringify(searActivities));
          this.searchActivities(searActivities);
      }
    });
  }

  getCardsUser(token: string) {
    this.onePocketService.getCreditCards().subscribe((data: any) => {
      const listCards: any[][] = this.getSeparateListCreditCard(data);
      if (data.getCreditCardResponse.resultGetCreditCards
        && listCards['user'].length > 0) {

        for (const creditCardItem of listCards['user']) {
          if (creditCardItem && creditCardItem.creditCard
            && creditCardItem.creditCard.creditCardBase
            && creditCardItem.creditCard.creditCardBase.franquiseID
            && creditCardItem.creditCard.creditCardBase.defaultt) {
            // (creditCardItem.creditCard.creditCardBase.franquiseName);
            const franchice: number = Number(creditCardItem.creditCard.creditCardBase.franquiseID);
            this.sendBrandLoad(franchice);
          }
        }
      }
    }, (err) => {
    });
  }

  scrollIframe() {
    const elementSearch = document.getElementById('menuNav');
    elementSearch.scrollIntoView({ behavior: 'smooth' });
  }

  sendBrandLoad(franchiseId: number) {
    if (franchiseId === 3) {
      this.actionEventService.setSubBrand('amex');
      this.dataService.saveCreditCardDefult('amex');
      this.actionEventService.setChangeFooter(true);
    } else {
      this.actionEventService.setSubBrand('default');
      this.dataService.saveCreditCardDefult('');
      this.actionEventService.setChangeFooter(false);
    }
  }

  getSeparateListCreditCard(creditCardsResponse: any) {
    let creditCardsAll: any = [];
    const listCreditCardsSeparate: any[][] = [];
    const creditCardsStripe = [];
    const creditCards = [];

    if (creditCardsResponse.getCreditCardResponse.resultGetCreditCards.creditCards) {
      creditCardsAll = creditCardsResponse.getCreditCardResponse.resultGetCreditCards.creditCards;

      for (let i = 0; i < creditCardsAll.length; i++) {

        if (creditCardsAll[i].type && creditCardsAll[i].type === 'virtual') {
          creditCardsStripe.push(creditCardsAll[i]);
        } else {
          creditCards.push(creditCardsAll[i]);
        }
      }
    }

    listCreditCardsSeparate['virtual'] = creditCardsStripe;
    listCreditCardsSeparate['user'] = creditCards;

    return listCreditCardsSeparate;
  }

  getBrandRoute() {
    this.route.queryParams.subscribe((params: any) => {
      if (params.brand) {
        this.dataService.saveBrand(params.brand);
        this.actionEventService.sendBrandDynamic(params.brand);
        this.dataService.productSelected = EProduct.FLIGHTS;
        this.dataStoreService.preloaderImg = EProduct.FLIGHTS;
      } else {
        this.route.url.subscribe(resp => {
          if (resp[0].path === EProduct.TRAVEL) {
            this.dataService.productSelected = EProduct.FLIGHTS;
            this.dataStoreService.preloaderImg = EProduct.FLIGHTS;
          }
        });
      }
    });
  }

  aboutLoadNav() {
    this.actionEventService.isAboutLoad.subscribe((isAbout) => {
      this.dataService.productSelected = EProduct.FLIGHTS;
      this.dataStoreService.preloaderImg = EProduct.FLIGHTS;
    });
  }

  validateRegisterParams() {
    this.loginService.validateRegisterParams();
  }

  private searchFlight(search: Object) {
    search['startDate'] = formatDate(
      search['startDate'],
      'yyyy-MM-dd',
      locale(this.language)
    ).toString();

    if (search['endDate']) {
      search['endDate'] = formatDate(
        search['endDate'],
        'yyyy-MM-dd',
        locale(this.language)
      ).toString();
    } else {
      search['endDate'] = formatDate(
        search['startDate'],
        'yyyy-MM-dd',
        locale(this.language)
      ).toString();
    }

    if (search['tramo'] === 'idaVuelta') {
      this.dataService.flightAvailabilityRequest = {
        journey: [{
          departureAirportCity: search['departureAirportCity'],
          arrivalAirportCity: search['arrivalAirportCity'],
          flightDate: search['startDate']
        },
        {
          departureAirportCity: search['arrivalAirportCity'],
          arrivalAirportCity: search['departureAirportCity'],
          flightDate: search['endDate']
        }],
        passengers: [{ type: 'ADT', count: search['qAdultos'] },
        { type: 'CHD', count: search['qNinos'] },
        { type: 'INF', count: search['qBebes'] }],
        cabinClasses: [],
        targetCurrency: this.dataStoreService.brandTargetCurrency,
        language: this.languageService.systemLanguajeChange.value.toUpperCase()
      };

    } else {
      this.dataService.flightAvailabilityRequest = {
        journey: [{
          departureAirportCity: search['departureAirportCity'],
          arrivalAirportCity: search['arrivalAirportCity'],
          flightDate: search['startDate']
        },
        ],
        passengers: [{ type: 'ADT', count: search['qAdultos'] },
        { type: 'CHD', count: search['qNinos'] },
        { type: 'INF', count: search['qBebes'] }],
        cabinClasses: [],
        targetCurrency: this.dataStoreService.brandTargetCurrency,
        language: this.languageService.systemLanguajeChange.value.toUpperCase()
      };
    }
    // valores que fueron buscados
    this.searchedService.setModel(<SearchedFlight>{
      origin: {
        iata: search['departureAirportCity'],
        id: undefined,
        name: search['departureAirportCityText'],
        type: undefined
      },
      destination: {
        iata: search['arrivalAirportCity'],
        id: undefined,
        name: search['arrivalAirportCityText'],
        type: undefined
      },
      journey: ((this.dataService.flightAvailabilityRequest.journey.length === 1) ? 'oneWay' : 'idaVuelta'),
      date: {
        start: search['startDate'],
        end: search['endDate']
      },
      quantity: {
        adults: +search['qAdultos'],
        ninos: +search['qNinos'],
        babes: +search['qBebes']
      },
      cabinClass: {
        economy: true,
        economyPremium: true,
        executive: true,
        first: true,
        combinada: true
      }
    });

    this.dataService.productSelected = EProduct.FLIGHTS;
    this.dataStoreService.preloaderImg = this.dataService.productSelected;
    this.dataService.saveFlightRequest(this.dataService.flightAvailabilityRequest);
    this.router.navigate(['flights/results']);
  }

  private searchHotels(search: Object) {
    const checkInText = formatDate(
      search['checkIn'],
      this.translations['DATE']['format_weekday'],
      locale(this.languageService.systemLanguajeChange.value)
    ).toLocaleString();

    const checkOutText = formatDate(
      search['checkOut'],
      this.translations['DATE']['format_weekday'],
      locale(this.languageService.systemLanguajeChange.value)
    ).toLocaleString();

    const countryResidance = this.dataService.smartProfile && this.dataService.smartProfile.accountResultDto
      ? this.dataService.smartProfile.accountResultDto.accountSumaryDto.addtionalInformationDto.isoCountry : 'AR';

    const requestInfo = {
      'checkIn': search['checkIn'],
      'checkOut': search['checkOut'],
      'destinationCode': search['destinyCode'],
      'countryOfResidence': countryResidance,
      'roomDistribution': search['qPasangersB'],
      'targetCurrency': this.dataStoreService.brandTargetCurrency,
      'language': this.languageService.systemLanguajeChange.value.toUpperCase()
    };

    // valores que fueron buscados
    this.searchedService.setModel(<SearchedHotel>{
      destination: {
        iata: search['destinyIata'],
        id: search['destinyCode'],
        name: search['destinyName'],
        type: undefined
      },
      date: {
        start: search['checkIn'],
        end: search['checkOut']
      },
      roomsPaxes: <IRoomPaxes[]>search['qPasangersB']
    });

    this.dataService.saveRequestHotel(requestInfo);
    this.dataStoreService.hotelDataSearch = requestInfo;

    let searchParams_totalNights = '';
    if (+search['totalNights'] === 1) {
      searchParams_totalNights = search['totalNights'] + ' ' + this.translations['SEARCH_HOTELS']['text_day'];
    } else {
      searchParams_totalNights = search['totalNights'] + ' ' + this.translations['SEARCH_HOTELS']['text_days'];
    }

    this.dataStoreService.hotelInfoParams = {
      originDestinyIata: search['destinyName'],
      departureArrivalDates: 'Check in ' + checkInText + ' - ' + ' Check out ' + checkOutText,
      qPasangers: search['qPasangersB'],
      qNights: searchParams_totalNights,
      citiName: search['destinyName']
    };

    this.dataService.productSelected = EProduct.HOTELS;
    this.dataStoreService.preloaderImg = this.dataService.productSelected;
    this.dataService.saveHotelParamsInfo(this.dataStoreService.hotelInfoParams);
    this.router.navigate(['hotels/results']);
  }

  searchCar(search: Object) {
    const countryResidance = this.dataService.smartProfile && this.dataService.smartProfile.accountResultDto
      ? this.dataService.smartProfile.accountResultDto.accountSumaryDto.addtionalInformationDto.isoCountry : 'AR';

    const requestInfo = {
      'start': search['start'],
      'end': search['end'],
      'hour': search['hour'],
      'hourEnd': search['hourEnd'],
      'originZone': search['originZone'],
      'destinationZone': search['destinationZone'],
      'countryOfResidence': countryResidance,
      'ageDriver': search['ageDriver'],
      'targetCurrency': this.dataStoreService.brandTargetCurrency
    };

    // valores que fueron buscados
    this.searchedService.setModel(<SearchedCar>{
      withdrawal: {
        id: search['pickUp'],
        name: search['pickUpName']
      },
      return: {
        id: search['dropOff'],
        name: search['dropOffName']
      },
      date: {
        start: search['start'],
        end: search['end']
      },
      time: {
        start: search['hour'],
        end: search['hourEnd']
      },
      driverAge: search['driverAge']
    });

    this.dataStoreService.journeyParamsInfo = {
      citiName: 'De ' + search['pickUpName'] + ' a ' + search['dropOffName']
    };
    this.dataService.saveJourneyParamsInfo(this.dataStoreService.journeyParamsInfo);

    // información formateada para mostrar en el detalle
    const detalleDeBusqueda = <ICarInfo>{
      lugarRetiroDevolucionIguales: null,
      retiro: {
        iata: null,
        name: null,
        fecha: null,
        hora: null
      },
      devolucion: {
        iata: null,
        name: null,
        fecha: null,
        hora: null
      },
      edadConductor: null
    };

    // lugar de Retiro y Devolución
    let lugarRetiroDevolucion: string;
    if (search['originZone'] === search['destinationZone']) {
      detalleDeBusqueda.lugarRetiroDevolucionIguales = true;
      detalleDeBusqueda.retiro.iata = search['pickUpIata'];
      detalleDeBusqueda.retiro.name = search['pickUpName'];
      detalleDeBusqueda.devolucion.iata = search['dropOffIata'];
      detalleDeBusqueda.devolucion.name = search['dropOffName'];


      // retira y devuelve en el mismo lugar
      lugarRetiroDevolucion = this.textWithdrawalReturnIn + ' ' + search['pickUpIata'];
    } else {
      detalleDeBusqueda.lugarRetiroDevolucionIguales = false;
      detalleDeBusqueda.retiro.iata = search['pickUpIata'];
      detalleDeBusqueda.retiro.name = search['pickUpName'];
      detalleDeBusqueda.devolucion.iata = search['dropOffIata'];
      detalleDeBusqueda.devolucion.name = search['dropOffName'];

      // retira en un lugar y devuelve en un lugar diferente
      lugarRetiroDevolucion = this.textWithdrawalIn + ' ' + search['pickUpIata'];
      lugarRetiroDevolucion += ' / ';
      lugarRetiroDevolucion += this.textReturnIn + ' ' + search['dropOffIata'];
    }

    detalleDeBusqueda.retiro.fecha = moment(search['start'], ['YYYY-MM-DD']).format('DD/MM/YYYY');
    detalleDeBusqueda.retiro.hora = moment(search['hour'], ['HH:mm:ss']).format('HH:mm');
    detalleDeBusqueda.devolucion.fecha = moment(search['end'], ['YYYY-MM-DD']).format('DD/MM/YYYY');
    detalleDeBusqueda.devolucion.hora = moment(search['hourEnd'], ['HH:mm:ss']).format('HH:mm');

    // FechaHora de alquiler y FechaHora de devolución
    let fechaAlquilerDevolucion: string;
    fechaAlquilerDevolucion = this.textRental;
    fechaAlquilerDevolucion += ' ' + formatDate(
      search['start'],
      this.translations['DATE']['format_weekday'],
      locale(this.languageService.systemLanguajeChange.value)
    ).toLocaleString();
    fechaAlquilerDevolucion += ' ' + moment(search['hour'], ['HH:mm:ss']).format('HH:mm') + ' hs';
    fechaAlquilerDevolucion += ' - ';
    fechaAlquilerDevolucion += this.textReturnDate;
    fechaAlquilerDevolucion += ' ' + formatDate(
      search['end'],
      this.translations['DATE']['format_weekday'],
      locale(this.languageService.systemLanguajeChange.value)
    ).toLocaleString();
    fechaAlquilerDevolucion += ' ' + moment(search['hourEnd'], ['HH:mm:ss']).format('HH:mm') + ' hs';

    // traducimos la información de la búsqueda a un IJourneyInfo
    // que es lo que acepta el app-main-form
    this.dataStoreService.carInfoParams = Object.assign({ detalle: detalleDeBusqueda }, {
      originDestinyIata: lugarRetiroDevolucion,
      departureArrivalDates: fechaAlquilerDevolucion
    });
    this.dataService.productSelected = EProduct.CARS;
    this.dataStoreService.preloaderImg = this.dataService.productSelected;

    this.dataService.saveCarParamsInfo(this.dataStoreService.carInfoParams);
    this.dataService.saveRequestCars(requestInfo);
    this.dataStoreService.carDataSearch = requestInfo;
    this.router.navigate(['cars/results']);
  }

  searchActivities(search: Object) {
    search['startDate'] = formatDate(
      search['startDate'],
      'yyyy-MM-dd',
      locale(this.language)
    ).toString();

    search['endDate'] = formatDate(
      search['endDate'],
      'yyyy-MM-dd',
      locale(this.language)
    ).toString();

    // const desdeDateString = (<moment.Moment>(search['startDate'])).format('YYYY-MM-DD');
    // const hastaDateString = (<moment.Moment>(search['endDate'])).format('YYYY-MM-DD');
    const requestParameters = {
      destinationZone: search['destinationZone'],
      startDate: search['startDate'],
      endDate: search['endDate'],
      roomDistribution: [{}],
      language: (<string>this.languageService.systemLanguajeChange.value).toUpperCase(),
      targetCurrency: this.dataStoreService.brandTargetCurrency
    } as IActivityRequestParameters;

    // formatear paxes
    requestParameters.roomDistribution[0]['paxes'] = search['qPasangersB'];
    // .value.map((paxe: any, index) => {
    //   return {
    //     id: ((+index) + 1),
    //     age: +paxe.age
    //   };
    // });

    // valores que fueron buscados
    this.searchedService.setModel(<SearchedActivity>{
      destination: search['destinationZone'],
      date: {
        start: search['startDate'],
        end: search['endDate']
      },
      paxes: search['qPasangersB']
    });
    this.dataService.productSelected = EProduct.ACTIVITIES;
    this.dataStoreService.preloaderImg = this.dataService.productSelected;

    this.dataService.activityRequestParameters = requestParameters;
    this.router.navigate(['/activities/results']);

  }

  openRegister() {
    this.dialog.open(LoginComponent, {
      panelClass: 'login-dialog',
      data: {
        openRegister: true
      }
    });
  }

  chnageLoadIframeByService(item) {
    const urlJuniper = this.store.getJustOneUniversalPropertie(keysUniversal.URL_TRAVEL_JUNIPER);
    this.selectedTab = item;
    switch (item) {
      case typeService.flights: {
        this.actionEventService.setItemActiveTab(EProduct.FLIGHTS);
        this.dataService.productSelected = EProduct.FLIGHTS;
        this.dataStoreService.preloaderImg = EProduct.FLIGHTS;

        let urlToSend = `${urlJuniper}flights/?idioma=${this.translate.currentLang}`;
        this.actionEventService.setChangeUrlJuniper(urlToSend, false);

        this.objActivatesTabs.activateFligths = true;
        this.objActivatesTabs.activateHotels = false;
        this.objActivatesTabs.activateCars = false;
        this.objActivatesTabs.activateHouseVacations = false;

        break;
      }
      case typeService.hotels: {
        this.actionEventService.setItemActiveTab(EProduct.HOTELS);
        this.dataService.productSelected = EProduct.HOTELS;
        this.dataStoreService.preloaderImg = EProduct.HOTELS;


        let urlToSend = `${urlJuniper}hotels/?idioma=${this.translate.currentLang}`;
        this.actionEventService.setChangeUrlJuniper(urlToSend, false);

        this.objActivatesTabs.activateFligths = false;
        this.objActivatesTabs.activateHotels = true;
        this.objActivatesTabs.activateCars = false;
        this.objActivatesTabs.activateHouseVacations = false;

        break;
      }
      case typeService.vacationHomes: {
        if (this.brand != 'credomatic' && this.brand != 'scotia') {
          this.actionEventService.setItemActiveTab(EProduct.HOMEVACATIONS);
          this.dataService.productSelected = EProduct.HOMEVACATIONS;
          this.dataStoreService.preloaderImg = EProduct.HOMEVACATIONS;
        } else {
          this.actionEventService.setItemActiveTab(EProduct.TRAVEL);
          this.dataService.productSelected = EProduct.TRAVEL;
          this.dataStoreService.preloaderImg = EProduct.TRAVEL;
        }

        let urlToSend = `${urlJuniper}homes/homes/homes.aspx?idioma=${this.translate.currentLang}`;
        this.actionEventService.setChangeUrlJuniper(urlToSend, false);

        this.objActivatesTabs.activateFligths = false;
        this.objActivatesTabs.activateHotels = false;
        this.objActivatesTabs.activateCars = false;
        this.objActivatesTabs.activateHouseVacations = true;
        break;
      }
      case typeService.cars: {
        this.actionEventService.setItemActiveTab(EProduct.CARS);
        this.dataService.productSelected = EProduct.CARS;
        this.dataStoreService.preloaderImg = EProduct.CARS;

        let urlToSend = `${urlJuniper}cars/?idioma=${this.translate.currentLang}`;
        this.actionEventService.setChangeUrlJuniper(urlToSend, false);

        this.objActivatesTabs.activateFligths = false;
        this.objActivatesTabs.activateHotels = false;
        this.objActivatesTabs.activateCars = true;
        this.objActivatesTabs.activateHouseVacations = false;

        break;
      }
      default: {
        this.actionEventService.setItemActiveTab(EProduct.FLIGHTS);
        this.dataService.productSelected = EProduct.FLIGHTS;
        this.dataStoreService.preloaderImg = EProduct.FLIGHTS;

        let urlToSend = `${urlJuniper}flights/?idioma=${this.translate.currentLang}`;
        this.actionEventService.setChangeUrlJuniper(urlToSend, false);

        this.objActivatesTabs.activateFligths = true;
        this.objActivatesTabs.activateHotels = false;
        this.objActivatesTabs.activateCars = false;
        this.objActivatesTabs.activateHouseVacations = false;

        break;
      }
    }
  }

  changeLoadIframe(item) {
    const urlJuniper = this.store.getJustOneUniversalPropertie(keysUniversal.URL_TRAVEL_JUNIPER);
    this.selectedTab = item;
    switch (item) {
      case typeService.flights: {
        this.router.navigateByUrl('travel/flights');
        let urlToSend = `${urlJuniper}flights/?idioma=${this.translate.currentLang}`;
        this.actionEventService.setChangeUrlJuniper(urlToSend, false);

        if (this.dataStoreService.brand === 'credomatic') {
          this.actionEventService.setItemActiveTab(EProduct.FLIGHTS);
          this.dataService.productSelected = EProduct.FLIGHTS;
          this.dataStoreService.preloaderImg = EProduct.FLIGHTS;
        } else {
          this.actionEventService.setItemActiveTab(EProduct.TRAVEL);
          this.dataService.productSelected = EProduct.TRAVEL;
          this.dataStoreService.preloaderImg = EProduct.TRAVEL;
        }

        break;
      }
      case typeService.hotels: {
        this.router.navigateByUrl('travel/hotels');
        let urlToSend = `${urlJuniper}hotels/?idioma=${this.translate.currentLang}`;
        this.actionEventService.setChangeUrlJuniper(urlToSend, false);

        this.objActivatesTabs.activateFligths = false;
        this.objActivatesTabs.activateHotels = true;
        this.objActivatesTabs.activateCars = false;
        this.objActivatesTabs.activateHouseVacations = false;

        if (this.dataStoreService.brand === 'credomatic') {
          this.actionEventService.setItemActiveTab(EProduct.HOTELS);
          this.dataService.productSelected = EProduct.HOTELS;
          this.dataStoreService.preloaderImg = EProduct.HOTELS;
        } else {
          this.actionEventService.setItemActiveTab(EProduct.TRAVEL);
          this.dataService.productSelected = EProduct.TRAVEL;
          this.dataStoreService.preloaderImg = EProduct.TRAVEL;
        }

        break;
      }
      case typeService.vacationHomes: {
        this.router.navigateByUrl('travel/vacationHomes');
        let urlToSend = `${urlJuniper}homes/homes/homes.aspx?idioma=${this.translate.currentLang}`;
        this.actionEventService.setChangeUrlJuniper(urlToSend, false);

        this.objActivatesTabs.activateFligths = false;
        this.objActivatesTabs.activateHotels = false;
        this.objActivatesTabs.activateCars = false;
        this.objActivatesTabs.activateHouseVacations = true;

        if (this.dataStoreService.brand != 'credomatic' && this.dataStoreService.brand != 'scotia') {
          this.actionEventService.setItemActiveTab(EProduct.HOMEVACATIONS);
          this.dataService.productSelected = EProduct.HOMEVACATIONS;
          this.dataStoreService.preloaderImg = EProduct.HOMEVACATIONS;
        } else {
          this.actionEventService.setItemActiveTab(EProduct.TRAVEL);
          this.dataService.productSelected = EProduct.TRAVEL;
          this.dataStoreService.preloaderImg = EProduct.TRAVEL;
        }

        break;
      }
      case typeService.cars: {
        this.router.navigateByUrl('travel/cars');
        let urlToSend = `${urlJuniper}cars/?idioma=${this.translate.currentLang}`;
        this.actionEventService.setChangeUrlJuniper(urlToSend, false);

        this.objActivatesTabs.activateFligths = false;
        this.objActivatesTabs.activateHotels = false;
        this.objActivatesTabs.activateCars = true;
        this.objActivatesTabs.activateHouseVacations = false;

        if (this.dataStoreService.brand === 'credomatic') {
          this.actionEventService.setItemActiveTab(EProduct.CARS);
          this.dataService.productSelected = EProduct.CARS;
          this.dataStoreService.preloaderImg = EProduct.CARS;
        } else {
          this.actionEventService.setItemActiveTab(EProduct.TRAVEL);
          this.dataService.productSelected = EProduct.TRAVEL;
          this.dataStoreService.preloaderImg = EProduct.TRAVEL;
        }

        break;
      }
      default:
        break;
    }
  }

  loadDefaultItem() {
    if (this.dataStoreService.brand === 'credomatic') {
      this.actionEventService.setItemActiveTab(EProduct.FLIGHTS);
      this.dataService.productSelected = EProduct.FLIGHTS;
      this.dataStoreService.preloaderImg = EProduct.FLIGHTS;
    } else {
      this.actionEventService.setItemActiveTab(EProduct.TRAVEL);
      this.dataService.productSelected = EProduct.TRAVEL;
      this.dataStoreService.preloaderImg = EProduct.TRAVEL;
    }

    const urlJuniper = this.store.getJustOneUniversalPropertie(keysUniversal.URL_TRAVEL_JUNIPER);
    let urlToSend = `${urlJuniper}flights/?idioma=${this.translate.currentLang}`;

    this.actionEventService.setChangeUrlJuniper(urlToSend, false);

    this.objActivatesTabs.activateFligths = true;
    this.objActivatesTabs.activateHotels = false;
    this.objActivatesTabs.activateCars = false;
    this.objActivatesTabs.activateHouseVacations = false;
  }

  activateTabs() {
    this.actionEventService.activateMenuItems.subscribe((objActivates: any) => {
      this.objActivatesTabs = objActivates;
    });
  }

  ngOnDestroy() {
    this.sbs.unsubscribe();
  }
}
