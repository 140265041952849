import { Component, OnInit } from '@angular/core';
import {CardComponent} from '../../card.component';
import {Images} from '../../model/images';

@Component({
  selector: 'app-card-image',
  templateUrl: './card-image.component.html',
  styleUrls: ['./card-image.component.scss']
})
export class CardImageComponent implements OnInit {

  image: Images = {
    imgURL: './assets/images/empty_room.png',
    label: ''
  };
  isHidden = true;

  constructor(private cardComponent: CardComponent) {
  }

  ngOnInit() {
  }
  imgToUse() {
    const img = this.cardComponent.product.images;
    if (img !== undefined && img !== null && img[0] !== undefined) {
      return img[0];
    } else {
      return this.image;
    }
  }
  onErrorFn() {
    this.cardComponent.product.images[0] = this.image;
    this.isHidden = false;
  }

}
