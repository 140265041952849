import { CardLayoutTwoComponent } from './../card-layout-two.component';
import { Component, OnInit } from '@angular/core';
import { CardLayoutTwoModel } from '../../../models/card-layout-two.model';
import { EProduct } from '../../../../searched/models/searched.model';

@Component({
  selector: 'app-card-image',
  templateUrl: './card-image.component.html',
  styleUrls: ['./card-image.component.scss']
})
export class CardImageComponent implements OnInit {

  product: CardLayoutTwoModel;
  constructor(private layout: CardLayoutTwoComponent) { }
  isHidden = true;

  ngOnInit() {
    this.product = this.layout.product;
    if (!this.product.images || this.product.images === null || this.product.images.length === 0) {
      this.product.images = [];
      if (this.layout.options.type === EProduct.CARS) {
        this.product.images[0] = {
          imgURL: '/assets/images/empty_car.png',
          label: 'default'
        };
      } else if (this.layout.options.type === EProduct.HOTELS) {
        this.product.images[0] = {
          imgURL: '/assets/images/empty_room.png',
          label: 'default'
        };
      } else {
        this.product.images[0] = {
          imgURL: '/assets/images/empty_activities.png',
          label: 'default'
        };
      }
      this.isHidden = !this.layout.options.isViewDetail;
    }
  }

  get image() {
    try {
      return this.product.images[0];
    } catch (e) {
      this.isHidden = false;
      return {
        imgURL: '/assets/images/empty_room.png',
        label: 'default'
      };
    }
  }

  onErrorFn() {
    this.isHidden = false;
    if (this.product.images && this.product.images.length > 1) {
      // Elimina el elemento dañado del array
      this.product.images = this.product.images.slice(1, this.product.images.length);
    } else {
      // Agrega la imagen por defecto
      this.product.images[0] = {
        imgURL: '/assets/images/empty_room.png',
        label: 'default'
      };
    }
  }

}
