<section class="content-slider container-fluid" *ngIf="!isBACSlider">
    <swiper class="swiper-container" fxFlex="auto" [config]="config" [disabled]="disabled">

        <figure class="item swiper-slide" *ngFor="let slider of sliders ; let i = index" [ngClass]="{'cursor-pointer': slider['action']}">
            <div class="bl-slide">
                <div class="texto-slider">
                    <span class="titular" *ngIf="slider.text.tittle" [innerHTML]="slider.text.tittle|translate"></span>
                    <p class="lead" *ngIf="slider.text.description" [innerHTML]="slider.text.description|translate"></p>
                    <p class="lead" *ngIf="slider.text.footer" [innerHTML]="slider.text.footer|translate"></p>


                    <!-- MODULE APAP SLIDE-->
                    <!-- <div class="bl__items_slide_apap">
                        <span>Ahora todas tus reservas incluyen beneficios únicos.</span>
                        <ul>
                            <li class="item-flex"><span>Viaja flex</span>Modifica tus reservas sin costo.</li>
                            <li class="item-protegido"><span>Viaja protegido</span>Tus reservas incluyen asistencia médica y protección COVID-19.</li>
                        </ul>
                    </div> -->


                    <button *ngIf="slider['action']" class="what" (click)="openWindow(slider)">{{ slider.text.textWhat | translate }} <img alt=""
            src="../../../../assets/images/whatsapp-logo.png"></button>

                </div>
            </div>
            <img [src]="slider.image" />
            <div id="counter" class="slider-counter">{{i+1}} </div>
        </figure>

    </swiper>
</section>

<section class="content-slider container-fluid bac-slider" *ngIf="isBACSlider">
    <swiper class="swiper-container" fxFlex="auto" [config]="config" [disabled]="disabled">
        <figure class="item swiper-slide" *ngFor="let slider of sliders ; let i = index" [ngClass]="{'cursor-pointer': slider['action']}">
            <img [src]="slider.image" *ngIf="slider['action']" (click)="openWindow(slider)"/>
            <img [src]="slider.image" *ngIf="!slider['action']" />
            <div id="counter" class="slider-counter">{{i+1}} </div>
        </figure>
    </swiper>
</section>