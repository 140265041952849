import { DataStoreService } from 'src/app/services/data-store.service';
import { Injectable } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  porcentajeConversion = 100;
  constructor(
    public dataService: DataService,
    public dataStore: DataStoreService,
  ) { }


  updateActivityOptionsPrice(activityOptions, conversionRate?, percentage?, puntosUsuarios?) {
    conversionRate = conversionRate || this.dataService.conversionRate;
    percentage = percentage || this.dataStore.porcentajeConversion;
    puntosUsuarios = puntosUsuarios || this.dataService.userPointsSP;
    activityOptions.forEach((option) => {
      option.selectedDate = option.dates[0];
      option.dates.forEach(item => {
        item.price = this.updateActivityOptionPrice(item.price, conversionRate, percentage, puntosUsuarios)
      });
    });
    return activityOptions;
  }

  updateActivityOptionPrice(price, conversionRate?, percentage?, puntosUsuarios?) {
    conversionRate = conversionRate || this.dataService.conversionRate;
    percentage = percentage || this.dataStore.porcentajeConversion;
    puntosUsuarios = puntosUsuarios || this.dataService.userPointsSP;

    const precioProducto = +price.totalPrice;
    const precioMillasProducto = (precioProducto / +conversionRate);
    const millasAdicionalNecesarias = precioMillasProducto - +puntosUsuarios;

    let puntosPagar = 0;
    let millasSobrantes = 0;
    if (millasAdicionalNecesarias >= 0) {
      puntosPagar = (millasAdicionalNecesarias === 0) ? (precioMillasProducto * percentage) / 100 : (puntosUsuarios * percentage) / 100;
    } else {
      // const maxDineroDisponible = puntosUsuarios / conversionRate;
      millasSobrantes = puntosUsuarios - precioMillasProducto;
      puntosPagar = (precioMillasProducto * percentage) / 100;
    }

    let dineroPagar = (precioProducto - Math.ceil(puntosPagar * conversionRate));
    if (dineroPagar < 0) {
      dineroPagar = 0;
    }

    price.pointsPrice = Math.ceil(puntosPagar).toString();
    price.calculatedPrice = dineroPagar.toFixed(2);
    price.restPointValue = Math.ceil(millasSobrantes).toString();

    price.pointsValue = (Math.ceil(puntosPagar)).toString();
    price.restMoneyValue = dineroPagar.toString();
    price.restPointValue = (Math.ceil(millasSobrantes)).toString();

    return price;
  }
}
