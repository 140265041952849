import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { DataStoreService } from '../../services/data-store.service';
declare var androidProxy: any;
declare var window: any;
@Component({
  selector: 'app-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss']
})
export class WarningModalComponent implements OnInit {

  error = '';
  tittle = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _storeLibrary: StoreServiceLibrary,
    private dataStore: DataStoreService,
    private dialogRef: MatDialogRef<WarningModalComponent>) {
    this.error = data.error;
    this.tittle = data.tittle;
  }

  ngOnInit() {

  }

  close() {
    this.dialogRef.close();
  }
}
