<section [class]="classSearchContent">
    <iframe allow="clipboard-read; clipboard-write" id="iframe_onepocket" [src]="urlSearchIframe | srcSanitizer" *ngIf="useSearchIframe" class="iframe-content" [ngStyle]="{height: iframeScrollHeight}" [style.visibility]="hidenIframeTravel ? 'hidden' : 'visible'"> </iframe>

    <div class=" menu-buscardor container tabset" *ngIf="!useSearchIframe">
        <div class="menu-search" [ngClass]="{'showOnTop':ocultadorService.ocultar}">
            <button aria-controls="vuelos" (click)="tabSelected(EProduct.FLIGHTS)" [ngClass]="{active: tabSelect === EProduct.FLIGHTS}" class="vuelos">
          <span class="back"></span>
          <span innerHTML="{{'SEARCH.flights'|translate}}">
            Flights
          </span>
        </button>
            <button aria-controls="hoteles" (click)="tabSelected(EProduct.HOTELS)" [ngClass]="{active: tabSelect === EProduct.HOTELS}" class="hoteles">
          <span innerHTML="{{'SEARCH.hotels'|translate}}">
            Hotels
          </span>
        </button>
            <button aria-controls="autos" (click)="tabSelected(EProduct.CARS)" [ngClass]="{active: tabSelect === EProduct.CARS}" class="autos">
          <span innerHTML="{{'SEARCH.cars'|translate}}">
            Cars
          </span>
        </button>
            <button aria-controls="actividades" *ngIf="hasActivities" (click)="tabSelected(EProduct.ACTIVITIES)" [ngClass]="{active: tabSelect === EProduct.ACTIVITIES}" class="actividades">
          <span innerHTML="{{'SEARCH.activities'|translate}}">
            Activities
          </span>
        </button>
            <button aria-controls="beneficios" *ngIf="hasBenefits" (click)="tabSelected(EProduct.BENEFITS)" [ngClass]="{active: tabSelect === EProduct.BENEFITS}" class="beneficios">
          <span innerHTML="{{'SEARCH.benefits'|translate}}">
            Benefits
          </span>
        </button>
        </div>

        <div class="tab-panels">
            <app-search-flight *ngIf="tabSelect === EProduct.FLIGHTS" [benefits]="false">
            </app-search-flight>
            <app-search-hotel *ngIf="tabSelect === EProduct.HOTELS">
            </app-search-hotel>
            <app-search-cars *ngIf="tabSelect === EProduct.CARS"></app-search-cars>
            <app-search-activities *ngIf="tabSelect === EProduct.ACTIVITIES">
            </app-search-activities>
            <app-search-benefits *ngIf="tabSelect === EProduct.BENEFITS">
            </app-search-benefits>
        </div>
    </div>
</section>