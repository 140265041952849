import { Component, OnInit } from '@angular/core';
import {AbstractCardListComponent} from '../../abstract-card-list.component';

@Component({
  selector: 'app-abstract-card-body',
  templateUrl: './abstract-card-body.component.html',
  styleUrls: ['./abstract-card-body.component.scss']
})
export class AbstractCardBodyComponent implements OnInit {
  options: any;
  constructor(public listCmp: AbstractCardListComponent) { }

  ngOnInit() {
    this.options = this.listCmp.options;
  }

}
