import { DataStoreService } from './data-store.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import {
  UniversalService,
  CountryDto,
  CityDto
} from '@wearenovae/novae-core-services';

@Injectable({
  providedIn: 'root'
})
export class RegistryLocationService {

  universalUrlBase = environment.brands[this.dataStore.brand].novaeUniversalUrlBase;
  user = 'UNIVERSAL_APP_ID';
  pass = 'b^BJCYCA;wA2w[Y#';
  idApplication = 20;
  inputCountry: CountryDto = { name: '', iso: '' };

  // Listas
  countries: CountryDto[] = [];
  cities: CityDto[] = [];

  universalJwtUrl: string;
  universalJwtUN: string;
  universalJwtPW: string;
  useJwtToken: boolean;

  constructor(
    private universalService: UniversalService,
    private dataStore: DataStoreService,
    private dataService: DataService
  ) {
    this.universalJwtUrl = environment.brands[this.dataStore.brand].UNIVERSAL_GET_JWTTOKEN_URL || '';
    this.universalJwtUN = environment.brands[this.dataStore.brand].UNIVERSAL_GET_JWTTOKEN_UN || '';
    this.universalJwtPW = environment.brands[this.dataStore.brand].UNIVERSAL_GET_JWTTOKEN_PW || '';
    this.useJwtToken = this.dataService.getJwtUse();
  }

  async getCountries() {
    try {
      const res = await this.universalService.getCountries(this.universalUrlBase).toPromise();
      this.countries = res.countryDtoList;
      this.dataStore.countryList = res.countryDtoList;
    } catch (error) {
      
    }
  }

  // ANTES DE EJECUTAR ESTE METODO SE DEBE SELECCIOAR UN PAIS Y ENVIARLO AL METODO "getCityByCountryId"
  async getCities(Country) {

    if (Country) {
      this.cities = await this.universalService.getCityByCountryId(Country, this.universalUrlBase, atob(this.universalJwtUrl),
        atob(this.universalJwtUN),
        atob(this.universalJwtPW),
        this.useJwtToken).toPromise()
        .then((res) => {
          return res.cityDtoList;
        })
        .catch((error) => {
          return [];
        });

    } else {

    }
  }


}
