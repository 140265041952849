import { Component, HostListener, AfterContentChecked, ViewChild, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { LoginService } from 'src/app/container/shared/login/login.service';
import { LoginComponent } from 'src/app/container/shared/login/login.component';
import { DataService } from 'src/app/services/data.service';
import { AuthGetAccount2Response, StoreServiceLibrary, ScotiaImplementationService } from '@wearenovae/novae-core-services';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { trigger, style, transition, animate } from '@angular/animations';
import { LanguageService } from 'src/app/component/language/language.service';
import { LanguagePrefix } from '../language/language.model';
import { ActionEventService } from '../../services/action-event.service';
import { InitService } from 'src/app/services/init.service';
import { environment } from 'src/environments/environment';
import { DataStoreService } from 'src/app/services/data-store.service';
import data from '../../resources/screenAndEventsTracking.json';
import { EProduct } from '../searched/models/searched.model';
import { AlertErrorComponent } from '../product-detail/alert-error/alert-error.component';
import { GenerateService } from '../../services/generate.service';
import { keysUniversal } from 'src/app/resources/keys.resources';
import { GoogleAnalytics } from '../../resources/GoogleAnalytics.resource';

enum EMode {
  LARGE = 'LARGE',
  HAMBURGER = 'HAMBURGER'
}

@Component({
  selector: 'app-hamburger',
  templateUrl: './hamburger.component.html',
  styleUrls: ['./hamburger.component.scss'],
  animations: [
    trigger('animation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('150ms cubic-bezier(0.36, 0.1, 0.32, 1)')
      ])
    ])
  ],
})
export class HamburgerComponent implements OnInit, AfterContentChecked, AfterViewInit {
  public isNewMenu = false;
  appName = '';
  EProduct = EProduct;
  loadPointsOrMiles = '';
  public fixed: boolean;

  EMode = EMode;

  @ViewChild('menu') menu;

  /**
   * true cunado el menú está levantado
   */
  isMenuOpen = false;

  /**
 * true cunado el menú está levantado
 */
  isMenuOpencountry = false;

  /**
   * selector de lenguaje
   */
  islanguageSelectOpened = false;


  /**
   * selector del pais
   */
  isCountrySelectOpened = false;

  public hasAbout = false;
  optionMenu: any;

  hasTwoLanguages: any;
  hasLoadCountries: any;

  countryClass: string = 'country-CRI';

  countrysClass = 'country'

  countries = [];

  countryDefault = 'CRI';

  countryName: string;
  interval: any;
  useJwtToken: boolean;

  /**
   * retorna el modo en el que se muestran los botones
   */
  get mode(): EMode {
    if (this.windowWidth > 991) {
      return EMode.LARGE;
    } else {
      return EMode.HAMBURGER;
    }
  }

  /**
   * obtener y almacenar el width del window
   * ademas mantiene actualizado el mode del componente
   */
  private _windowWidth: number;
  private get windowWidth(): number {
    return this._windowWidth;
  }
  private set windowWidth(v: number) {
    this._windowWidth = v;
  }

  get isLogged() {
    return this.loginService.isLogged();
  }

  profile: AuthGetAccount2Response;
  dataUser: any;
  hideCountrys = false;

  get firstName(): string {
    if (this.profile && this.profile.accountResultDto && this.profile.accountResultDto.accountSumaryDto && this.profile.accountResultDto.accountSumaryDto.firstName) {
      return this.profile.accountResultDto.accountSumaryDto.firstName;
    }
    return '';
  }

  get balance(): string {
    this.profile = this.dataService.smartProfile;
    if (this.profile && this.profile.accountResultDto) {
      return this.profile.accountResultDto.balance;
    }
    return '';
  }

  get balanceUser(): string {
    const valuePointsCard = this.storeLibrary.getCardScotiaPointsSelected();
    if (valuePointsCard) {
      if (typeof valuePointsCard == 'string') {
        const jsonPointsCard = JSON.parse(valuePointsCard);
        return jsonPointsCard['quantityPoints'];
      } else {
        return valuePointsCard['quantityPoints'];
      }
    }
    return '';
  }

  get firstNameUser(): string {
    let userData = this.storeLibrary.getUserData();
    if (userData.accountSumaryDto && userData.accountSumaryDto.firstName) {
      return userData.accountSumaryDto.firstName;
    }
    return '';
  }

  get languages() {
    return this.languageService.languageNames$.value;
  }

  get selectedLanguagePrefix(): LanguagePrefix {
    return this.languageService.systemLanguajeChange.value;
  }

  get selectedLanguageName(): string {
    const language = this.languages.find(x => x.prefix === this.selectedLanguagePrefix);
    return language ? language.name : '';
  }

  get selectCountry(): string {
    return this.countryName || this.countries[0].name;
  }

  get tabSelect(): EProduct {
    return this.dataService.productSelected;
  }

  set tabSelect(value: EProduct) {
    this.dataService.productSelected = value;
  }

  get brand(): string {
    return this.dataStore.brand;
  }

  valuePointsCard: string;
  tabSelectData: string;
  // balanceUser: string;

  constructor(
    private loginService: LoginService,
    private dataService: DataService,
    private dialog: MatDialog,
    public router: Router,
    private languageService: LanguageService,
    private actionEventService: ActionEventService,
    private dataStore: DataStoreService,
    private initService: InitService,
    private generateService: GenerateService,
    private storeLibrary: StoreServiceLibrary,
    private scotiaServiceImplementation: ScotiaImplementationService
  ) {
    this.initService.isInitializedBrand.subscribe((isInitialized) => {
      if (isInitialized) {
        let jsonCountries = this.storeLibrary.getJustOneUniversalPropertie(keysUniversal.JSON_COUNTRIES_ALLETRAVEL);
        if (jsonCountries) {
          let castCountries = JSON.parse(jsonCountries);
          this.countries = castCountries['countries'];
        }

        this.actionEventService.changeItemMenu.subscribe((tabSelect) => {
          this.tabSelectData = tabSelect;
        });

      }
    });

    setTimeout(() => {
      this.hasTwoLanguages = environment.brands[this.dataStore.brand].config.hasTwoLanguages;
      this.hasLoadCountries = environment.brands[this.dataStore.brand].config.loadCountries;
    }, 1000);
  }

  ngOnInit() {
    this.useJwtToken = this.dataService.getJwtUse();
    this.appName = environment.brands[this.dataStore.brand].appName;
    this.actionEventService.getDataUserRegisterParams.subscribe((data) => {
      this.dataUser = data;
    });

    this.initService.isInitializedBrand.subscribe(isInitialized => {
      if (isInitialized) {
        if (environment.brands[this.dataStore.brand].config.hasAbout) {
          this.hasAbout = environment.brands[this.dataStore.brand].config.hasAbout;
        }

        if (this.dataStore.brand === 'scotia') {
          this.valuePointsCard = this.storeLibrary.getCardScotiaPointsSelected();
          if (this.valuePointsCard) {
            if (typeof this.valuePointsCard == 'string') {
              const jsonPointsCard = JSON.parse(this.valuePointsCard);
            }
            // this.balanceUser = jsonPointsCard['quantityPoints'];
          } else {
            // this.router.navigateByUrl('home');
            this.dataService.saveCreditCardDefult('');
            this.actionEventService.setSubBrand('default');
            this.actionEventService.setChangeFooter(true);
            this.storeLibrary.clearCardScotiaPointsSelected();
          }
        }
      }
    });
    if (environment.brands[this.dataStore.brand].config.isNewHome) {
      this.isNewMenu = environment.brands[this.dataStore.brand].config.isNewHome;
      this.optionMenu = environment.brands[this.dataStore.brand].optionmenu;
    }

    this.actionEventService.setInfoCard.subscribe((infoUser) => {
      if (infoUser) {
        // this.balanceUser = infoUser['quantityPoints'];
      } else {
        // this.balanceUser = '0';
      }
    });

    this.actionEventService.countryCodeChange.subscribe((countryCodeChange) => {
      if (countryCodeChange && countryCodeChange.classCountry) {
        this.countryClass = countryCodeChange.classCountry;
      }
    });

  }

  /**
   * set mode inicial
   */
  ngAfterContentChecked() {
    this.windowWidth = window.innerWidth;
  }

  ngAfterViewInit() {

    this.actionEventService.getInfoStorageUser.subscribe((save) => {
      if (save) {

        this.valuePointsCard = this.storeLibrary.getCardScotiaPointsSelected();
        if (this.valuePointsCard) {
          const jsonPointsCard = JSON.parse(this.valuePointsCard);
          // this.balanceUser = jsonPointsCard['quantityPoints'];
        }

      }

    });
  }

  /**
   * mantener width del window actualizado
   * cuando hacen resize del window
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = event.target.innerWidth;
  }

  @HostListener('body:scroll', ['$event'])
  onScrollingEvent($event): void {
    let scrollOffset = $event.target.scrollTop;
    if (scrollOffset > 0) {
      this.fixed = true;
    } else {
      this.fixed = false;
    }
  }

  getDataPintsBalceOrMiles() {
  }

  openCountrys() {

  }

  /**
   * menu para ingresar
   */
  openLogin() {

    if (this.dataStore.brand && this.dataStore.brand !== 'scotia') {

      // this.trackingManager.trackEventString(data.MENU.signInUp, data.WELCOME.welcomeScreen);
      GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.MENU.signInUp, '');
      const dialogRef = this.dialog.open(LoginComponent, {
        panelClass: 'login-dialog'
      });

      this.actionEventService.sendDataUserHamburger(this.dataUser);

      dialogRef.afterClosed().subscribe(successful => {
        if (successful) {
          // si ingreso y/o se registró correctamente
          this.profile = this.dataService.smartProfile;
          this.actionEventService.changeLogin(true);
        }
      });
    } else {
      this.warningModal('No se puede realizar la accion');
      if (this.dataService.token) {
      }
    }
  }

  warningModal(message) {
    const heightPadding = this.dataStore.isMobileDevice ? '0px' : '40px';
    const widthPadding = this.dataStore.isMobileDevice ? '0px' : '16vw';
    this.generateService.warningModalShow(message, widthPadding, heightPadding);
  }

  /**
   * show/hide menu
   */
  toggleMenu() {
    // this.trackingManager.trackEventString(data.WELCOME.menu, data.WELCOME.welcomeScreen);
    GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.MENU.menu, '');
    this.isMenuOpen = !this.isMenuOpen;
    if (!this.isMenuOpen) {
      this.islanguageSelectOpened = false;
    }
  }

  /**
 * show/hide menu
 */
  toggleCountryMenu() {
    // this.trackingManager.trackEventString(data.WELCOME.menu, data.WELCOME.welcomeScreen);
    GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.MENU.menu, '');
    this.isMenuOpencountry = !this.isMenuOpencountry;
    if (!this.isMenuOpencountry) {
      this.isCountrySelectOpened = false;
    }
  }

  /**
   * show/hide optiones del selector de idioma
   */
  toggleLanguageSelect(event) {
    // this.trackingManager.trackEventString(data.MENU.languageMenu, data.WELCOME.welcomeScreen);
    GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.MENU.languageMenu, '');
    event.stopPropagation();
    this.islanguageSelectOpened = !this.islanguageSelectOpened;
  }

  /**
 * show/hide optiones del selector de idioma
 */
  toggleCountrySelect(event) {
    event.stopPropagation();
    this.isCountrySelectOpened = !this.isCountrySelectOpened;
  }



  selectLanguage(event, language) {
    // this.trackingManager.trackEventString(data.MENU.languageMenu, data.WELCOME.welcomeScreen, '', language);
    GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.MENU.languageMenu, language);
    event.stopPropagation();
    this.languageService.setSystemLanguaje(language);
  }

  selectedcountry(event, country) {
    event.stopPropagation();
    this.countryName = country.name;
    this.isCountrySelectOpened = false;
    this.actionEventService.sendCountryCode(country);
  }


  aboutMiles() {
    // this.trackingManager.trackEventString(data.MENU.aboutMiles, data.WELCOME.welcomeScreen);
    GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.MENU.aboutMiles, '');
    this.router.navigate(['/about']);
  }

  gotoMyProfile() {
    this.dataService.vaidateOnePocketStay().subscribe((observer) => {
      if (observer === 2) {
        this.continueMyProfile();
      } else if (observer === 1) {
        this.dataService.openModalQuestion().subscribe((isLoad) => {
          if (isLoad) {
            this.continueMyProfile();
          }
        });
      }
    });
  }

  continueMyProfile() {
    // this.trackingManager.trackEventString(data.MENU.myAccount, data.WELCOME.welcomeScreen);
    GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.MENU.myAccount, '');
    this.router.navigate(['myProfile']);
  }

  goToHome() {
    let appNameUpper = this.appName.replace(/\b\w/g, (l) => l.toUpperCase());
    let appNameReplace = appNameUpper.replace(/ /g, "_");
    let finishNameEvent = data.MENU.aboutRewards + appNameReplace + data.MENU.menu;
    // this.trackingManager.trackEventString(finishNameEvent, data.WELCOME.welcomeScreen);
    // this.trackingManager.trackEventString(data.MENU.myAccount , data.WELCOME.welcomeScreen);
    GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, finishNameEvent, '');
    this.router.navigate(['/home']);
  }
  goToTravel() {
    // this.trackingManager.trackEventString(data.MENU.voyage, data.WELCOME.welcomeScreen);
    GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.MENU.voyage, '');
    this.router.navigate(['/travel']);
  }

  routing(route) {
    switch (route) {
      case '/home':
        let appNameUpper = this.appName.replace(/\b\w/g, (l) => l.toUpperCase());
        let appNameReplace = appNameUpper.replace(/ /g, "_");
        let finishNameEvent = data.MENU.aboutRewards + appNameReplace + data.MENU.menu;
        // this.trackingManager.trackEventString(finishNameEvent, data.WELCOME.welcomeScreen);
        GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, finishNameEvent, '');
        this.router.navigate(['/home']);
        break;
      case '/myRewards':
        // this.trackingManager.trackEventString(data.MENU.myRewards, data.WELCOME.welcomeScreen);
        GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.MENU.myRewards, '');
        this.router.navigate(['/myRewards']);
        break;
      case '/travel':
        // this.trackingManager.trackEventString(data.MENU.voyage, data.WELCOME.welcomeScreen);
        GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.MENU.voyage, '');
        this.router.navigate(['/travel']);
        break;
      case '/flights': {

        this.tabSelect = EProduct.FLIGHTS;
        this.dataStore.preloaderImg = EProduct.FLIGHTS;

        this.router.navigateByUrl('travel/flights');

      }
        break;
      case '/hotels': {

        this.tabSelect = EProduct.HOTELS;
        this.dataStore.preloaderImg = EProduct.HOTELS;

        this.router.navigateByUrl('travel/hotels');


      }
        break;
      case '/homeVacations': {

        this.tabSelect = EProduct.HOMEVACATIONS;
        this.dataStore.preloaderImg = EProduct.HOMEVACATIONS;

        this.router.navigateByUrl('travel/homeVacations');

      }
        break;
      case '/cars': {

        this.tabSelect = EProduct.CARS;
        this.dataStore.preloaderImg = EProduct.CARS;

        this.router.navigateByUrl('travel/cars');

      }
        break;
      case '/fixed-rates':
        GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.WELCOME.fixedRates, '');
        this.router.navigateByUrl(route);
        break;
      case '/tutorials':
        this.router.navigateByUrl(route);
        break;
    }

  }

  async logout() {
    this.dataService.vaidateOnePocketStay().subscribe((observer) => {
      if (observer === 2) {
        this.continueFlowLogout();
      } else if (observer === 1) {
        this.dataService.openModalQuestion().subscribe((isLoad) => {
          if (isLoad) {
            this.continueFlowLogout();
          }
        });
      }
    });
  }

  async continueFlowLogout() {
    // this.trackingManager.trackEventString(data.MENU.signOut, data.WELCOME.welcomeScreen);
    GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.MENU.signOut, '');

    if (this.dataStore.brand === 'scotia') {
      this.dataService.saveCreditCardDefult('');
      this.actionEventService.setSubBrand('default');
      this.actionEventService.setChangeFooter(true);
      this.storeLibrary.clearCardScotiaPointsSelected();

      let optionMenuLogout = [
        { label: 'NAV.home', router: '/home', tabSelect: [EProduct.HOME] },
        { label: 'NAV.travel', router: '/travel', tabSelect: [EProduct.TRAVEL, EProduct.FLIGHTS, EProduct.HOTELS, EProduct.CARS, EProduct.ACTIVITIES, EProduct.BENEFITS] }
      ];

      this.optionMenu = [
        { label: 'NAV.home', router: '/home', tabSelect: [EProduct.HOME] },
        { label: 'NAV.travel', router: '/travel', tabSelect: [EProduct.TRAVEL, EProduct.FLIGHTS, EProduct.HOTELS, EProduct.CARS, EProduct.ACTIVITIES, EProduct.BENEFITS] }
      ];

      this.actionEventService.setChangeItemsRouteTab(optionMenuLogout);

    }
    await this.loginService.logout();
  }

  /**
   * cerrar el panel si hacen click afuera
   */
  @HostListener('document:click', ['$event.target'])
  onClick(targetElement) {
    if (this.isMenuOpen && this.menu) {
      const clickedInside = this.menu.nativeElement.contains(targetElement);
      if (!clickedInside) {
        this.isMenuOpen = false;
      }
    }
  }


  // showUserPoint() {
  //   this.interval = setInterval(() => {
  //     this.valuePointsCard = this.storeLibrary.getCardScotiaPointsSelected();
  //     if (this.valuePointsCard) {
  //       const jsonPointsCard = JSON.parse(this.valuePointsCard);
  //       // this.balanceUser = jsonPointsCard['quantityPoints'];
  //     }
  //   }, 1);
  // }
}
