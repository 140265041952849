import { Component, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FixedRatesService, StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { keyJsonFixedRates } from 'src/app/resources/keys.resources';
import { DataStoreService } from '../../services/data-store.service';
import { InitService } from '../../services/init.service';
import { UtilsResource } from 'src/app/resources/UtilsResource.resource';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-fixed-rates-table',
  templateUrl: './fixed-rates-table.component.html',
  styleUrls: ['./fixed-rates-table.component.scss']
})
export class FixedRatesTableComponent implements OnInit, AfterViewInit {

  @Output() notifyLoadData = new EventEmitter<object>();

  pointUser: number;

  objFixedRates = {
    product: {
      id: 0,
      class: '',
      key: '',
      quantity: 0,
      otherInformation: ''
    },
    type: 'fixedrates',
    opt_seleted: '',
    total: 0,
    passenger: 0,
    subtotal: 0
  };

  get brand() {
    return this.storeService.brand;
  }

  fixedRates = {
    TypeFixedRates: []
  };

  disabledButton = true;
  activateButton = false;
  startYourAventure = true;

  fixedRatesSelected = {
    value: 0,
    description: ''
  };

  priceType = '';
  languaje: string;


  typeSelectedClass = {
    economy: {
      en: 'Economy',
      es: 'Económica'
    },
    business: {
      en: 'Business',
      es: 'Ejecutiva'
    }
  }


  constructor(
    private storeService: DataStoreService,
    private initService: InitService,
    private fixedRatesService: FixedRatesService,
    private storeLibrary: StoreServiceLibrary,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.languaje = this.translate.currentLang;
    this.languajeChange();
  }

  ngAfterViewInit() {
    this.createTableFixedRates();
  }

  createTableFixedRates() {
    this.initService.isInitializedBrand.subscribe((isInitialized) => {
      if (isInitialized) {
        this.calledServiceTable();
        const userPoints = JSON.parse(this.storeLibrary.getCardScotiaPointsSelected() || '{"quantityPoints":0}');
        this.pointUser = Number(userPoints.quantityPoints);
      }
    });
  }

  calledServiceTable() {
    this.fixedRatesService.getPrices().subscribe((resp) => {
      if (resp['prices']) {
        const pricesData = resp['prices'];
        this.fixedRates.TypeFixedRates = [];
        pricesData.map((item) => {
          let returnArrayTransform = {
            title: {
              es: item['name'].es,
              en: item['name'].en
            },
            economy: {
              id: `${item['priceEconomy'].key}`,
              value: item['priceEconomy'].price,
              valueName: `${item['priceEconomy'].price}`
            },
            business: {
              id: `${item['priceBusiness'].key}`,
              value: item['priceBusiness'].price,
              valueName: `${item['priceBusiness'].price}`
            },
            idPrice: item['id'],
            idOrigin: item['idOrigin'],
            idDepature: item['idDepature'],
          };
          this.fixedRates.TypeFixedRates.push(returnArrayTransform);
        });
      }
    }, (err) => {
      UtilsResource.NSMarkLogError('GET PRICES TABLE ERROR', err);
      this.fixedRates.TypeFixedRates = [];
    });
    /*

    this.fixedRatesService.getCountriesByRegion(body).subscribe((resp) => {
    }, (err) => {
    }); */
  }

  getRegions() {
    this.fixedRatesService.getRegions().subscribe((resp) => {
    }, (err) => {
      UtilsResource.NSMarkLogError('GET REGIONS ERROR', err);
    });
  }

  getCountriesByRegion(regions) {

    let body = {
      data: {
        regions
      }
    };

    this.fixedRatesService.getCountriesByRegion(body).subscribe((resp) => {
    }, (err) => {
      UtilsResource.NSMarkLogError('GET COUNTRIES BY REGION', err);
    });
  }

  getDestinations(idPrice) {
    this.fixedRatesService.getDestinations(idPrice, 0).subscribe((resp) => {
    }, (err) => {
      UtilsResource.NSMarkLogError('GET DESTINATIONS', err);
    });
  }

  changeValuePoints(event) {
    this.fixedRatesSelected.value = event;
  }

  verifyActivatedRadio(itemPrice, fixedRates, title, typePrice) {
    this.disabledButton = false;
    this.activateButton = true;
    this.fixedRatesSelected = {
      value: fixedRates.value,
      description: title
    };
    this.priceType = typePrice;
    this.objFixedRates.product.id = Number(itemPrice['idPrice']);
    this.objFixedRates.product.class = typePrice;
    this.objFixedRates.product.key = fixedRates['id'],
    this.objFixedRates.product.quantity = 0;
    this.objFixedRates.product.otherInformation = '';
    this.objFixedRates.subtotal = this.fixedRatesSelected.value;
    this.objFixedRates.opt_seleted = fixedRates['id'];
    const objTranform = JSON.stringify(this.objFixedRates);
    sessionStorage.setItem(keyJsonFixedRates.json_fixed_rates, objTranform);
    sessionStorage.setItem(keyJsonFixedRates.fixed_rates_selected, JSON.stringify(this.fixedRatesSelected));
  }

  backToTableFixedRates() {
    this.startYourAventure = true;
  }

  searchFlightsFixedRates() {
    this.startYourAventure = false;

  }

  showIframeLoadData(event) {
    this.notifyLoadData.emit(event);
  }

  languajeChange() {
    this.translate.onLangChange.subscribe((data) => {
      this.languaje = data['lang'];
    });
  }
}
