<main class="main">
  <div class="main-wrapper">
    <section class="padding-first about1" id="about_us">
      <div class="container">

        <a [routerLinkActive]="['active']" class="more">
          <div class="icon-back" (click)="back()">
            <span>Regresar</span>
          </div>
        </a>

        <div wmAnimate="fadeInUp" speed="faster" aos>
          <div class="bl-rows">
            <div class="text" [innerHTML]="newDetail.titleLarge">
            </div>

            <div class="img">
              <img [src]="newDetail.image | domSecurity" alt="">
            </div>
          </div>
        </div>

        <div class="bl-center" [innerHTML]="newDetail.descriptionLarge">
        </div>

      </div>
    </section>


  </div>
</main>
<app-footer></app-footer>
