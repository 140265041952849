<mat-form-field>
<input matInput readonly class=""
       type="text"
       ngxDaterangepickerMd
       placeholder="Seleccione Fecha"
       [showDropdowns]="true"
       [autoApply]="true"
       [locale]="locale"
       [singleDatePicker]="!secondDatePicker"
       [minDate]="minDate"
       (change)="onInput($event)"
/>
</mat-form-field>
