<div class="container" *ngIf="benefit && benefit.insurance">
    <div class="header">
        <p innerHTML="{{'BENEFIT_ACTIVE.certificate'|translate}}">Certificade de
            Cobertura</p>
        <img src="ellogo" alt="">
    </div>
    <div class="body">
        <div class="general-info">
            <div class="container-info product-info">
                <div class="info-header">
                    <p
                        innerHTML="{{'BENEFIT_ACTIVE.product_information'|translate}}">
                        Información del Producto</p>
                </div>
                <div class="info-body">
                    <div class="item">
                        <p class="key"
                            innerHTML="{{'BENEFIT_ACTIVE.product'|translate}}">
                            Producto</p>
                        <p class="value">{{ benefit.productDto.name }}</p>
                    </div>
                    <!-- <div class="item">
                        <p class="key">Núm de Certificado</p>
                        <p class="value">{{ benefit. }}</p>
                    </div> -->
                    <div class="item">
                        <p class="key"
                            innerHTML="{{'BENEFIT_ACTIVE.emision_date'|translate}}">
                            Fecha de Emisión</p>
                        <p class="value">{{ startDate }}</p>
                    </div>
                    <div class="item">
                        <p class="key"
                            innerHTML="{{'BENEFIT_ACTIVE.expiration_date'|translate}}">
                            Fecha de Vencimiento</p>
                        <p class="value">{{ expirationDate }}</p>
                    </div>
                    <div class="item">
                        <p class="key"
                            innerHTML="{{'BENEFIT_ACTIVE.product_price'|translate}}">
                            Costo del Producto</p>
                        <p class="value">{{ benefit.productDto.value }}</p>
                    </div>
                </div>
            </div>
            <div class="container-info user-info">
                <div class="info-header">
                    <p
                        innerHTML="{{'BENEFIT_ACTIVE.personal_information'|translate}}">
                        Información Personal</p>
                </div>
                <div class="info-body">
                    <div class="item">
                        <p class="key"
                            innerHTML="{{'BENEFIT_ACTIVE.name'|translate}}">
                            Nombre</p>
                        <p class="value">{{ user.firstName }}</p>
                    </div>
                    <div class="item">
                        <p class="key"
                            innerHTML="{{'BENEFIT_ACTIVE.lastname'|translate}}">
                            Apellido</p>
                        <p class="value">{{ user.lastName1 }}</p>
                    </div>
                    <div class="item">
                        <p class="key"
                            innerHTML="{{'BENEFIT_ACTIVE.doc'|translate}}">
                            Documento Identidad</p>
                        <p class="value">{{ user.identificationDto.typeId }}
                            {{ user.identificationDto.number }}</p>
                    </div>
                    <div class="item">
                        <p class="key"
                            innerHTML="{{'BENEFIT_ACTIVE.email'|translate}}">
                            Correo</p>
                        <p class="value">{{ user.emailaddress }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-info">
            <p class="text"
                innerHTML="{{'BENEFIT_ACTIVE.assistance_text'|translate}}">SI
                NECESITA ASISTENCIA, CONTÁCTENOS A TRAVÉS DE SU APLICACIÓN
                UPPERCLUB</p>
        </div>
        <div class="container-detail">
            <app-benefit-detail [benefit]="benefit.productDto"
                [brand]="dataStore.brand"></app-benefit-detail>
        </div>
    </div>
</div>