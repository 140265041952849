<button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
</button>
<div class="header">
    <app-detail-header [product]="product"></app-detail-header>
</div>
<div class="body">
    <div>
        <div class="gallery">
            <app-gallery-carousel [images]="product.images"></app-gallery-carousel>
        </div>
        <div class="reservation">
            <app-selection [product]="product"></app-selection>
        </div>
    </div>
    <div>
        <div class="cards-container">
            <div class="detalle-gral" *ngIf="product.description">
                <h6>{{'PRODUCT_DETAIL.general_information'|translate}}</h6>
                <p>
                    {{product.description|truncate:(isDescriptionShowingMore ? product.description.length : 135)}}
                    <button class="see-more"
                        (click)="isDescriptionShowingMore=!isDescriptionShowingMore">
                        {{('PRODUCT_DETAIL.see_' + ((isDescriptionShowingMore) ? 'less' : 'more'))|translate}}
                    </button>
                </p>
            </div>
            <app-card
                *ngFor="let option of product.options; let i = index; let f2 = first;"
                [product]="option"
                [selected]="isProductSelected(option)"
                (productSelected)="cardListener($event)">
                <ng-template appShowProduct
                    [product]="option"
                    [options]="cardOptions"
                    (layoutOptionRadio)="getProductRadio($event)"
                    (layoutDropDown)="getProductDropdown($event)"
                ></ng-template>
            </app-card>
        </div>
        <div class="amenities-container">
            <div class="continue-button">
                <button class="btn-travel" [class.disabled]="!isValidActivity"
                    innerHTML="{{ 'PRODUCT_DETAIL.continue' | translate }}"
                    (click)="continue()">Continue to register</button>
            </div>
        </div>
    </div>
</div>