import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { SearchedService } from '../searched/searched.service';
import { EProduct } from '../searched/models/searched.model';
import { SearchedHotel } from '../searched/models/searched-hotel.model';
import { SearchedFlight } from '../searched/models/searched-flight.model';
import { SearchedCar } from '../searched/models/searched-car.model';
import { SearchedActivity } from '../searched/models/searched-activity.model';
import { SearchedBenefit } from '../searched/models/searched-benefit.model';

@Component({
  selector: 'app-search-resume-bar',
  templateUrl: './search-resume-bar.component.html',
  styleUrls: ['./search-resume-bar.component.scss']
})
export class SearchResumeBarComponent implements OnInit {

  EProduct = EProduct;

  get productSelected(): EProduct {
    return this.dataService.productSelected;
  }

  get searchedFlight(): SearchedFlight {
    return this.searchedService.getSearchedFlight();
  }

  get searchedHotel(): SearchedHotel {
    return this.searchedService.getSearchedHotel();
  }

  get searchedCar(): SearchedCar {
    return this.searchedService.getSearchedCar();
  }

  get searchedActivity(): SearchedActivity {
    return this.searchedService.getSearchedActivity();
  }

  get searchedBenefit(): SearchedBenefit {
    return this.searchedService.getSearchedBenefit();
  }

  constructor(
    private dataService: DataService,
    private searchedService: SearchedService
  ) { }

  ngOnInit() { }
}
