<div class="container registration" id="container">
  <app-main-form></app-main-form>
  <h1 class="titulo-formulario">
    {{ (confirmationData !== undefined) ? ('REGISTRY_ACTIVITIES.successful_purchase'|translate) : ('REGISTRY_ACTIVITIES.complete_your_data'|translate)}}
  </h1>

  <div class="b-registro-pasajeros" *ngIf="init">
    <div class="row">
      <div class="col-md-8 col-xs-12" id="passenger-group">

        <div class="" *ngIf="!confirmationData">

          <div class="mobile-collapsable" [class.mobile-hide]="purchaseID" [class.active]="step === 1">
            <div class="head" (click)="setStep(1)">
              <p innerHTML="{{travellerTitle | translate}}">
                Pasajeros
              </p>
            </div>
            <div class="body">
              <app-traveller-form [formConfig]="travellerFormConfig" (formIsOk)="passengerFormListener($event)">
              </app-traveller-form>
            </div>
          </div>

          <div class="mobile-collapsable" *ngIf="false" [class.mobile-hide]="purchaseID" [class.active]="step === 2">
            <div class="head" (click)="setStep(2)">
              <p innerHTML="{{'REGISTRATION.contact' | translate}}">
                Contacto
              </p>
            </div>
            <div class="body">
              <app-contact-form *ngIf="false" (formIsOk)="contactFormListener(true)">
              </app-contact-form>
            </div>
          </div>

          <div class="button-container mb-2 mt-3" *ngIf="!purchaseID">
            <button class="btn-travel" (click)="onPay()" [class.btn-disabled]="!dataTravellerForm"
              innerHTML="{{'REGISTRATION.pay' | translate}}"></button>
          </div>
        </div>

        <div *ngIf="confirmationData">
          <app-confirmation [confirmationData]="confirmationData">
          </app-confirmation>
        </div>
      </div>
      <div class="col-md-4 col-xs-12 mb-5 flight-detail" id="flight-detail">
        <div class="mobile-collapsable" [class.mobile-hide]="purchaseID" [class.active]="step === 3">
          <div class="head" (click)="setStep(3)">
            <p innerHTML="{{'REGISTRATION.details' | translate}}">
              Detalles</p>
          </div>
          <div class="body">
            <app-purchase-summary [config]="purchaseSummaryConfig">
            </app-purchase-summary>
          </div>
        </div>

        <app-onepocket [class.mobile-hide]="!purchaseID || confirmationData" *ngIf="onePocketToken && onePocketURL"
          [token]="onePocketToken" [purchaseID]="purchaseID" [iframeURL]="onePocketURL"
          (outputEmiter)="onePocketListener($event)"></app-onepocket>
      </div>
    </div>
  </div>
</div>
