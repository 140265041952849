import { PipesModule } from 'src/app/pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardLayoutOneComponent } from './card-layout-one.component';
import { SegmentComponent } from './segment/segment.component';
import { DetailComponent } from './detail/detail.component';
import { DetailHeaderComponent } from './detail/detail-header/detail-header.component';
import { DetailDateComponent } from './detail/detail-date/detail-date.component';
import { DetailRouteComponent } from './detail/detail-route/detail-route.component';
import { DetailScaleInfoComponent } from './detail/detail-scale-info/detail-scale-info.component';
import { TranslateModule } from '@ngx-translate/core';
import { InfoComponent } from './info/info.component';



@NgModule({
  declarations: [
    CardLayoutOneComponent,
    SegmentComponent,
    DetailComponent,
    DetailHeaderComponent,
    DetailDateComponent,
    DetailRouteComponent,
    DetailScaleInfoComponent,
    InfoComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    PipesModule
  ],
  exports: [
    CardLayoutOneComponent
  ]
})
export class CardLayoutOneModule { }
