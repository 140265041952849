import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { DataStorage, StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { DataStoreService } from 'src/app/services/data-store.service';
import { InitService } from '../../services/init.service';
import { environment } from 'src/environments/environment';
import { EProduct } from '../searched/models/searched.model';

import data from '../../resources/screenAndEventsTracking.json';
import { LanguageService } from '../language/language.service';
import { keysUniversal } from '../../resources/keys.resources';
import { GoogleAnalytics } from '../../resources/GoogleAnalytics.resource';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  readonly YEAR = (new Date()).getFullYear();
  public isNewMenu = false;

  isFooterServices = false;
  LoadNewMenu = false;

  public hasBenefits = false;
  public hasActivities = true;
  public isSearchIframe = this.storeLibrary.getJustOneUniversalPropertie(keysUniversal.USE_SEARCH_IFRAME);
  private systemLanguage = 'EN';
  EProduct = EProduct;

  get tabSelect(): EProduct {
    return this.dataService.productSelected;
  }

  set tabSelect(value: EProduct) {
    this.dataService.productSelected = value;
  }

  constructor(
    private dataService: DataService,
    private dataStore: DataStoreService,
    private initService: InitService,
    private languageService: LanguageService,
    private storeLibrary: StoreServiceLibrary

  ) {
    // cuando cambia el idioma del sistema
    this.languageService.systemLanguajeChange.subscribe(language => {
      if (language) {
        this.systemLanguage = language.toUpperCase();
      }
    });
  }


  ngOnInit() {
    this.initService.isInitializedBrand.subscribe(isInitialized => {
      if (isInitialized) {
        this.hasBenefits = environment.brands[this.dataStore.brand].config.hasBenefits;

        if (environment.brands[this.dataStore.brand].config.hasActivities) {
          this.hasActivities = environment.brands[this.dataStore.brand].config.hasActivities;
        }

        // if (this.dataStore.brand !== 'miles'
        //   && this.dataStore.brand !== 'firstcitizens'
        //   && this.dataStore.brand !== 'bancopopular'
        //   && this.dataStore.brand !== 'bancoindustrial'
        //   && this.dataStore.brand !== 'ncb'
        // ) {
        //   this.hasBenefits = true;
        // }
      }
    });
    // $(window).scroll(function(){
    //   var stickyElement = $('.boton-chat').outerHeight()
    //   var windowHeight = $(this).outerHeight()
    //   var target = $('.content-footer').offset().top - windowHeight - stickyElement

    //   if($(window).scrollTop() <= target){
    //     $('.content-footer').css('height', stickyElement)
    //     $('.boton-chat').css({
    //       'position': 'fixed',
    //       'bottom': '20px'
    //     })
    //   } else {
    //     $('.content-footer').css('height', 0)
    //     $('.boton-chat').css({
    //       'position': 'fixed',
    //       'bottom': '100px',
    //       'right': '20px'
    //     })
    //   }

    // })

    if (environment.brands[this.dataStore.brand].config.isNewHome) {
      this.isNewMenu = environment.brands[this.dataStore.brand].config.isNewHome;
      this.isFooterServices = environment.brands[this.dataStore.brand].config.showOtherServicesFooter;

      if (this.isNewMenu && this.isFooterServices) {
        this.LoadNewMenu = false;
      } else {
        this.isNewMenu = true;
      }

      if (this.isFooterServices) {
        this.isNewMenu = false;
        this.LoadNewMenu = false;
        this.isFooterServices = true;
      }
    }
  }

  tabSelected(tipo: string, el: HTMLElement) {
    this.dataService.productSelected = tipo as EProduct;
    this.tabSelect = this.dataService.productSelected;

    window.scroll(0, 0);

    let tipoUpperCase = this.tabSelect.replace(/\b\w/g, (l) => l.toUpperCase());
    let actionName = 'Web_' + tipoUpperCase + '_Bottom';
    // this.trackingManager.trackEventString(actionName, data.WELCOME.welcomeScreen);
    GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, actionName, '');
  }

  showTermsAndConditionsDialog() {
    const lang = this.storeLibrary.getItemFromStorage('lang') || 'en';
    // const urlTerms = environment.brands[this.dataStore.brand].terminos_y_condiciones[lang.toUpperCase()];
    let urlTerms = this.storeLibrary.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_en);

    if (lang) {
      if (lang == 'es') {
        urlTerms = this.storeLibrary.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_es);
      }
    }
    // this.trackingManager.trackEventString(data.WELCOME.TermsConditionsBottom, data.WELCOME.welcomeScreen);
    GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.WELCOME.TermsConditionsBottom, '');
    // this.dataService.openTermsAndConditionsDialog();
    window.open(urlTerms, '_blank');

  }

}
