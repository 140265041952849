import { PipesModule } from 'src/app/pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchaseSummaryComponent } from './purchase-summary.component';
import { TranslateModule } from '@ngx-translate/core';
import { FlightSummaryComponent } from './flight-summary/flight-summary.component';
import { FlightSegmentComponent } from './flight-summary/flight-segment/flight-segment.component';
import { FlightSegmentDetailComponent } from './flight-summary/flight-segment-detail/flight-segment-detail.component';
import { HotelSummaryComponent } from './hotel-summary/hotel-summary.component';
import { CarSummaryComponent } from './car-summary/car-summary.component';
import { BenefitSummaryComponent } from './benefit-summary/benefit-summary.component';
import { MatIconModule } from '@angular/material/icon';
import { ActivitySummaryComponent } from './activity-summary/activity-summary.component';
import { MaterialModule } from 'src/app/models/material';



@NgModule({
  declarations: [
    PurchaseSummaryComponent,
    FlightSummaryComponent,
    FlightSegmentComponent,
    FlightSegmentDetailComponent,
    HotelSummaryComponent,
    CarSummaryComponent,
    BenefitSummaryComponent,
    ActivitySummaryComponent
  ],
  exports: [
    PurchaseSummaryComponent,
    FlightSummaryComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatIconModule,
    MaterialModule,
    PipesModule
  ]
})
export class PurchaseSummaryModule { }
