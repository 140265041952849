import { TravellerFormModule } from './../../component/traveller-form/traveller-form.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationComponent } from './registration.component';
import { TranslateModule } from '@ngx-translate/core';
import { PurchaseSummaryModule } from 'src/app/component/purchase-summary/purchase-summary.module';
import { OnepocketModule } from 'src/app/component/onepocket/onepocket.module';
import { ContactFormModule } from 'src/app/component/contact-form/contact-form.module';
import { MainFormModule } from 'src/app/container/main-form/main-form.module';
import { Routes, RouterModule } from '@angular/router';
import { ConfirmationModule } from 'src/app/component/confirmation/confirmation.module';

const routes: Routes = [{ path: '', component: RegistrationComponent }];

@NgModule({
  declarations: [
    RegistrationComponent,
  ],
  exports: [
    RegistrationComponent
  ],
  imports: [
    CommonModule,
    PurchaseSummaryModule,
    ConfirmationModule,
    OnepocketModule,
    TranslateModule.forChild(),
    MainFormModule,
    TravellerFormModule,
    ContactFormModule,
    RouterModule.forChild(routes)
  ]
})
export class RegistrationModule { }
