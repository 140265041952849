<div class="tab-changeable">
    <div class="container">
        <div class="bloque">
            <div class="left">
                <h2 innerHTML="{{'LANDING.scotia.ONEPOCKETPAY.booking_benefits_title_desc'|translate}}">100% changeable and
                    refundable
                    <span innerHTML="{{'LANDING.scotia.ONEPOCKETPAY.booking_benefits_desc'|translate}}">reservations,
                        all included in your purchase</span>
                </h2>
            </div>
            <div class="right">
                <div class="button" (click)="showBenefits()"
                    innerHTML="{{'LANDING.scotia.ONEPOCKETPAY.booking_benefits_btn'|translate}}">
                    Check all included benefits
                </div>
            </div>
        </div>
    </div>
</div>

<iframe allow="clipboard-read; clipboard-write" id="iframe_fixed_rates" [src]="urlIframeJuniperToLoad | srcSanitizer"
    *ngIf="true" class="iframe-content" [ngStyle]="{height: iframeScrollHeight, width: iframeScrollWidth }"> </iframe>