import {Component, Input, OnInit} from '@angular/core';
import { HotelCardComponent } from '../hotel-card.component';

@Component({
  selector: 'app-hotel-card-header',
  templateUrl: './hotel-card-header.component.html',
  styleUrls: ['./hotel-card-header.component.scss']
})
export class HotelCardHeaderComponent implements OnInit {


  card;
  starsArr;
  constructor(private cardComponent: HotelCardComponent) {
  }

  ngOnInit() {
    this.card = this.cardComponent.hotel;
    this.starsArr = Array(parseInt(this.card.Stars)).fill(0);
  }


}
