import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.scss']
})
export class WebsiteComponent implements OnInit {

  public show: boolean;
  public show_mobile: boolean;
  public show_description: boolean;

  languaje: string;

  @Input() show_content: boolean;
  @Input() is_active: boolean;
  @Output() x = new EventEmitter();

  constructor(private route: ActivatedRoute) {
    this.languaje = 'en';
  }

  ngOnInit(): void {
  }

  showContent() {
    this.x.emit({})
  }

  showMobileContent() {
    this.show_mobile = !this.show_mobile;
  }

  toggleClass(number: any) {
    this["show_description" + number] = !this["show_description" + number];
  }

}
