import { Component, OnInit } from '@angular/core';
import {DataStoreService} from '../../../services/data-store.service';

@Component({
  selector: 'app-search-tab',
  templateUrl: './search-tab.component.html',
  styleUrls: ['./search-tab.component.scss']
})
export class SearchTabComponent implements OnInit {
  get tabSelect(): string {
    return this.dataStore.tabSelectActivate;
  }
  set tabSelect(value: string) {
    this.dataStore.tabSelectActivate = value;
  }
  constructor(
    private dataStore: DataStoreService,
  ) { }

  ngOnInit() {
    this.tabSelect = this.dataStore.tabSelectActivate;
  }
  tabSelected(type: string) {
    /*this.searchConfigFn(type);*/
    this.dataStore.tabSelectActivate = type;
    this.tabSelect = this.dataStore.tabSelectActivate;
  }
}
