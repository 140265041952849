<div class="{{brand}} modal-form-container" [ngSwitch]="state">
  <ng-container *ngSwitchCase="LOGIN_STATES.CHOOSE_OPTION">
    <h1 mat-dialog-title class="title">
      {{'LOGIN.STATES.choose_option'|translate}}
    </h1>
  </ng-container>
  <ng-container *ngSwitchCase="LOGIN_STATES.LOG_IN">
    <h1 mat-dialog-title class="title">
      {{'LOGIN.STATES.log_in'|translate}}
    </h1>
  </ng-container>
  <ng-container *ngSwitchCase="LOGIN_STATES.SIGN_UP">
    <h1 mat-dialog-title class="title">
      {{'LOGIN.sign_up_title'|translate}}
    </h1>
  </ng-container>

  <div mat-dialog-content class="container">

    <ng-container *ngSwitchCase="LOGIN_STATES.CHOOSE_OPTION">
      <mat-button-toggle-group>
        <mat-button-toggle (click)="chooseOptionLogIn()">
          {{'LOGIN.log_in'|translate}}
        </mat-button-toggle>
        <mat-button-toggle (click)="chooseOptionSignUp()">
          {{'LOGIN.sign_up'|translate}}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </ng-container>

    <form [formGroup]="formLogIn" *ngSwitchCase="LOGIN_STATES.LOG_IN">
      <div class="box" *ngIf="isErrorLogin && !isblock && !isOneIntent">
        <div class="bot error">
          <p> {{ 'LOGIN.invalidData'|translate }} </p>
        </div>
      </div>

      <div class="box" *ngIf="isblock">
        <div class="bot error">
          <p> {{ 'LOGIN.gameOver'|translate }} </p>
        </div>
      </div>

      <div class="box" *ngIf="isOneIntent && !isblock">
        <div class="bot error">
          <p> {{ 'LOGIN.warningOneMoreAttemptToBlockTheAccount'|translate }} </p>
        </div>
      </div>

      <div class="input" [class.error]="formLogIn.get('email').touched && formLogIn.get('email').invalid">
        <mat-icon class="icon-mail-new"></mat-icon>
        <input type="email" formControlName="email" autocomplete="off" placeholder="{{'LOGIN.email'|translate}}">
      </div>
      <div class="input" [class.error]="formLogIn.get('password').touched && formLogIn.get('password').invalid">
        <mat-icon class="icon-lock"></mat-icon>
        <input #password type="password" formControlName="password" autocomplete="off"
          placeholder="{{'LOGIN.password'|translate}}">
        <mat-icon (touchstart)="password.type='text'" (touchend)="password.type='password'"
          (mousedown)="password.type='text'" (mouseup)="password.type='password'"
          (mouseleave)="password.type='password'">
          {{password.type=='text' ? 'visibility_off' : 'visibility'}}
        </mat-icon>
      </div>
      <mat-checkbox formControlName="checkInformation" (click)="isRememberInformation()" class="example-margin">
        {{'LOGIN.rememberInformation'|translate}}</mat-checkbox>
      <div class="ingresar">
        <button mat-button [disabled]="nextButtonDisabled || loading" (click)="next()">
          {{'LOGIN.sign_in'|translate}}
        </button>
      </div>
      <button class="what" (click)="goToExWhatsapp()"> <img src="../../../../assets/images/whatsapp-logo.png" alt="">
        {{'LOGIN.contactWhatsApp'|translate}}</button>
      <a class="reset" (click)="showModalRecoveryPass()">{{'LOGIN.resetPassword'|translate}}</a>
    </form>

    <ng-container *ngSwitchCase="LOGIN_STATES.SIGN_UP_PRE_REGISTERED_STEP_2">
      <form [formGroup]="formSignUpPreLoadedStep2">
        <div class="personal">
          <h4>LANDING_ALLO.register-now</h4>
          <div class="cont-miga">
            <span class="active"></span>
            <span class="active"></span>
            <span></span>
            <span></span>
          </div>
          <span class="lead">Escribe los siguientes datos</span>
          <div class="input"
            [class.error]="formSignUpPreLoadedStep2.get('bin').touched && formSignUpPreLoadedStep2.get('bin').invalid">
            <mat-icon class="icon-card-new"></mat-icon>
            <input type="text" formControlName="bin" autocomplete="off" placeholder="{{'LOGIN.bin'|translate}}">
          </div>
          <div class="input"
            [class.error]="formSignUpPreLoadedStep2.get('product').touched && formSignUpPreLoadedStep2.get('product').invalid">
            <mat-icon class="icon-card-new"></mat-icon>
            <input type="text" formControlName="product" autocomplete="off" placeholder="{{'LOGIN.product'|translate}}">
          </div>
        </div>
        <div class="confirmar">
          <button mat-button (click)="next()" [disabled]="nextButtonDisabled || loading">
            {{'LOGIN.confirm'|translate}}
          </button>
        </div>
      </form>
    </ng-container>

    <ng-container *ngSwitchCase="LOGIN_STATES.SIGN_UP_PRE_REGISTERED_STEP_1">
      <form [formGroup]="formSignUpPreLoadedStep1">
        <div class="personal">
          <h4>LANDING_ALLO.register-now</h4>
          <div class="cont-miga">
            <span class="active"></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <span class="lead">{{'LOGIN.enter-youe-email | translate'}}</span>
          <div class="input" [class.error]="formSignUp.get('email').touched && formSignUp.get('email').invalid">
            <mat-icon class="icon-mail-new"></mat-icon>
            <input type="email" formControlName="email" autocomplete="off" placeholder="{{'LOGIN.email'|translate}}">
          </div>
          <div class="confirmar">
            <button mat-button (click)="next()" [disabled]="nextButtonDisabled || loading">
              {{'LOGIN.confirm'|translate}}
            </button>
          </div>
        </div>
      </form>
    </ng-container>

    <ng-container *ngSwitchCase="LOGIN_STATES.SIGN_UP_PRE_REGISTERED_STEP_3">
      <form [formGroup]="formSignUpPreLoadedStep3">
        <div class="personal">
          <h4>LANDING_ALLO.register-now</h4>
          <div class="cont-miga">
            <span class="active"></span>
            <span class="active"></span>
            <span class="active"></span>
            <span></span>
          </div>
          <span class="lead">{{'LOGIN.enter_information' | translate}}</span>
          <p class="datos"> E-mail: <span> {{ data.email }}</span></p>
          <div class="input" [class.error]="formSignUp.get('firstName').touched && formSignUp.get('firstName').invalid">
            <mat-icon class="icon-user-new"></mat-icon>
            <input type="text" formControlName="firstName" autocomplete="off" placeholder="{{'LOGIN.name'|translate}}">
          </div>
          <div class="input" [class.error]="formSignUp.get('lastName').touched && formSignUp.get('lastName').invalid">
            <mat-icon class="icon-user-new"></mat-icon>
            <input type="text" formControlName="lastName" autocomplete="off"
              placeholder="{{'LOGIN.last_name'|translate}}">
          </div>
        </div>
        <div class="confirmar">
          <button mat-button (click)="next()" [disabled]="nextButtonDisabled || loading">
            {{'LOGIN.confirm'|translate}}
          </button>
        </div>
      </form>
    </ng-container>

    <ng-container *ngSwitchCase="LOGIN_STATES.ACTIVATE_NO_NAME_AND_LNAME">
      <form [formGroup]="formSignUpPreLoadedStep3">
        <div class="personal">
          <h4>{{ 'LANDING_ALLO.register-now' | translate }}</h4>
          <div class="cont-miga">
            <span class="active"></span>
            <span class="active"></span>
            <span class="active"></span>
            <span></span>
          </div>
          <span class="lead">{{'LOGIN.enter_information' | translate}}</span>
          <p class="datos"> E-mail: <span> {{ data.email }}</span></p>
          <div class="input" [class.error]="formSignUp.get('firstName').touched && formSignUp.get('firstName').invalid">
            <mat-icon class="icon-user-new"></mat-icon>
            <input type="text" formControlName="firstName" autocomplete="off" placeholder="{{'LOGIN.name'|translate}}"
              #firstName (keyup)="disabledKeyUpName(firstName, lastName)">
          </div>
          <div class="input" [class.error]="formSignUp.get('lastName').touched && formSignUp.get('lastName').invalid">
            <mat-icon class="icon-user-new"></mat-icon>
            <input type="text" formControlName="lastName" autocomplete="off"
              placeholder="{{'LOGIN.last_name'|translate}}" #lastName (keyup)="disabledKeyUpName(firstName, lastName)">
          </div>
        </div>
        <div class="confirmar">
          <button mat-button (click)="validateEmptyInputs(firstName, lastName)" [disabled]="noNameEmpty || loading">
            {{'LOGIN.confirm'|translate}}
          </button>
        </div>
      </form>
    </ng-container>

    <ng-container *ngSwitchCase="LOGIN_STATES.ACTIVATE_SEND_PASSWORD">
      <form [formGroup]="formSignUpPreLoadedStep4">
        <div class="personal">
          <h4>{{ 'LANDING_ALLO.register-now' | translate }}</h4>
          <div class="cont-miga">
            <span class="active"></span>
            <span class="active"></span>
            <span class="active"></span>
            <span class="active"></span>
          </div>
          <span class="lead">{{ 'LANDING_ALLO.plase-confirm-your-password' | translate }}</span>
          <span>{{ 'LANDING_ALLO.your-password-must-contain' | translate }}</span>
          <div class="input" matTooltip="{{'LOGIN.password_format'|translate}}"
            [class.error]="(formSignUpPreLoadedStep4.get('password').touched && formSignUpPreLoadedStep4.get('password').invalid) || this.formSignUpPreLoadedStep4.hasError('password_not_match')">
            <mat-icon class="icon-lock"></mat-icon>
            <input #password type="password" formControlName="password" autocomplete="off"
              placeholder="{{'LOGIN.password'|translate}}"
              (keyup)="validateInputsPassword(password, password_confirm, true); isValidPassword(password)">
            <mat-icon (touchstart)="password.type='text'" (touchend)="password.type='password'"
              (mousedown)="password.type='text'" (mouseup)="password.type='password'"
              (mouseleave)="password.type='password'">
              {{password.type=='text' ? 'visibility_off' : 'visibility'}}
            </mat-icon>
          </div>
          <div *ngIf="this.formSignUpPreLoadedStep4.get('password').hasError('password_length')" class="error">
            {{'LOGIN.password_length'|translate}}
          </div>
          <div
            *ngIf="formSignUpPreLoadedStep4.get('password').touched && formSignUpPreLoadedStep4.controls.password.errors && formSignUpPreLoadedStep4.controls.password.errors.pattern"
            class="error">
            {{'LOGIN.password_format'|translate}}
          </div>
          <div class="input"
            [class.error]="(formSignUpPreLoadedStep4.get('password_confirm').touched && formSignUpPreLoadedStep4.get('password_confirm').invalid) || this.formSignUpPreLoadedStep4.hasError('password_not_match')">
            <mat-icon class="icon-lock"></mat-icon>
            <input #password_confirm type="password" formControlName="password_confirm" autocomplete="off"
              placeholder="{{'LOGIN.password_confirm'|translate}}"
              (keyup)="validateInputsPassword(password, password_confirm, true); isValidPassword(password_confirm)">
            <mat-icon (touchstart)="password_confirm.type='text'" (touchend)="password_confirm.type='password'"
              (mousedown)="password_confirm.type='text'" (mouseup)="password_confirm.type='password'"
              (mouseleave)="password_confirm.type='password'">
              {{password_confirm.type=='text' ? 'visibility_off' : 'visibility'}}
            </mat-icon>
          </div>
          <div *ngIf="this.formSignUpPreLoadedStep4.hasError('password_not_match')" class="error">
            {{'LOGIN.password_not_match'|translate}}
          </div>
          <div class="confirmar">
            <button mat-button (click)="validateInputsPassword(password, password_confirm, false)"
              [disabled]="disabledPasswordRegister || this.formSignUpPreLoadedStep4.hasError('password_not_match') || loading ">
              {{'LOGIN.confirm'|translate}}
            </button>
          </div>
        </div>
      </form>
    </ng-container>

    <ng-container *ngSwitchCase="LOGIN_STATES.SIGN_UP_PRE_REGISTERED_STEP_4">
      <form [formGroup]="formSignUpPreLoadedStep4">
        <div class="personal">
          <h4>{{ 'LANDING_ALLO.register-now' | translate }}</h4>
          <div class="cont-miga">
            <span class="active"></span>
            <span class="active"></span>
            <span class="active"></span>
            <span class="active"></span>
          </div>
          <span class="lead">{{ 'LANDING_ALLO.plase-confirm-your-password' | translate }}</span>
          <div class="input" matTooltip="{{'LOGIN.password_format'|translate}}"
            [class.error]="(formSignUpPreLoadedStep4.get('password').touched && formSignUpPreLoadedStep4.get('password').invalid) || this.formSignUp.hasError('password_not_match')">
            <mat-icon class="icon-lock"></mat-icon>
            <input #password type="password" formControlName="password" autocomplete="off"
              placeholder="{{'LOGIN.password'|translate}}">
            <mat-icon (touchstart)="password.type='text'" (touchend)="password.type='password'"
              (mousedown)="password.type='text'" (mouseup)="password.type='password'"
              (mouseleave)="password.type='password'">
              {{password.type=='text' ? 'visibility_off' : 'visibility'}}
            </mat-icon>
          </div>
          <div *ngIf="this.formSignUpPreLoadedStep4.get('password').hasError('password_length')" class="error">
            {{'LOGIN.password_length'|translate}}
          </div>
          <div
            *ngIf="formSignUpPreLoadedStep4.get('password').touched && formSignUpPreLoadedStep4.controls.password.errors && formSignUpPreLoadedStep4.controls.password.errors.pattern"
            class="error">
            {{'LOGIN.password_format'|translate}}
          </div>
          <div class="input"
            [class.error]="(formSignUpPreLoadedStep4.get('password_confirm').touched && formSignUpPreLoadedStep4.get('password_confirm').invalid) || this.formSignUp.hasError('password_not_match')">
            <mat-icon class="icon-lock"></mat-icon>
            <input #password_confirm type="password" formControlName="password_confirm" autocomplete="off"
              placeholder="{{'LOGIN.password_confirm'|translate}}">
            <mat-icon (touchstart)="password_confirm.type='text'" (touchend)="password_confirm.type='password'"
              (mousedown)="password_confirm.type='text'" (mouseup)="password_confirm.type='password'"
              (mouseleave)="password_confirm.type='password'">
              {{password_confirm.type=='text' ? 'visibility_off' : 'visibility'}}
            </mat-icon>
          </div>
          <div *ngIf="this.formSignUpPreLoadedStep4.hasError('password_not_match')" class="error">
            {{'LOGIN.password_not_match'|translate}}
          </div>
          <div class="confirmar">
            <button mat-button (click)="next()" [disabled]="nextButtonDisabled || loading">
              {{'LOGIN.confirm'|translate}}
            </button>
          </div>
        </div>
      </form>
    </ng-container>

    <ng-container *ngSwitchCase="LOGIN_STATES.PRE_REGISTERED_CASE_4">
      <form [formGroup]="formSignUpPreLoadedStep1">
        <div class="personal">
          <h4>{{ 'LANDING_ALLO.register-now' | translate }}</h4>
          <div class="cont-miga">
            <span class="active"></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <span class="lead">{{ 'LANDING_ALLO.enter-youe-email' | translate }}</span>
          <div class="error" *ngIf="activateError">{{ 'LANDING_ALLO.generalError' | translate }}</div>
          <div class="error" *ngIf="activateErrorEmail && brand === 'credomatic'">
            {{ 'LANDING_ALLO.errorEmail' | translate }}</div>
          <div class="input" [class.error]="formSignUp.get('email').touched && formSignUp.get('email').invalid">
            <mat-icon class="icon-mail-new"></mat-icon>
            <input type="email" formControlName="email" autocomplete="off" placeholder="{{'LOGIN.email'|translate}}"
              (keyup)="disabledKeyup(emailInput)" #emailInput>
          </div>
          <div class="confirmar">
            <button mat-button (click)="next()" [disabled]="nextButtonDisabled || loading">
              {{'LOGIN.confirm'|translate}}
            </button>
          </div>
        </div>
      </form>
    </ng-container>

    <ng-container *ngSwitchCase="LOGIN_STATES.SIGN_UP">
      <form [formGroup]="formSignUp">
        <div class="personal">
          <h4>{{'LOGIN.personal'|translate}}</h4>
          <div class="input" [class.error]="formSignUp.get('firstName').touched && formSignUp.get('firstName').invalid">
            <input type="text" formControlName="firstName" autocomplete="off" placeholder="{{'LOGIN.name'|translate}}">
          </div>
          <div class="input" [class.error]="formSignUp.get('lastName').touched && formSignUp.get('lastName').invalid">
            <input type="text" formControlName="lastName" autocomplete="off"
              placeholder="{{'LOGIN.last_name'|translate}}">
          </div>
          <div class="input" [class.error]="formSignUp.get('gender').touched && formSignUp.get('gender').invalid">
            <select formControlName="gender" appIsInputFocused class="form-control" placeholder="Titulo">
              <option disabled selected value="">
                {{'TRAVELLER_FORM.select_title'|translate}}
              </option>
              <option *ngFor="let titleType of titleTypeList" [value]="titleType.spId">
                {{titleType.value}}
              </option>
            </select>
          </div>
          <div class="input" [class.error]="formSignUp.get('email').touched && formSignUp.get('email').invalid">
            <input type="email" formControlName="email" autocomplete="off" placeholder="{{'LOGIN.email'|translate}}">
          </div>

          <!-- inpit pass-->
          <div class="input" matTooltip="{{'LOGIN.password_format'|translate}}"
            [class.error]="(formSignUp.get('password').touched && formSignUp.get('password').invalid) || this.formSignUp.hasError('password_not_match')">
            <input #password type="password" formControlName="password" autocomplete="off"
              placeholder="{{'LOGIN.password'|translate}}">
            <mat-icon (touchstart)="password.type='text'" (touchend)="password.type='password'"
              (mousedown)="password.type='text'" (mouseup)="password.type='password'"
              (mouseleave)="password.type='password'">
              {{password.type=='text' ? 'visibility_off' : 'visibility'}}
            </mat-icon>
          </div>
          <div *ngIf="this.formSignUp.get('password').hasError('password_length')" class="error">
            {{'LOGIN.password_length'|translate}}
          </div>
          <div
            *ngIf="formSignUp.get('password').touched && formSignUp.controls.password.errors && formSignUp.controls.password.errors.pattern"
            class="error">
            {{'LOGIN.password_format'|translate}}
          </div>
          <div class="input"
            [class.error]="(formSignUp.get('password_confirm').touched && formSignUp.get('password_confirm').invalid) || this.formSignUp.hasError('password_not_match')">
            <input #password_confirm type="password" formControlName="password_confirm" autocomplete="off"
              placeholder="{{'LOGIN.password_confirm'|translate}}">
            <mat-icon (touchstart)="password_confirm.type='text'" (touchend)="password_confirm.type='password'"
              (mousedown)="password_confirm.type='text'" (mouseup)="password_confirm.type='password'"
              (mouseleave)="password_confirm.type='password'">
              {{password_confirm.type=='text' ? 'visibility_off' : 'visibility'}}
            </mat-icon>
          </div>
          <div *ngIf="this.formSignUp.hasError('password_not_match')" class="error">
            {{'LOGIN.password_not_match'|translate}}
          </div>


          <div class="input" [class.error]="formSignUp.get('phone').touched && formSignUp.get('phone').invalid">
            <span innerHtml="{{getCountryPhoneCodeByISO(formSignUp.get('countryIso'))}}"></span>
            <input type="tel" formControlName="phone" autocomplete="off" placeholder="{{'LOGIN.phone'|translate}}">
          </div>
          <div class="input" [class.error]="formSignUp.get('docType').touched && formSignUp.get('docType').invalid">
            <select formControlName="docType">
              <option disabled selected value="">
                {{'LOGIN.select_id_type'|translate}}
              </option>
              <option *ngFor="let IDtype of IDtypes" value="{{IDtype.id}}">
                {{IDtype.value}}
              </option>
            </select>
          </div>
          <div class="input" [class.error]="formSignUp.get('numberId').touched && formSignUp.get('numberId').invalid">
            <input type="text" formControlName="numberId" autocomplete="off"
              placeholder="{{'LOGIN.id_number'|translate}}">
          </div>
          <div class="form-group">
            <div class="input"
              [class.error]="formSignUp.get('dateOfBirth').touched && formSignUp.get('dateOfBirth').invalid">
              <input class="dateSelect form-control" style="padding-left: 50px; width: 277px !important;" type="text"
                formControlName="dateOfBirth" [placeholder]="'TRAVELLER_FORM.date_of_birth'|translate"
                ngxDaterangepickerMd [locale]="locale" [autoApply]="true" [closeOnAutoApply]="true"
                [showDropdowns]="true" [singleDatePicker]="true" />
              <!--
                  <small class="validation-error"
                            *ngIf="isFormControlInvalid(row.get('dateOfBirth'))">
                            {{'TRAVELLER_FORM.date_of_birth_enter' | translate}}
                        </small>
              -->
            </div>
          </div>
        </div>
        <div class="ubicacion">
          <h4>{{'LOGIN.location'|translate}}</h4>
          <div class="input"
            [class.error]="formSignUp.get('countryIso').touched && formSignUp.get('countryIso').invalid">
            <select formControlName="countryIso">
              <option disabled selected value="">
                {{'LOGIN.select_country'|translate}}
              </option>
              <option *ngFor="let countri of countries" value="{{countri.iso}}">
                {{countri.name}}
              </option>
            </select>
          </div>
          <div class="input" [class.error]="formSignUp.get('cityName').touched && formSignUp.get('cityName').invalid">
            <select formControlName="cityName">
              <option disabled selected value="">
                {{'LOGIN.select_city'|translate}}
              </option>
              <option *ngFor="let city of cities" value="{{city.name}}">
                {{city.name}}
              </option>
            </select>
          </div>
          <div class="input" [class.error]="formSignUp.get('address').touched && formSignUp.get('address').invalid">
            <input type="text" formControlName="address" autocomplete="off" placeholder="{{'LOGIN.address'|translate}}">
          </div>
        </div>
        <div class="confirmar">
          <button mat-button (click)="next()" [disabled]="nextButtonDisabled || loading">
            {{'LOGIN.confirm'|translate}}
          </button>
        </div>
      </form>
    </ng-container>
    <!-- modal confeti modificada -->
    <div class="success" *ngSwitchCase="LOGIN_STATES.SIGN_UP_PRE_REGISTERED_SUCCESSFUL">
      <h4>{{ 'LANDING_ALLO.register-now' | translate }}</h4>
      <div class="cont-miga">
        <span class="active"></span>
        <span class="active"></span>
        <span></span>
        <span></span>
      </div>
      <span class="lead">{{ 'LANDING_ALLO.send-email' | translate }}</span><br>
      <span class="lead gray">{{ 'LANDING_ALLO.received-confirmation-email' | translate }}</span>
      <div class="confirmar">
        <button mat-button (click)="registerCaseFour(true)" [disabled]="nextButtonDisabled">
          {{ 'LANDING_ALLO.try-again' | translate }}
        </button>
      </div>
    </div>
    <!-- modal nueva despues de correo de confirmación -->
    <div class="success" *ngSwitchCase="LOGIN_STATES.ACTIVATE_TERMS_AND_CONDITIONS">
      <h4>{{ 'LANDING_ALLO.register-now' | translate }}</h4>
      <div class="cont-miga">
        <span class="active"></span>
        <span class="active"></span>
        <span class="active"></span>
        <span></span>
      </div>
      <span class="lead">{{ 'LANDING_ALLO.please-confirm-information' | translate }}</span>
      <p class="datos"> E-mail: <span> {{ data.email }}</span></p>
      <p class="datos"> {{ 'LANDING_ALLO.first-name' | translate }}: <span> {{ data.name }}</span></p>
      <p class="datos"> {{ 'LANDING_ALLO.last-name' | translate }}: <span> {{ data.lname }}</span></p>
      <mat-checkbox class="example-margin" [(ngModel)]="terms"></mat-checkbox>
      <a class="example-margin"
        (click)="showTermsAndConditionsDialog()">{{ 'LANDING_ALLO.acept-terms-and-conditions' | translate  }}</a>
      <div class="confirmar">
        <button mat-button (click)="validateTermsAndConditions($event)" [disabled]="!terms">
          {{'LOGIN.confirm'|translate}}
        </button>
      </div>
      <a (click)="validateErrorSeems()"> {{'LANDING_ALLO.there-seems-to-be-err' | translate}} </a>
    </div>

    <!-- modal confeti modificada -->
    <div class="success confeti" *ngSwitchCase="LOGIN_STATES.ACTIVATE_CARD_CONFETI">
      <img class="confeti" src="../../../../assets/images/confeti.svg" alt="">
      <svg width="105px" height="105px" viewBox="0 0 105 105" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" class="check-icon">
        <!-- Generator: Sketch 63.1 (92452) - https://sketch.com -->
        <title>Combined Shape</title>
        <desc>Created with Sketch.</desc>
        <g id="VUELOS" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="CONGRATULATIONS" transform="translate(-669.000000, -664.000000)" fill="#5D13FE">
            <g id="Group" transform="translate(512.000000, 574.000000)">
              <g id="Group-4">
                <g id="Group-8">
                  <g id="Group-6" transform="translate(0.000000, 36.000000)">
                    <g id="Group-7">
                      <g id="Group-3">
                        <g id="Group-10">
                          <g id="Group-5">
                            <g id="Group-11" transform="translate(157.000000, 54.000000)">
                              <path
                                d="M52.5,0 C81.4949494,0 105,23.5050506 105,52.5 C105,81.4949494 81.4949494,105 52.5,105 C23.5050506,105 0,81.4949494 0,52.5 C0,23.5050506 23.5050506,0 52.5,0 Z M84.6482324,33.80508 C83.4360116,32.618644 81.491516,32.6395467 80.30508,33.8517676 L80.30508,33.8517676 L48.733,66.108 L33.69492,50.743383 L33.5547442,50.6087992 C32.3599907,49.5313402 30.5173645,49.5558916 29.3517676,50.6966954 C28.1395467,51.8831314 28.118644,53.827627 29.30508,55.0398479 L29.30508,55.0398479 L46.5389209,72.6482324 L46.6812599,72.7847735 C47.8946553,73.877102 49.770576,73.8315883 50.928761,72.6482324 L50.928761,72.6482324 L84.69492,38.1482324 L84.8264594,38.0051959 C85.8779858,36.7875564 85.8138294,34.9458838 84.6482324,33.80508 Z"
                                id="Combined-Shape"></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <h4>{{ 'LANDING_ALLO.congratulations' | translate }}</h4>
      <p class="lead gray">
        {{ 'LANDING_ALLO.your-shiny-new-miles-card' | translate }}
      </p>
    </div>
  </div>
  <!-- reset password modal -->
  <ng-container *ngSwitchCase="LOGIN_STATES.RECOVERY_PASS">
    <form [formGroup]="formRecoveryPass">
      <div class="personal" *ngIf="!passwordSent">
        <h4>{{'LOGIN.forgotPass'| translate}}</h4>
        <span class="lead dark">{{'LOGIN.enterEmail' | translate}}</span>
        <div class="input"
          [class.error]="formRecoveryPass.get('emailRecovery').touched && formRecoveryPass.get('emailRecovery').invalid">
          <mat-icon class="icon-mail-new"></mat-icon>
          <input type="email" formControlName="emailRecovery" autocomplete="off"
            placeholder="{{'LOGIN.email'|translate}}">
        </div>
        <div class="confirmar">
          <button mat-button (click)="next()" [disabled]="nextButtonDisabled">
            {{'LOGIN.confirm'|translate}}
          </button>
        </div>
      </div>
      <div *ngIf="passwordSent" class="bl-restablecer">
        <h4>{{'LOGIN.forgotPass'| translate}}</h4>
        <span innerHTML="{{ 'LOGIN.sendAgainPasswordAlright' | translate }}"></span>
        <span class="lead dark"
          innerHTML="{{ 'LOGIN.sendAgainPassword1' | translate:'{inputEmail:\'' + inputEmail + '\'}' }}"> </span>
        <span class="lead dark" innerHTML="{{ 'LOGIN.sendAgainPassword2' | translate }}"> </span>
      </div>
    </form>
  </ng-container>

  <!-- change password modal -->
  <ng-container *ngSwitchCase="LOGIN_STATES.MUST_CHANGE_PASS">
    <form [formGroup]="formMustChangePass">
      <div class="box" *ngIf="invalidPass">
        <div class="bot error">
          <p> {{ message }} </p>
        </div>
      </div>
      <div class="personal" *ngIf="!passwordSent">
        <h4>{{'LOGIN.STATES.log_in'| translate}}</h4>
        <span class="lead dark">{{'LOGIN.change_pass' | translate}}</span>

        <div class="input" [class.error]="formLogIn.get('email').touched && formLogIn.get('email').invalid">
          <mat-icon class="icon-lock"></mat-icon>
          <input #password type="password" formControlName="newPwd" autocomplete="off"
            placeholder="{{'LOGIN.newPasswor'|translate}}">
          <mat-icon (touchstart)="password.type='text'" (touchend)="password.type='password'"
            (mousedown)="password.type='text'" (mouseup)="password.type='password'"
            (mouseleave)="password.type='password'">
            {{password.type=='text' ? 'visibility_off' : 'visibility'}}
          </mat-icon>
        </div>
        <div class="input" [class.error]="formLogIn.get('password').touched && formLogIn.get('password').invalid">
          <mat-icon class="icon-lock"></mat-icon>
          <input #password1 type="password" formControlName="confirmPwd" autocomplete="off"
            placeholder="{{'LOGIN.password_confirm'|translate}}">
          <mat-icon (touchstart)="password1.type='text'" (touchend)="password1.type='password'"
            (mousedown)="password1.type='text'" (mouseup)="password1.type='password'"
            (mouseleave)="password1.type='password'">
            {{password1.type=='text' ? 'visibility_off' : 'visibility'}}
          </mat-icon>
        </div>
        <div class="confirmar">
          <button mat-button (click)="next()" [disabled]="nextButtonDisabled">
            {{'LOGIN.confirm'|translate}}
          </button>
        </div>
      </div>
      <div *ngIf="passwordSent" class="bl-restablecer">
        <h4>{{'LOGIN.forgotPass'| translate}}</h4>
        <span innerHTML="{{ 'LOGIN.sendAgainPasswordAlright' | translate }}"></span>
        <span class="lead dark"
          innerHTML="{{ 'LOGIN.sendAgainPassword1' | translate:'{inputEmail:\'' + inputEmail + '\'}' }}"> </span>
        <span class="lead dark" innerHTML="{{ 'LOGIN.sendAgainPassword2' | translate }}"> </span>
      </div>

    </form>
  </ng-container>

  <button class="close" mat-button [disabled]="loading" (click)="cancelar()">
    <mat-icon>close</mat-icon>
  </button>
</div>


<div class="{{brand}} footer" *ngIf="state === LOGIN_STATES.LOG_IN && dataService.getRegistrationType() > 1">
  <div class="text-one">
    {{'LOGIN.still_not_registered'|translate}}
  </div>
  <div class="text-two" *ngIf="brand === 'credomatic'" innerHTML="{{'LOGIN.be_part_of'|translate:'{milesGift:\''+ milesGift +'\'}'}}">
    {{'LOGIN.be_part_of'|translate:'{appName:\''+ appName +'\'}'}}
  </div>
  <div class="text-two" *ngIf="brand !== 'credomatic'" innerHTML="{{'LOGIN.be_part_of'|translate:'{appName:\''+ appName +'\'}'}}">
    {{'LOGIN.be_part_of'|translate:'{appName:\''+ appName +'\'}'}}
  </div>

  <div class="bac-text"> <!--bloque para footer credomatic-->
    <!-- <p *ngIf="showTextmilesGift" innerHTML="{{'LOGIN.regaloRegister'| translate: '{milesGift:\''+milesGift+'\'}'}}"></p> -->
    <button mat-button class="registrate" (click)="chooseOptionSignUp()">
      {{'LOGIN.sign_up'|translate}}
    </button>
  </div>


</div>

<div class="{{brand}} footer congratulations-footer" *ngIf="loadCardConfirm">
  <div class="text-two">
    Para continuar necesitas agregar una tarjeta asociada a tu programa de lealtad
  </div>
  <button mat-button class="registrate" (click)="openGetCard()">
    Agregar tarjeta
  </button>
</div>
