import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {SwiperComponent, SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {HotelGalleryComponent} from '../hotel-gallery.component';
import { Images } from 'src/app/container/shared/card/model/images';

@Component({
  selector: 'app-gallery-slider',
  templateUrl: './gallery-slider.component.html',
  styleUrls: ['./gallery-slider.component.scss']
})
export class GallerySliderComponent implements OnInit {

  type = 'component';
  show = true;
  config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 6,
    mousewheel: false,
    scrollbar: false,
    height: 65,
    spaceBetween: 10
  };
  prev: number;
  next: number;
  mainImageIndex = 0;


  images = [
    {
      imgURL: './assets/images/empty_room.png',
      label: ''
    }
  ];

  slides: any[] = [];
  @ViewChild(SwiperComponent) swiper: SwiperComponent;
  @Output() clickedImgSrc = new EventEmitter();


  constructor(public hotelGalleryComponent: HotelGalleryComponent) { }

  ngOnInit() {

    this.hotelGalleryComponent.images.forEach(img => {
      const found = this.slides.find(item => {
        const url1 = img.imgURL.split('?');
        const url2 = item.imgURL.split('?');
        if (url1[0] === url2[0]) {
          return item;
        }
      });
      if (found === undefined) {
        this.slides.push(img);
      }
    });

  }
  onIndexChange(ev) {
  }

  updateIndex (i) {
    this.mainImageIndex = i ;
    this.clickedImgSrc.emit(this.slides[this.mainImageIndex].imgURL);
    this.swiper.directiveRef.setIndex(this.mainImageIndex);
  }

  onErrorFn(index) {
    this.slides.splice(index, 1);
  }

  onNext() {
    if (this.mainImageIndex < this.slides.length - 1  ) {
      this.mainImageIndex += 1;
      this.clickedImgSrc.emit(this.slides[this.mainImageIndex].imgURL);
      this.swiper.directiveRef.setIndex(this.mainImageIndex);
    }
  }
  onPrev() {
    if (this.mainImageIndex > 0 ) {
      this.mainImageIndex -= 1;
      this.clickedImgSrc.emit(this.slides[this.mainImageIndex].imgURL);
      this.swiper.directiveRef.setIndex(this.mainImageIndex);
    }
  }

}
