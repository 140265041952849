<div>
    <app-main-form class="mb-1"></app-main-form>

    <div class="container">
        <div class="row">

            <div class="container mb-4" id="activity_container" *ngIf="activityList.length > 0">
                <div class="row">
                    <!-- CONTENEDORES BOTONES MOBILE -->
                    <div class="content-btn-filter">
                        <div (click)="showFilters = !showFilters" class="btn-click"
                        innerHTML="{{ 'ACTIVITY_BODY.filter_result' | translate }}">
                        Filter Results
                        </div>
                        <button mt-raised-button class="btn-click"
                        (click)="showPriceSlider = !showPriceSlider"
                        innerHTML="{{ 'ACTIVITY_BODY.adjust_price' | translate }}">
                        Adjust price
                        </button>
                    </div>

                    <!-- CONTENEDOR PARA FILTROS -->
                    <div [ngClass]="{'show':showFilters}"
                        class="filtros-responsive col-lg-2 mb-5" id="filters">
                        <app-filters #filtros
                            *ngIf="filtersParamsList"
                            [fullData]="activityList"
                            [filtersParamsList]="filtersParamsList"
                            (idsArrayToFilter)="filterListener($event)">
                        </app-filters>
                        <div (click)="showFilters = false" class="btn-click2"
                            innerHTML="{{ 'ACTIVITY_BODY.close_filters' | translate }}">
                            Close filters
                        </div>
                    </div>

                    <div class="content-activity col-lg-6">
                        <div [ngClass]="(mapView) ? 'bl-items-mapa' : 'bl-items'">
                            <app-activity-list #activityListCmp *ngIf="activityList.length > 0"
                                [list]="activityList" [style]="(!mapView) ? 1 : 2"
                                (detail)="activityDetailListener($event)"
                                [filteredID]="filteredID">
                            </app-activity-list>
                        </div>
                    </div>


                    <!-- AJUSTAR PRECIO: ONE-POCKET -->
                    <div class="col-md-4 col-12 content-iframe-b" id="one-pocket" *ngIf="dataStore.selectedActivity">
                        <div class="sticky-top" [class.slider-mobile]="(showPriceSlider)">
                            <div class="backdrop" (click)="showPriceSlider = false"></div>
                            <div class="body">
                                <app-slider
                                    *ngIf="dataStore.iframeUrl"
                                    [iframeURL]="dataStore.iframeUrl"
                                    (getPorcentaje)="actualizarPorcentaje($event)"
                                    [moneyCurrency]="dataStore.selectedActivity.price.currency"
                                    [totalCost]="dataStore.selectedActivity.price.totalPrice"
                                    [token]="dataService.token"
                                    [enabled]="dataStore.selectedActivity && (dataService.token && dataService.token !== '')">
                                </app-slider>
                                <div class="btn-aceptar">
                                <button class="btn-travel" (click)="showPriceSlider = false"
                                    innerHTML="{{ 'ACTIVITY_BODY.accept' | translate }}">
                                    Accept
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
