import { PurchaseSummaryService } from './../purchase-summary.service';
import { Component, OnInit } from '@angular/core';
import { SearchedService } from '../../searched/searched.service';
import { formatDate, getCurrencySymbol } from '@angular/common';
import { locale } from 'moment';
import { LanguageService } from 'src/app/component/language/language.service';
import { SearchedHotel } from '../../searched/models/searched-hotel.model';
import { HotelAvailabilityOptions } from 'src/app/models/new-hotel/hotel-availability-response.model';

@Component({
  selector: 'app-hotel-summary',
  templateUrl: './hotel-summary.component.html',
  styleUrls: ['./hotel-summary.component.scss']
})
export class HotelSummaryComponent implements OnInit {

  hotel: HotelAvailabilityOptions;
  translationDataService = [];
  searchedHotel: SearchedHotel;

  get translationsHotel(): any[] {
    return this.purchaseSummaryService.translationsHotel;
  }

  get translationDate(): any[] {
    return this.purchaseSummaryService.translationDate;
  }

  get currency() {
    const currency = this.purchaseSummaryService.hotel.selectedOption.price.currency;
    return currency + ' ' + getCurrencySymbol(currency, 'narrow');
  }

  constructor(
    private purchaseSummaryService: PurchaseSummaryService,
    private searchedService: SearchedService,
    private languageService: LanguageService
  ) {
    this.hotel = purchaseSummaryService.hotel;
    this.searchedHotel = this.searchedService.getModel(SearchedHotel);
  }

  get nights() {
    return this.searchedHotel.qNightsToString;
  }

  get guests() {
    return this.searchedHotel.qGuestToString;
  }

  get rooms() {
    const travellerInfo = this.hotel.selectedOption.rooms.length;
    return travellerInfo === 1
      ? travellerInfo + ' ' + this.translationsHotel['room']
      : travellerInfo + ' ' + this.translationsHotel['rooms'];
  }

  get roomName() {
    return this.hotel.selectedOption.rooms.map(room => room.name).join(', ');
  }

  get board() {
    return this.hotel.subnameSelected();
  }

  get dateCheckIn() {
    return formatDate(
      this.searchedHotel.date.start,
      this.translationDate['format_weekday'],
      locale(this.languageService.systemLanguajeChange.value)
    ).toString();
  }

  get dateCheckOut() {
    return formatDate(
      this.searchedHotel.date.end,
      this.translationDate['format_weekday'],
      locale(this.languageService.systemLanguajeChange.value)
    ).toString();
  }

  get cancellationPolicies() {
    if (this.hotel.bookingRules.results.cancellationPolicy) {
      return this.hotel.bookingRules.results.cancellationPolicy;
    } else {
      return null;
    }
  }

  fromDate(value) {
    return formatDate(
      value.from,
      this.translationDate['pipe_date'],
      locale(this.languageService.systemLanguajeChange.value));
  }

  ngOnInit() {
  }
}
