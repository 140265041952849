import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchResumeBarComponent } from './search-resume-bar.component';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { MaterialModule } from 'src/app/models/material';



@NgModule({
  declarations: [
    SearchResumeBarComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MaterialModule,
    PipesModule
  ],
  exports: [
    SearchResumeBarComponent
  ]
})
export class SearchResumeBarModule { }
