<!-- <div class="landing-sales-deck landing-fc" *ngIf="componentIsLoad"> -->
<div class="landing-sales-deck landing-fc">
	<app-nav-sales-deck></app-nav-sales-deck>
	<section class="section-cero">
	    <div class="container">
	        <div class="bl-coming">
	            <div class="bl-center">
	                <h1 [innerHTML]="'LANDING_SALESDECK.SECTION_FIRST.say_hello_to' | translate"></h1>
									<div class="logo-miles"></div>
	                <h3 [innerHTML]="'LANDING_SALESDECK.SECTION_FIRST.desc_1' | translate"></h3>
	                <h4 [innerHTML]="'LANDING_SALESDECK.SECTION_FIRST.desc_2' | translate"></h4>
	                <div class="landing-video">
	                	<video #video1 class="video-vimeo" poster="./assets/images/myrewards-miles/poster-video.png" controls>
											<source [src]="urlsVideos.one.url | srcSanitizer" type="video/mp4">
										</video>
	                </div>
	            </div>
	        </div>
	    </div>
	</section>

	<section class="gray">
		<div class="container">
			<div class="bl-coming">
				<div class="bl-center">
					<h3 [innerHTML]="'LANDING_SALESDECK.SECTION_TWO.desc_1' | translate"></h3>
					<img class="bg-mobiles" [src]="imgBgMovilesSales" alt="">
					<h3 [innerHTML]="'LANDING_SALESDECK.SECTION_TWO.desc_2' | translate"></h3>

					<ul class="logos-coming logos-fc">
			            <li><img src="./assets/images/myrewards-miles/logof-amazon.png" alt="mr"></li>
			            <li><img src="./assets/images/myrewards-miles/logof-ebay.png" alt="mr"></li>
			            <li><img src="./assets/images/myrewards-miles/logo-samsung1.png" alt="mr"></li>
			            <li><img src="./assets/images/myrewards-miles/logof-netflix.png" alt="mr"></li>
			            <li><img src="./assets/images/myrewards-miles/logof-apple.png" alt="mr"></li>
			            <li><img src="./assets/images/myrewards-miles/logof-microsoft.png" alt="mr"></li>
			        </ul>
				</div>
			</div>
		</div>
	</section>
	<section class="benefits">
		<div class="container">
			<div class="learn-about">

				<p>{{ 'LANDING_SALESDECK.SECTION_CONTACT.learn_more_about' | translate }} <a (click)="openGoToRedeem()">{{ 'LANDING_SALESDECK.SECTION_CONTACT.my_rewards' | translate }}</a></p>

				<p>{{ 'LANDING_SALESDECK.SECTION_CONTACT.contact_us' | translate }} <a [href]="linkWhatsApp" target="_blank">{{ 'LANDING_SALESDECK.SECTION_CONTACT.whatsapp' | translate }}</a></p>
			</div>

			<h2 class="underline">{{ 'LANDING_SALESDECK.SECTION_THREE.title_1' | translate }}</h2>

			<h3 [innerHTML]="'LANDING_SALESDECK.SECTION_THREE.subtitle_1' | translate"></h3>
			<div class="grid">
				<div class="item">
					<div class="head">
						<img src="./assets/images/myrewards-miles/benefits-icon1.svg">

						<h5 [innerHTML]="'LANDING_SALESDECK.SECTION_THREE.SECTION_1.title_1' | translate"></h5>
					</div>

					<p [innerHTML]="'LANDING_SALESDECK.SECTION_THREE.SECTION_1.text_1' | translate"></p>
				</div>
				<div class="item">
					<div class="head">
						<img src="./assets/images/myrewards-miles/benefits-icon2.svg">

						<h5 [innerHTML]="'LANDING_SALESDECK.SECTION_THREE.SECTION_2.title_1' | translate"></h5>
					</div>

					<p [innerHTML]="'LANDING_SALESDECK.SECTION_THREE.SECTION_2.text_1' | translate"></p>

					<p [innerHTML]="'LANDING_SALESDECK.SECTION_THREE.SECTION_2.text_2' | translate"></p>
				</div>
				<div class="item">
					<div class="head">
						<img src="./assets/images/myrewards-miles/benefits-icon3.svg">

						<h5 [innerHTML]="'LANDING_SALESDECK.SECTION_THREE.SECTION_3.title_1' | translate"></h5>
					</div>

					<p [innerHTML]="'LANDING_SALESDECK.SECTION_THREE.SECTION_3.text_1' | translate"></p>
				</div>
				<div class="item">
					<div class="head">
						<img src="./assets/images/myrewards-miles/benefits-icon4.svg">

						<h5 [innerHTML]="'LANDING_SALESDECK.SECTION_THREE.SECTION_4.title_1' | translate"></h5>
					</div>

					<p [innerHTML]="'LANDING_SALESDECK.SECTION_THREE.SECTION_4.text_1' | translate"></p>
				</div>
				<div class="item">
					<div class="head">
						<img src="./assets/images/myrewards-miles/benefits-icon5.svg">

						<h5 [innerHTML]="'LANDING_SALESDECK.SECTION_THREE.SECTION_5.title_1' | translate"></h5>
					</div>

					<p [innerHTML]="'LANDING_SALESDECK.SECTION_THREE.SECTION_5.text_1' | translate"></p>
				</div>
				<div class="item">
					<div class="head">
						<img src="./assets/images/myrewards-miles/benefits-icon6.svg">

						<h5 [innerHTML]="'LANDING_SALESDECK.SECTION_THREE.SECTION_6.title_1' | translate"></h5>
					</div>

					<p [innerHTML]="'LANDING_SALESDECK.SECTION_THREE.SECTION_6.text_1' | translate"></p>
				</div>
			</div>
		</div>
	</section>

	<section class="s-flex questions">
	    <div class="container">
	        <div class="bl-row">
	            <div class="bl-left">
									<h2 class="text-gradient underline" [innerHTML]="'LANDING_SALESDECK.SECTION_QUESTION.title_1' | translate"></h2>
	                <p [innerHTML]="'LANDING_SALESDECK.SECTION_QUESTION.text_1' | translate"></p>
	            </div>
	            <div class="bl-right">
	                <img class="imagen-video" [src]="imgIphoneTops" alt="">
	            </div>
	        </div>
	    </div>
	</section>

	<section class="capabilities">
		<div class="container">

			<h2 class="underline" [innerHTML]="'LANDING_SALESDECK.SECTION_FIVE.title_1' | translate"></h2>

			<h3 [innerHTML]="'LANDING_SALESDECK.SECTION_FIVE.text_1' | translate"></h3>
			<div class="grid">
				<div class="item">
					<div class="head">

						<h5 [innerHTML]="'LANDING_SALESDECK.SECTION_FIVE.SECTION_1.title_1' | translate"></h5>
					</div>
					<div class="body">

						<p [innerHTML]="'LANDING_SALESDECK.SECTION_FIVE.SECTION_1.text_1' | translate"></p>
						<video class="video-vimeo" (click)="showVideoModal('two')">

							<source [src]="urlsVideos?.two?.url | srcSanitizer" type="video/mp4">
						</video>
					</div>
				</div>
				<div class="item">
					<div class="head">

						<h5 [innerHTML]="'LANDING_SALESDECK.SECTION_FIVE.SECTION_2.title_1' | translate"></h5>
					</div>
					<div class="body">

						<p [innerHTML]="'LANDING_SALESDECK.SECTION_FIVE.SECTION_2.text_1' | translate"></p>
						<video class="video-vimeo" (click)="showVideoModal('three')">

							<source [src]="urlsVideos?.three?.url | srcSanitizer" type="video/mp4">
	                    </video>
					</div>
				</div>
				<div class="item">
					<div class="head">

						<h5 [innerHTML]="'LANDING_SALESDECK.SECTION_FIVE.SECTION_3.title_1' | translate"></h5>
					</div>
					<div class="body">

						<p [innerHTML]="'LANDING_SALESDECK.SECTION_FIVE.SECTION_3.text_1' | translate"></p>
						<video class="video-vimeo" (click)="showVideoModal('four')">

							<source [src]="urlsVideos?.four?.url | srcSanitizer" type="video/mp4">
	                    </video>
					</div>
				</div>
				<div class="item">
					<div class="head">

						<h5 [innerHTML]="'LANDING_SALESDECK.SECTION_FIVE.SECTION_4.title_1' | translate"></h5>
					</div>
					<div class="body">

						<p [innerHTML]="'LANDING_SALESDECK.SECTION_FIVE.SECTION_4.text_1' | translate"></p>
						<video class="video-vimeo" (click)="showVideoModal('five')">

							<source [src]="urlsVideos?.five?.url | srcSanitizer" type="video/mp4">
	                    </video>
					</div>
				</div>
				<div class="item">
					<div class="head">

						<h5 [innerHTML]="'LANDING_SALESDECK.SECTION_FIVE.SECTION_5.title_1' | translate"></h5>
					</div>
					<div class="body">

						<p [innerHTML]="'LANDING_SALESDECK.SECTION_FIVE.SECTION_5.text_1' | translate"></p>
						<video class="video-vimeo" (click)="showVideoModal('six')">

							<source [src]="urlsVideos?.six?.url | srcSanitizer" type="video/mp4">
	                    </video>
					</div>
				</div>
				<div class="item">
					<div class="head">

						<h5 [innerHTML]="'LANDING_SALESDECK.SECTION_FIVE.SECTION_6.title_1' | translate"></h5>
					</div>
					<div class="body">

						<p [innerHTML]="'LANDING_SALESDECK.SECTION_FIVE.SECTION_6.text_1' | translate"></p>
						<video class="video-vimeo" (click)="showVideoModal('seven')">

							<source [src]="urlsVideos?.seven?.url | srcSanitizer" type="video/mp4">
	                    </video>
					</div>
				</div>
				<div class="item">
					<div class="head">

						<h5 [innerHTML]="'LANDING_SALESDECK.SECTION_FIVE.SECTION_7.title_1' | translate"></h5>
					</div>
					<div class="body">

						<p [innerHTML]="'LANDING_SALESDECK.SECTION_FIVE.SECTION_7.text_1' | translate"></p>
						<ol>

							<li>{{ 'LANDING_SALESDECK.SECTION_FIVE.SECTION_7.list.text_1' | translate }}</li>

							<li>{{ 'LANDING_SALESDECK.SECTION_FIVE.SECTION_7.list.text_2' | translate }}</li>

							<li>{{ 'LANDING_SALESDECK.SECTION_FIVE.SECTION_7.list.text_3' | translate }}</li>
						</ol>
						<video class="video-vimeo" (click)="showVideoModal('eight')">

							<source [src]="urlsVideos?.eight?.url | srcSanitizer" type="video/mp4">
	                    </video>
					</div>
				</div>
				<div class="item">
					<div class="head">

						<h5 [innerHTML]="'LANDING_SALESDECK.SECTION_FIVE.SECTION_8.title_1' | translate"></h5>
					</div>
					<div class="body">

						<p [innerHTML]="'LANDING_SALESDECK.SECTION_FIVE.SECTION_8.text_1' | translate"></p>
						<video class="video-vimeo" (click)="showVideoModal('nine')">

							<source [src]="urlsVideos?.nine?.url | srcSanitizer" type="video/mp4">
	                    </video>
					</div>
				</div>
			</div>
			<div class="button-container">

				<button class="btn" (click)="openGoToPdf()">{{ 'LANDING_SALESDECK.SECTION_FIVE.text_button_download' | translate }}</button>
			</div>
			<div class="learn-about">

				<p>{{ 'LANDING_SALESDECK.SECTION_CONTACT.learn_more_about' | translate }} <a (click)="openGoToRedeem()">{{ 'LANDING_SALESDECK.SECTION_CONTACT.my_rewards' | translate }}</a></p>

				<p>{{ 'LANDING_SALESDECK.SECTION_CONTACT.contact_us' | translate }} <a [href]="linkWhatsApp" target="_blank">{{ 'LANDING_SALESDECK.SECTION_CONTACT.whatsapp' | translate }}</a></p>
			</div>
		</div>
	</section>

	<app-footer-fc-mr [brand]="brand"></app-footer-fc-mr>

	<!-- Modal Content Wrapper -->
      <div class="video-modal-content" [ngClass]="{'show': show_video}">
        <video id="video-mercy" width="400" controls muted>
            <source [src]="url">
        </video>
        <a class="close-video-modal" (click)="closeVideoModal()">
          <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0"
          y="0"
          viewBox="0 0 32 32"
          style="enable-background:new 0 0 32 32;"
          xml:space="preserve"
          width="24"
          height="24"
          >

              <g id="icon-x-close">
                  <path fill="#ffffff" d="M30.3448276,31.4576271 C29.9059965,31.4572473 29.4852797,31.2855701 29.1751724,30.980339 L0.485517241,2.77694915 C-0.122171278,2.13584324 -0.104240278,1.13679247 0.52607603,0.517159487 C1.15639234,-0.102473494 2.17266813,-0.120100579 2.82482759,0.477288136 L31.5144828,28.680678 C31.9872448,29.1460053 32.1285698,29.8453523 31.8726333,30.4529866 C31.6166968,31.0606209 31.0138299,31.4570487 30.3448276,31.4576271 Z" id="Shape"></path>
                  <path fill="#ffffff" d="M1.65517241,31.4576271 C0.986170142,31.4570487 0.383303157,31.0606209 0.127366673,30.4529866 C-0.12856981,29.8453523 0.0127551942,29.1460053 0.485517241,28.680678 L29.1751724,0.477288136 C29.8273319,-0.120100579 30.8436077,-0.102473494 31.473924,0.517159487 C32.1042403,1.13679247 32.1221713,2.13584324 31.5144828,2.77694915 L2.82482759,30.980339 C2.51472031,31.2855701 2.09400353,31.4572473 1.65517241,31.4576271 Z" id="Shape"></path>
              </g>

              </svg>
          </a>

      </div><!-- end modal content wrapper -->
</div>