import { Component, OnInit, Input } from '@angular/core';
import { DataStoreService } from '../../../services/data-store.service';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-section-three-scotia',
  templateUrl: './section-three-scotia.component.html',
  styleUrls: ['./section-three-scotia.component.scss']
})
export class SectionThreeScotiaComponent implements OnInit {

  @Input() brand: string;
  urlsVideos: any = environment.brands[this.dataStore.brand].videosLandingEN.sectionThree;

  imgSwitch = `./assets/images/${this.dataStore.brand}-miles/iphone-switch.png`;
  imgPayPint = `./assets/images/${this.dataStore.brand}-miles/pay-points.png`;

  constructor(private dataStore: DataStoreService,
    private store: StoreServiceLibrary,
    private translate: TranslateService) { }

  ngOnInit() {
    this.validateLanguaje();
    this.changeLanguaje();
  }

  changeLanguaje() {
    this.translate.onLangChange.subscribe((data) => {
      if (data.lang === 'es') {
        this.imgSwitch = `./assets/images/${this.dataStore.brand}-miles/es/iphone-switch.png`;
        this.imgPayPint = `./assets/images/${this.dataStore.brand}-miles/es/pay-points.png`;
        this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingES.sectionThree;
      } else {
        this.imgSwitch = `./assets/images/${this.dataStore.brand}-miles/iphone-switch.png`;
        this.imgPayPint = `./assets/images/${this.dataStore.brand}-miles/pay-points.png`;
        this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingEN.sectionThree;
      }
    });
  }

  validateLanguaje() {
    let lang = this.translate.currentLang;
    if (lang === 'es') {
      this.imgSwitch = `./assets/images/${this.dataStore.brand}-miles/es/iphone-switch.png`;
      this.imgPayPint = `./assets/images/${this.dataStore.brand}-miles/es/pay-points.png`;
      this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingES.sectionThree;
    } else {
      this.imgSwitch = `./assets/images/${this.dataStore.brand}-miles/iphone-switch.png`;
      this.imgPayPint = `./assets/images/${this.dataStore.brand}-miles/pay-points.png`;
      this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingEN.sectionThree;
    }
  }
}


