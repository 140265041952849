<div class="item ps-0" *ngIf="list && list.length > 0">
    <ng-container
        *ngFor="let activity of list | filter:filteredID; let j = index; let f2 = first;">
        <app-card *ngIf="j < qtyResultShow && dataStore.selectedActivity"
            [product]="activity" [options]="cardOptions"
            [selectedProductId]="dataStore.selectedActivity.id"
            (productDetailSelected)="detailCardListener($event)"
            (selectProduct)="selectProductListener($event)">
        </app-card>
    </ng-container>
    <div class="clearfix"></div>
    <div class="button-container mt-3">
        <button class="btn-travel btn-hotel" *ngIf="(list | filter:filteredID).length > qtyResultShow"
        (click)="AddResults()" innerHTML="{{ 'HOTEL_BODY.HOTEL_LIST.more_results' | translate }}">
        Mostrar más resultados
        </button>
    </div>
</div>