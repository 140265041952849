 <!--START ESTE ES EL COMPONENTE DE LA TABLA FLIGHTS -->
 <div class="container">
 	
 
<div class="content-flights">

    <div class="info-flights">
    	<div class="top-info">
    		<h1>Flight <br> Redemption <br> Chart</h1>
	    	<p>Only applies to purchases made within the last 14 days. In case of travel bookings, applicable rates may apply.</p>
    	</div>	
    	<div class="bottom-info">
    		<p>For destinations marked as "variable", cost is variable at a rate of US$ 0,015 per point.</p>
        <p>The reduced points threshold for redemption for the Orlando, Ft Lauderdale, Miami and New York geographies includes only the major international airports located in these cities.</p>
    	</div>	
    </div>

    <div class="table-container">
	    <div class="table-flights">
	        <div class="bl-box-tittle">
	            <div class="left">
	                <span>Roundtrip Destinations</span>
	            </div>

	            <div class="right">
	                <p>ScotiaPoints Required</p>
	                <div class="bl-two">
	                    <span>Economy</span>
	                    <span>Business</span>
	                </div>
	            </div>
	        </div>

	        <div class="bl-box">
	            <div class="left">
	                <p>Within the Caribbean</p>
	            </div>

	            <div class="right">
	                <div class="item">
	                		<label for="1">
	                    	<input id="1" type="checkbox">
	                    	<span class="custom-check"></span>
	                    </label>
	                    <span>20,000</span>
	                </div>
	                
	                <div class="item">
	                		<label for="2">
	                			<input id="2" type="checkbox" >
	                			<span class="custom-check"></span>
	                		</label>
	                    <span>40,000</span>
	                </div>                    
	            </div>
	        </div>

	        <div class="bl-box gray strong">
	            <div class="left">
	                <p>Caribbean to Miami (MIA), Orlando (MCO), Fort Lauderdale (FLL), & New York (JFK & LGA)</p>
	            </div>

	            <div class="right">
	                <div class="item">
	                		<label for="3">
	                			<input id="3" type="checkbox">
	                			<span class="custom-check"></span>
	                		</label>
	                    <span>20,000<br> (NEW)</span>
	                </div>
	                <div class="item">
	                		<label for="4">
	                			<input id="4" type="checkbox" >
	                			<span class="custom-check"></span>
	                		</label>
	                    <span>40,000<br> (NEW)</span>
	                </div>
	            </div>
	        </div>

	        <div class="bl-box">
	            <div class="left">
	                <p>Caribbean & North America (East Coast)</p>
	            </div>

	            <div class="right">
	                <div class="item">
	                		<label for="5">
	                			<input id="5" type="checkbox" >
	                			<span class="custom-check"></span>
	                		</label> 
	                    <span>30,000</span>
	                </div>
	                <div class="item">
	                		<label for="6">
	                			<input id="6" type="checkbox" >
	                    	<span class="custom-check"></span>
	                		</label>
	                    <span>60,000</span>
	                </div>
	            </div>
	        </div>

	        <div class="bl-box gray">
	            <div class="left">
	                <p>Caribbean & North America (West Coast)</p>
	            </div>

	            <div class="right">
	                <div class="item disabled">
	                		<label for="7">
	                			<input id="7" type="checkbox" disabled>
	                    	<span class="custom-check"></span>
	                		</label>
	                    <span>50,000</span>
	                </div>
	                <div class="item">
	                		<label for="8">
	                			<input id="8" type="checkbox" >
	                    	<span class="custom-check"></span>
	                		</label>
	                    <span>100,000</span>
	                </div>
	            </div>
	        </div>

	        <div class="bl-box">
	            <div class="left">
	                <p>Caribbean & Central America, Colombia, Ecuador, Peru, Venezuela</p>
	            </div>

	            <div class="right">
	                <div class="item disabled">
	                		<label for="9">
	                			<input id="9" type="checkbox" disabled>
	                    	<span class="custom-check"></span>
	                		</label>
	                    <span>50,000</span>
	                </div>
	                <div class="item">
	                    <label for="10">
	                			<input id="10" type="checkbox" >
	                			<span class="custom-check"></span>
	                		</label>
	                    <span>100,000</span>
	                </div>
	            </div>
	        </div>

	        <div class="bl-box gray">
	            <div class="left">
	                <p>Caribbean & Europe</p>
	            </div>

	            <div class="right">
	                <div class="item disabled">
	                    <label for="11">
	                			<input id="11" type="checkbox" disabled>
	                			<span class="custom-check"></span>
	                		</label>
	                    <span>70,000</span>
	                </div>
	                <div class="item disabled">
	                    <label for="12">
	                			<input id="12" type="checkbox" disabled>
	                			<span class="custom-check"></span>
	                		</label>
	                    <span>140,000</span>
	                </div>
	            </div>
	        </div>

	        <div class="bl-box">
	            <div class="left">
	                <p>Return airfares within the Continental USA</p>
	            </div>

	            <div class="right">
	                <div class="item">
	                    <label for="13">
	                			<input id="13" type="checkbox" >
	                			<span class="custom-check"></span>
	                		</label>
	                    <span>30,000</span>
	                </div>
	                <div class="item">
	                    <label for="14">
	                			<input id="14" type="checkbox" >
	                			<span class="custom-check"></span>
	                		</label>
	                    <span>60,000</span>
	                </div>
	            </div>
	        </div>

	        <div class="bl-box gray">
	            <div class="left">
	                <p>One way airfares and destinations not listed above</p>
	            </div>

	            <div class="right">
	                <div class="item">
	                    <label for="15">
	                			<input id="15" type="checkbox" >
	                			<span class="custom-check"></span>
	                		</label>
	                    <span>Variable</span>
	                </div>
	                <div class="item disabled">
	                    <label for="16">
	                			<input id="16" type="checkbox" disabled>
	                			<span class="custom-check"></span>
	                		</label>
	                    <span>Variable</span>
	                </div>
	            </div>
	        </div>
	    </div>
	    <div class="checkout-form">
	    	<h3>Roundtrip Destinations</h3>
	    	<div class="info">
	    		<p>Caribbean to Miami (MIA), Orlando (MCO), Fort Lauderdale (FLL), & New York (JFK & LGA)</p>
	    		<p><span>Total Scotia Points</span> 20,000</p>
	    	</div>
	    </div>
	    <div class="btn btn-disable">Start your Adventure</div> <!--clases para btn active y disable "btn-disable" "btn-active"-->
	  </div>

</div>
</div>
<!-- END ESTE ES EL COMPONENTE DE LA TABLA FLIGHTS -->
