<form [formGroup]="carsSearchForm" novalidate class="carSearcher" (ngSubmit)="searchC()">
    <div class="search-cars" [ngClass]="{'is-mobile': dataStore.isMobileDevice}">

        <div class="panel">


            <div class="input" [ngClass]="{'return-in-same-place':carsSearchForm.get('dropOff').disabled}">
                <label class="label-of-ng-select">
          {{'SEARCH_CARS.withdrawal'|translate}}
        </label>
                <ng-select class="autocompleteSearch" [selectOnTab]="true" [items]="allCities" bindLabel="name" bindValue="id" dropdownPosition="bottom" [searchFn]="returnTrue" placeholder="{{placeholderPlace}}" [virtualScroll]="true" (search)="onSearch($event)" (change)="onChangePickUp($event)"
                    formControlName="pickUp" [notFoundText]="notFoundText" groupBy="type">
                    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                        <div>
                            <span>{{item.name}}</span>
                        </div>
                    </ng-template>
                </ng-select>
                <div class="alert_red" *ngIf="pickUpNotSelected" innerHTML="{{'SEARCH_CARS.please_indicate_pick_up'|translate}}">
                    Please indicate the origin of your trip
                </div>
                <div class="chk">
                    <mat-checkbox (click)="placeOfReturnToggle()" color="primary">
                        {{'SEARCH_CARS.back_another'|translate}}
                    </mat-checkbox>
                </div>
            </div>

            <div *ngIf="carsSearchForm.get('dropOff').enabled" class="input">
                <label class="label-of-ng-select" [ngClass]="{'disabled':carsSearchForm.get('dropOff').disabled}">
          {{'SEARCH_CARS.return'|translate}}
        </label>
                <ng-select class="autocompleteSearch" [selectOnTab]="true" [items]="allCities" bindLabel="name" bindValue="id" dropdownPosition="bottom" [searchFn]="returnTrue" placeholder="{{placeholderPlace}}" [virtualScroll]="true" (search)="onSearch($event)" (change)="onChangeDropOff($event)"
                    formControlName="dropOff" [notFoundText]="notFoundText" groupBy="type">
                    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                        <div>
                            <span>{{item.name}}</span>
                        </div>
                    </ng-template>
                </ng-select>
                <div class="alert_red" *ngIf="pickUpNotSelected" innerHTML="{{'SEARCH_CARS.please_indicate_drop_off'|translate}}">
                    Please indicate the origin of your trip
                </div>
            </div>

            <!-- <div class="input fecha-inputs">
                <label>{{'SEARCH_CARS.rental_date'|translate}}</label>
                <input *ngIf="refresh" class="dateSelect form-control" type="text" ngxDaterangepickerMd readonly formControlName="dateSelect" emptyWeekRowClass="empty-week" lastDayOfPreviousMonthClass="disabled-previous-day" firstDayOfNextMonthClass="disabled-nex-day"
                    [linkedCalendars]="true" placeholder="{{placeholderSelectdate}}" [showDropdowns]="true" [autoApply]="false" [locale]="locale" [singleDatePicker]="false" [minDate]="minDate" [timePicker]="true" [timePickerIncrement]="15" />
            </div> -->

            <div class="fecha-inputs" *ngIf="refresh">
                <div class="bl-input-icon">
                    <input #dateStart [(ngModel)]="selected" class="dateSelect form-control" (openedChange)="openedChangeStart($event)" type="text" appCalendarSearch readonly formControlName="dateSelect" emptyWeekRowClass="empty-week" placeholder="{{'SEARCH_HOTELS.select_date' | translate}}"
                        [linkedCalendars]="true " [showDropdowns]="true " [autoApply]="false " [locale]="locale " [singleDatePicker]="true " [minDate]="minDate " [dateShowTogether]="false " [isStart]="true " [timePicker]="true" [timePickerIncrement]="15"
                    />
                </div>
                <div class="bl-input-icon icon-last">
                    <input #dateEnd [(ngModel)]="selected" class="dateSelect form-control" (openedChange)="openedChangeEnd($event)" type="text" appCalendarSearch readonly formControlName="dateSelect" emptyWeekRowClass="empty-week" placeholder="{{'SEARCH_HOTELS.select_date' | translate}}"
                        [linkedCalendars]="true " [showDropdowns]="true " [autoApply]="false " [locale]="locale " [singleDatePicker]="true " [minDate]="minDate " [dateShowTogether]="false " [isEnd]="true " [timePicker]="true" [timePickerIncrement]="15" />

                </div>
            </div>

            <div class="input driver">
                <label>{{'SEARCH_CARS.drivers_age'|translate}}</label>
                <mat-form-field>
                    <mat-label innerHTML="{{'SEARCH_CARS.select_age'|translate}}">
                        Select age
                    </mat-label>
                    <mat-select formControlName="driverAge" (change)="updateAgeValidator()">
                        <mat-option *ngFor="let age of driverAges" [value]="age">
                            {{age}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="alert_red" *ngIf="ageNotSelected" innerHTML="{{'SEARCH_CARS.please_select_age'|translate}}">
                </div>
            </div>

        </div>

        <div class="buscar" (click)="searchC()" [title]="'SEARCH_CARS.search'|translate">
            <span>{{'SEARCH_CARS.search'|translate}}</span>
        </div>

    </div>
</form>