import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HamburgerComponent } from './hamburger.component';
import { TranslateModule } from '@ngx-translate/core';
import { LanguajeModule } from 'src/app/component/language/language.module';
import { IconMenuComponent } from './icon-menu/icon-menu.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { SelectorCountryComponent } from '../selector-country/selector-country.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';



@NgModule({
  declarations: [
    HamburgerComponent,
    IconMenuComponent,
    SelectorCountryComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    LanguajeModule,
    PipesModule,
    ReactiveFormsModule,
    NgSelectModule
  ],
  exports: [
    HamburgerComponent
  ],
  entryComponents: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HamburgerModule { }
