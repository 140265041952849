import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-passengers-landing',
  templateUrl: './passengers-landing.component.html',
  styleUrls: ['./passengers-landing.component.css']
})
export class PassengersLandingComponent implements OnInit {
  totalPassengersSelect:number;
  totalPassenger:number = 3;
  
  totalAges = [];
  totalAgeSelect = [];
  totalPassengers = [];
  minorPassengers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  existeError: boolean;
  msgError:string;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PassengersLandingComponent>
  ) { 
    this.totalPassenger = data.totalPassenger;
  }

  ngOnInit(): void {
    if(this.totalPassenger > 0){
      this.totalPassengers = [];
      for (let nPos = 0; nPos <= this.totalPassenger; nPos++) {
        this.totalPassengers.push(nPos);
      }
    }
  }

  getTotalPassengers() {
    this.totalAges = [];
    this.totalAgeSelect = [];
    this.existeError = false;
    this.msgError= '';
    for (let nPos = 0; nPos < this.totalPassengersSelect; nPos++) {
      this.totalAges.push(nPos);
      this.totalAgeSelect.push(0);
    }
  }

  apply() {
    if(!this.validInfo()){
      this.close();
    }
  }

  validInfo(): boolean{
    const adults = this.totalPassenger - this.totalPassengersSelect;
    var infants = 0;
    this.msgError = '';
    this.totalAgeSelect.forEach((age) => {
      if(age < 2) {
        infants++;
      }
    });
    
    if(infants > adults) {
      this.msgError = 'Solo 1 infante por adulto (-2)';
      this.existeError = true;
    }
    return false;
  }

  close() {
    let infants = 0;
    let infantsAges = [];
    let children = 0;
    let childrenAges = [];
    
    this.totalAgeSelect.forEach((age) => {
      if(age < 2) {
        infants++;
        infantsAges.push((age < 10 ? '0' : '') + age);
      } else {
        children++;
        childrenAges.push((age < 10 ? '0' : '') + age);
      }
    });

    const response = {
      adults: this.totalPassenger - this.totalPassengersSelect,
      infants: infants,
      infantsAges: infantsAges,
      children: children,
      childrenAges: childrenAges
    };
    this.dialogRef.close(response);
  }
}
