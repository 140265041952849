import { Component, OnInit } from '@angular/core';
import { ContentfulService } from 'src/app/services/contentful/contentful.service';
import { InitService } from 'src/app/services/init.service';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ActionEventService } from 'src/app/services/action-event.service';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { LanguageService } from '../language/language.service';

import { GoogleAnalytics } from '../../resources/GoogleAnalytics.resource';
import { JuniperService } from '../../services/juniper.service';
import data from '../../resources/screenAndEventsTracking.json';
import { keysUniversal } from '../../resources/keys.resources';
import { formatDate } from '@angular/common';
import { locale } from 'moment';
import { DataService } from 'src/app/services/data.service';
import { DataStoreService } from 'src/app/services/data-store.service';
import { Router } from '@angular/router';
import { SearchedService } from '../searched/searched.service';
import { SearchedHotel } from '../searched/models/searched-hotel.model';
import { IRoomPaxes, IPaxe, EProduct } from '../searched/models/searched.model';
import { TranslateService } from '@ngx-translate/core';
import { SearchedFlight } from '../searched/models/searched-flight.model';

@Component({
  selector: 'app-promociones-ctf',
  templateUrl: './promociones-ctf.component.html',
  styleUrls: ['./promociones-ctf.component.scss']
})
export class PromocionesCtfComponent implements OnInit {

  EProduct = EProduct;

  flightPromo: {
    src?: string;
    destino?: string;
    precio?: string;
    currency?: string;
    // datos para realizar la búsqueda
    search?: Object;
  }[] = [];

  hotelPromo: {
    src?: string;
    destino?: string;
    precio?: string;
    currency?: string;
    // datos para realizar la búsqueda
    search?: Object;
  }[] = [];

  swiperConfig = <SwiperConfigInterface>{
    slidesPerView: 3,
    slidesPerGroup: 3,
    slidesPerColumn: 1,
    slidesPerColumnFill: 'row',
    spaceBetween: 30,
    centeredSlidesBounds: true,
    autoHeight: true,
    autoplay: true,
    loop: true,
    navigation: true,
    breakpoints: {
      960: {
        slidesPerView: 3,
      },
      880: {
        slidesPerView: 2.3,
        slidesPerGroup: 2.3,
      },
      740: {
        slidesPerView: 2.1,
        slidesPerGroup: 2.1,
      },
      640: {
        slidesPerView: 1.5,
        slidesPerGroup: 1.5,
      },
      540: {
        slidesPerView: 1.3,
        slidesPerGroup: 1.3,
      },
      440: {
        slidesPerView: 1.15,
        slidesPerGroup: 1.15,
      },
      360: {
        slidesPerView: 1.1,
        slidesPerGroup: 1.1,
      },
      240: {
        slidesPerView: 1.1,
        slidesPerGroup: 1.1,
      },
    }
  };

  contentful: any;
  private systemLanguage = 'EN';

  brand = '';

  constructor(
    private actionEventService: ActionEventService,
    private _storeLibrary: StoreServiceLibrary,
    private initService: InitService,
    private contentfulService: ContentfulService,
    private languageService: LanguageService,
    private juniperService: JuniperService,
    private dataService: DataService,
    private dataStore: DataStoreService,
    private router: Router,
    private searchedService: SearchedService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.initService.isInitializedBrand.subscribe((isInit) => {
      if (isInit) {
        // this.contentfulService.getContentful(Object.assign({ 'content_type': 'Test1234' })).then((entry: any) => {
        // this.contentfulService.getContentful(Object.assign({ 'content_type': 'sliderPromoCri' })).then((entry: any) => {

        // });

        this.brand = this.dataStore.brand;

        let contentful = this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.contentful);
        if (contentful != '') {
          this.contentful = JSON.parse(contentful);
        }

        this.countryCodeChange();
        if(
          this._storeLibrary.getItemFromStorage('CountrySelected')
          && this.contentful?.slidersCodePromotions[this._storeLibrary.getItemFromStorage('CountrySelected')]){
          this.loadSliders(this.contentful.slidersCodePromotions[this._storeLibrary.getItemFromStorage('CountrySelected')]);
        }
      }


      this.languageService.systemLanguajeChange.subscribe((language) => {
        if (language) {
          this.systemLanguage = language.toUpperCase();
        }
      });


    });
  }

  countryCodeChange() {
    this.actionEventService.countryCodeChange.subscribe((countryCodeChange) => {
      if (
        countryCodeChange?.code
        && this.contentful?.slidersCodePromotions
        && this.contentful?.slidersCodePromotions[countryCodeChange.code]) {
          this.loadSliders(this.contentful.slidersCodePromotions[countryCodeChange.code]);
      }
    });
  }

  //http://cdn.contentful.com/spaces/ehvm3g5ujya3/entries?access_token=7AJFPp_LjrN5K2FuV31KlNpyJIJ_jpe1dYr89cAhaCo&content_type=test1
  private loadSliders(contentType: string) {
    this.contentfulService.getContentfulV2(Object.assign({ 'content_type': contentType })).then((entry: any) => {
      let auxSliders = entry.items.sort(function (a, b) {
        return a.fields.order - b.fields.order;
      });

      this.flightPromo = [];
      this.hotelPromo = [];
      for (const itrSlider of auxSliders) {

        //Asignar la imagen segun el src del JSON o la imagen insertada en contentful
        let imagen = itrSlider?.fields?.jsonPromociones?.promotion[this.systemLanguage]?.src;
        if (itrSlider?.fields?.imagen?.sys?.id) {
          for (const itr of entry?.includes?.Asset) {
            if (itr?.sys?.id == itrSlider?.fields?.imagen?.sys?.id) {
              if (itr?.fields?.file?.url) {
                imagen = itr?.fields?.file?.url;
              }
            }
          }
        }

        switch (itrSlider.fields.jsonPromociones.product) {
          case 'flights':
            this.flightPromo.push(
              {
                src: imagen,
                destino: itrSlider?.fields?.jsonPromociones?.promotion[this.systemLanguage]?.destino,
                precio: itrSlider?.fields?.jsonPromociones?.promotion[this.systemLanguage]?.precio,
                currency: itrSlider?.fields?.jsonPromociones?.promotion[this.systemLanguage]?.currency,
                // datos para realizar la búsqueda
                search: itrSlider?.fields?.jsonPromociones?.promotion[this.systemLanguage]?.search
              }
            );
            break;
          case 'hotels':
            this.hotelPromo.push(
              {
                src: imagen,
                destino: itrSlider?.fields?.jsonPromociones?.promotion[this.systemLanguage]?.destino,
                precio: itrSlider?.fields?.jsonPromociones?.promotion[this.systemLanguage]?.precio,
                currency: itrSlider?.fields?.jsonPromociones?.promotion[this.systemLanguage]?.currency,
                // datos para realizar la búsqueda
                search: itrSlider?.fields?.jsonPromociones?.promotion[this.systemLanguage]?.search
              }
            );
            break;
        }
      }
    });
  }





  /**
   * realizar búsqueda
   */
  search(product: EProduct, search: Object) {
    let paramsPromo = search;
    let useSerachIframe = this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.USE_SEARCH_IFRAME) || false;
    switch (product) {
      case EProduct.HOTELS:
        // this.trackingManager.trackEventString(data.WELCOME.promoHotels, data.WELCOME.welcomeScreen, '', JSON.stringify(paramsPromo));
        GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.WELCOME.promoHotels, JSON.stringify(paramsPromo));

        if (useSerachIframe) {
          // GET PARAMS OF SEARCH
          const urlToChangeHotels = this.juniperService.searchHotelsJuniper('searchhotels', search);
          this.actionEventService.setChangeUrlJuniper(urlToChangeHotels, true);
        } else {
          this.searchHotels(search);
        }
        break;
      case EProduct.FLIGHTS:
        // this.trackingManager.trackEventString(data.WELCOME.promoFlights, data.WELCOME.welcomeScreen, '', JSON.stringify(paramsPromo));
        GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, data.WELCOME.promoFlights, JSON.stringify(paramsPromo));
        if (useSerachIframe) {
          const urlToChangeFligths = this.juniperService.searchFlightsJuniper('searchflights', search);
          this.actionEventService.setChangeUrlJuniper(urlToChangeFligths, true);
        } else {
          this.searchFlight(search);
        }
        break;
    }
  }


  private searchFlight(search: Object) {
    search['startDate'] = formatDate(
      search['startDate'],
      'yyyy-MM-dd',
      locale(this.systemLanguage.toLocaleLowerCase())
    ).toString();

    search['endDate'] = formatDate(
      search['endDate'],
      'yyyy-MM-dd',
      locale(this.systemLanguage.toLocaleLowerCase())
    ).toString();

    this.dataService.flightAvailabilityRequest = {
      journey: [{
        departureAirportCity: search['departureAirportCity'],
        arrivalAirportCity: search['arrivalAirportCity'],
        flightDate: search['startDate']
      },
      {
        departureAirportCity: search['arrivalAirportCity'],
        arrivalAirportCity: search['departureAirportCity'],
        flightDate: search['endDate']
      }],
      passengers: [{ type: 'ADT', count: search['qAdultos'] },
      { type: 'CHD', count: 0 },
      { type: 'INF', count: 0 }],
      cabinClasses: ['Y', 'B', 'F', 'P', 'M'],
      targetCurrency: this.dataStore.brandTargetCurrency,
      language: this.languageService.systemLanguajeChange.value.toUpperCase()
    };

    // valores que fueron buscados
    this.searchedService.setModel(<SearchedFlight>{
      origin: {
        iata: search['departureAirportCity'],
        id: undefined,
        name: search['departureAirportCityText'],
        type: undefined
      },
      destination: {
        iata: search['arrivalAirportCity'],
        id: undefined,
        name: search['arrivalAirportCityText'],
        type: undefined
      },
      journey: ((this.dataService.flightAvailabilityRequest.journey.length === 1) ? 'oneWay' : 'idaVuelta'),
      date: {
        start: search['startDate'],
        end: search['endDate']
      },
      quantity: {
        adults: +search['qAdultos'],
        ninos: +search['qNinos'],
        babes: +search['qBebes']
      },
      cabinClass: {
        economy: true,
        economyPremium: true,
        executive: true,
        first: true,
        combinada: true
      }
    });

    this.dataService.productSelected = EProduct.FLIGHTS;
    this.dataStore.preloaderImg = this.dataService.productSelected;
    this.dataService.saveFlightRequest(this.dataService.flightAvailabilityRequest);
    this.router.navigate(['flights/results']);
  }

  private searchHotels(search: Object) {
    const checkInText = formatDate(
      search['checkIn'],
      this.translateService.instant('DATE.format_weekday'),
      locale(this.languageService.systemLanguajeChange.value)
    ).toLocaleString();

    const checkOutText = formatDate(
      search['checkOut'],
      this.translateService.instant('DATE.format_weekday'),
      locale(this.languageService.systemLanguajeChange.value)
    ).toLocaleString();

    const countryResidance = this.dataService.smartProfile && this.dataService.smartProfile.accountResultDto
      ? this.dataService.smartProfile.accountResultDto.accountSumaryDto.addtionalInformationDto.isoCountry : 'AR';

    const requestInfo = {
      'checkIn': search['checkIn'],
      'checkOut': search['checkOut'],
      'destinationCode': search['destinyCode'],
      'countryOfResidence': countryResidance,
      'roomDistribution': [
        {
          'paxes': [
            {
              'id': 1,
              'age': 21
            },
            {
              'id': 2,
              'age': 21
            }
          ]
        }
      ],
      'targetCurrency': this.dataStore.brandTargetCurrency,
      'language': this.languageService.systemLanguajeChange.value.toUpperCase()
    };

    // valores que fueron buscados
    this.searchedService.setModel(<SearchedHotel>{
      destination: {
        iata: search['destinyIata'],
        id: search['destinyCode'],
        name: search['destinyName'],
        type: undefined
      },
      date: {
        start: search['checkIn'],
        end: search['checkOut']
      },
      roomsPaxes: <IRoomPaxes[]>[<IRoomPaxes>{
        paxes: [<IPaxe>{
          id: 1,
          age: 21
        }, <IPaxe>{
          id: 2,
          age: 21
        }]
      }]
    });

    this.dataService.saveRequestHotel(requestInfo);
    this.dataStore.hotelDataSearch = requestInfo;

    let searchParams_totalNights = '';
    if (+search['totalNights'] === 1) {
      // searchParams_totalNights = search['totalNights'] + ' ' + this.translations['SEARCH_HOTELS']['text_day'];
      searchParams_totalNights = search['totalNights'] + ' ' + this.translateService.instant('SEARCH_HOTELS.text_day');
    } else {
      // searchParams_totalNights = search['totalNights'] + ' ' + this.translations['SEARCH_HOTELS']['text_days'];
      searchParams_totalNights = search['totalNights'] + ' ' + this.translateService.instant('SEARCH_HOTELS.text_days');
    }

    this.dataStore.hotelInfoParams = {
      originDestinyIata: search['destinyName'],
      departureArrivalDates: 'Check in ' + checkInText + ' - ' + ' Check out ' + checkOutText,
      qPasangers: search['qPasangersB'],
      qNights: searchParams_totalNights,
      citiName: search['destinyName']
    };

    this.dataService.productSelected = EProduct.HOTELS;
    this.dataStore.preloaderImg = this.dataService.productSelected;
    this.dataService.saveHotelParamsInfo(this.dataStore.hotelInfoParams);
    this.router.navigate(['hotels/results']);
  }



}
