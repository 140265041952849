import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HotelService {

  public BaseUrl = 'https://api.bitacora.travel/api/';
  //public BaseUrl = 'http://testapi.bitacora.travel/api/';
  //public BaseUrl = 'http://localhost:11049/api/';

  constructor(
    private httpClient: HttpClient
  ) { }

  GetHotels() {
    return this.httpClient.post(this.BaseUrl + 'Hotel/Hotels/', {});
  }
  GetHotelsBySegment() {
    return this.httpClient.post(this.BaseUrl + 'Hotel/HotelsSegment/', {});
  }

  GetPriorityHotels() {
    return this.httpClient.post(this.BaseUrl + 'Hotel/HotelsByPriority/', {});
  }

  GetHotelDetail(Hotel) {
    return this.httpClient.post(this.BaseUrl + 'Hotel/HotelDetail', Hotel);
  }

  GetAdditionalInformation(Request) {
    return this.httpClient.post(this.BaseUrl + 'Hotel/RoomAditionalInformation', Request);
  }

}
