import { Component, OnInit, QueryList, ViewChild, ViewChildren, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { DataService } from '../../services/data.service';
import { DataStoreService } from '../../services/data-store.service';
import { ModalOptions } from '../shared/modal/modal-options.model';
import { HotelAvailabilityResponse } from '../../models/hotel/response/hotel-availability-response.model';
import { Card } from '../shared/card/model/card';
import { Info } from '../shared/card/model/info';
import { HotelAvailabilityOptions } from '../../models/hotel/response/hotel-availability-options.model';
import { Router, ActivatedRoute } from '@angular/router';
import { SearchErrorType } from 'src/app/models/search-error-type.model';
import { HotelListComponent } from './hotel-list/hotel-list.component';
import { SmartProfileService } from 'src/app/services/smart-profile.service';
import { HotelAvailabilityRequest } from 'src/app/models/hotel/request/hotel-availability-request.model';
import { RoomPaxDistribution } from 'src/app/models/hotel/room-pax-distribution.model';
import { Subscription } from 'rxjs';
import { FilterDataType, FilterGroupType, IFilterParams } from '../shared/filters/filterParams';
import { FiltersComponent } from '../shared/filters/filters.component';
import { DebounceCall } from 'src/app/decorators/debounce.decorator';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../component/language/language.service';
import { FilterService } from '../shared/filters/filter.service';
import { SearchedService } from 'src/app/component/searched/searched.service';
import { SearchedHotel } from 'src/app/component/searched/models/searched-hotel.model';

declare var window: any;
declare var androidProxy: any;

@Component({
  selector: 'app-hotel-body',
  templateUrl: './hotel-body.component.html',
  styleUrls: ['./hotel-body.component.scss']
})

export class HotelBodyComponent implements OnInit, AfterViewInit, OnDestroy {

  translations = {};
  showPriceSlider = false;
  availabilitySubscription: Subscription;
  showFilters = false;
  @Input() getParameters: any;

  public hotelData: HotelAvailabilityResponse;
  private paxIdAcumulator = 1;
  /* Mobile Carousel */
  @ViewChildren('MobileCarousel') private MobileCarousel: QueryList<any>;
  @ViewChild('hotelListCmp') private hotelListCmp: HotelListComponent;
  @ViewChild('filtros') private _filtersComponent: FiltersComponent;

  filtersParamsList: IFilterParams[];
  CurrentItemCarousel = 0;
  /* Mobile Carousel */

  isMobile = false;
  porcentajeConversion = 100;

  cityName = '';
  showLoader: boolean;
  mapView = false;
  limitShow = 5;
  currentSegment = 0;
  /*Listado de hoteles a mostrar*/
  hotelList: Card[] = [];
  hotelDetail: any;
  openModal = false;
  optionModal: ModalOptions = {
    type: null,
    subtitle: null,
    message: null,
    cancelText: null,
    confirmText: null,
    openButton: null,
    modalOptions: {
      size: 'xl',
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static',
      keyboard: false
    }
  };
  filteredID: any = {
    type: 'hotel',
    values: []
  };
  chequedValues;
  smartProfileLoadIntents = 5;

  constructor(
    public dataService: DataService,
    public dataStore: DataStoreService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private smartProfile: SmartProfileService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private filterService: FilterService,
    private searchedService: SearchedService
  ) {
    this.getTranslations();
  }

  ngOnInit() {
    this.dataStore.mainFormTab = 'results';
    this.getHotelList();


    // await this._getSmartProfile();

    /* if (this.activatedRoute.snapshot.routeConfig.path === 'hotels-mobile') {
       await this.fillHoteltRequestFromUrl();
       await this.dataService.saveRequestHotel(this.dataService.hotelAvailabilityRequest);

       this.getHotelList();
       this.isMobile = true;
       this.dataStore.isAlleMobile = true;
       this.dataService.saveMobile(true);
     } else {
       this.getHotelList();
       this.isMobile = false;
       this.dataService.saveMobile(false);
     }
     if (this.dataStore.hotelInfoParams) {
       this.cityName = this.dataStore.hotelInfoParams.citiName;
     }*/
  }

  /*private async _getSmartProfile() {
    if (this.dataService && !this.dataService.token && this.smartProfileLoadIntents) {
      this.smartProfileLoadIntents--;
      setTimeout(() => {
        this._getSmartProfile();
      }, 150);
      return await null;
    }
    await this.smartProfile.getAccountMethod(this.dataService.token);
  }
*/
  /**
   * Inicializa los filtros en base a la información del smart profile
   */
  @DebounceCall(150)
  private _initSmartFilters() {
    /* if (typeof this._filtersComponent === 'undefined') {
       // aún no está inicializado el componente, intentar luego
       setTimeout(() => {
         this._initSmartFilters();
       }, 250);
       return;
     }

     // Obtenemos las preferencias del smart profile
     if (this.dataService.smartProfile) {
       const smartProfileData = this.dataService.smartProfile;
       if (smartProfileData && smartProfileData.accountResultDto) {
         const accountResultDto = smartProfileData.accountResultDto;
         if (accountResultDto.accountSumaryDto) {
           const accountSumaryDto = accountResultDto.accountSumaryDto;
           if (accountSumaryDto.merchantAccounts) {
             const merchantAccounts = accountSumaryDto.merchantAccounts;
             if (Array.isArray(merchantAccounts)) {
               // para cada una de las preferencias
               merchantAccounts.forEach(merchantAccount => {
                 let preference;
                 if (Array.isArray(merchantAccount.preferences)) {
                   preference = merchantAccount.preferences[0];
                 } else {
                   preference = merchantAccount.preferences;
                 }
                 if (preference.codeTypePreference) {
                   const codeTypePreference = preference.codeTypePreference;
                   // preferencia de hoteles
                   if (codeTypePreference.indexOf('HOTELES') !== -1) {
                     if (preference.valueText) {
                       const valueText = this.smartProfile.preferenceHotelCategory + ' ';                       
                       // cantidad mínima de estrellas del filtro
                       if (valueText.toString().toLowerCase().indexOf('estrellas') || valueText.toString().toLowerCase().indexOf('stars')) {
                         this._filtersComponent.groups.forEach((group) => {
                           if (group.filterGroupData.groupName === 'category') {
                             let aplicarFiltroEstrellas = false;
                             group.optionsIcon.forEach(option => {
                               if (aplicarFiltroEstrellas || valueText.indexOf(option.id) !== -1) {
                                 // el filtro se aplica desde las estrellas seleccionadas hacia adelante
                                 aplicarFiltroEstrellas = true;
                                 // activar el filtro de estrellas (simulamos un click)
                                 option.checked = true;
                                 option.change.emit({ source: option, checked: true });
                               }
                             });
                           }
                         });
                       }
                     }
                   }
                 }
               });
             }
           }
         }
       }
     }*/
  }

  ngAfterViewInit(): void {
    // if (typeof androidProxy !== 'undefined') {
    //   androidProxy.postMessage(JSON.stringify({ type: 'INIT_BITACORA_PAGE' }));
    // } else if (window.webkit !== undefined && window.webkit !== 'undefined') {
    //   window.webkit.messageHandlers.native.postMessage(JSON.stringify({ type: 'INIT_BITACORA_PAGE' }));
    // } else {
    //   window.parent.postMessage(JSON.stringify({ type: 'INIT_BITACORA_PAGE' }), '*');
    // }
  }

  async fillHoteltRequestFromUrl() {
    this.activatedRoute.queryParams.subscribe(async params => {
      if (params['token'] !== undefined) {
        await this.dataService.saveToken(decodeURIComponent(params['token'].replace(/ /g, '+')));

        const roomDistribution: RoomPaxDistribution[] = [];
        if (params['roomDistribution'] instanceof Array) {
          params['roomDistribution'].forEach(async room => {
            roomDistribution.push(this.processRooms(room));
          });
        } else {
          roomDistribution.push(this.processRooms(params['roomDistribution']));
        }
        const req: HotelAvailabilityRequest = {
          checkIn: params['checkIn'].trim(),
          checkOut: params['checkOut'].trim(),
          destinationCode: params['destinationCode'].trim(),
          countryOfResidence: params['countryOfResidence'].trim(),
          roomDistribution: roomDistribution,
          targetCurrency: (params['targetCurrency']) ? params['targetCurrency'] : 'USD',
          language: this.languageService.systemLanguajeChange.value.toUpperCase()
        };


        // req.passengers = this.getPassengerFromUrl(params['pax']);
        // req.cabinClasses = this.getCabinFromUrl(params['cabin']);
        // req.journey = this.getJourneyFromUrl(params);

        this.dataService.hotelAvailabilityRequest = req;
      }
    });
  }

  getHotelList() {
    this.availabilitySubscription = this.dataService.sendRequestAvailabilityHotel().subscribe((response: HotelAvailabilityResponse) => {
      this.hotelData = response;
      if (response.hotelOptions === undefined || response.hotelOptions === null) {
        this.dataService.flightSearchError = this.translations['NO_RESULTS.JUNIP1'] as SearchErrorType;
        this.router.navigate(['/hotels/noResults']);
      } else {
        if (this.dataStore.smartProfilePreferences && this.dataStore.smartProfilePreferences.hotelCategory) {
          let lightCategories = [];
          for (let i = this.dataStore.smartProfilePreferences.hotelCategory; i <= 5; i++) {
            if (i === this.dataStore.smartProfilePreferences.hotelCategory) {
              lightCategories = [this.dataStore.smartProfilePreferences.hotelCategory];
            } else {
              lightCategories.push(i);
            }
          }
          this.chequedValues = {
            category: lightCategories,
          };
        } else {
          this.chequedValues = { category: undefined };
        }
        this.fillFilterParamList(this.chequedValues);

        this.hotelList = this.formatCardData(response.hotelOptions);
        const defaultHotel = this.hotelData.hotelOptions[0];
        defaultHotel.selectedRoomOption = defaultHotel.options[0];
        this.dataService.saveSelectedHotel(defaultHotel);
        this.actualizarPorcentaje(100);
      }
    }, error => {
      this.dataService.flightSearchError = this.translations['NO_RESULTS.JUNIP1'] as SearchErrorType;
      this.router.navigate(['/hotels/noResults']);
    });
  }

  fillFilterParamList(chequedValues) {
    this.filtersParamsList = [
      {
        groupTitle: 'Hotel',
        groupName: 'hotel',
        groupType: FilterGroupType.textAutocompleteFG,
        optionValuePath: 'data.name',
        optionsIdsPath: 'data.id',
        optionType: FilterDataType.autocompleteFilter,
        // Deben ser expresiones regulares
        includeText: undefined,
        excludeText: undefined,
        iconImg: '',
        multipleOptions: false,
      },
      {
        groupTitle: this.translations['HOTEL_BODY.category'],
        groupName: 'category',
        groupType: FilterGroupType.checkboxIconsFG,
        optionValuePath: 'data.category',
        optionsIdsPath: 'data.id',
        optionType: FilterDataType.iconFilter,
        // Deben ser expresiones regulares
        includeText: new RegExp(/Estre|estre|Star|star/),
        excludeText: undefined,
        iconImg: 'travel-icon icon-starF',
        chequedValues: chequedValues ? chequedValues.category : undefined,
        multipleOptions: true,
      },
    ];
  }

  /*ChangeViewList($event, Segment, isMobile = false) {
    const that = this;
    this.mapView = !this.mapView;
  }
*/
  formatCardData(hotelResponse: HotelAvailabilityOptions[]): Card[] {
    if (hotelResponse) {
      const list: Card[] = hotelResponse.map(hotel => {

        /* SET OPTION[0] COMO LA OPCION PRESELECCIONADA */
        hotel.selectedRoomOption = hotel.options[0];
        const totalNights = this.searchedService.getModel(SearchedHotel).qNights;
        const totalTravellers = this.dataService.getHotelGuests();
        const info: Info[] = [
          {
            type: 'refundable',
            value: this.translations['CARD.check_refundable']
          },
          {
            type: 'night',
            value: totalNights
          },
          {
            type: 'traveller',
            value: totalTravellers
          },
          {
            type: 'breakfast',
            value: true
          },
          {
            type: 'wifi',
            value: true
          }
        ];
        const arr = hotel.options[0].rooms.map(room => room.name);
        hotel.category = hotel.category.replace('1/2 ', '');

        const toReturn: Card = {
          id: hotel.id,
          name: hotel.name,
          address: hotel.address,
          category: hotel.category,
          images: (hotel.images === null) ? [] : hotel.images,
          info: info,
          detailName: arr.join(', '),
          price: hotel.options[0].price,
          hotelObj: hotel
        };
        return toReturn;
      });
      return list;
    } else {
      return [];
    }
  }

  /*
  * ABRIR MODAL DE DETALLE DE HOTEL
  * */
  hotelDetailListener(ev: Card) {
    this.hotelDetail = ev;
    this.openModal = true;
    this.dataStore.selectedHotel.options = this.dataService.getPriceConversion(
      this.dataStore.selectedHotel.options,
      this.dataService.conversionRate,
      this.porcentajeConversion,
      this.dataService.userPointsSP
    );
    // this.updateHotelRoomsPrice(
    //   this.dataService.conversionRate,
    //   this.porcentajeConversion,
    //   this.dataService.userPointsSP);
    // this.dataService.saveSelectedHotel(this.hotelDetail.hotelObj);
    setTimeout(() => {
      this.openModal = false;
    }, 2000);
  }

  actualizarPorcentaje(evt) {
    const conversionRate = this.dataService.conversionRate;
    const userPointsSP = this.dataService.userPointsSP;
    this.dataService.getPriceConversion(this.hotelList, conversionRate, evt, userPointsSP);

    this.porcentajeConversion = evt;
  }

  updateHotelRoomsPrice(conversionRate, percentage, puntosUsuarios) {
    this.dataStore.selectedHotel.options.map((room) => {
      const precioProducto = +room.price.totalPrice;
      const precioMillasProducto = (precioProducto / +conversionRate);
      const millasAdicionalNecesarias = precioMillasProducto - +puntosUsuarios;

      let puntosPagar = 0;
      let millasSobrantes = 0;
      if (millasAdicionalNecesarias >= 0) {
        puntosPagar = (millasAdicionalNecesarias === 0) ? (precioMillasProducto * percentage) / 100 : (puntosUsuarios * percentage) / 100;
      } else {
        // const maxDineroDisponible = puntosUsuarios / conversionRate;
        millasSobrantes = puntosUsuarios - precioMillasProducto;
        puntosPagar = (precioMillasProducto * percentage) / 100;
      }

      let dineroPagar = (precioProducto - Math.ceil(puntosPagar * conversionRate));
      if (dineroPagar < 0) {
        dineroPagar = 0;
      }

      room.price.pointsPrice = Math.ceil(puntosPagar).toString();
      room.price.calculatedPrice = dineroPagar.toFixed(2);
      room.price.restPointValue = Math.ceil(millasSobrantes).toString();

    });

  }
sortFn() {

  this.hotelList.sort((a, b) => {
    const sortParam = 'category';
      // a should come before b in the sorted order
      if (a[sortParam] > b[sortParam]) {
        return -1;
        // a should come after b in the sorted order
      } else if (a[sortParam] > b[sortParam]) {
        return 1;
        // and and b are the same
      } else {
        return 0;
      }
    }
  );
}
  filterListener(filteredIDs) {
    this.filteredID = {
      type: 'hotel',
      values: filteredIDs.IDs,
      isFiltered: filteredIDs.isFiltered
    };
    if (this.filteredID.values.length > 0) {
      const index = this.filteredID.values.filter(item => {
        return item !== undefined;
      }).sort((a, b) => {
        return a - b;
      });
      const hotel = this.hotelList.find(item => {
        return item.id === index[0];
      });
      this.dataService.saveSelectedHotel(hotel.hotelObj);
    }
  }

  processRooms(room: string): RoomPaxDistribution {
    const roomDist: RoomPaxDistribution = {};
    roomDist.paxes = [];
    if (room.indexOf('-') > 0) {
      // 3-6,8 => 3 adultos, un menor de 6 y un menor de 8
      // tiene menores
      const paxes = room.split('-');
      for (let index = 0; index < +paxes[0]; index++) {
        roomDist.paxes.push({ id: this.paxIdAcumulator++, age: 21 });
      }
      paxes[1].split(',').forEach(child => {
        roomDist.paxes.push({ id: this.paxIdAcumulator++, age: +child });
      });
    } else {
      // Solo adultos
      for (let index = 0; index < +room; index++) {
        roomDist.paxes.push({ id: this.paxIdAcumulator++, age: 21 });
      }
    }
    roomDist.paxes.push();
    return roomDist;
  }

  ngOnDestroy(): void {
    if (this.availabilitySubscription && !this.availabilitySubscription.closed) {
      this.availabilitySubscription.unsubscribe();
    }
  }

  getTranslations() {
    this.translateService.get([
      'CARD.night',
      'CARD.nights',
      'CARD.guest',
      'CARD.guests',
      'CARD.check_refundable',
      'HOTEL_BODY.category',
      'NO_RESULTS.JUNIP1'
    ]).subscribe((trns) => {
      this.translations = trns;
    });
  }
}
