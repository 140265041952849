<form [formGroup]="form">
    <div class="search-activities" [ngClass]="{'is-mobile': dataStoreService.isMobileDevice}">

        <!-- <div class="coming-soon">Coming soon</div> -->
        <div class="panel">

            <div class="input destination">
                <ng-select formControlName="destinationZone" class="autocompleteSearch" bindLabel="name" groupBy="type" dropdownPosition="bottom" [searchFn]="returnTrue" [items]="cities" (search)="onDestinationSearch($event)" [placeholder]="translations_search_activities['destination']"
                    [notFoundText]="translations_search_activities['not_found']" [virtualScroll]="true" [selectOnTab]="true">
                    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                        <div>
                            <span>{{item.name}}</span>
                        </div>
                    </ng-template>
                </ng-select>
                <div class="alert_red" *ngIf="destinyNotSelected" innerHTML="{{'SEARCH_ACTIVITIES.please_indicate_destiny'|translate}}">
                </div>
            </div>


            <div class="fechasActivity">
                <label>{{translations_search_activities['dates']}}</label>
                <!-- <input class="dateSelect" formControlName="selectDate" *ngIf="refresherDates" type="text" ngxDaterangepickerMd readonly emptyWeekRowClass="empty-week" lastDayOfPreviousMonthClass="disabled-previous-day" firstDayOfNextMonthClass="disabled-nex-day" [placeholder]="translations_search_activities['select_date']"
                    [minDate]="minDate" [locale]="locale" [linkedCalendars]="true" [showDropdowns]="true" [autoApply]="true" [singleDatePicker]="false" (change)="updateDateValidator()" /> -->
                <div class="fecha-inputs" *ngIf="refresherDates">
                    <div class="bl-input-icon">
                        <input #dateStart [(ngModel)]="selected" class="dateSelect form-control" (openedChange)="openedChangeStart($event)" type="text" appCalendarSearch readonly formControlName="selectDate" emptyWeekRowClass="empty-week" placeholder="{{'SEARCH_HOTELS.select_date' | translate}}"
                            [linkedCalendars]="true " [showDropdowns]="true " [autoApply]="true " [locale]="locale " [singleDatePicker]="true " [minDate]="minDate " [dateShowTogether]="false " [isStart]="true " (change)="updateDateValidator()" />
                    </div>
                    <div class="bl-input-icon icon-last">
                        <input #dateEnd [(ngModel)]="selected" class="dateSelect form-control" (openedChange)="openedChangeEnd($event)" type="text" appCalendarSearch readonly formControlName="selectDate" emptyWeekRowClass="empty-week" placeholder="{{'SEARCH_HOTELS.select_date' | translate}}"
                            [linkedCalendars]="true " [showDropdowns]="true " [autoApply]="true " [locale]="locale " [singleDatePicker]="true " [minDate]="minDate " [dateShowTogether]="false " [isEnd]="true " (change)="updateDateValidator()" />
                    </div>
                </div>

                <div class="alert_red" *ngIf="dateNotSelected" innerHTML="{{'SEARCH_ACTIVITIES.please_indicate_dates'|translate}}">
                </div>
            </div>

            <div class="input passengers">
                <label>{{translations_search_activities['passengers']}}</label>
                <app-input-ages min="1" (apply)="onApplyAgesPassengers($event)" [personText]="translations_search_activities['passenger']">
                </app-input-ages>
                <div class="alert_red" *ngIf="passengerNotSelected" innerHTML="{{'SEARCH_ACTIVITIES.please_indicate_passenger'|translate}}">
                </div>
            </div>

        </div>

        <!-- TODO: agregar esta clase cuando se hagan las validaciones
             [ngClass]="{'disabled':this.form.invalid}" -->
        <div class="buscar" (click)="search()" [title]="translations_search_activities['search']">
            <span>{{translations_search_activities['search']}}</span>
        </div>

    </div>
</form>