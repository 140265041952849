import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input } from '@angular/core';
import { formatDate } from '@angular/common';
import { locale } from 'moment';
import { LanguageService } from 'src/app/component/language/language.service';
import { IActivityResult } from 'src/app/models/activities/response/activity-availability.model';

@Component({
  selector: 'app-confirmation-activity',
  templateUrl: './confirmation-activity.component.html',
  styleUrls: ['./confirmation-activity.component.scss']
})
export class ConfirmationActivityComponent implements OnInit {

  @Input() activity: IActivityResult;
  @Input() transactionNumber: string;

  get datetime() {
    return formatDate(this.activity.selectedOption.selectedMeetingPoint.time, this.trans['DATE']['format_weekday'],
      locale(this.languageService.systemLanguajeChange.value)).toString();
  }

  get startDate() {
    return formatDate(this.activity.selectedOption.selectedDate.startDate, this.trans['DATE']['format_weekday_hour'],
      locale(this.languageService.systemLanguajeChange.value)).toString();
  }

  trans = [];
  constructor(
    private languageService: LanguageService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.languageService.systemLanguajeChange.subscribe(language => {
      if (language) {
        this.translateService.get([
          'CONFIRMATION',
          'DATE'
        ]).subscribe((trns) => {
          this.trans = trns;
        });
      }
    });
  }

}
