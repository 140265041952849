<ng-select
  class="autocompleteSearch" id="targetIn"
  [selectOnTab]=true
  [items]="allCities"
  bindLabel="name"
  bindValue="iata"
  groupBy="type"
  dropdownPosition="bottom"
  placeholder="{{targetPlaceholder}}"
  [virtualScroll]="true"
  (search)="onSearch($event)"
  (change)="onInput($event)">
>
  <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
    <div>
      <span>{{item.name}}</span>
    </div>
  </ng-template>
</ng-select>
