import {Component, Input, OnInit} from '@angular/core';
import {CardComponent} from '../../card.component';

@Component({
  selector: 'app-card-detail',
  templateUrl: './card-detail.component.html',
  styleUrls: ['./card-detail.component.scss']
})
export class CardDetailComponent implements OnInit {

  product: any;
  options: any;

  constructor(public card: CardComponent) {}

  ngOnInit() {
    this.options = this.card.options;
    this.product = this.card.product;
  }

}
