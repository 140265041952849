import { Journey } from 'src/app/models/new-flight/journey.model';
import { ProductHandlerService } from './../../services/product-handler.service';
import { SearchedService } from './../searched/searched.service';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { DataStoreService } from 'src/app/services/data-store.service';
import { LanguageService } from 'src/app/component/language/language.service';
import { HotelAvailabilityOptions } from 'src/app/models/new-hotel/hotel-availability-response.model';
import { RentacarAvailabilityOption } from 'src/app/models/new-rentacar/rentacar-availability-option.model';

@Injectable({
  providedIn: 'root'
})
export class PurchaseSummaryService {

  isTranslationsLoaded = false;
  translationsHotel = [];
  translationsFlight = [];
  translationsCar = [];
  translationsBenefit = [];
  translationDate = [];
  translationsActivity = [];
  translationDataService = [];

  constructor(
    public dataService: DataService,
    public dataStore: DataStoreService,
    public searchedService: SearchedService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private productHandlerService: ProductHandlerService
  ) {
    this.languageService.systemLanguajeChange.subscribe((language) => {
      if (language) {
        this.getTranslations();
      }
    });
  }

  get locale() {
    return this.languageService.systemLanguajeChange.value;
  }

  get journey() {
    return this.productHandlerService.selectedProduct as Journey;
  }

  get hotel() {
    return this.productHandlerService.selectedProduct as HotelAvailabilityOptions;
  }

  get car() {
    return this.productHandlerService.selectedProduct as RentacarAvailabilityOption;
  }

  get benefit() {
    return this.dataStore.selectedBenefit;
  }

  get activity() {
    return this.dataStore.selectedActivity;
  }

  getTranslations() {
    return new Promise<any>((resolve) => {
      this.translateService.get([
        'DATE',
        'DATA_SERVICE',
        'PURCHASE_SUMARY.CAR',
        'PURCHASE_SUMARY.HOTEL',
        'PURCHASE_SUMARY.FLIGHT',
        'PURCHASE_SUMARY.BENEFIT',
        'PURCHASE_SUMARY.ACTIVITY',
      ]).subscribe((trans) => {
        this.translationDate = trans['DATE'];
        this.translationDataService = trans['DATA_SERVICE'];
        this.translationsHotel = trans['PURCHASE_SUMARY.HOTEL'];
        this.translationsFlight = trans['PURCHASE_SUMARY.FLIGHT'];
        this.translationsCar = trans['PURCHASE_SUMARY.CAR'];
        this.translationsBenefit = trans['PURCHASE_SUMARY.BENEFIT'];
        this.translationsActivity = trans['PURCHASE_SUMARY.ACTIVITY'];
        this.isTranslationsLoaded = true;
        resolve(true);
      });
    });
  }
}
