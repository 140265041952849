import { ProductHandlerService } from './../../services/product-handler.service';
import { Injectable } from '@angular/core';
import { InitService } from 'src/app/services/init.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../language/language.service';
import { EProduct } from '../searched/models/searched.model';

@Injectable({
  providedIn: 'root'
})
export class ProductDetailService {

  public translations = [];
  public translationsDate = []; 

  constructor(
    private initService: InitService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private productHandlerService: ProductHandlerService
  ) {
    this.initService.isInitializedBrand.subscribe(async isInitialized => {
      if (isInitialized) {
        this.languageService.systemLanguajeChange.subscribe((language) => {
          if (language) {
            this.getTranslations();
          }
        });
      }
    });
  }

  get travellerType() {
    if (this.productHandlerService.productType === EProduct.HOTELS) {
      return [this.translations['guest'], this.translations['guests']];
    } else {
      return [this.translations['passenger'], this.translations['passengers']];
    }
  }

  getTranslations() {
    return new Promise<any>((resolve) => {
      this.translateService.get([
        'DATE',
        'LAYOUT_TWO'
      ]).subscribe((trans) => {
        this.translations = trans['LAYOUT_TWO'];
        this.translationsDate = trans['DATE'];
        resolve(true);
      });
    });
  }
}
