import { ActivityDetailComponent } from './../activity-detail.component';
import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { locale } from 'moment';

@Component({
  selector: 'app-detail-selection',
  templateUrl: './detail-selection.component.html',
  styleUrls: ['./detail-selection.component.scss']
})
export class DetailSelectionComponent implements OnInit {

  constructor(
    public detail: ActivityDetailComponent
  ) { }

  ngOnInit() {
  }

}
