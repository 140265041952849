import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { formatDate } from "@angular/common";
import { NgSelectConfig } from "@ng-select/ng-select";
import { Router } from "@angular/router";
import { DataStoreService } from "../../services/data-store.service";
import { locale } from "moment";
import { DataService } from "../../services/data.service";
import moment from 'moment';
import { OcultadorService } from "../../pages/home/ocultador.service";
import { LanguageService } from "../language/language.service";
import { EProduct } from "../searched/models/searched.model";
import { HomeSearchService } from "./home-search.service";

export interface ISearchConfig {
  targetIn?: string;
  targetOut?: string;
  dateIn?: string;
  dateOut?: string;
  buyer?: string;
}

export interface IJourneyRequest {
  departureAirportCity: string;
  arrivalAirportCity: string;
  flightDate: string;
}

export interface IPassengerRequest {
  type: string;
  count: number;
}

export interface IFlightAvailabilityRequestModel {
  journey: IJourneyRequest[];
  passengers: IPassengerRequest[];
  cabinClasses: any[];
}

export interface IJourneyResponse {
  journeys?: IJourney[];
}

export interface IJourney {
  id?: string;
  queryID?: string;
  flightsIDS?: string[];
  journeyID?: string;
  flights?: Flight[];
  validatingCompany?: Company;
  info?: Info;
  price?: Price;
  travelFare?: TravelFare[] | TravelFare;
  basePrice?: string;
  charges?: string;
  currency?: string;
  discount?: string;
  passengerType?: string;
  quantity?: string;
  taxes?: string;
  totalPrice?: string;
}

export interface Flight {
  arrivalDate: string;
  arrivalHour: string;
  departureDate: string;
  departureHour: string;
  company: Company;
  destination: ITarget;
  origin: ITarget;
  flightDuration: string;
  segmentIatas: string[];
  segments: Segment[];
}

export interface Company {
  iata?: string;
  icao?: string;
  shortName?: string;
  longName?: string;
}

export interface Info {
  cabin?: Cabin | string;
  baggage?: Baggage | string;
  refundable?: string | Refundable;
}

export interface Cabin {
  longName: string;
}

export interface Baggage {
  unit: string;
}

export interface Refundable {
  isRefundable: string;
}

export class Price {
  currency: string;
  basePrice: string;
  taxes: string;
  totalPrice: string;
  travelFare: TravelFare | TravelFare[];
  /* CAMPOS NECESARIOS PARA CONVERSION DE PUNTOS */
  pointsValue?: string;
  restMoneyValue?: string;
  restPointValue?: string;
}

export interface TravelFare {
  basePrice?: string;
  charges?: string;
  currency?: string;
  discount?: string;
  passengerType?: string;
  quantity?: string;
  taxes?: string;
  totalPrice?: string;
}

export interface ITarget {
  cityName?: string;
  country?: string;
  countryName?: string;
  iata: string;
  id?: number;
  longName?: string;
  shortName?: string;
  terminal?: string;
  timeZone?: string;
  type?: string;
}

export interface Segment {
  departureDate?: string;
  arrivalDate?: string;
  departureHour?: string;
  arrivalHour?: string;
  flightDuration?: string;
  flightNumber?: string;
  stopDuration?: string;
  origin?: ITarget;
  destination?: ITarget;
  company?: Company;
  info?: Info;
  scale?: Scale;
}

export interface Scale {
  cityName: string;
  iata: string;
  scaleDuration: string;
}

@Component({
  selector: 'app-home-search',
  templateUrl: './home-search.component.html',
  styleUrls: ['./home-search.component.scss']
})
export class HomeSearchComponent implements OnInit {
  get searchConfig(): ISearchConfig {
    return this.searchService.searchConfig;
  }

  set searchConfig(value: ISearchConfig) {
    this._searchConfig = this.searchService.searchConfig;
  }
  searchForm: FormGroup;
  allCities = [];
  private _searchConfig: ISearchConfig;
  startDate: any;
  endDate: any;
  tramo: string;
  tramoCalendar: boolean;
  secondTarget: boolean;
  secondDatePicker: boolean;
  invertTarget: boolean;
  targetIn: string;
  targetOut: string;
  cabins: any[];
  languaje: string;
  EProduct = EProduct;
  productSelected;
  constructor(
    private dataStore: DataStoreService,
    private dataService: DataService,
    private authConfig: NgSelectConfig,
    private router: Router,
    public ocultadorService: OcultadorService,
    private languageService: LanguageService,
    private searchService: HomeSearchService
  ) {
    this.authConfig.notFoundText = 'Realice búsqueda';
    this.searchForm = this.createFormSearchGroup();
  }

  ngOnInit() {
    this.invertTarget = false;
    this.tramo = 'idaVuelta';
    this.languaje = this.languageService.systemLanguajeChange.value
  }

  createFormSearchGroup() {
    return new FormGroup({
      targetIn: new FormControl(null, [Validators.required]),
      targetOut: new FormControl(null, [Validators.required]),
      dateSelect: new FormControl({
        startDate: moment(new Date()),
        endDate: moment(new Date())
      }, [Validators.required]),
      buyer: new FormControl(null, [Validators.required]),
    });
  }

  invertTargetFn() {
    this.invertTarget = !this.invertTarget;
  }

  setValues() {
    const controlsData = this.searchForm.controls;
    this.startDate = formatDate(controlsData.dateSelect.value.startDate, 'yyyy-MM-dd', locale()).toString();
    this.endDate = formatDate(controlsData.dateSelect.value.endDate, 'yyyy-MM-dd', locale()).toString();
    this.targetIn = controlsData.targetIn.value;
    this.targetOut = controlsData.targetOut.value;
  }


  doSearch() {
    this.setValues();
    switch (this.productSelected) {
      case 'flights':
        if (this.tramo === 'idaVuelta') {
          this.dataService.flightAvailabilityRequest = {
            journey: [{
              departureAirportCity: this.targetIn,
              arrivalAirportCity: this.targetOut,
              flightDate: this.startDate
            },
            {
              departureAirportCity: this.targetOut,
              arrivalAirportCity: this.targetIn,
              flightDate: this.endDate
            }],
            passengers: this.searchForm.get('buyer').value.pasangerConf,
            cabinClasses: this.searchForm.get('buyer').value.cabins,
            targetCurrency: this.dataStore.brandTargetCurrency,
            language: this.languaje.toUpperCase()
          };
        } else {
          this.dataService.flightAvailabilityRequest = {
            journey: [{
              departureAirportCity: this.targetIn,
              arrivalAirportCity: this.targetOut,
              flightDate: this.startDate
            },
            ],
            passengers: this.searchForm.get('buyer').value.pasangerConf,
            cabinClasses: this.searchForm.get('buyer').value.cabins,
            targetCurrency: this.dataStore.brandTargetCurrency,
            language: this.languaje.toUpperCase()
          };
        }
        this.dataService.saveFlightRequest(this.dataService.flightAvailabilityRequest);
        this.router.navigate(['flights/results']);
        break
    }
  }

  tabSelect(type: EProduct) {
    this.searchService.searchConfigFn(type);
    this.dataService.productSelected = type;
    this.productSelected = this.dataService.productSelected;
  }

  tramoFn(tramo) {
    this.tramo = tramo;
    this.tramoCalendar = tramo !== 'idaVuelta';
  }
  invertFlightSelection() {
    if (this.searchForm.controls['targetIn'].value !== undefined && this.searchForm.controls['targetOut'].value !== undefined) {
      const nuevoDestino = this.searchForm.controls['targetIn'].value;
      const nuevoOrigen = this.searchForm.controls['targetOut'].value;
      this.searchForm.get('targetIn').setValue(nuevoOrigen);
      this.searchForm.get('targetOut').setValue(nuevoDestino);
    }
  }
}
