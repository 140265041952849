<!--TODO: Componente para pintar los cards-->
<main class="main">
  <div class="main-wrapper">
    <section class="section press1 padding-first gray">
      <div class="container">
        <div class="tittle">
          <h2>Error</h2>
          <span>{{ 'LANDING_ALLO.generalError' | translate }}</span>
          <p>{{ theUri }}</p>
        </div>
          <a class="button" href="/">{{ 'LANDING_ALLO.go-to-home' | translate }}</a>
      </div>
    </section>
  </div>
</main>

<app-footer-all [brand]="brand" [ngClass]="{'min-footer': true}"></app-footer-all>
