<section class="section-cero gray">
    <div class="container">
        <div class="bl-coming">
            <div class="bl-center">

                <h1>
                    <strong innerHTML="{{'LANDING.'+brand+'.SECTION_FIRST.SECTION0.say_hello_to' | translate}}"></strong><br>
                    <strong innerHTML="{{'LANDING.'+brand+'.SECTION_FIRST.SECTION0.title_app_name' | translate}}"></strong>
                </h1>

                <h3 innerHTML="{{'LANDING.'+brand+'.SECTION_FIRST.SECTION0.description_1' | translate }}"></h3>

                <p class="text-center" *ngIf="brand === 'credomatic'" innerHTML="{{'LANDING.'+brand+'.SECTION_FIRST.SECTION0.description_2' | translate }}"></p>
                <!-- <p class="text-center" *ngIf="brand === 'credomatic'" innerHTML="{{'LANDING.'+brand+'.SECTION_FIRST.SECTION0.description_3' | translate }}"></p> -->

                <div class="store" *ngIf="brand === 'credomatic'">
                    <span innerHTML="{{'LANDING.'+brand+'.SECTION_FIRST.SECTION0.download_app' | translate }}"></span>
                    <app-download-store [class]="'logo'"></app-download-store>
                </div>

                <a href="#/travel"><img class="img-coming" [src]="imagesBgIphone1" alt="mr"></a>


                <div class="store" *ngIf="brand === 'scotia'">
                    <span innerHTML="{{'LANDING.'+brand+'.SECTION_FIRST.SECTION0.download_app' | translate }}"></span>
                    <app-download-store [class]="'logo'"></app-download-store>
                </div>
            </div>
        </div>
    </div>
</section>