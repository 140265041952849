import { HotelBodyComponent } from './hotel-body.component';
import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HotelListComponent } from './hotel-list/hotel-list.component';
import { HotelCardComponent } from './hotel-list/hotel-card/hotel-card.component';
import { HotelCardHeaderComponent } from './hotel-list/hotel-card/hotel-card-header/hotel-card-header.component';
import { HotelImageComponent } from './hotel-list/hotel-card/hotel-image/hotel-image.component';
import { HotelDetailComponent } from './hotel-list/hotel-card/hotel-detail/hotel-detail.component';
import { HotelPriceComponent } from './hotel-list/hotel-card/hotel-price/hotel-price.component';
import { SharedModule } from '../shared/shared.module';
import { HotelDetailModalComponent } from './hotel-detail-modal/hotel-detail-modal.component';
import { HotelDetailHeaderComponent } from './hotel-detail-modal/hotel-detail-header/hotel-detail-header.component';
import { HotelGalleryComponent } from './hotel-detail-modal/hotel-gallery/hotel-gallery.component';
import { GallerySliderComponent } from './hotel-detail-modal/hotel-gallery/gallery-slider/gallery-slider.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SelectionDetailComponent } from './hotel-detail-modal/selection-detail/selection-detail.component';
import { HotelDetailFooterComponent } from './hotel-detail-modal/hotel-detail-footer/hotel-detail-footer.component';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { MainFormModule } from '../main-form/main-form.module';
import { FiltersModule } from '../shared/filters/filters.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SwiperModule,
    TranslateModule.forChild(),
    PipesModule,
    MainFormModule,
    FiltersModule,
    PipesModule
  ],
  exports: [
    HotelListComponent,
    HotelCardComponent,
    HotelCardHeaderComponent,
    HotelImageComponent,
    HotelDetailComponent,
    HotelDetailModalComponent,
    HotelBodyComponent
  ],
  declarations: [
    HotelListComponent,
    HotelCardComponent,
    HotelCardHeaderComponent,
    HotelImageComponent,
    HotelDetailComponent,
    HotelPriceComponent,
    HotelDetailModalComponent,
    HotelDetailHeaderComponent,
    HotelGalleryComponent,
    GallerySliderComponent,
    SelectionDetailComponent,
    HotelDetailFooterComponent,
    HotelBodyComponent
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'es-AR'
    }
  ]
})
export class HotelModule { }
