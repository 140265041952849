import { Segment } from '../../../../models/flights/Segment.model';
import { Component, OnInit, Input } from '@angular/core';
import { FlightSummaryComponent } from '../flight-summary.component';

@Component({
  selector: 'app-flight-segment-detail',
  templateUrl: './flight-segment-detail.component.html',
  styleUrls: ['./flight-segment-detail.component.scss']
})
export class FlightSegmentDetailComponent implements OnInit {

  @Input() segment: Segment;

  translationDate = [];
  translationDataService = [];

  get cabin() {
    const cabin = this.segment.info.cabin;
    switch (cabin) {
      case 'Y':
        return this.translationDataService['economy_cabin'];
      case 'B':
        return this.translationDataService['bussines_cabin'];
      case 'F':
        return this.translationDataService['first_class_cabin'];
      case 'P':
        return this.translationDataService['premium_economy_cabin'];
      case 'M':
        return this.translationDataService['combined_cabin'];
      default:
        break;
    }
  }

  get baggage() {
    const baggage = this.segment.info.baggage;
    let label = '';
    if (baggage.indexOf('PC') > -1) {
      label = ' ' + this.translationDataService['baggage_pieces'];
    } else if (baggage.indexOf('K') > -1) {
      label = this.translationDataService['baggage_kilograms'];
    }
    const qty = parseInt(baggage);
    if (qty === 1) {
      return this.translationDataService['baggage_one_piece'];
    } else if (qty > 1) {
      return qty + label;
    } else {
      return this.translationDataService['baggage_hand_baggage'];
    }
  }

  constructor(
    private flightSummaryComponent: FlightSummaryComponent
  ) { }

  ngOnInit() {
    this.translationDate = this.flightSummaryComponent.translationDate;
    this.translationDataService = this.flightSummaryComponent.translationDataService;
  }

}
