<ng-container [ngSwitch]="mode">

    <!-- HAMBURGER -->
    <div *ngSwitchCase="EMode.HAMBURGER" class="hamburger">
        <ng-container *ngIf="isLogged">
            <div class="button-hamburger miles">
                <i class="miles-icon"></i>
                <div class="text">
                    <span class="balance" *ngIf="brand !== 'scotia'">{{balance | milesFormat}}</span>
                    <span class="balance" *ngIf="brand === 'scotia'">{{balanceUser | milesFormat}}</span>
                    <span class="unidad">{{'HAMBURGER.miles' | translate}}</span>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!isLogged && brand !== 'scotia'">
            <div class="button-hamburger user" (click)="openLogin()">
                <i class="user-icon"></i>
                <span>{{'HAMBURGER.signin' | translate }}</span>
            </div>
        </ng-container>
        <div #menu class="menu">
            <app-icon-menu (click)="toggleMenu()"></app-icon-menu>
            <div *ngIf="isMenuOpen" [@animation] class="panel" (click)="toggleMenu()">
                <div class="buttons">

                    <!-- opciones del menú -->
                    <div class="opciones">

                        <h5 *ngIf="!isLogged" class="opcion title">
                            {{'HAMBURGER.welcome' | translate}}
                        </h5>


                        <h5 *ngFor="let item of optionMenu" class="opcion title"
                            [ngClass]="{active: item.tabSelect == tabSelectData }" (click)="routing(item.router)">
                            {{item.label|translate: '{appName:\'' + appName +'\'}'}}
                        </h5>

                        <h5 *ngIf="isLogged && brand !== 'scotia'" class="opcion title">
                            {{'HAMBURGER.hi' | translate: '{firstName:\'' + firstName + '\'}'}}
                        </h5>

                        <h5 *ngIf="isLogged && brand === 'scotia'" class="opcion title">
                            {{'HAMBURGER.hi' | translate: '{firstName:\'' + firstNameUser + '\'}'}}
                        </h5>

                        <h6 *ngIf="!isLogged && brand !== 'scotia'" (click)="openLogin()" class="opcion">
                            {{'HAMBURGER.sign' | translate}}
                            <span class="point"></span>
                        </h6>
                        <h6 *ngIf="isLogged" (click)="gotoMyProfile()" class="opcion">
                            {{'HAMBURGER.my_account' | translate}}
                            <span class="point"></span>
                        </h6>
                        <div class="button-hamburger language" *ngIf="brand === 'credomatic'">
                            <div class="language" [ngClass]="{
                                opened: isCountrySelectOpened
                            }" *ngIf="hasLoadCountries">
                                <h6 (click)="toggleCountrySelect($event)" class="option selected">
                                    <span>{{selectCountry}}</span>
                                    <i class="icon down"></i>
                                </h6>
                                <h6 *ngFor="let country of countries" class="option"
                                    (click)="selectedcountry($event, country)" [ngClass]="{'hide': hideCountrys }">
                                    {{country.name}}
                                </h6>
                            </div>
                        </div>
                        <div class="language" [ngClass]="{
                            opened: islanguageSelectOpened
                        }" *ngIf="hasTwoLanguages">
                            <h6 (click)="toggleLanguageSelect($event)" class="option selected">
                                <span>{{selectedLanguageName}}</span>
                                <i class="icon down"></i>
                            </h6>
                            <h6 *ngFor="let language of languages" class="option selector-country"
                                (click)="selectLanguage($event, language.prefix)"
                                [ngClass]="{'hide':language.name===selectedLanguageName}">
                                {{language.name}}
                            </h6>
                        </div>
                        <!-- <h6 class="opcion" *ngIf="hasAbout" (click)="aboutMiles()">
                            {{'HAMBURGER.about'|translate}}
                        </h6> -->
                        <h6 *ngIf="isLogged" (click)="logout()" class="opcion">
                            {{'HAMBURGER.sign_out'|translate}}
                        </h6>

                    </div>

                    <!-- footer del menú (bordes redondeados) -->
                    <div class="footer"></div>

                </div>
                <div class="overlay"></div>
            </div>
        </div>
    </div>

    <!-- LARGE -->
    <div *ngSwitchCase="EMode.LARGE" class="large">
        <ng-container *ngIf="isLogged">
            <div class="button-hamburger miles">
                <i class="miles-icon"></i>
                <div class="text">
                    <span class="balance" *ngIf="brand !== 'scotia'">{{balance | milesFormat}}</span>
                    <span class="balance" *ngIf="brand === 'scotia'">{{balanceUser | milesFormat}}</span>
                    <span class="unidad">{{'HAMBURGER.miles'|translate}}</span>
                </div>
            </div>
            <div #menu class="button-hamburger menu" (click)="toggleMenu()" [ngClass]="{active: isLogged}">
                <i class="user-icon"></i><span *ngIf="brand !== 'scotia'"> {{'HAMBURGER.hi'|translate: '{firstName:\'' +
                    firstName + '\'}'}}</span>
                <span *ngIf="brand === 'scotia'">{{'HAMBURGER.hi'|translate: '{firstName:\'' + firstNameUser +
                    '\'}'}}</span>
                <i class="arrow-icon"></i>
                <!-- menú -->
                <div *ngIf="isMenuOpen" [@animation] class="panel">
                    <div class="menu-button" (click)="gotoMyProfile()">
                        {{'HAMBURGER.my_account'|translate}}
                    </div>
                    <div class="menu-button" (click)="logout()">
                        {{'HAMBURGER.sign_out'|translate}}
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!isLogged && brand !== 'scotia'">
            <div class="button-hamburger menu" (click)="openLogin()">
                <i class="user-icon"></i>
                <span>{{'HAMBURGER.signin'|translate}}</span>
                <i class="arrow-icon"></i>
            </div>
        </ng-container>
        <div class="button-hamburger language" [ngClass]="countryClass" *ngIf="brand === 'credomatic'">
            <app-selector-country class="selector-country" [ocultedCountries]="hasLoadCountries"></app-selector-country>
        </div>
        <div class="button-hamburger language twoLanguages">
            <app-languaje [ocultedLanguage]="hasTwoLanguages"></app-languaje>
        </div>
    </div>

</ng-container>