import { CardModule } from './../card/card.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from './../../pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailHeaderComponent } from './detail-header/detail-header.component';
import { GalleryCarouselModule } from '../gallery-carousel/gallery-carousel.module';
import { SelectionComponent } from './selection/selection.component';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { ProductDetailComponent } from './product-detail.component';



@NgModule({
  declarations: [
    ProductDetailComponent,
    DetailHeaderComponent,
    SelectionComponent,
  ],
  exports: [
    ProductDetailComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    GalleryCarouselModule,
    TranslateModule,
    DirectivesModule,
    CardModule
  ]
})
export class ProductDetailModule { }
