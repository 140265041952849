import { Component, OnInit, AfterContentInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OcultadorService } from './ocultador.service';



import { DataStoreService } from '../../services/data-store.service';
import { environment } from '../../../environments/environment';
import { ActionEventService } from '../../services/action-event.service';
import { DataService } from '../../services/data.service';
import { LanguageService } from '../../component/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { Location } from '@angular/common';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterContentInit, AfterViewInit {

  public showForm = false;
  public isNewHome = environment.brands[this.dataStoreService.brand].config.isNewHome;
  section: string = '';

  get brand() {
    return this.dataStoreService.brand;
  }

  constructor(
    private dataStoreService: DataStoreService,
    public route: Router,
    private languageService: LanguageService,
    private dataService: DataService,
    private actionEventService: ActionEventService,
    private activateRoute: ActivatedRoute,
    public ocultadorService: OcultadorService,
    private translate: TranslateService,
    private store: StoreServiceLibrary,
    private location: Location
  ) {
    const objStateParams = this.location.getState();
    this.section = objStateParams['section'];
    this.getSubLoadBrand();
  }

  /**
   * @method brand va a retornar el brand del Storage
   * HomeComponent decide como renderizar el home
   */
  ngOnInit() {
  }


  ngAfterViewInit() {
    this.scrollIntoViewSection();
  }

  ngAfterContentInit() {
    this.getSubLoadBrand();
  }

  getSubLoadBrand() {
    const brandSub = environment.brands[this.dataStoreService.brand].subBrand;
    if (brandSub) {
      if (this.dataStoreService.creditCardDefult === 'amex') {
        this.actionEventService.setSubBrand('amex');
        this.dataService.saveCreditCardDefult('amex');
        this.actionEventService.setChangeFooter(true);
      } else {
        this.actionEventService.setSubBrand('default');
        this.dataService.saveCreditCardDefult('');
        this.actionEventService.setChangeFooter(true);
      }
    } else {
      this.dataService.saveCreditCardDefult('');
      this.actionEventService.setChangeFooter(false);
    }
  }

  scrollIntoViewSection() {
    if (this.section !== '' && this.section !== undefined) {
      setTimeout(() => {
        const elementSection = document.getElementById(this.section);
        let topElement = elementSection.getBoundingClientRect().top;
        document.body.scroll({
          top: topElement,
          behavior: 'smooth'
        });
      }, 500);
    }
  }
}
