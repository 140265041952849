export class Slider {

    static getPriceConversion(dataList, conversionRate, percent, userPoints) {
        dataList.forEach((item, i) => {
            const precioProducto = +item.price.totalPrice;

            const precioMillasProducto = (precioProducto * percent) / 100;
            const millasAdicionalNecesarias = precioMillasProducto - +userPoints;

            let puntosPagar = 0;
            let millasSobrantes = 0;
            if (millasAdicionalNecesarias >= 0) {
                puntosPagar = (millasAdicionalNecesarias === 0) ? (precioMillasProducto * percent) / 100 : (userPoints * percent) / 100;
            } else {
                millasSobrantes = userPoints - precioMillasProducto;
                puntosPagar = (precioMillasProducto * percent) / 100;
                puntosPagar = Math.ceil(precioMillasProducto / conversionRate);
            }
            let dineroPagar = Number((precioProducto - (puntosPagar * conversionRate)).toFixed(2));

            if (dineroPagar < 0) {
                dineroPagar = 0;
            }

            item.price.calculatedPrice = Math.abs(dineroPagar);
            item.price.pointsPrice = Math.abs(puntosPagar);
            item.price.restPointValue = Math.abs(millasSobrantes);
        });
        return dataList;
    }

}
