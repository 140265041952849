import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputNumberComponent),
      multi: true
    }
  ]
})
export class InputNumberComponent implements OnInit, ControlValueAccessor {

  @Input() min: number;
  private _max: number;
  @Input() set max(v: number) {
    if (+this.value > +v) {
      this.onInput(+v);
    }
    this._max = v;
  };
  get max(): number {
    return this._max;
  }
  value: number;
  isDisabled: boolean;
  onChange = (_: number) => { };
  onTouch = () => { };

  constructor() { }

  ngOnInit() { }

  onInput(value: number | string) {
    this.value = +value;
    this.validateLimits();
    this.onTouch();
    this.onChange(+this.value);
  }

  validateLimits() {
    if (!isNaN(this.min) && +this.value < +this.min) {
      this.value = +this.min;
    }
    if (!isNaN(this.max) && +this.value > +this.max) {
      this.value = +this.max;
    }
  }

  writeValue(value: any = 0) {
    if (isNaN(value) || value === '') {
      setTimeout(() => {
        this.onInput(0);
      });
    } else {
      this.value = +value;
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
  setDisabledMax(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
