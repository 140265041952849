/**
 * provee el selector de idioma (requiere que exista "ngx-translate" en el "app.module.ts")
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguajeComponent } from './language.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';



@NgModule({
  declarations: [LanguajeComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule
  ],
  exports: [LanguajeComponent]
})
export class LanguajeModule { }
