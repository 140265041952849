<footer>
    <div class="container">
        <div class="foot">
            <img src="./assets/images/{{brand}}-miles/logo-footer.svg" alt="">        
            <div class="bl-text bl-text--icon">
                <span class="" innerHTML="{{'LANDING.'+brand+'.FOOTER.all_rights' | translate}}"></span>
                <a class="underline_none" innerHTML="{{'LANDING.'+brand+'.FOOTER.all_rights_click_here' | translate}}"  (click)="openUrl('https://www.wearenovae.com/')" target="_blank"></a>
                <span class="" innerHTML="{{'LANDING.'+brand+'.FOOTER.all_rights2' | translate}}"></span>
                
                <span class="text-program" innerHTML="{{'LANDING.'+brand+'.FOOTER.learn_more' | translate}}"></span>
            </div>
            
            <div class="bl-text">
                <span class="" innerHTML="{{'LANDING.'+brand+'.FOOTER.terms' | translate}}"></span>
                <a class="" (click)="openUrl(loadUrl())" innerHTML="{{'LANDING.'+brand+'.FOOTER.term_click_here' | translate}}"></a>
                <span class="" innerHTML="{{'LANDING.'+brand+'.FOOTER.terms2' | translate}}"></span>
            </div>
        </div>
        
        <span innerHTML="{{'LANDING.'+brand+'.FOOTER.all_brand' | translate}}"></span>
    </div>
</footer>