import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DataStoreService } from '../../services/data-store.service';
import { environment } from '../../../environments/environment';
import { ActionEventService } from '../../services/action-event.service';
import { EProduct } from '../../component/searched/models/searched.model';
import { DataService } from '../../services/data.service';

@Injectable({
  providedIn: 'root'
})
export class AboutGuard implements CanActivate {

  hasAbout: boolean = false;

  constructor(private router: Router,
    private dataStore: DataStoreService,
    private dataService: DataService,
    private actionEventService: ActionEventService) {

  }

  canActivate() {
    if (this.dataStore.brand) {
      if (!environment.brands[this.dataStore.brand].config['hasAbout']) {
        if (environment.brands[this.dataStore.brand].config['isNewHome']) {
          if (this.dataStore.isAlleMobile) {
            this.dataService.productSelected = EProduct.TRAVEL;
            this.router.navigateByUrl('travel');
          } else {
            this.dataService.productSelected = EProduct.HOME;
            this.dataStore.preloaderImg = EProduct.GENERAL;
            this.router.navigateByUrl('home');
          }
          return false;
        } else {
          this.dataService.productSelected = EProduct.FLIGHTS;
          this.dataStore.preloaderImg = EProduct.FLIGHTS;
          this.actionEventService.sendRestringedAbout(false);
          this.router.navigateByUrl('travel');
          return false;
        }
      } else {
        this.hasAbout = environment.brands[this.dataStore.brand].config.hasAbout;
        if (this.hasAbout) {
          if (environment.brands[this.dataStore.brand].config['isNewHome']) {
            if (this.dataStore.isAlleMobile) {
              this.dataService.productSelected = EProduct.TRAVEL;
              this.router.navigateByUrl('travel');
            } else {
              this.dataService.productSelected = EProduct.HOME;
              this.dataStore.preloaderImg = EProduct.GENERAL;
              this.router.navigateByUrl('home');
            }
            return false;
          } else {
            this.dataService.productSelected = EProduct.FLIGHTS;
            this.dataStore.preloaderImg = EProduct.FLIGHTS;
            this.router.navigateByUrl('travel');
            return false;
          }
        } else {
          this.dataService.productSelected = EProduct.FLIGHTS;
          this.dataStore.preloaderImg = EProduct.FLIGHTS;
          this.router.navigateByUrl('travel');
          return false;
        }
      }
    } else {
      this.dataService.productSelected = EProduct.FLIGHTS;
      this.dataStore.preloaderImg = EProduct.FLIGHTS;
      this.router.navigateByUrl('home');
      this.actionEventService.sendRestringedAbout(false);
      return false;
    }
  }
}


