import { FilterGroupType, FilterDataType } from '../../shared/filters/filterParams';
import { EProduct } from 'src/app/component/searched/models/searched.model';
import { SearchedHotel } from 'src/app/component/searched/models/searched-hotel.model';
import { SearchedCar } from 'src/app/component/searched/models/searched-car.model';
import { SearchedFlight } from 'src/app/component/searched/models/searched-flight.model';
import { SearchedActivity } from 'src/app/component/searched/models/searched-activity.model';
import { SearchedBenefit } from 'src/app/component/searched/models/searched-benefit.model';

export class FiltersFormatter {
    constructor(
        private translations: any[],
        // private searched: SearchedHotel | SearchedCar | SearchedFlight | SearchedActivity | SearchedBenefit,
        private flightType: number
    ) { }

    getFiltersParamsList(productType: EProduct) {
        let chequedValues;
        switch (productType) {
            case EProduct.HOTELS:
                return this.hotelFilters(chequedValues);
                break;
            case EProduct.FLIGHTS:
                return this.flightFilters(chequedValues);
                break;
            case EProduct.CARS:
                return this.rentacarFilters(chequedValues);
                break;
            case EProduct.ACTIVITIES:
                return this.activityFilters(chequedValues);
                break;
            default:
                break;
        }
    }

    flightFilters(chequedValues) {
        const filtersParamsList = [];
        if (this.flightType > 1) {
            for (let i = 0; i < this.flightType; i++) {
                filtersParamsList.push(
                    {
                        groupTitle: 'RESULTS.stops_scales' + i,
                        groupName: 'segments' + i,
                        groupOrder: 1,
                        groupType: FilterGroupType.checkboxTextFG,
                        optionValuePath: 'data.flights[' + i + '].segments.length - 1',
                        optionsIdsPath: 'data.id',
                        optionType: FilterDataType.indistinctFilter,
                        includeText: undefined,
                        excludeText: undefined,
                        dictionary: [
                            {
                                inValue: 0,
                                outValue: this.translations['RESULTS.stops_direct'],
                                outValueTranslate: { text: 'RESULTS.stops_direct', params: undefined }
                            },
                            {
                                inValue: 1,
                                outValue: '1 ' + this.translations['RESULTS.stops_scale'],
                                outValueTranslate: { text: 'RESULTS.stops_scale_value', params: '{\'value\':\'' + 1 + '\'}' }
                            },
                            {
                                inValue: 2,
                                outValue: '2 ' + this.translations['RESULTS.stops_scales'],
                                outValueTranslate: { text: 'RESULTS.stops_scales_value', params: '{\'value\':\'' + 2 + '\'}' }
                            },
                            {
                                inValue: 3,
                                outValue: '3 ' + this.translations['RESULTS.stops_scales'],
                                outValueTranslate: { text: 'RESULTS.stops_scales_value', params: '{\'value\':\'' + 3 + '\'}' }
                            },
                            {
                                inValue: 4,
                                outValue: '4 ' + this.translations['RESULTS.stops_scales'],
                                outValueTranslate: { text: 'RESULTS.stops_scales_value', params: '{\'value\':\'' + 4 + '\'}' }
                            },
                            {
                                inValue: 5,
                                outValue: '5 ' + this.translations['RESULTS.stops_scales'],
                                outValueTranslate: { text: 'RESULTS.stops_scales_value', params: '{\'value\':\'' + 5 + '\'}' }
                            },
                        ],
                        multipleOptions: true,
                    },
                    {
                        groupTitle: 'RESULTS.company' + i,
                        groupName: 'Company' + i,
                        groupOrder: 2,
                        groupType: FilterGroupType.checkboxTextFG,
                        optionValuePath: 'data.flights[' + i + '].company.longName',
                        optionsIdsPath: 'data.id',
                        optionType: FilterDataType.indistinctFilter,
                        multipleOptions: true,
                    },
                    {
                        groupTitle: this.translations['flight_duration' + i],
                        groupOrder: 3,
                        groupName: 'duration' + i,
                        groupType: FilterGroupType.sliderFG,
                        optionValuePath: 'data.flights[' + i + '].flightDuration',
                        optionsIdsPath: 'data.id',
                        optionType: FilterDataType.momentFilter
                    },
                    {
                        groupTitle: this.translations['departure_hour' + i],
                        groupOrder: 4,
                        groupName: 'hour' + i,
                        groupType: FilterGroupType.sliderFG,
                        optionValuePath: 'data.flights[' + i + '].departureHour',
                        optionsIdsPath: 'data.id',
                        optionType: FilterDataType.momentFilter
                    }
                );
            }
        } else {
            filtersParamsList.push(
                {
                    groupTitle: 'RESULTS.stops_scales' + 0,
                    groupName: 'segments' + 0,
                    groupOrder: 1,
                    groupType: FilterGroupType.checkboxTextFG,
                    optionValuePath: 'data.flights[0].segments.length - 1',
                    optionsIdsPath: 'data.id',
                    optionType: FilterDataType.indistinctFilter,
                    includeText: undefined,
                    excludeText: undefined,
                    dictionary: [
                        {
                            inValue: 0,
                            outValue: this.translations['RESULTS.stops_direct'],
                            outValueTranslate: { text: 'RESULTS.stops_direct', params: undefined }
                        },
                        {
                            inValue: 1,
                            outValue: '1 ' + this.translations['RESULTS.stops_scale'],
                            outValueTranslate: { text: 'RESULTS.stops_scale_value', params: '{\'value\':\'' + 1 + '\'}' }
                        },
                        {
                            inValue: 2,
                            outValue: '2 ' + this.translations['RESULTS.stops_scales'],
                            outValueTranslate: { text: 'RESULTS.stops_scales_value', params: '{\'value\':\'' + 2 + '\'}' }
                        },
                        {
                            inValue: 3,
                            outValue: '3 ' + this.translations['RESULTS.stops_scales'],
                            outValueTranslate: { text: 'RESULTS.stops_scales_value', params: '{\'value\':\'' + 3 + '\'}' }
                        },
                        {
                            inValue: 4,
                            outValue: '4 ' + this.translations['RESULTS.stops_scales'],
                            outValueTranslate: { text: 'RESULTS.stops_scales_value', params: '{\'value\':\'' + 4 + '\'}' }
                        },
                        {
                            inValue: 5,
                            outValue: '5 ' + this.translations['RESULTS.stops_scales'],
                            outValueTranslate: { text: 'RESULTS.stops_scales_value', params: '{\'value\':\'' + 5 + '\'}' }
                        },
                    ],
                    multipleOptions: true,
                },
                {
                    groupTitle: 'RESULTS.company' + 0,
                    groupName: 'Company' + 0,
                    groupOrder: 4,
                    groupType: FilterGroupType.checkboxTextFG,
                    optionValuePath: 'data.flights[0].company.longName',
                    optionsIdsPath: 'data.id',
                    optionType: FilterDataType.indistinctFilter,
                    multipleOptions: true,
                },
                {
                    groupTitle: 'RESULTS.cabin',
                    groupName: 'Clase',
                    groupOrder: 3,
                    groupType: FilterGroupType.checkboxTextFG,
                    optionValuePath: 'data.info.cabin',
                    optionsIdsPath: 'data.id',
                    chequedValues: [], // this.chequedValues.cabin,
                    optionType: FilterDataType.indistinctFilter,
                    dictionary: [
                        {
                            inValue: 'Y',
                            outValue: this.translations['RESULTS.economy_cabin'],
                            outValueTranslate: { text: 'RESULTS.economy_cabin', params: undefined }
                        },
                        {
                            inValue: 'B',
                            outValue: this.translations['RESULTS.bussines_cabin'],
                            outValueTranslate: { text: 'RESULTS.bussines_cabin', params: undefined }
                        },
                        {
                            inValue: 'F',
                            outValue: this.translations['RESULTS.first_class_cabin'],
                            outValueTranslate: { text: 'RESULTS.first_class_cabin', params: undefined }
                        },
                        {
                            inValue: 'P',
                            outValue: this.translations['RESULTS.premium_economy_cabin'],
                            outValueTranslate: { text: 'RESULTS.premium_economy_cabin', params: undefined }
                        },
                        {
                            inValue: 'M',
                            outValue: this.translations['RESULTS.combined_cabin'],
                            outValueTranslate: { text: 'RESULTS.combined_cabin', params: undefined }
                        },
                    ],
                    multipleOptions: true,
                },
                {
                    groupTitle: 'RESULTS.flight_duration' + 0,
                    groupName: 'duration' + 0,
                    groupType: FilterGroupType.sliderFG,
                    optionValuePath: 'data.flights[0].flightDuration',
                    optionsIdsPath: 'data.id',
                    optionType: FilterDataType.momentFilter
                },
                {
                    groupTitle: 'RESULTS.departure_hour' + 0,
                    groupName: 'departureHour' + 0,
                    groupType: FilterGroupType.sliderFG,
                    optionValuePath: 'data.flights[0].departureHour',
                    optionsIdsPath: 'data.id',
                    optionType: FilterDataType.momentFilter
                }
            );
        }
        return filtersParamsList;
    }

    hotelFilters(chequedValues) {
        return [
            {
                groupTitle: 'Hotel',
                groupName: 'hotel',
                groupType: FilterGroupType.textAutocompleteFG,
                optionValuePath: 'data.name',
                optionsIdsPath: 'data.id',
                optionType: FilterDataType.autocompleteFilter,
                // Deben ser expresiones regulares
                includeText: undefined,
                excludeText: undefined,
                iconImg: '',
            },
            {
                groupTitle: this.translations['category'],
                groupName: 'category',
                groupType: FilterGroupType.checkboxIconsFG,
                optionValuePath: 'data.category',
                optionsIdsPath: 'data.id',
                optionType: FilterDataType.iconFilter,
                // Deben ser expresiones regulares
                includeText: new RegExp(/Estre|estre|Star|star/),
                excludeText: undefined,
                iconImg: 'travel-icon icon-starF',
                chequedValues: chequedValues ? chequedValues.category : undefined,
                multipleOptions: true,
            },
        ];
    }

    rentacarFilters(chequedValues) {
        return [
            {
                groupTitle: this.translations['model'],
                groupName: 'Marca-Modelo',
                groupType: FilterGroupType.textAutocompleteFG,
                optionValuePath: 'data.name',
                optionsIdsPath: 'data.id',
                optionType: FilterDataType.autocompleteFilter,
                //Deben ser expresiones regulares
                includeText: undefined,
                excludeText: undefined
            },
            {
                groupTitle: this.translations['price'],
                groupName: 'price',
                groupType: FilterGroupType.sliderFG,
                optionValuePath: 'data.price.totalPrice',
                optionsIdsPath: 'data.id',
                optionType: FilterDataType.priceFilter,
                //Deben ser expresiones regulares
                includeText: undefined,
                excludeText: undefined,
                currencyPath: 'data.price.currency'
            },
        ];
    }

    activityFilters(chequedValues) {
        return [
            {
                groupTitle: this.translations['name'],
                groupName: 'Nombre',
                groupType: FilterGroupType.textAutocompleteFG,
                optionValuePath: 'data.name',
                optionsIdsPath: 'data.id',
                optionType: FilterDataType.autocompleteFilter,
                //Deben ser expresiones regulares
                includeText: undefined,
                excludeText: undefined,
                multipleOptions: true
            },
            {
                groupTitle: this.translations['price'],
                groupName: 'price',
                groupType: FilterGroupType.sliderFG,
                optionValuePath: 'data.price.totalPrice',
                optionsIdsPath: 'data.id',
                optionType: FilterDataType.priceFilter,
                //Deben ser expresiones regulares
                includeText: undefined,
                excludeText: undefined,
                currencyPath: 'data.price.currency',
                multipleOptions: true
            }
        ];
    }
}
