<!--<h1 (click)="sortFn()">botoooon</h1>-->
<div class="b-contenido-hotel">

  <app-main-form class="mb-1"></app-main-form>

  <div class="container mb-4" id="hotel_container" *ngIf="hotelList.length > 0">
    <app-hotel-detail-modal [openModal]="openModal" [options]="optionModal"
      [hotel]="hotelDetail" [porcentajeConversion]="porcentajeConversion">
    </app-hotel-detail-modal>

    <div class="row">

      <!-- CONTENEDORES BOTONES MOBILE -->
      <div class="content-btn-filter">
        <div (click)="showFilters = !showFilters" class="btn-click"
          innerHTML="{{ 'HOTEL_BODY.filter_result' | translate }}">
          Filter Results
        </div>
        <button mt-raised-button class="btn-click"
          (click)="showPriceSlider = !showPriceSlider"
          innerHTML="{{ 'HOTEL_BODY.adjust_price' | translate }}">
          Adjust price
        </button>
      </div>

      <!-- CONTENEDOR PARA FILTROS -->
      <div [ngClass]="{'show':showFilters}"
        class="filtros-responsive col-lg-2 mb-5" id="filters">
        <app-filters #filtros [fullData]="hotelData.hotelOptions"
          [filtersParamsList]="filtersParamsList"
          (idsArrayToFilter)="filterListener($event)">
        </app-filters>
        <div (click)="showFilters = false" class="btn-click2"
          innerHTML="{{ 'HOTEL_BODY.close_filters' | translate }}">
          Close filters
        </div>
      </div>

      <!-- CABECERA DE DESTINO -->
      <div class="content-hotel col-lg-6">
        <!-- <div class="header-filters" attr.id="filter-{{currentSegment}}">
          <div class="items">
            <p class="title"
              innerHTML="{{ 'HOTEL_BODY.accommodation_in' | translate:'{city:\'' + cityName + '\'}' }}">
              Accommodation in
            </p>
            <p class="quantity"></p>
          </div>
        </div> -->
        <div [ngClass]="(mapView) ? 'bl-items-mapa' : 'bl-items'">
          <app-hotel-list #hotelListCmp *ngIf="hotelList.length > 0"
            [hotelList]="hotelList" [style]="(!mapView) ? 1 : 2"
            (hotelDetail)="hotelDetailListener($event)"
            [filteredID]="filteredID">
          </app-hotel-list>
        </div>
      </div>

      <!-- AJUSTAR PRECIO: ONE-POCKET -->
      <div class="col-md-4 col-12 content-iframe-b" id="one-pocket">
        <div class="sticky-top" [class.slider-mobile]="(showPriceSlider)">
          <div class="backdrop" (click)="showPriceSlider = false"></div>
          <div class="body">
            <app-slider (getPorcentaje)="actualizarPorcentaje($event)"
              [moneyCurrency]="dataStore.selectedHotel.selectedRoomOption.price.currency"
              [totalCost]="dataStore.selectedHotel.selectedRoomOption.price.totalPrice"
              [token]="dataService.token"
              [enabled]="dataService.selectedJourney && (dataService.token !== '' && dataService.token !== undefined)">
            </app-slider>
            <div class="btn-aceptar">
              <button class="btn-travel" (click)="showPriceSlider = false"
                innerHTML="{{ 'HOTEL_BODY.accept' | translate }}">
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
