import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {delay} from 'rxjs/operators';
import {DataService} from '../../../services/data.service';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-target-input',
  templateUrl: './target-input.component.html',
  styleUrls: ['./target-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TargetInputComponent),
      multi: true
    }
  ]
})
export class TargetInputComponent implements OnInit, ControlValueAccessor {
  @Input() targetPlaceholder;
  value = '';
  allCities = [];
  constructor(private dataService: DataService) { }
  ngOnInit() {
  }
  onChange = (_: any) => { };
  onInput($event) {
    this.onChange($event.iata);
  }

  writeValue(value: any): void {
    if (value) {
      this.value = value || '';
    } else {
      this.value = '';
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }
  onSearch($event) {
    if ($event.term.length > 2) {
      this.dataService.getCities($event.term).pipe(delay(100)).subscribe(cities => {
        this.allCities = cities;
      });
    }
  }
}
