import { Component, OnInit } from '@angular/core';
import { CardLayoutTwoComponent } from '../card-layout-two.component';
import { ECardType } from '../../../models/options.model';
import { getCurrencySymbol } from '@angular/common';
import { DataStoreService } from '../../../../../services/data-store.service';

@Component({
  selector: 'app-card-price-modal',
  templateUrl: './card-price.component.html',
  styleUrls: ['./card-price.component.scss']
})
export class CardPriceComponent implements OnInit {

  get currency() {
      return this.layout.currency + ' ' + getCurrencySymbol(this.layout.currency, 'narrow');
  }

  get options() {
    return this.layout.options;
  }

  get hasInfo() {
    return this.layout.product.info && this.layout.product.info.length ? true : false;
  }

  get travellerLabel() {
    const trans = this.layout.translations;
    const options = this.options;
    switch (options.type) {
      case ECardType.HOTELS:
        return { traveller: trans['guest'] , travellers: trans['guests'] };
        break;
      case ECardType.ROOMS:
        return { traveller: trans['guest'] , travellers: trans['guests'] };
        break;
      case ECardType.ACTIVITIES:
        return { traveller: trans['passenger'] , travellers: trans['passengers'] };
        break;
      default:
        break;
    }
  }

  get priceLabel() {
    const trans = this.layout.translations;
    const travellerLabel = this.travellerLabel;
    const traveller = this.hasInfo ? +this.getinfoValue('traveller') : 0;
    const label = trans['total_price_for'];
    if(this.dataStore.brand === 'scotia'){
      return trans['total_price']
    }
    switch (traveller) {
      case 0:
        return trans['total_price']
        break;
      case 1:
        return label + '1 ' +  travellerLabel.traveller;
        break;
      default:
        return label + traveller + ' ' +  travellerLabel.travellers;
        break;
    }
    return '';
  }

  getinfoValue(info): any {
    try {
      return this.layout.product.info.find(item => item.type === 'traveller').value;
    } catch {
      return 0;
    }
  }

  get showBottomInfo() {
    return this.layout.options.showBottomInfo;
  }

  constructor(
    public layout: CardLayoutTwoComponent,
    private dataStore: DataStoreService,
    ) {
  }

  ngOnInit() {
  }

}
