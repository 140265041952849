import { Component, OnInit } from '@angular/core';
import { InitService } from '../../services/init.service';
import { EProduct } from '../../component/searched/models/searched.model';
import { ActionEventService } from '../../services/action-event.service';
import { DataService } from '../../services/data.service';
import { DataStoreService } from '../../services/data-store.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalLoadVideoTutorialComponent } from '../../component/modal-load-video-tutorial/modal-load-video-tutorial.component';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { keysUniversal } from 'src/app/resources/keys.resources';

@Component({
  selector: 'app-landing-upperclub',
  templateUrl: './landing-upperclub.component.html',
  styleUrls: ['./landing-upperclub.component.css']
})
export class LandingUpperclubComponent implements OnInit {

  isoCoutry: string;

  tutorialsData = [];
  tutorialsDataFilter = [];
  titleTutorials: string = '';

  get brand() {
    return this.dataStoreService.brand;
  }


  constructor(private initService: InitService,
    private actionEventService: ActionEventService,
    private dataService: DataService,
    private dataStoreService: DataStoreService,
    private storeLibrary: StoreServiceLibrary,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.initService.isInitializedBrand.subscribe((isInit) => {
      if (isInit) {
        this.actionEventService.setItemActiveTab(EProduct.TUTORIALS);
        this.dataService.productSelected = EProduct.TUTORIALS;
        this.dataStoreService.preloaderImg = EProduct.TUTORIALS;
        this.getTutorialsData();
        this.getCountryCodeStore();
        this.changeTutorialsCountry();
        this.filterData();
      }
    });
  }


  openModalVideo(tutorial) {
    const dialogRef = this.dialog.open(ModalLoadVideoTutorialComponent, {
      panelClass: 'video-tutorial',
      id: 'modal-video-tutorial',
      data: {
        tutorial
      }
    });

  }


  getTutorialsData() {
    let tutorialInfo = JSON.parse(this.storeLibrary.getJustOneUniversalPropertie(keysUniversal.TUTORIALS_DATA));
    this.tutorialsData = tutorialInfo['tutorials'];
    this.titleTutorials = tutorialInfo['title'];
  }

  changeTutorialsCountry() {
    this.actionEventService.countryCodeChange.subscribe((countryCodeChange) => {
      this.isoCoutry = countryCodeChange.code;
      this.tutorialsDataFilter = [];
      this.filterData();
    });
  }

  getCountryCodeStore() {
    let countryCode = this.storeLibrary.getItemFromStorage('CountrySelected');
    this.isoCoutry = countryCode;
  }

  filterData() {
    this.tutorialsData.map((newInfo) => {
      if (newInfo.countryCode == this.isoCoutry) {
        this.tutorialsDataFilter.push(newInfo);
      }
    });
  }

}
