<footer class="content-footer">
  <div id="containerNav" class="container" #containerNav>
    <ul *ngIf="LoadNewMenu">
      <li (click)="tabSelected(EProduct.FLIGHTS, containerNav)" routerLink="/travel"
        innerHTML="{{'FOOTER.flight'|translate}}" *ngIf="!isSearchIframe">
        Flight
      </li>
      <li (click)="tabSelected(EProduct.HOTELS, containerNav)" routerLink="/home"
        innerHTML="{{'FOOTER.hotel'|translate}}" *ngIf="!isSearchIframe">
        Hotel
      </li>
      <li (click)="tabSelected(EProduct.CARS, containerNav)" routerLink="/home" innerHTML="{{'FOOTER.cars'|translate}}"
        *ngIf="!isSearchIframe">
        Cars
      </li>
      <li (click)="tabSelected(EProduct.ACTIVITIES, containerNav)" routerLink="/home"
        *ngIf="hasActivities && !isSearchIframe " innerHTML="{{'FOOTER.activities'|translate}}">
        Activities
      </li>
      <li (click)="tabSelected(EProduct.BENEFITS, containerNav)" routerLink="/home"
        *ngIf="hasBenefits && !isSearchIframe" innerHTML="{{'FOOTER.benefits'|translate}}">
        Benefits
      </li>
      <li (click)="showTermsAndConditionsDialog()" innerHTML="{{'FOOTER.terms_conditions'|translate}}">
        Terms and Conditions
      </li>
      <span>©&nbsp;{{YEAR}}</span>
    </ul>
    <ul *ngIf="isNewMenu">
      <li (click)="tabSelected(EProduct.FLIGHTS, containerNav)" routerLink="/travel"
        innerHTML="{{'FOOTER.flight'|translate}}" *ngIf="!isSearchIframe">
        Flight
      </li>
      <li (click)="tabSelected(EProduct.HOTELS, containerNav)" routerLink="/travel"
        innerHTML="{{'FOOTER.hotel'|translate}}" *ngIf="!isSearchIframe">
        Hotel
      </li>
      <li (click)="tabSelected(EProduct.CARS, containerNav)" routerLink="/travel"
        innerHTML="{{'FOOTER.cars'|translate}}" *ngIf="!isSearchIframe">
        Cars
      </li>
      <li (click)="tabSelected(EProduct.ACTIVITIES, containerNav)" routerLink="/travel"
        *ngIf="hasActivities && !isSearchIframe" innerHTML="{{'FOOTER.activities'|translate}}">
        Activities
      </li>
      <li (click)="tabSelected(EProduct.BENEFITS, containerNav)" routerLink="/travel"
        *ngIf="hasBenefits && !isSearchIframe" innerHTML="{{'FOOTER.benefits'|translate}}">
        Benefits
      </li>
      <li (click)="showTermsAndConditionsDialog()" innerHTML="{{'FOOTER.terms_conditions'|translate}}">
        Terms and Conditions
      </li>
      <span>©&nbsp;{{YEAR}}</span>
    </ul>
    <ul *ngIf="isFooterServices">
      <li (click)="showTermsAndConditionsDialog()" innerHTML="{{'FOOTER.terms_conditions'|translate}}">
        Terms and Conditions
      </li>
      <span>©&nbsp;{{YEAR}}</span>
    </ul>
  </div>
</footer>
