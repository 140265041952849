<section class="travel-protection">
  <h1 innerHTML="{{'LANDING.'+brand+'.CERTIFICATES_USER.protect_your_trip' | translate}}">Protect your trip</h1>

  <div class="bl-trip">
    <div class="bl-passengers-l">
      <div class="passengers">
        <h2 innerHTML="{{'LANDING.'+brand+'.CERTIFICATES_USER.passengers' | translate}}">Passengers</h2>
        <p innerHTML="{{'LANDING.'+brand+'.CERTIFICATES_USER.please_select_passengers' | translate}}">Please select the passengers you want to protect:</p>

        <div class="list-passengers">
          <div [id]="i+'passenger'" class="item" *ngFor="let item of passengers; let i = index;"
            (click)="clickPassengersData(item, i)" [ngClass]="{'disabled': disabledItem }">
            <span class="checkbox"></span>
            <p>{{item.name}} {{item.surname}}</p>
          </div>

        </div>


      </div>
    </div>

    <div class="box-travel-protection">
      <div class="header">
        <h2 innerHTML="{{'LANDING.'+brand+'.CERTIFICATES_USER.travel_protecion' | translate}}">Travel protection</h2>
        <div class="logo"><img src="./assets/images/logo-milescare-novae.svg" alt=""></div>
      </div>

      <div class="plan-items" *ngFor="let item of certificatesGroup; let i = index;">
        <div class="valor">
          <div class="item">
            <input type="radio" name="itemBenefits" (click)="factoryObject(item)">
          </div>
          <span>{{ item['productCurrency'] }}{{ item['productRate'] | currency:currencyUniversal.iso:'symbol':'1.0-2':this.currencyUniversal.locale }}</span>
        </div>
        <div class="benefits">
          <span>{{ item['productName'] }}</span>
          <ul class="benefits-items" *ngFor="let benefits of item['benefitGroups']; let j = index;">
            <div *ngFor="let benefitsItem of benefits['benefits']; let k = index;">
              <li *ngIf="benefitsItem['order'] !== null">
                <span *ngIf="benefitsItem['order'] !== null">{{ benefitsItem.benefitName }}</span>
              </li>
            </div>
          </ul>
          <div class="learn-more" (click)="openLearnMore(item)"
            innerHTML="{{'LANDING.'+brand+'.CERTIFICATES_USER.learn_more' | translate}}">+ Learn More</div>
        </div>
      </div>

      <div class="plan-items last-child">
        <div class="valor">
          <div class="item">
            <input type="radio" name="itemBenefits" (click)="disabledSelector()">
          </div>
          <span innerHTML="{{'LANDING.'+brand+'.CERTIFICATES_USER.not_now' | translate}}">Not now</span>
        </div>
        <div class="benefits">
          <ul class="benefits-items">
            <li><span innerHTML="{{'LANDING.'+brand+'.CERTIFICATES_USER.you_can_buy_travel_benefits' | translate}}">You
                can buy travel benefits at any time</span> </li>
          </ul>
        </div>
      </div>
      <div class="terms">
        <div class="left">
          <p><a (click)="showTermsAndConditionsDialog()"
              innerHTML="{{'LANDING.'+brand+'.CERTIFICATES_USER.see_t_y_c' | translate}}">See Terms and Conditions.</a>
          </p>
        </div>
        <div class="right">
          <p innerHTML="{{'LANDING.'+brand+'.CERTIFICATES_USER.in_some_countries' | translate}}">In some countries some
            extra charges may apply.</p>
        </div>
      </div>
    </div>
  </div>



  <div class="bl-total">
    Total: {{currencyGlobal}}{{ dataService.getCurrencySymbol(currencyGlobal,'narrow')}}
    &nbsp;{{ totalPrice }}
  </div>


  <div class="bl-foot">

    <p class="conditions" innerHTML="{{'LANDING.'+brand+'.CERTIFICATES_USER.footer_table_text' | translate}}">Miles care
      is a product of novae Care & Connect that offers travel assistance benefits. Miles
      Care is not an insurance and neither novae nor LifeMiles are insurance brokers or intermediaries. Lifemiles is not
      responsible for the Miles Care products. novae is responsible for managing the assistance program and everything
      related to the benefits and coverage of the Miles Care program. Check the Miles Care terms and conditions here:
      Link
      T&C.</p>

    <button [ngClass]="{'button-inactive': activateButton}" (click)="proccesCertificate()" [disabled]="activateButton"
      innerHTML="{{'LANDING.'+brand+'.CERTIFICATES_USER.continue_button' | translate}}">Continue</button>
  </div>



</section>
