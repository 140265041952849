<div class="img-container"
    [style.background-image]="'url(' + promotion.src + ')'">
    <div class="promo-container">
        <!-- icono -->
        <i [class]="product"></i>
        <h3 class="destino">{{promotion.destino}}</h3>
        <p class="detalle" *ngIf="showDetail"
            innerHTML="{{'PROMOCIONES.PROMOCION.product'|translate:'{product:\'' + (('EProduct.' + product)|translate) + '\'}'}}">
        </p>
        <p class="precio" *ngIf="showDetail">{{promotion.precio}}</p>
        <p class="currency" *ngIf="showDetail">{{promotion.currency}}</p>
    </div>
</div>