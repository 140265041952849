<div ><!--*ngIf="translations"-->
    <div class="header" *ngIf="options.hasTitle">
        <app-card-header></app-card-header>
    </div>
    <div class="d-flex align-items-center flex-column flex-sm-row selector-container">
        <div class="actions-container">
            <app-dropdown-option></app-dropdown-option>
            <app-radio-option></app-radio-option>
        </div>

        <div class="divider align-self-stretch"></div>

        <div class="price-container" *ngIf="product.getMoneyAmount()">
            <p class="strong" innerHTML="{{ 'ACTIVITY_DETAIL.activity_price' | translate }}">Precio por actividad</p>
            <p class="point-price">{{ product.getPointAmount() | milesFormat }}
                <span innerHTML="{{ 'ACTIVITY_DETAIL.miles' | translate }}">points</span>
            </p>
            <p class="money-price">+ {{ product.getMoneyAmount() }}</p>
        </div>
    </div>
</div>