<div class="container">
  <div class="row">
    <ng-container *ngFor="let item of data | filter:filteredID; let i = index;">
      <app-abstract-card *ngIf="i < qtyResultShow" [product]="item">
      </app-abstract-card>
    </ng-container>
    <div class="clearfix"></div>
    <div class="select-button-container mt-3 col-12">
      <button class="btn-travel btn-hotel" *ngIf="data.length > qtyResultShow"
        (click)="AddResults()" innerHTML="{{ 'RENTACAR_BODY.LIST.more_results' | translate }}">Mostrar más resultados</button>
    </div>
  </div>
</div>