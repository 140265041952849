
<section class="gray s-flex voyage viajes-voyage" *ngIf="brand == 'apap' || brand == 'myrewards' || brand == 'ncb' || brand == 'cibc' || brand == 'firstcitizens'">
    <div class="container">

            <h2 class="text-gradient" innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE_TRAVEL.tops_title' | translate}}">En la sección de viajes "Voyage", encontrarás el viaje ideal para cada momento.</h2>
            <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE_TRAVEL.tops_list_1' | translate}}"><strong>Con My Rewards APAP, accede a más de 28 millones de ofertas</strong> en vuelos, hoteles, alojamiento y alquileres de autos en todo el mundo. Reserva tu viaje con millas y disfruta de una experiencia sin restricciones de fechas o asientos.</p>

            <div class="bl-center">
                <ul class="logos-voyage">
                    <li><img src="./assets/images/{{brand}}-miles/voyage-expedia.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/voyage-priceline.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/voyage-viator.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/voyage-vrbo.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/voyage-hotels.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/voyage-american.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/voyage-avianca.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/voyage-qatar.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/voyage-copa.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/voyage-jet.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/voyage-airbnb.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/voyage-wyndham.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/voyage-hyatt.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/voyage-ihg.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/voyage-marriott.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/voyage-voyages.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/voyage-universal.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/voyage-disney.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/voyage-golf.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/voyage-car.png" alt="mr"></li>
                </ul>
            </div>
    </div>
</section>


<section class="gray s-flex voyage"  *ngIf="brand != 'apap' && brand != 'myrewards' && brand != 'ncb' && brand != 'cibc' && brand != 'firstcitizens'">
    <div class="container">
        <div class="bl-row">
            <div class="bl-left">
                <h2 class="text-gradient" innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE_TRAVEL.tops_title' | translate}}"></h2>
                <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE_TRAVEL.tops_list_1' | translate}}"></p>
            </div>

            <div class="bl-right">
                <ul class="logos-voyage" *ngIf="brand ==='firstcitizens' || brand === 'cibc' || brand === 'bancoindustrial'">
                    <li><img src="./assets/images/{{brand}}-miles/logo-expedia.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/logo-hotels.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/logo-vrbo.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/logo-homeaway.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/logo-arbitz.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/logo-travelocity.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/logo-carrentals.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/logo-cruise.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/logo-vacations.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/logo-cheap.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/logo-traveldoo.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/logo-silverrail.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/logo-trivago.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/logo-hotwire.png" alt="mr"></li>
                    <li><img src="./assets/images/{{brand}}-miles/logo-ebookers.png" alt="mr"></li>
                </ul>
            </div>
        </div>
    </div>
</section>





<section class="rewards-benefits">
    <div class="container">
        <h2 innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_BENEFITS.tops_title' | translate}}">My Rewards benefits</h2>

        <div class="bl-row">
            <div class="bl-left">
                <img *ngIf="brand != 'firstcitizens'" src="./assets/images/myrewards-miles/icon-uppermiles.svg" alt="">
                <img *ngIf="brand === 'firstcitizens'" src="./assets/images/firstcitizens-miles/icon-uppermiles.svg" alt="">
                <span innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_BENEFITS.tops_list_1_title1' | translate}}">upperMiles: it's leveraged miles</span>
                <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_BENEFITS.tops_list_1_descript1' | translate}}">Earn miles when spending and redeeming. Offering up to 3X rewards on all spending and redemptions, even when paying with rewards. There’s no limit to how much you can earn, there’s no expiration date, either.</p>
            </div>
            <div class="bl-right">
                <img *ngIf="brand != 'firstcitizens'" src="./assets/images/myrewards-miles/icon-purchase.svg" alt="">
                <img *ngIf="brand === 'firstcitizens'" src="./assets/images/firstcitizens-miles/icon-purchase.svg" alt="">

                <span innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_BENEFITS.tops_list_2_title2' | translate}}">International purchase protection</span>
                <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_BENEFITS.tops_list_2_descript2' | translate}}">Earn miles when spending and redeeming. Offering up to 3X rewards on all spending and redemptions, even when paying with rewards. There’s no limit to how much you can earn, there’s no expiration date, either.</p>
            </div>
        </div>


        <div class="bl-row">
            <div class="bl-left">
                <img *ngIf="brand != 'firstcitizens'" src="./assets/images/myrewards-miles/icon-voyage.svg" alt="">
                <img *ngIf="brand === 'firstcitizens'" src="./assets/images/firstcitizens-miles/icon-voyage.svg" alt="">

                <span id="benefits" innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_BENEFITS.tops_list_3_title3' | translate}}">Voyage Benefits</span>
                <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_BENEFITS.tops_list_3_descript3' | translate}}">Travel the flex way and with total peace of mind. Enjoy Voyage's unique benefits so that you never worry about anything going wrong while you're on the go.</p>
                <button pageScroll href="#bl-tablas" class="btn button" (click)="openSection('1')">{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.btnLeranMore' | translate}}</button>
            </div>
            <div class="bl-right">
                <img *ngIf="brand != 'firstcitizens'" src="./assets/images/myrewards-miles/icon-benefits.svg" alt="">
                <img *ngIf="brand === 'firstcitizens'" src="./assets/images/firstcitizens-miles/icon-benefits.svg" alt="">

                <span innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_BENEFITS.tops_list_4_title4' | translate}}">More benefits</span>
                <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_BENEFITS.tops_list_4_descript4' | translate}}">Miles Care brings you plans tailored to your needs. Select the membership that fits you best for all your flights during one year.</p>
                <button pageScroll href="#bl-tablas" class="btn button" (click)="openSection('2')">{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.btnLeranMore' | translate}}</button>
            </div>
        </div>

        <!-- <button *ngIf="brand === 'myrewards'" class="btn button" (click)="openSection()">{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.btnLeranMore' | translate}}</button> -->
    </div>
</section>


<!--START NUEVA SECCION-->

<section class="benefits-voyage benefits-voyage-apap">
    <div pageScroll href="#benefits" class="arrow-up" (click)="closeSection('1')"></div>

    <div class="container">

        <div class="bl-row align-top" *ngIf="brand == 'apap' || brand == 'myrewards' || brand == 'ncb' || brand == 'cibc' || brand == 'firstcitizens'">
            <div class="bl-center">
                <h1 class="text-gradient">{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE.redeem_title' | translate}}</h1>
                <p class="intro" innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE.redeem_desc_1' | translate}}"></p>

                <ul class="list-benefits">
                    <span innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE.redeem_subtitle1' | translate}}"></span>
                    <li>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE.redeem_item_1' | translate}}"></p>
                    </li>
                </ul>

                <ul class="list-benefits">
                    <span innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE.redeem_subtitle2' | translate}}"></span>
                    <li>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE.redeem_item_2' | translate}}"></p>
                    </li>
                    <li>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE.redeem_item_3' | translate}}"></p>
                    </li>
                    <li>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE.redeem_item_4' | translate}}"></p>
                    </li>
                </ul>

                <ul class="list-benefits">
                    <span innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE.redeem_subtitle3' | translate}}">Viajar es lo tuyo. Cuidarte es lo nuestro.</span>
                    <li>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE.redeem_item_8' | translate}}"><strong>Hasta US$ 50.000</strong> por gastos de emergencias médicas, incluido COVID-19.</p>
                    </li>
                    <li>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE.redeem_item_9' | translate}}"><strong>Cobertura en países de la comunidad Schengen.</strong></p>
                    </li>
                </ul>

                <ul class="list-benefits">
                    <span innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE.redeem_subtitle4' | translate}}"></span>
                    <li>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE.redeem_item_6' | translate}}"></p>
                    </li>
                    <li>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE.redeem_item_5' | translate}}"></p>
                    </li>
                    <li>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE.redeem_item_7' | translate}}"></p>
                    </li>
                </ul>

                <small innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE.terms_conditions' | translate}}"></small>
            </div>
        </div>


        <div class="bl-row align-top" *ngIf="brand != 'apap' && brand != 'myrewards' && brand != 'ncb' && brand != 'cibc' && brand != 'firstcitizens'">
            <div class="bl-center">
                <h1 class="text-gradient">{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE.redeem_title' | translate}}</h1>
                <p class="intro" innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE.redeem_desc_1' | translate}}"></p>

                <ul class="list-benefits">
                    <li>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE.redeem_item_1' | translate}}"></p>
                    </li>
                    <li>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE.redeem_item_2' | translate}}"></p>
                    </li>
                    <li>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE.redeem_item_3' | translate}}"></p>
                    </li>
                    <li>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE.redeem_item_4' | translate}}"></p>
                    </li>
                    <li *ngIf="brand === 'cibc'">
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE.redeem_item_5' | translate}}"></p>
                    </li>
                </ul>

                <div class="ad-benefits" *ngIf="brand !== 'cibc'">
                    <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_VOYAGE.redeem_item_5' | translate}}"></p>
                </div>

            </div>
        </div>
    </div>
</section>

<section class="bl-tablas" id="bl-tablas">
    <div pageScroll href="#benefits" class="arrow-up" (click)="closeSection('2')"></div>
    <div class="container container-full">

        <div class="seccion-centrar tabs">
            <h1 class="text-gradient" innerHTML="{{'DROP_DOWN_BENEFIST.HEADER.title' | translate}}">Más beneficios</h1>
            <p class="intro text-center" innerHTML="{{'DROP_DOWN_BENEFIST.HEADER.boddy' | translate}}">Con más beneficios solo debes pensar en disfrutar tu viaje. <br> Dale un vistazo rápido a lo que podemos hacer por ti.</p>
            <app-tabs></app-tabs>
        </div>

        <div class="seccion-centrar bl-plan">
            <h3 innerHTML="{{'DROP_DOWN_BENEFIST.SELECT_PLAN_BENEFITS.title' | translate}}"></h3>
            <h4 innerHTML="{{'DROP_DOWN_BENEFIST.SELECT_PLAN_BENEFITS.boddy' | translate}}"> </h4>
            <swiper #bigSlider class="swiper-container" [config]="bigSliderConfig">
                <div class="card-content-beneficios section swiper-slide" *ngFor="let cont of contentCard2">
                    <div class="recomendacion" *ngIf="cont.titulo === 'Miles Care Exclusive'">
                        {{'DROP_DOWN_BENEFIST.SELECT_PLAN_BENEFITS.litle_title' | translate}}
                    </div>
                    <div class="card-beneficios bg-blanco " [ngClass]="{
                'bg-gradient':cont.titulo === 'Miles Care Exclusive'
                }">

                        <span class="titulo-card"> {{cont.titulo}} </span>
                        <div class="precio-card"> <strong>{{cont.precioUno}}<span>/{{'DROP_DOWN_BENEFIST.SELECT_PLAN_BENEFITS.year_text' | translate}}</span></strong></div>
                        <div class="contenido-card">
                            <p class="parrafo-card">
                                <span class="titulo-parrafo-card" innerHTML=" DROP_DOWN_BENEFIST.TAB_FLYCRAE.tittle">FLYCARE</span>
                                <span class="titulo-parrafo-card" innerHTML="{{'DROP_DOWN_BENEFIST.SELECT_PLAN_BENEFITS.flight_text' | translate}}">(Durante tu vuelo)</span>
                                <span innerHTML="{{'DROP_DOWN_BENEFIST.SELECT_PLAN_BENEFITS.lost_luggage' | translate}}"></span>
                                <span>
                                    <strong>US$ {{cont.precioTres}}</strong>
                                </span> 
                                
                                <span innerHTML="{{'DROP_DOWN_BENEFIST.SELECT_PLAN_BENEFITS.flight_delay' | translate}}"></span>
                                <span>
                                    <strong>US$ {{cont.precioCuatro}}</strong>
                                </span> 
                                
                            </p>
                            <p class="parrafo-card">
                                <span class="titulo-parrafo-card">STAYCARE</span>
                                <span class="titulo-parrafo-card" innerHTML="{{'DROP_DOWN_BENEFIST.SELECT_PLAN_BENEFITS.trip_text' | translate}}">(During your trip)</span>
                                <span innerHTML="{{'DROP_DOWN_BENEFIST.SELECT_PLAN_BENEFITS.medical_expenses' | translate}}">Gastos médicos de emergencia</span>
                                <span><strong>US$ {{cont.precioCinco}}</strong></span>
                                <span innerHTML="{{'DROP_DOWN_BENEFIST.SELECT_PLAN_BENEFITS.prescription_drugs' | translate}}">Medicamentos con receta</span>
                                <span><strong>US$ {{cont.precioSeis}}</strong></span>
                            </p>
                        </div>
                    </div>
                </div>
            </swiper>
            <p class="parrafo-18-medium cards-texto" *ngIf="brand === 'lifeMilesTravel' || brand == 'apap' || brand == 'cibc'" innerHTML="{{'DROP_DOWN_BENEFIST.DOWNLOAD_APP.text_1' | translate}}"></p>
            <p *ngIf="brand === 'cibc'" class="parrafo-18-medium cards-texto" innerHTML="{{'DROP_DOWN_BENEFIST.DOWNLOAD_APP.text_2' | translate}}"></p>
            <p *ngIf="brand === 'ncb' || brand === 'firstcitizens'" class="parrafo-18-medium cards-texto" innerHTML="{{'DROP_DOWN_BENEFIST.DOWNLOAD_APP.text_ncb' | translate}}"></p>
            <div class="store">
                <p  innerHTML="{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.reg_download_6' | translate}}"></p>
                <app-download-store [class]="'logo'"></app-download-store>
             
            </div>
        </div>

    </div>
</section>

<!--END NUEVA SECCION-->


<section class="gray s-flex questions">
    <div class="container">
        <div class="bl-row">
            <div class="bl-left">
                <h2 class="text-gradient" innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_TOPS.tops_title' | translate}}"></h2>
                <ul class="items-number">
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.1' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_TOPS.tops_list_1' | translate}}"></p>
                    </li>
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.2' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_TOPS.tops_list_2' | translate}}"></p>
                    </li>
                    <li *ngIf="brand === 'apap'|| brand === 'myrewards' || brand === 'ncb' || brand === 'firstcitizens' || brand === 'cibc'"><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.2' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_TOPS.tops_list_3' | translate}}"></p>
                    </li>
                </ul>
            </div>

            <div class="bl-right">
                <img class="imagen-video" [src]="iphoneTops" alt="">
            </div>
        </div>
    </div>
</section>




<section class="safe">
    <div class="container">
        <div class="bl-row align-top">
            <div class="bl-center">
                <h1 class="text-gradient" innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_SAFE.safe_title_1' | translate}}"></h1>
                <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_SAFE.safe_list_1' | translate}}"></p>

                <img class="img-always" src="./assets/images/{{brand}}-miles/iphone-miles.png" alt="mr">
            </div>
        </div>

    </div>
</section>

<section class="descargas">

    <div class="download" *ngIf="brand ==='firstcitizens'">
        <h2 class="text-gradient"><strong>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.reg_download' |
            translate}}<br> {{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.reg_download_2' | translate}}</strong>
    </h2> <div class="store">
            <app-download-store [class]="'logo'"></app-download-store>
        </div>
    </div>

    <div class="download" *ngIf="brand !='firstcitizens' && brand !='cibc' ">
        <h2 innerHTML="{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.reg_download3' |
            translate}}" ></h2>
         <div class="store">
            <app-download-store [class]="'logo'"></app-download-store>
        </div>
    </div>

    <div class="download" *ngIf="brand ==='cibc'">
        <h2  class="text-gradient"><strong>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.reg_download' | translate}}
                {{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.reg_download_6' | translate}}</strong></h2>
        <div class="store">
            <app-download-store [class]="'logo'"></app-download-store>
            
        </div>
    </div>
</section>