/**
 * Ocultar componentes cuando se loguearon desde la aplicación y abrieron bitacora
 */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OcultadorService {

  ocultar = false;

  constructor() { }
}
