<div class="bg" [ngClass]="{'bg': activeReset, 'pc-respuesta': isResetPass}"></div>
<div class="bg-mobile"></div>

<header>
  <img src="../assets/img/logo-novae.svg" alt="">
</header>

<div class="element"><img src="../assets/img/element.png" alt=""></div>
<div class="element-mobile"><img src="../assets/img/element-mobile.png" alt=""></div>

<form (ngSubmit)="validatePass()" *ngIf="activeReset">

  <div class="container">
    <div class="b-password">
      <div class="b-hi">
        <h1>{{ 'RECOVERY_PASS.hi' | translate }}</h1>
        <h2>{{ 'RECOVERY_PASS.hiDescription' | translate }}</h2>
      </div>

      <div class="box intro">
        <div class="bot">
          <figure class="icon-alle"> </figure>
          <div class="bubble">
            <p>{{'RECOVERY_PASS.changePassDesc'|translate}}</p>
          </div>
        </div>
      </div>

      <div class="b-input">

        <div class="box">
          <div class="bot alert">
            <p> {{ message }} </p>
          </div>
        </div>

        <div class="box">
          <div class="bot">
            <figure class="icon-alle"> </figure>
            <div class="bubble">
              <p>{{'RECOVERY_PASS.writeYourPassword' | translate}}</p>
            </div>
          </div>
        </div>

        <div class="box">
          <div class="user">
            <figure class="icon-user"> </figure>
            <div class="bubble" [ngClass]="{'input-alert': classPwd1}">
              <input type="password" minlength="8" [(ngModel)]="newPwd" name="firtValue"
                placeholder="{{'RECOVERY_PASS.enterYourNewPassword' | translate}}" required (keyup)="cleanErros()">
            </div>
          </div>
        </div>

        <div class="box">
          <div class="bot">
            <figure class="icon-alle"> </figure>
            <div class="bubble">
              <p>{{'RECOVERY_PASS.confirmYourNewPassword' | translate}}</p>
            </div>
          </div>
        </div>

        <div class="box">
          <div class="user">
            <figure class="icon-user"> </figure>
            <div class="bubble" [ngClass]="{'input-alert': classPwd2}">
              <input type="password" minlength="8" [(ngModel)]="confirmPwd" name="secundValue"
                placeholder="{{'RECOVERY_PASS.enterYourNewPasswordAgain' | translate}}" required (keyup)="cleanErros()">
            </div>
          </div>
        </div>


        <button type="submit" class="btn">
          {{'RECOVERY_PASS.novaeDone' | translate}}
        </button>

      </div>

    </div>

    <div class="b-password" *ngIf="!showForm">
      <div class="b-hi">
        <h1>HTTP 400 </h1>
        <h2>{{'RECOVERY_PASS.badRequest' |translate}}</h2>
      </div>
    </div>
  </div>

</form>


<div class="container">
  <div class="b-password" *ngIf="isResetPass">
    <div class="b-hi">
      <h1>{{ 'RECOVERY_PASS.great' | translate }}</h1>
      <h2>{{ 'RECOVERY_PASS.youHaveANewPassword' | translate }}</h2>
    </div>

    <div class="box intro">
      <div class="bot">
        <figure class="icon-alle"> </figure>
        <div class="bubble">
          <p>{{ 'RECOVERY_PASS.yourAccountAccessData' | translate }} <strong>{{ email }}</strong>
            {{ 'RECOVERY_PASS.hasBeenModifiedSuccesfully' | translate }}</p>
        </div>
      </div>
      <a class="btn" [href]=" (href) | domSecurity " *ngIf="showButton">{{ 'RECOVERY_PASS.goToLifemiles' | translate }}
        {{ appName }}</a>
    </div>
  </div>

  <div class="b-password" *ngIf="isActiveCard">
    <div class="b-hi">
      <h1>{{ 'greatNews' | translate }}</h1>
      <h2>{{ 'descGreatActive' | translate }} {{ appName }} {{ 'descGreatActive1' | translate }}</h2>
    </div>

    <div class="box intro">
      <div class="bot">
        <figure class="icon-alle"> </figure>
        <div class="bubble">
          <p>{{ 'messageGreatActive1' | translate :'{appName:\'' + appName + '\'}' }} <strong>
              {{ 'messageGreatActive2' | translate }} </strong> {{ 'messageGreatActive3' | translate }}
            <strong>{{ 'messageGreatActive4' | translate }}</strong> {{
                        'messageGreatActive5' | translate }}</p>
        </div>
      </div>
      <a class="btn" [href]=" (href) | domSecurity ">{{ 'redeemButton' | translate }}</a>
    </div>
  </div>
</div>
