import { DataService } from 'src/app/services/data.service';
import { DataStoreService } from 'src/app/services/data-store.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Card } from '../../shared/card/model/card';
import { IActivityResult } from 'src/app/models/activities/response/activity-availability.model';

@Component({
  selector: 'app-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.scss']
})
export class ActivityListComponent implements OnInit {

  @Input() public filteredID: any = {
    type: '',
    values: []
  };
  @Input() list: Card[];
  @Output() detail = new EventEmitter();
  qtyResultShow = 5;
  cardOptions = {
    type: 'activity',
  };
  constructor(
    public dataStore: DataStoreService,
    public dataSevice: DataService,
  ) { }

  ngOnInit() {
  }
  AddResults() {
    this.qtyResultShow += 5;
  }

  detailCardListener(ev: IActivityResult) {
    this.selectProductListener(ev);
    this.detail.emit(ev);
  }

  selectProductListener(ev: IActivityResult) {
    this.dataStore.selectedActivity = ev;
  }

}
