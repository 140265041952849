<ng-template #content let-modal>
  <div class="modal-header">
    <!--<h4 class="modal-title" id="modal-basic-title">Profile update</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>-->
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="main-img text-center">
        <img attr.src="{{ mainImgUrl }}" alt="">
      </div>
      <div class="subtitle-container">
        <p *ngIf="options.subtitle !== null" class="subtitle"> {{ options.subtitle }} </p>
      </div>
      <div class="message-container">
        <p *ngIf="options.message !== null" class="message"> {{ options.message }} </p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="container">
      <div class="row">
        <div class="col" *ngIf="options.cancelText != null">
          <button type="button" class="btn-travel" (click)="cancelButton($event); modal.close('Save click')">{{ options.cancelText }}</button>
        </div>
        <div class="col" *ngIf="options.confirmText != null">
          <button type="button" class="btn-travel btn-fill" (click)="successButton($event); modal.close('Save click')">{{ options.confirmText }}</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
