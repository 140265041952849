import { SearchedHotel } from 'src/app/component/searched/models/searched-hotel.model';
import { SearchedCar } from 'src/app/component/searched/models/searched-car.model';
import { SearchedFlight } from 'src/app/component/searched/models/searched-flight.model';
import { SearchedActivity } from 'src/app/component/searched/models/searched-activity.model';
import { SearchedBenefit } from 'src/app/component/searched/models/searched-benefit.model';
import { FormConfig, TravellerData } from 'src/app/component/traveller-form/models/form-config.model';
import { EProduct } from 'src/app/component/searched/models/searched.model';
import { formatDate } from '@angular/common';
import { locale } from 'moment';
import { TravellerType } from 'src/app/component/traveller-form/enums/traveller-type';

export class TravellerFormConfig {
    constructor(
        private typeProduct: EProduct,
        private searchedData: SearchedHotel | SearchedCar | SearchedFlight | SearchedActivity | SearchedBenefit,
        private travellerData: TravellerData[],
        private translationsForm: any[]
    ) { }

    get getConfig(): FormConfig {
        const travelDate = formatDate(this.searchedData.date.start, 'yyyy-MM-dd', locale('ES')).toString();
        return {
            formType: this.typeProduct as any,
            travellerTypes: this.travellerTypes,
            travellerAges: this.travellerAges,
            travellerData: this.travellerData,
            travelDate: travelDate,
            title: this.titleForm,
        };
    }

    get titleForm() {
        switch (this.typeProduct) {
            case EProduct.FLIGHTS:
                return this.translationsForm['passengers'];
                break;
            case EProduct.HOTELS:
                return this.translationsForm['guests'];
                break;
            case EProduct.CARS:
                return this.translationsForm['driver'];
                break;
            case EProduct.ACTIVITIES:
                return this.translationsForm['passengers'];
                break;
            case EProduct.BENEFITS:
                return this.translationsForm['beneficiary'];
                break;
            default:
                break;
        }
    }

    get travellerTypes(): TravellerType[] {
        switch (this.typeProduct) {
            case EProduct.FLIGHTS:
                return this.getFlightTravellerTypes();
                break;
            case EProduct.HOTELS:
                return this.getHotelTravellerTypes();
                break;
            case EProduct.CARS:
                return this.getCarTravellerTypes();
                break;
            case EProduct.ACTIVITIES:
                return this.getActivityTravellerTypes();
                break;
            case EProduct.BENEFITS:
                return this.getBenefitTravellerTypes();
                break;
            default:
                break;
        }
    }

    get travellerAges() {
        switch (this.typeProduct) {
            case EProduct.ACTIVITIES:
                return this.getActivityTravellerAges();
                break;
            case EProduct.BENEFITS:
                return this.getBenefitTravellerAges();
                break;
            default:
                return null;
                break;
        }
    }

    getFlightTravellerTypes(): TravellerType[] {
        const travellerTypes: TravellerType[] = [];
        const quantity = (this.searchedData as SearchedFlight).quantity;
        const paxes = [quantity.adults, quantity.ninos, quantity.babes];
        paxes.forEach((item, i) => {
            for (let qty = 0; qty < item; qty++) {
                if (i === 0) {
                    travellerTypes.push(TravellerType.ADT);
                } else if (i === 1) {
                    travellerTypes.push(TravellerType.CHD);
                } else if (i === 2) {
                    travellerTypes.push(TravellerType.INF);
                }
            }
        });
        return travellerTypes;
    }

    getHotelTravellerTypes(): TravellerType[] {
        const travellerTypes: TravellerType[] = [];
        const roomsPaxes = (this.searchedData as SearchedHotel).roomsPaxes;
        roomsPaxes.forEach(item => {
            travellerTypes.push(TravellerType.ADT);
        });
        return travellerTypes;
    }

    getCarTravellerTypes(): TravellerType[] {
        const travellerTypes: TravellerType[] = [];
        travellerTypes.push(TravellerType.ADT);
        return travellerTypes;
    }

    getBenefitTravellerTypes(): TravellerType[] {
        return null;
    }

    getBenefitTravellerAges(): number[] {
        const travellerAges: number[] = [];
        const paxes = (this.searchedData as SearchedBenefit).paxes;
        paxes.forEach(pax => travellerAges.push(+pax.age));
        return travellerAges;
    }

    getActivityTravellerTypes(): TravellerType[] {
        const travellerTypes: TravellerType[] = [];
        const paxes = (this.searchedData as SearchedActivity).paxes;
        paxes.forEach(pax => {
            if (+pax.age < 18) {
                travellerTypes.push(TravellerType.ADT);
            } else {
                travellerTypes.push(TravellerType.CHD);
            }
        });
        return travellerTypes;
    }

    getActivityTravellerAges(): number[] {
        const travellerAges: number[] = [];
        const paxes = (this.searchedData as SearchedActivity).paxes;
        paxes.forEach(pax => travellerAges.push(+pax.age));
        return travellerAges;
    }
}
