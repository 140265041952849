import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { DataStoreService } from 'src/app/services/data-store.service';
import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IActivityResult, IActivityOption } from 'src/app/models/activities/response/activity-availability.model';
import { formatDate } from '@angular/common';
import { locale } from 'moment';
import { ActivityService } from '../activity.service';

@Component({
  selector: 'app-activity-detail',
  templateUrl: './activity-detail.component.html',
  styleUrls: ['./activity-detail.component.scss']
})
export class ActivityDetailComponent implements OnInit {

  @Input() activity: IActivityResult;

  isDescriptionShowingMore = false;

  cardOptions = {
    type: 'activity-option'
  };

  get name() {
    return (this.activity) ? this.activity.name : '';
  }

  get selectionMilesPrice() {
    return this.dataStore.selectedActivity.selectedOption.selectedDate.price.pointsPrice;
  }

  get selectionMoneyPrice() {
    return this.dataStore.selectedActivity.selectedOption.selectedDate.price.calculatedPrice
      + ' ' + this.dataStore.selectedActivity.selectedOption.selectedDate.price.currency;
  }

  get selectionName() {
    return this.dataStore.selectedActivity.selectedOption.name;
  }

  get travellersQty() {
    return this.dataService.activityRequestParameters.roomDistribution[0].paxes.length;
  }

  get hasOptionMeetingPoint() {
    const meetingPoints = this.dataStore.selectedActivity.selectedOption.meetingPoints;
    return (meetingPoints === null) ? false : true;
  }

  get isSelectedMeetingPoint() {
    const meetingPoints = this.dataStore.selectedActivity.selectedOption.selectedMeetingPoint;
    return (meetingPoints) ? true : false;
  }

  get selectionDateMeetingPoint() {
    try {
      const selectedMeetingPoint = this.dataStore.selectedActivity.selectedOption.selectedMeetingPoint;
      if (selectedMeetingPoint) {
        return formatDate(selectedMeetingPoint.time, 'dd/MM', locale()).toString();
      }
    } catch (err) {
      return '';
    }
  }
  get selectionPlaceMeetingPoint() {
    try {
      const selectedMeetingPoint = this.dataStore.selectedActivity.selectedOption.selectedMeetingPoint;
      if (selectedMeetingPoint) {
        return selectedMeetingPoint.name;
      }
    } catch (err) {
      return '';
    }
  }

  get selectionDate() {
    try {
      return formatDate(this.dataStore.selectedActivity.selectedOption.selectedDate.startDate, 'dd/MM hh:mm', locale()).toString();
    } catch (e) {
      return this.dataStore.selectedActivity.selectedOption.selectedDate.startDate;
    }
  }

  constructor(
    private activityService: ActivityService,
    public dialogRef: MatDialogRef<ActivityDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dataStore: DataStoreService,
    private dataService: DataService,
    private router: Router
  ) { }

  ngOnInit() {
    this.activity = this.data;

    this.dialogRef.afterOpened().toPromise().then(() => {
      this.optionListener(this.activity.options[0]);
    });
  }

  selectOption(option, date) {
    option.selectedDate = date;
    const selection = this.dataStore.selectedActivity;
    selection.selectedOption = option;
    this.dataStore.selectedActivity = selection;
  }

  getBookingRules(req) {
    return new Promise<any>((resolve, reject) => {
      this.dataService.sendBookingRulesActivities(req).subscribe((response) => {
        resolve(response);
      }, err => {
        reject(err);
        alert('ERROR CONFIRMANDO LA ACTIVIDAD SELECCIONADA, POR FAVOR ELIJA OTRA OPCIÓN');
      });
    });
  }

  optionListener(option: IActivityOption) {
    if (!option.meetingPoints) {
      option.meetingPoints = null;
      option.selectedMeetingPoint = null;
    }

    const ratePlanCode = option.selectedDate.ratePlanCode;
    const targetCurrency = this.dataStore.brandTargetCurrency;
    const providerCurrency = option.selectedDate.price.providerCurrency;

    const req: any = {
      ratePlanCode: ratePlanCode,
      targetCurrency: targetCurrency,
      providerCurrency: providerCurrency
    };


    this.getBookingRules(req).then(response => {
      if (response.bookingCode && response.serviceCode) {
        option.bookingCode = response.bookingCode;
        option.serviceCode = response.serviceCode;
        option.cancellationPolicy = response.cancellationPolicy;

        option.selectedDate.price = this.activityService.updateActivityOptionPrice(option.selectedDate.price);

        if (response.warnings && response.warnings.length > 0) {
          option.selectedDate.price.totalPrice = response.totalPrice;
          option.selectedDate.price.providerTotalPrice = response.providerTotalPrice;
          option.dates.find(item => {
            if (item.ratePlanCode === option.selectedDate.ratePlanCode) {
              item.price.totalPrice = response.totalPrice;
              item.price.providerTotalPrice = response.providerTotalPrice;
              return item;
            }
          });
        }

        if (response.meetingPoints && response.meetingPoints.length > 0) {
          response.meetingPoints = this.formatMeetingPoint(
            option.selectedDate.startDate,
            response.meetingPoints);
          option.meetingPoints = response.meetingPoints;
          option.selectedMeetingPoint = response.meetingPoints[0];
        } else {
          option.meetingPoints = null;
        }
        const selectedActivity = this.dataStore.selectedActivity;
        selectedActivity.selectedOption = option;
        this.dataStore.selectedActivity = selectedActivity;
      }
    });
  }

  isSelected(option) {
    return this.dataStore.selectedActivity.selectedOption.name === option.name;
  }

  formatMeetingPoint(date, meetingPoints) {
    date = formatDate(date, 'yyyy-MM-dd', locale()).toString()
    meetingPoints.forEach(item => {
      try {
        let dateTime = date + ' ' + item.time;
        dateTime = formatDate(dateTime, 'yyyy-MM-ddThh:mm:ss', locale()).toString();
        item.time = dateTime;
      } catch (e) {
        item.time = item.time;
      }
    });
    return meetingPoints;
  }

  continue() {
    if (this.hasOptionMeetingPoint === this.isSelectedMeetingPoint) {
      this.router.navigate(['/activities/registry']);
      this.dialogRef.close();
    }
  }

  close() {
    this.dialogRef.close();
  }

  // initSelection() {
  //   const option = this.activity.options[0];
  //   const ratePlanCode = option.selectedDate.ratePlanCode;
  //   this.getBookingRules(ratePlanCode).then(response => {
  //     if (response.bookingCode && response.serviceCode) {
  //       option.bookingCode = response.bookingCode;
  //       option.serviceCode = response.serviceCode;

  //       if (response.meetingPoints && response.meetingPoints.length > 0) {
  //         response.meetingPoints = this.formatMeetingPoint(
  //           option.selectedDate.startDate,
  //           response.meetingPoints);
  //         option.meetingPoints = response.meetingPoints;
  //         option.selectedMeetingPoint = response.meetingPoints[0];
  //         this.activity.options = this.setOptionMeetingPoint(
  //         this.activity.options, option.id, response.meetingPoints);
  //       }

  //       const selectedActivity = this.dataStore.selectedActivity;
  //       selectedActivity.selectedOption = option;
  //       this.dataStore.selectedActivity = selectedActivity;
  //     }
  //   });
  // }
  // setOptionMeetingPoint(options, selectedID, meetingPoints) {
  //   options.find(item => {
  //     if (item.id === selectedID) {
  //       item.meetingPoints = meetingPoints;
  //       return item;
  //     }
  //   });
  //   return options;
  // }
}
