import { keysUniversal, StoreServiceLibrary, ProductOfferService, OnePocketService, BodyUpdatePurchase, SecurityObject, CryptoService } from '@wearenovae/novae-core-services';
import { Component, OnInit, EventEmitter, Output, Input, AfterContentInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { CertificateLearnMoreComponent } from '../certificate-learn-more/certificate-learn-more.component';
import { InitService } from '../../services/init.service';
import { Observable } from 'rxjs';
import { DataService } from '../../services/data.service';
import { typeApp } from '../../../environments/environment.prod';
import { DataStoreService } from '../../services/data-store.service';
import { LanguageService } from '../language/language.service';
import { environment } from 'src/environments/environment';
import { AlertErrorComponent } from '../product-detail/alert-error/alert-error.component';
import { keys } from 'src/app/resources/keys.resources';
import { getCurrencySymbol } from '@angular/common';
import { UtilsResource } from 'src/app/resources/UtilsResource.resource';

@Component({
  selector: 'app-certificates-user',
  templateUrl: './certificates-user.component.html',
  styleUrls: ['./certificates-user.component.css']
})
export class CertificatesUserComponent implements OnInit, AfterContentInit {

  @Output() sendBenefits: EventEmitter<any> = new EventEmitter();

  globalPurchaceId = 0;
  globalTaxes = 0;

  travelData: any;

  disabledItem = true;
  activateButton = true;
  clickBenefits = false;
  currencyUniversal: any;
  dataEncryptedGlobal: any;
  agenciesData: any;
  requestConfirmSale: any;
  currencyGlobal: any;

  certificatesGroup = [];
  currentPurchaceProduct = [];
  passengersArray = [];
  objectCurrentProductBenefits = {
    itemName: '',
    itemCode: '',
    itemValue: '',
    itemQuantity: '',
    categoryName: '',
    categoryCode: '',
    productCurrency: ''
  };

  systemLanguage = 'EN';

  selectPassengers = 1;

  passengers = [];
  totalPrice = 0;

  isCompleteLoadUniversal: boolean;

  get brand() {
    return this.dataStore.brand;
  }

  constructor(private store: StoreServiceLibrary,
    private productOffer: ProductOfferService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private initService: InitService,
    private onePocketService: OnePocketService,
    public dataService: DataService,
    private dataStore: DataStoreService,
    private languageService: LanguageService,
    private renderer: Renderer2,
    private cryptoService: CryptoService) {
    this.initService.isInitializedBrand.subscribe((initialized) => {
      if (initialized) {
        // this.getProducts();
        this.globalPurchaceId = this.store.getItemFromStorage('purchaceId');
        this.globalTaxes = this.store.getItemFromStorage('globalTaxes');
        this.currencyUniversal = JSON.parse(
          this.store.getJustOneUniversalPropertie(
            keysUniversal.app_default_currency
          )
        );
        this.isCompleteLoadUniversal = initialized;
        if (this.isCompleteLoadUniversal) {
          this.verifyPurchaseLogic();
        }
      }
    });
    // cuando cambia el idioma del sistema
    this.languageService.systemLanguajeChange.subscribe(language => {
      if (language) {
        this.systemLanguage = language.toUpperCase();
      }
    });

  }

  ngOnInit(): void {
  }

  ngAfterContentInit() {
  }

  verifyPurchaseLogic() {
    if (this.globalPurchaceId !== null || this.globalPurchaceId !== undefined) {
      this.store.setItemInStorage('purchaceId', this.globalPurchaceId);
      this.verifyPurchaseProxyTravel().subscribe((purchaceId) => {
        this.loadPurchace(purchaceId);
        this.store.setItemInStorage('purchaceId', purchaceId);
        this.globalPurchaceId = Number(purchaceId);
      });
    } else {
      let currentPurchase = this.store.getItemFromStorage('purchaceId');
      this.globalPurchaceId = Number(currentPurchase);
      this.loadPurchace(currentPurchase);
    }
  }

  getProducts() {
    // const channelId = Number(this.store.getJustOneUniversalPropertie(keysUniversal.app_channel_id_mr) || this.store.getJustOneUniversalPropertie(keysUniversal.app_channel_id));
    const channelId = 7;
    this.productOffer
      .getProducts(channelId, this.translate.currentLang.toUpperCase())
      .subscribe(
        (res) => {
          this.certificatesGroup = res;
        },
        (err) => {
          UtilsResource.NSMarkLogError('GET PRODUCTS ERROR',err);
        }
      );
    // UtilsResource.NSLog('%c test alle', 'background:red; color:white;', this._alleService);
  }

  proccesCertificate() {
    this.updatePurchaseTravel();
  }

  factoryObject(item) {
    this.disabledItem = false;
    const objInsuranceUniversal = JSON.parse(this.store.getJustOneUniversalPropertie(keys.JSON_CATEGORY_BENFITS) || '');
/*     let fechaEnMiliseg = Date.now();
    this.objectCurrentProductBenefits.itemCode = `${item.productCode}${fechaEnMiliseg}`; */
    this.objectCurrentProductBenefits.itemCode = `${item.productCode}`;
    this.objectCurrentProductBenefits.itemName = item.productName;
    this.objectCurrentProductBenefits.itemValue = item.productRate;
    // this.objectCurrentProductBenefits.itemQuantity = `${this.passengersArray.length}`;
    this.objectCurrentProductBenefits.productCurrency = item.productCurrency;
    this.objectCurrentProductBenefits = { ...this.objectCurrentProductBenefits, ...objInsuranceUniversal };
    this.clickBenefits = true;
    this.validateTotalPssenger();
    this.totalPriceBenefits();
  }

  verifyPurchaseProxyTravel(): Observable<string> {
    const channelId = Number(this.store.getJustOneUniversalPropertie(keysUniversal.app_channel_id_mr) || this.store.getJustOneUniversalPropertie(keysUniversal.app_channel_id));
    let purchaseId = this.globalPurchaceId;

    return new Observable<string>(
      (observer) => {
        this.store.clearFlagVerifyProxyTravel();
        this.onePocketService.verifyPurchaseId(purchaseId, channelId).subscribe(
          (response) => {
            if (response.getPurchasesResponse.resultGetPurchases.purchases) {
              let purchaseTravel = response.getPurchasesResponse.resultGetPurchases.purchases.purchase[0];
              observer.next(purchaseTravel.purchaseID);
            }
          },
          (err) => {
            observer.error(err);
            this.errorModal('ALERT.ERROR_BOOKING.boddy');
          },
          () => {
            observer.complete();
          }
        );
      }
    );
  }

  loadPurchace(purchaceId) {
/*     const userData = this.store.getUserData();
    userData.authenticationToken = 'Ty7Dd6mMe4XEZwlhouSfPKa4pcGpryF8+S78vrzcl3M=:iVTK6HSMhbOI0OL46YfDqj4/C5b2hMmU//QKEv+MfD0=';
    this.store.setUserData(userData); */
    this.onePocketService.getPurchaseIdV2(purchaceId).subscribe((resp) => {

      let products = resp['getPurchasesResponse'].resultGetPurchases.purchases.purchase[0].product;
      this.currentPurchaceProduct.push(...products);

      if (resp['getPurchasesResponse'].resultGetPurchases.purchases.purchase[0].travel) {
        let objTravel = resp['getPurchasesResponse'].resultGetPurchases.purchases.purchase[0].travel;
        this.travelData = {
          travelInfo: objTravel
        };
        let secretKey = atob('IVozZGcvRi1HcGAoXzh4JEIleyRuVXlZYXZycmQjJDU=')
        let dataEncrypted = this.cryptoService.decryptedUniversal(objTravel['content'], secretKey, objTravel['iv']);
        this.dataEncryptedGlobal = dataEncrypted;
        if (dataEncrypted['bookingRS'].reservations.reservation[0].paxes) {
          let pax = dataEncrypted['bookingRS'].reservations.reservation[0].paxes.pax;
          this.agenciesData = dataEncrypted['bookingRS'].reservations.reservation[0].agenciesData;
          let itemDtaFligths = dataEncrypted['bookingRS'].reservations.reservation[0].items.flightItem;
          let segment = itemDtaFligths[0].routes.route[0].segments.segment;
          let prices = itemDtaFligths[0].prices;
          let currency = prices.price[0].currency;
          this.currencyGlobal = currency;
          let extraCurrency = JSON.parse(this.store.getJustOneUniversalPropertie(keys.EXTRA_CURRENCY_DATA));
          pax.pop();
          this.passengers.push(...pax);
          let flightData = [];

          segment.map((item) => {
            let temporalArrayDates = {
              "dateFrom": `${item.departureDate.year}-${this.vaidateMonthAndDay(item.departureDate.month)}-${this.vaidateMonthAndDay(item.departureDate.day)}`,
              "dateTo": `${item.arrivalDate.year}-${this.vaidateMonthAndDay(item.arrivalDate.month)}-${this.vaidateMonthAndDay(item.arrivalDate.day)}`,
              "origin": item.departureAirport,
              "destination": item.arrivalAirport,
              "flightClass": item.clazz
            };
            flightData.push(temporalArrayDates);
          });

          //  dataEncrypted['bookingRS'].reservations.reservation[0].language.toUpperCase(),
          let requestBenfits = {
            language: this.translate.currentLang.toUpperCase(),
            nPassengers: dataEncrypted['bookingRS'].reservations.reservation[0].paxes.pax.length,
            precisionCurrency: extraCurrency['precisionCurrency'],
            scaleCurrency: extraCurrency['scaleCurrency'],
            exchangeRate: extraCurrency['exchangeRate'],
            descExchangeRate: getCurrencySymbol(currency, 'narrow'),
            flightData: flightData
          }

          this.getTokenBenefits().subscribe((resp) => {
            this.getBenefits(requestBenfits, resp['access_token']);
          });

        }
      }

    }, (err) => {
      UtilsResource.NSMarkLogError('GET PURCHASE ERROR V2',err);
    });
  }

  updatePurchaseTravel() {
    if (!this.disabledItem) {
      this.currentPurchaceProduct.push(this.objectCurrentProductBenefits);
      this.factoryConfirmSale();

      let insuranceValue = Number(this.objectCurrentProductBenefits.itemValue) * this.passengersArray.length;
      let purchaseIdFinal = this.store.getItemFromStorage('purchaceId');
      const requestBodyUpdatePurchase: BodyUpdatePurchase = {
        updatePurchaseTravel: {
          purchaseId: purchaseIdFinal,
          isoLanguage: this.translate.currentLang,
          insuranceValue: insuranceValue,
          product: this.currentPurchaceProduct,
          taxAmount: this.globalTaxes,
          security: SecurityObject.getSecurityObject()
        }
      };

      this.onePocketService.updatePurchase(requestBodyUpdatePurchase).subscribe((resp) => {

        let objToSend = {
          send: true,
          confirmSale: this.requestConfirmSale
        };

        this.sendBenefits.emit(objToSend);
      }, (err) => {
        this.currentPurchaceProduct = [];
        this.errorModal('ALERT.ERROR_BOOKING.boddy');
      });
    } else {

      let objToSend = {
        send: true,
        confirmSale: this.requestConfirmSale
      };

      this.sendBenefits.emit(objToSend);
    }
  }

  openLearnMore(groupBenefits) {
    const dialogRefLearnMore = this.dialog.open(CertificateLearnMoreComponent, {
      panelClass: 'travel-protection',
      data: groupBenefits
    });
  }

  disabledSelector() {
    this.disabledItem = true;
    this.activateButton = false;
    this.passengersArray.map((item, index) => {
      const elementRefItem = document.getElementById(`${index}passenger`);
      if (elementRefItem) {
        if (elementRefItem.classList.contains('active')) {
          this.renderer.removeClass(elementRefItem, 'active');
        }
      }
    });
    this.passengersArray = [];
    this.totalPrice = 0;
  }

  showTermsAndConditionsDialog() {
    if (this.dataService.idApplication === String(typeApp.LIFEMILES_TRAVEL)) {
      const lang = this.store.getItemFromStorage('lang') || 'en';
      // const urlTerms = environment.brands[this.dataStore.brand].terminos_y_condiciones[lang.toUpperCase()];
      let urlTerms = this.store.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_en);

      if (lang) {
        if (lang == 'es') {
          urlTerms = this.store.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_es);
        }
      }
      window.open(urlTerms, '_blank');
    } else {
      this.dataService.openTermsAndConditionsDialog();
    }
  }


  getTokenBenefits() {
    return new Observable((observer) => {
      this.productOffer.getTokenBnefits().subscribe((resp) => {
        observer.next(resp);
        observer.complete();
      }, (err) => {
        UtilsResource.NSMarkLogError('GET TOKEN BENEFITS ERROR',err);
      });
    });
  }

  getBenefits(requestBenefits, tokenBearer) {
    this.productOffer.getBenefits(requestBenefits, tokenBearer).subscribe((resp) => {
      this.certificatesGroup = resp['benefits'];
      this.travelData['queryUUID'] = resp['queryUUID'];
    }, (err) => {
      UtilsResource.NSMarkLogError('GET BENEFITS ERROR',err);
    });
  }

  errorModal(message) {
    const heightPadding = this.dataStore.isMobileDevice ? '0px' : '40px';
    const widthPadding = this.dataStore.isMobileDevice ? '0px' : '16vw';
    const dialogRef = this.dialog.open(AlertErrorComponent, {
      maxWidth: '50vw',
      width: 'calc(50vw - ' + widthPadding + ')',
      height: 'calc(50vh - ' + heightPadding + ')',
      minHeight: '59vh',
      disableClose: true,
      data: {
        tittle: 'ALERT.ERROR_BOOKING.title',
        error: `${message}`,
        validateHaveGoToLogin: true,
        showIconClose: false
      }
    });
  }

  clickPassengersData(item, i) {
    const elementRef = document.getElementById(`${i}passenger`);
    if (!this.disabledItem) {
      if (elementRef.classList.contains('active')) {
        this.renderer.removeClass(elementRef, 'active');
        let numberArray = this.passengersArray.indexOf(item);
        this.passengersArray.splice(numberArray, 1);
      } else {
        this.renderer.addClass(elementRef, 'active');
        this.passengersArray.push(item);
      }
      this.validateTotalPssenger();
      this.totalPriceBenefits();
      this.objectCurrentProductBenefits.itemQuantity = `${this.passengersArray.length}`;
    }
  }

  factoryConfirmSale() {
    const userData = this.store.getUserData();
    let reservation = this.dataEncryptedGlobal['bookingRS'].reservations.reservation[0];
    let transformDataPassenger = [];


    this.passengersArray.map((item, index) => {
      let objUser = {
        firstName: item['name'],
        lastName: item['surname'],
        email: this.agenciesData['agencyData'][0].agencyEmail
      }
      transformDataPassenger.push(objUser);
    });


    let objSelectedItem = {
      currency: this.objectCurrentProductBenefits['productCurrency'],
      amount: this.objectCurrentProductBenefits['itemValue'],
      productCode: this.objectCurrentProductBenefits['itemCode']
    };

    this.requestConfirmSale = {
      uuid: this.travelData['queryUUID'],
      partnerPNR: reservation['locator'],
      buyerCode: userData['memNum'],
      buyerEmail: this.agenciesData['agencyData'][0].agencyEmail,
      sendEmail: false,
      transactionId: "",
      selectedItem: objSelectedItem,
      passengerData: transformDataPassenger
    }
  }

  vaidateMonthAndDay(month) {
    if (month < 10) {
      return `0${month}`
    } else {
      return `${month}`;
    }
  }

  totalPriceBenefits() {
    this.totalPrice = this.passengersArray.length * Number(this.objectCurrentProductBenefits.itemValue);
  }

  validateTotalPssenger() {
    if (this.passengersArray.length > 0 && this.clickBenefits) {
      this.activateButton = false;
    } else {
      this.activateButton = true;
    }
  }
}
