import { Component, OnInit } from '@angular/core';
import { HotelAmenity } from '../../../../models/hotel/hotel-amenity.model';
import { HotelDetailModalComponent } from '../hotel-detail-modal.component';

@Component({
  selector: 'app-hotel-detail-footer',
  templateUrl: './hotel-detail-footer.component.html',
  styleUrls: ['./hotel-detail-footer.component.scss']
})
export class HotelDetailFooterComponent implements OnInit {

  amenities: HotelAmenity[];
  constructor(private hotelDetailCmp: HotelDetailModalComponent) { }

  ngOnInit() {
    this.amenities = this.hotelDetailCmp.hotel.hotelObj.amenities;
  }

}
