import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationData } from './confirmation.model';
import { Router } from '@angular/router';
import moment from 'moment';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { TranslateService } from '@ngx-translate/core';
import { IActivityResult } from 'src/app/models/activities/response/activity-availability.model';
import { DataService } from 'src/app/services/data.service';
import { LanguageService } from 'src/app/component/language/language.service';
import { EProduct } from 'src/app/component/searched/models/searched.model';
import { GoogleAnalyticsAction } from 'src/app/models/GoogleAnalyticsEvent';
import { RegistrationService } from 'src/app/pages/registration/registration.service';
import { Journey } from 'src/app/models/new-flight/journey.model';
import { HotelAvailabilityOptions } from 'src/app/models/new-hotel/hotel-availability-response.model';
import { ActionEventService } from '../../services/action-event.service';
import data from '../../resources/screenAndEventsTracking.json';
import { GoogleAnalytics } from '../../resources/GoogleAnalytics.resource';
import { InitService } from '../../services/init.service';
import { UtilsResource } from 'src/app/resources/UtilsResource.resource';


@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})

export class ConfirmationComponent implements OnInit {

  @Input() confirmationData: ConfirmationData;
  info: any;

  EProduct = EProduct;


  public locator;
  public isCollapsed = false;
  public trans = [];

  get journey() {
    return this.confirmationData.product as Journey;
  }

  get hotel() {
    return (this.confirmationData.product as HotelAvailabilityOptions);
  }

  get activity() {
    return (this.confirmationData.product as IActivityResult);
  }

  get showPlus() {
    try {
      if (this.confirmationData.transactionData.transactionMoney > 0 && this.confirmationData.transactionData.transactionPoints > 0) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  constructor(public router: Router,
    public data: DataService,
    protected $gaService: GoogleAnalyticsService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private registrationService: RegistrationService,
    private actionEventService: ActionEventService,
    private initService: InitService
  ) { }

  ngOnInit() {
    let tipoUpperCase = this.confirmationData.type.replace(/\b\w/g, (l) => l.toUpperCase());
    // this.trackingManager.setCurrentScreen('/confirm-pay', data.CONFIRM_PAY.confirmPayScreen + tipoUpperCase);
    this.initService.isInitializedBrand.subscribe((isInit) => {
      if (isInit) {
        GoogleAnalytics.setCurrentScreen('/confirm-pay');
      }
    });
    this.getTranslations().then(() => {
      if (this.confirmationData.type === EProduct.FLIGHTS) {
        this._sendGoogleAnalyticsPaymentEvents();

        // const interval = setInterval(() => {
        //   const purchaseId = this.confirmationData.bookingData.purchaseId;
        //   this.registrationService.getBookingCode(purchaseId).then(locator => {
        //     this.locator = locator;
        //     clearInterval(interval);
        //   });
        // }, 10000);
      }
    });
  }

  getTranslations() {
    return new Promise<any>((resolve, reject) => {
      this.languageService.systemLanguajeChange.subscribe(language => {
        if (language) {
          this.translateService.get([
            'CONFIRMATION',
            'DATE'
          ]).subscribe((trns) => {
            this.trans = trns;
            resolve(true);
          });
        }
      });
    });
  }

  finalizar(){
    this.router.navigate(['home']);
    let paramsTransaction = {
      'trasactionData': this.confirmationData.transactionData,
      'bookingData': this.confirmationData.bookingData
    }
    let tipoUpperCase = this.confirmationData.type.replace(/\b\w/g, (l) => l.toUpperCase());
    // this.trackingManager.trackEventString(data.CONFIRM_PAY.finally + tipoUpperCase, data.CONFIRM_PAY.confirmPayScreen + tipoUpperCase, '', JSON.stringify(paramsTransaction));
    GoogleAnalytics.sendEventFirebase('ga_event', data.CONFIRM_PAY.confirmPayScreen + tipoUpperCase,  data.CONFIRM_PAY.finally + tipoUpperCase, JSON.stringify(paramsTransaction));
  }

  private _sendGoogleAnalyticsPaymentEvents() {
    // TODO: CAMBIAR RESERVATIONCODE
    const reservationCode = 'ASDASD';//this.confirmationData.bookingData.locator;
    if (reservationCode) {
      this.$gaService.event(
        'Order Number',
        GoogleAnalyticsAction.FlightPayment,
        reservationCode
      );
    }

    const transactionData = this.confirmationData.transactionData;
    if (transactionData) {

      // Monto
      const transactionMoney = +transactionData.transactionMoney;
      if (transactionMoney) {
        this.$gaService.event(
          'Amount',
          GoogleAnalyticsAction.FlightPayment,
          transactionMoney.toString()
        );
      }

      // Puntos
      const transactionPoints = +transactionData.transactionPoints;
      if (transactionPoints) {
        this.$gaService.event(
          'Points',
          GoogleAnalyticsAction.FlightPayment,
          transactionPoints.toString()
        );
      }

      // Tipo de pago
      let paymentType = '';
      if (transactionMoney && transactionPoints) {
        paymentType = 'Mixed';
      } else if (transactionMoney) {
        paymentType = 'Cash';
      } else if (transactionPoints) {
        paymentType = 'Points';
      }
      if (paymentType) {
        this.$gaService.event(
          'Payment Type',
          GoogleAnalyticsAction.FlightPayment,
          paymentType
        );
      }
    }
  }

  get confirmationTitles() {
    const type = this.confirmationData.type;
    switch (type as EProduct) {
      case EProduct.FLIGHTS: {
        return {
          productTitle: this.trans['CONFIRMATION']['merchant_information'],
          clientTitle: this.trans['CONFIRMATION']['passenger_information']
        };


      } break;
      case EProduct.HOTELS: {
        return {
          productTitle: this.trans['CONFIRMATION']['room_information'],
          clientTitle: this.trans['CONFIRMATION']['guest_information']
        };


      } break;
      case EProduct.BENEFITS: {
        return {
          productTitle: this.trans['CONFIRMATION']['benefit_information'],
          clientTitle: this.trans['CONFIRMATION']['beneficiary_information']
        };


      } break;
      case EProduct.CARS: {
        return {
          productTitle: this.trans['CONFIRMATION']['merchant_information'],
          clientTitle: this.trans['CONFIRMATION']['driver_information']
        };

      } break;
      case EProduct.ACTIVITIES: {
        return {
          productTitle: this.trans['CONFIRMATION']['merchant_information'],
          clientTitle: this.trans['CONFIRMATION']['passenger_information']
        };

      } break;
    }
  }

  purchaseLocator() {
    setInterval(() => {
      this.registrationService.getBookingLocator(this.confirmationData.bookingData.purchaseId).subscribe(res => {
        UtilsResource.ShowLog('*** ConfirmationComponent - purchaseLocator - res', res);
      }, err => {
        UtilsResource.NSMarkLog(err);
      });
    }, 10000);
  }

  getCreditCardFranchise() {
    return 'icon-card'
      + (this.confirmationData.transactionData.transactionCreditCardFranchise !== undefined
        ? '-' + this.confirmationData.transactionData.transactionCreditCardFranchise
        : '');

  }

  SegmentDepartureDate(departureDate) {
    return moment(departureDate).locale("es").format('ddd DD/MM');
  }
  formatTime(datetime) {
    return moment(datetime);
  }

  getNights(info: any[]) {
    if (info !== undefined) {
      const nights = info.find(item => {
        return (item.type === 'night');
      });
      if (nights !== undefined) {
        const nightsQty = parseInt(nights.value);
        return (nightsQty === 1) ? nightsQty + ' Noche'
          : nightsQty + ' Noches';
      }
    } else {
      return '';
    }
  }
}
