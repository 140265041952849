import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { SearchHotelComponent } from '../search-hotel.component';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { LanguageService } from 'src/app/component/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { DataStoreService } from '../../../../../services/data-store.service';

export interface Iguests {
  id: number;
  age: number;
}

@Component({
  selector: 'app-guests',
  templateUrl: './guests.component.html',
  styleUrls: ['./guests.component.scss']
})
export class GuestsComponent implements OnInit {

  @ViewChild('guestCmp') guestCmp;

  get roomsArray() {
    return <FormArray>this.roomsForm.get('rooms');
  }

  ages = [];
  maxGuests = 8;
  menorText: string;
  textPax: string;
  textRom: string;
  roomsForm: FormGroup;
  lastGuest = -1;
  lastRoom = 0;
  paxes: Iguests[];
  rooms = [];
  qAdultos: number;
  qMenores = 0;
  qTotalGuests = 0;
  validatorH: boolean;
  translationTextMinorsSingular: string;
  translationTextMinorsPlural: string;
  translationTextAdultsSingular: string;
  translationTextAdultsPlural: string;
  translationTextRoomsSingular: string;
  translationTextRoomsPlural: string;
  translationTextGuestsSingular: string;
  translationTextGuestsPlural: string;

  constructor(
    private guestDataToSearch: SearchHotelComponent,
    private fb: FormBuilder,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private dataStore: DataStoreService
  ) {
    this.languageService.systemLanguajeChange.subscribe((languaje) => {
      if (languaje) {
        this.getTranslations();
      }
    });
  }

  ngOnInit() {
    // Seteo de valores de edad para niños
    for (let i = 1; i <= 17; i++) {
      this.ages.push(i);
    }
    // Creacion de reactiveForm
    this.roomsForm = this.fb.group({
      rooms: this.fb.array([this.createRooms()])
    });
    // seteo de cantidad minima de adultos para la primer habitacion
    this.roomsForm.controls['rooms'].get([0]).get('qAdultos').setValue(1);
    // seteo de cantidad de menores en 0 para la primer habitacion creada por default
    this.qMenores = 0;
    // seteo de validador generico (a modificar por validaciones de reactive form
    this.validatorH = false;

    this.submitG();
  }

  private getTranslations() {
    this.translateService.get([
      'GUESTS.translation_text_minors_singular',
      'GUESTS.translation_text_adults_singular',
      'GUESTS.translation_text_rooms_singular',
      'GUESTS.translation_text_guests_singular',
      'GUESTS.translation_text_minors_plural',
      'GUESTS.translation_text_adults_plural',
      'GUESTS.translation_text_rooms_plural',
      'GUESTS.translation_text_guests_plural'
    ]).subscribe((translations) => {
      this.translationTextMinorsSingular = translations['GUESTS.translation_text_minors_singular'];
      this.translationTextAdultsSingular = translations['GUESTS.translation_text_adults_singular'];
      this.translationTextRoomsSingular = translations['GUESTS.translation_text_rooms_singular'];
      this.translationTextGuestsSingular = translations['GUESTS.translation_text_guests_singular'];
      this.translationTextMinorsPlural = translations['GUESTS.translation_text_minors_plural'];
      this.translationTextAdultsPlural = translations['GUESTS.translation_text_adults_plural'];
      this.translationTextRoomsPlural = translations['GUESTS.translation_text_rooms_plural'];
      this.translationTextGuestsPlural = translations['GUESTS.translation_text_guests_plural'];
    });
  }

  // METODOS PARA HABITACIONES
  // Crear habitacion.
  createRooms() {
    // Por defecto por cada habitaion nueva se carga 1 adulto
    this.qAdultos = 1;
    // Contabilizador de cantidad total de huespedes
    this.qTotalGuests++;
    return this.fb.group({ guests: this.fb.array([]), qAdultos: this.qAdultos });
  }

  // Nueva habitacion
  addNextRoom() {
    // Condicional para que no se puedan crear mas habitaciones (tomando en cuenta la cantidad maxima de huespedes establecida)
    if (this.qTotalGuests < this.maxGuests) {
      // Seteo de cantidad de menores en 0 para la nueva habitacion
      this.qMenores = 0;
      // Variable de control para posible eliminación de habitacion
      this.lastRoom++;
      // Adhecion de nueva habitacion al array de controles del reactiveForm
      (this.roomsForm.controls['rooms'] as FormArray).push(this.createRooms());
    }
  }

  // Eliminacion de ultima habitacion creada
  removeLastRoom() {
    // Condicional para por lo menos dejar una habitacion con un adulto seteada
    if (this.lastRoom > 0) {
      (this.roomsForm.controls['rooms'] as FormArray).removeAt(this.lastRoom);
      // Variable de control para posible eliminación de habitacion
      this.lastRoom--;
      // Modificacion del contabilizador de cantidad total de huespedes
      this.qTotalGuests--;
    }
  }

  // funcion para ingresar nuevo niño.
  createGuests() {
    return this.fb.group({
      age: ['', [Validators.required]],
    });
  }

  addNextGuest(roomId) {
    if (this.qTotalGuests < this.maxGuests) {
      this.qTotalGuests++;
      this.qMenores++;
      this.lastGuest++;
      (this.roomsForm.controls['rooms'].get([roomId]).get(['guests']) as FormArray).push(this.createGuests());
    }
  }

  removeLastGuest(roomId) {
    if (this.lastGuest >= 0) {
      this.qTotalGuests--;
      this.qMenores--;
      (this.roomsForm.controls['rooms'].get([roomId]).get(['guests']) as FormArray).removeAt(this.lastGuest);
      this.lastGuest--;
    }
  }

  mash(hab) {
    if (this.qTotalGuests < 8) {
      this.qTotalGuests++;
      const preValue = this.roomsForm.controls['rooms'].get([hab]).get('qAdultos').value;
      this.roomsForm.controls['rooms'].get([hab]).get('qAdultos').setValue(preValue + 1);
    }
  }

  menosh(hab) {
    const preValue = this.roomsForm.controls['rooms'].get([hab]).get('qAdultos').value;
    if (preValue > 1) {
      this.roomsForm.controls['rooms'].get([hab]).get('qAdultos').setValue(preValue - 1);
      this.qTotalGuests--;
    }
  }

  qMenoresD(roomId) {
    let menorText;
    const qMenoresA = (this.roomsForm.controls['rooms'].get([roomId]).get(['guests']) as FormArray).length;
    if (qMenoresA !== 0) {
      if (qMenoresA < 2) {
        menorText = ', ' + qMenoresA + ' ' + this.translationTextMinorsSingular;
      } else {
        menorText = ', ' + qMenoresA + ' ' + this.translationTextMinorsPlural;
      }
      return menorText;
    }
    return 'no';
  }

  qAdultosText(q) {
    if (q < 2) {
      return (q + ' ' + this.translationTextAdultsSingular + ' ');
    } else {
      return (q + ' ' + this.translationTextAdultsPlural + ' ');
    }
  }

  submitG() {
    if (this.roomsForm.valid) {
      this.guestDataToSearch.searchFormInvalid = false;
      this.rooms = [];
      let qPax = 1;
      let qRooms = 0;
      const arrayRoom = this.roomsForm.controls['rooms'].value;
      for (let i = 0; i < arrayRoom.length; i++) {
        this.paxes = [];
        for (let j = 0; j < arrayRoom[i].qAdultos; j++) {
          this.paxes.push({ 'id': qPax, 'age': 21 });
          qPax++;
        }
        for (let h = 0; h < arrayRoom[i].guests.length; h++) {
          this.paxes.push({ 'id': qPax, 'age': +arrayRoom[i].guests[h].age });
          qPax++;
        }
        qRooms++;
        this.rooms.push({ paxes: this.paxes });
      }
      this.guestDataToSearch.guests = this.rooms;
      this.guestDataToSearch.showGuestForm = true;
      this.guestDataToSearch.showGuestFormControl = false;
      this.dataStore.qPax = qPax - 1;
      this.dataStore.qRooms = qRooms;
      this.guestDataToSearch.hotelSearchForm.controls['passenger'].setValue(this.textRom + this.textPax);
    } else {
      this.validatorH = true;
    }
  }

  guestsArray(control: FormControl) {
    return <FormArray>control.get('guests');
  }

  /**
   * cerrar el panel si hacen click afuera
   */
  @HostListener('document:click', ['$event.target'])
  onClick(targetElement) {
    const clickedInside = this.guestCmp.nativeElement.contains(targetElement) ||
      (targetElement && (<HTMLElement>targetElement).getAttribute('attr-open-app-guest')) ||
      (targetElement && (<HTMLElement>targetElement).getAttribute('class') &&
        (<HTMLElement>targetElement).getAttribute('class').indexOf('mat-option-text') > -1);

    if (!clickedInside) {
      this.guestDataToSearch.showGuestForm = true;
    }
  }
}
