import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { SearchFlightComponent } from '../search-flight.component';
import { ViewEncapsulation } from '@angular/core';
import { DataStoreService } from '../../../../../services/data-store.service';
import { DataService } from '../../../../../services/data.service';
import { LanguageService } from 'src/app/component/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../../../container/shared/login/login.service';
import { SmartProfileService } from 'src/app/services/smart-profile.service';

@Component({
  selector: 'app-pasajeros',
  templateUrl: './pasajeros.component.html',
  styleUrls: ['./pasajeros.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PasajerosComponent implements OnInit {
  qAdultos: number;
  textAdults: string;
  qNinos: number;
  textNinos: string;
  qBebes: number;
  textBebes: string;
  pasajerosRsm: string;
  economica = false;
  ejecutiva = false;
  primera = false;
  economicaPremium = false;
  cabinClasses = [];
  validateCabin: boolean;
  validateQPassengers: boolean;
  preferCabin: string;
  translationTextAdultsSingular: string;
  translationTextChildrenSingular: string;
  translationTextInfantsSingular: string;
  translationTextAdultsPlural: string;
  translationTextChildrenPlural: string;
  translationTextInfantsPlural: string;

  @ViewChild('personasCmp') personasCmp;

  constructor(
    private search: SearchFlightComponent,
    private dataStore: DataStoreService,
    private data: DataService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private loginService: LoginService,
    private smartProfileService: SmartProfileService
  ) {
  }

  ngOnInit() {
    this.languageService.systemLanguajeChange.subscribe((languaje) => {
      if (languaje) {
        this.initValues();
      }
    });
    this.smartProfileService.profileDataLoadedChange.subscribe(dataLoaded => {
      if (dataLoaded) {
        if (this.dataStore.smartProfilePreferences && this.dataStore.smartProfilePreferences.flightClass) {
          this.preferCabin = this.dataStore.smartProfilePreferences.flightClass;
          this.preferCabinFn(this.preferCabin);
          this.qAdultos = this.dataStore.qAdultos;
          this.qNinos = this.dataStore.qNinos;
          this.qBebes = this.dataStore.qBebes;
        }
      } else {
        this.economica = true;
        this.ejecutiva = false;
        this.primera = false;
        this.economicaPremium = false;
        this.search.cabinClasses.push('Y');
      }
      this.qAdultos = this.dataStore.qAdultos;
      this.qNinos = this.dataStore.qNinos;
      this.qBebes = this.dataStore.qBebes;
    });

  }

  /**
   * cerrar el panel si hacen click afuera
   */
  @HostListener('document:click', ['$event.target'])
  onClick(targetElement) {
    const clickedInside = this.personasCmp.nativeElement.contains(targetElement) ||
      (targetElement && (<HTMLElement>targetElement).getAttribute('attr-open-app-pasajeros'));

    if (!clickedInside) {
      this.search.showPassengerForm = true;
    }
  }

  private initValues() {
    this.translateService.get([
      'PASSENGERS.adult',
      'PASSENGERS.indicate_passengers',
      'PASSENGERS.translation_text_adults_singular',
      'PASSENGERS.translation_text_children_singular',
      'PASSENGERS.translation_text_infant_singular',
      'PASSENGERS.translation_text_adults_plural',
      'PASSENGERS.translation_text_children_plural',
      'PASSENGERS.translation_text_infants_plural'
    ]).subscribe((translations) => {

      this.translationTextAdultsSingular = translations['PASSENGERS.translation_text_adults_singular'];
      this.translationTextChildrenSingular = translations['PASSENGERS.translation_text_children_singular'];
      this.translationTextInfantsSingular = translations['PASSENGERS.translation_text_infant_singular'];
      this.translationTextAdultsPlural = translations['PASSENGERS.translation_text_adults_plural'];
      this.translationTextChildrenPlural = translations['PASSENGERS.translation_text_children_plural'];
      this.translationTextInfantsPlural = translations['PASSENGERS.translation_text_infants_plural'];
    });
  }

  preferCabinFn(preferCabin) {
    switch (preferCabin) {
      case 'Y':
        this.search.cabinClasses.push('Y');
        this.economica = true;
        break;
      case 'F':
        this.search.cabinClasses.push('F');
        this.economica = false;
        this.primera = true;
        break;
      case 'B':
        this.search.cabinClasses.push('B');
        this.economica = false;
        this.ejecutiva = true;
        break;
    }
  }

  pullData() {
    this.cabinClasses = [];
    if (this.economica) {
      this.cabinClasses.push('Y');
    }
    if (this.ejecutiva) {
      this.cabinClasses.push('B');
    }
    if (this.primera) {
      this.cabinClasses.push('F');
    }
    if (this.economicaPremium) {
      this.cabinClasses.push('P');
    }
    const qTotal = this.qAdultos + this.qNinos + this.qBebes;
    if (this.cabinClasses.length <= 0 || qTotal < 1) {
      this.validateCabin = this.cabinClasses.length <= 0;
      if (qTotal < 1) {
        this.validateQPassengers = true;
      } else {
        this.validateQPassengers = false;
        this.search.passengersNotSelected = true;
      }
    } else {
      this.validateCabin = false;
      this.validateQPassengers = false;
      this.search.cabinClasses = this.cabinClasses;
      this.search.showPassengerForm = true;
      this.search.showPassengerFormControl = false;
      this.pasajerosRsm = '';
      this.search.isCollapsed = true;
      this.dataStore.qAdultos = this.qAdultos;
      this.dataStore.qNinos = this.qNinos;
      this.dataStore.qBebes = this.qBebes;
    }
  }

  mas(type: string) {
    if (this.qAdultos + this.qNinos + this.qBebes < 8) {
      switch (type) {
        case 'A':
          this.qAdultos++;
          break;
        case 'N':
          this.qNinos++;
          break;
        case 'B':
          if (this.qAdultos > this.qBebes) {
            this.qBebes++;
          }
          break;
      }
    }
  }

  menos(type: string) {
    switch (type) {
      case 'A':
        if (this.qAdultos > 0) {
          if (this.qBebes >= this.qAdultos) {
            this.qBebes--;
          }
          this.qAdultos--;
        }
        break;
      case 'N':
        if (this.qNinos > 0) {
          this.qNinos--;
        }
        break;
      case 'B':
        if (this.qBebes > 0) {
          this.qBebes--;
        }
        break;
    }
  }
}
