<form [formGroup]="buyerForm">
    <div #personasCmp class="bl-personas" name="personas">
        <div class="passengers">
            <div formGroupName="passangerConf">
                <h5 innerHTML="{{'PASSENGERS.passengers'|translate}}">
                    Passengers
                </h5>
                <div class="persons">

                    <div class="item">
                        <span innerHTML="{{'PASSENGERS.adults'|translate:'{value:\'+11\'}'}}">
          Adults (+11)
        </span>
                        <div class="range-age">
                            <app-input-number formControlName="adults" [min]="0" [max]="maxAdults"></app-input-number>
                        </div>
                    </div>
                    <div class="item">
                        <span innerHTML="{{'PASSENGERS.children'|translate:'{value:\'2-11\'}'}}">
          Children (2-11)
        </span>
                        <div class="range-age">
                            <app-input-number formControlName="kids" [min]="0" [max]="maxKids"></app-input-number>
                        </div>
                    </div>
                    <div class="item">
                        <span innerHTML="{{'PASSENGERS.infants'|translate:'{value:\'-2\'}'}}">
                            Infants (-2)
        </span>
                        <div class="range-age">
                            <app-input-number formControlName="infants" [min]="0" [max]="maxInfants"></app-input-number>
                        </div>
                    </div>
                </div>
                <h5 innerHTML="{{'PASSENGERS.class'|translate}}">
                    Class
                </h5>
            </div>
            <div class="row cabinRow" formGroupName="cabin">
                <div class="col-6">
                    <mat-checkbox formControlName="economy">
                        <span innerHTML="{{'PASSENGERS.economy'|translate}}">
          Economy
          </span>
                    </mat-checkbox>
                    <mat-checkbox formControlName="bussiness">
                        <span innerHTML="{{'PASSENGERS.executive'|translate}}">
            Executive
          </span>
                    </mat-checkbox>
                </div>
                <div class="col-6 cabinColFix">
                    <mat-checkbox formControlName="premiumEconomy">
                        <span innerHTML="{{'PASSENGERS.econ_premium'|translate}}">
            Econ premium
          </span>
                    </mat-checkbox>
                    <mat-checkbox formControlName="firstClass">
                        <span innerHTML="{{'PASSENGERS.first'|translate}}">
            First
          </span>
                    </mat-checkbox>
                </div>
            </div>
        </div>
        <div class="bg-btn"></div>
        <div class="btn-aplicar" (click)="onApply()" innerHTML="{{'PASSENGERS.apply'|translate}}">
            Apply
        </div>
    </div>
</form>