<section class="s-more-power">
    <div class="container">
        <h1 class="general-three text-gradient">{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.power_wallet_title' | translate}}</h1>

        <h3 class="text-intro">{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.text_intro_1' | translate}}<br>{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.text_intro_2' | translate}}<strong>{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.text_intro_3' | translate}}</strong>{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.text_intro_4'
            | translate}}</h3>

        <mat-tab-group class="paypal" mat-align-tabs="center">
            <mat-tab label="">
                <div class="bl-row">
                    <div class="bl-left">
                        <ul class="items-number">
                            <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.1' | translate}}</span>
                                <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.paypal_list_1' | translate}}"></p>
                            </li>
                            <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.2' | translate}}</span>
                                <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.paypal_list_2' | translate}}"></p>
                            </li>
                            <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.3' | translate}}</span>
                                <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.paypal_list_3' | translate}}"></p>
                            </li>
                            <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.4' | translate}}</span>
                                <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.paypal_list_5' | translate}}"></p>
                            </li>
                        </ul>
                    </div>
                    <div class="bl-right">
                        <!-- <div class="iphone-gif">
                            <img src="./assets/images/{{brand}}-miles/paypal.gif" alt="">
                        </div> -->
                        <div class="bloque-video">
                            <div class="iphone-video">
                                <video class="video-vimeo" loop muted playsinline autoplay oncanplay="this.play()" onloadedmetadata="this.muted=true"> 
                                    <source  src="https://player.vimeo.com/external/470228705.hd.mp4?s=d198b0fe09e65353b545204717884ce24d29e04c&profile_id=175"  type="video/mp4">
                                </video>
                            </div>
                            <img class="imagen-video" src="./assets/images/{{brand}}-miles/bg-iphone-miles.png" alt="">
                        </div>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="">
                <div class="bl-row">
                    <div class="bl-left">
                        <ul class="items-number">
                            <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.1' | translate}}</span>
                                <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.paypal_list_1' | translate}}"></p>
                            </li>
                            <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.2' | translate}}</span>
                                <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.paypal_list_2' | translate}}"></p>
                            </li>
                            <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.3' | translate}}</span>
                                <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.paypal_list_3' | translate}}"></p>
                            </li>
                            <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.4' | translate}}</span>
                                <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.paypal_list_5' | translate}}"></p>
                            </li>
                        </ul>
                    </div>
                    <div class="bl-right">
                        <!-- <div class="iphone-gif">
                            <img src="./assets/images/{{brand}}-miles/applepay.gif" alt="">
                        </div> -->
                        <div class="bloque-video">
                            <div class="iphone-video">
                                <video class="video-vimeo" loop muted playsinline autoplay oncanplay="this.play()" onloadedmetadata="this.muted=true"> 
                                    <source  src="https://player.vimeo.com/external/463493247.hd.mp4?s=d00a20fde5488d6ad75b9250c13770c9e14b2bd7&profile_id=175"  type="video/mp4">
                                </video>
                            </div>
                            <img class="imagen-video" src="./assets/images/{{brand}}-miles/bg-iphone-miles.png" alt="">
                        </div>
                    </div>
                </div>
            </mat-tab>



            <mat-tab label="">
                <div class="bl-row">
                    <div class="bl-left">
                        <ul class="items-number">

                            <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.1' | translate}}</span>
                                <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.samsung_list_1' | translate}}"></p>
                            </li>
                            <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.2' | translate}}</span>
                                <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.paypal_list_2' | translate}}"></p>
                            </li>
                            <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.3' | translate}}</span>
                                <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.paypal_list_3' | translate}}"></p>
                            </li>
                            <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.4' | translate}}</span>
                                <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_MPOWER.paypal_list_5' | translate}}"></p>
                            </li>
                        </ul>
                    </div>
                    <div class="bl-right">
                        <!-- <div class="iphone-gif">
                            <img src="./assets/images/{{brand}}-miles/samsungpay.gif" alt="">
                        </div> -->
                        <div class="bloque-video samsung-bloque">
                            <div class="iphone-video">
                                <video class="video-vimeo" loop muted playsinline autoplay oncanplay="this.play()" onloadedmetadata="this.muted=true"> 
                                    <source src="https://player.vimeo.com/external/463577277.hd.mp4?s=4b9301389acd3d8f05c0ab8776037d97934e5e95&profile_id=175"  type="video/mp4">
                                </video>
                            </div>
                            <img class="imagen-video" src="./assets/images/bg-samsung.png" alt="">
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</section>


<section class="available">
    <div class="container">
        <div class="bl-row">
            <div class="bl-left">
                <img class="img-watch" src="./assets/images/{{brand}}-miles/bg-watch.png" alt="fc">
            </div>

            <div class="bl-right">
                <h3>{{'LANDING.'+brand+'.SECTION_THREE.SECTION_AVAILABLE.available' | translate}}</h3>
                <img class="logos" src="./assets/images/{{brand}}-miles/logo-gpay.png" alt="fc">
            </div>
        </div>
    </div>
</section>


<section class="paying">
    <div class="container">
        <div class="bl-row">
            <div class="bl-left">
                <div class="bloque-video none">
                    <div class="iphone-video">
                        <video class="video-vimeo" loop muted playsinline autoplay oncanplay="this.play()" onloadedmetadata="this.muted=true">
                                <source [src]="urlsVideos.one.url | srcSanitizer" type="video/mp4">
                            </video>
                    </div>
                </div>
            </div>

            <div class="bl-right">
                <h2 class="text-gradient"><strong>{{'LANDING.'+brand+'.SECTION_THREE.SECTION_PAYING.paying_title' | translate}}</strong></h2>
                <p>{{'LANDING.'+brand+'.SECTION_THREE.SECTION_PAYING.desc_paying_1' | translate}}</p>
                <ul class="items-number">
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.1' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_PAYING.list_paying_1' | translate}}"></p>
                    </li>
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.2' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_PAYING.list_paying_2' | translate}}"></p>
                    </li>
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.3' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_PAYING.list_paying_3' | translate}}"></p>
                    </li>
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.4' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_PAYING.list_paying_4' | translate}}"></p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>


<section class="gray s-enough">
    <div class="container">
        <div class="bl-row">
            <div class="bl-left">
                <h2 class="text-gradient"><strong>{{'LANDING.'+brand+'.SECTION_THREE.SECTION_EMILES.enough_miles_title' | translate}}</strong></h2>
                <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_EMILES.emiles_desc_1' | translate}}"></p>
            </div>

            <div class="bl-right">
                <div class="bloque-video">
                    <div class="iphone-video">
                        <video class="video-vimeo" loop muted playsinline autoplay oncanplay="this.play()" onloadedmetadata="this.muted=true">
                                <source [src]="urlsVideos.three.url | srcSanitizer" type="video/mp4">
                            </video>
                    </div>
                    <img class="imagen-video" src="./assets/images/{{brand}}-miles/bg-iphone-miles.png" alt="">
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="s-flex" >
    <div class="container">
        <div class="bl-row">
            <div class="bl-left">
                <div class="bloque-video">
                    <div class="iphone-video">
                        <video class="video-vimeo" loop muted playsinline autoplay oncanplay="this.play()" onloadedmetadata="this.muted=true">
                                <source [src]="urlsVideos.two.url | srcSanitizer" type="video/mp4">
                            </video>
                    </div>
                    <img class="imagen-video" src="./assets/images/{{brand}}-miles/bg-iphone-miles.png" alt="">
                </div>
            </div>

            <div class="bl-right">

                <h2 class="text-gradient"><strong>{{'LANDING.'+brand+'.SECTION_THREE.SECTION_FTRAVEL.flex_travel_title' | translate}}</strong></h2>
                <p>{{'LANDING.'+brand+'.SECTION_THREE.SECTION_FTRAVEL.ftravel_desc_1' | translate}}<strong>{{'LANDING.'+brand+'.SECTION_THREE.SECTION_FTRAVEL.ftravel_desc_2' | translate}}</strong>{{'LANDING.'+brand+'.SECTION_THREE.SECTION_FTRAVEL.ftravel_desc_3'
                    | translate}}</p>
            </div>
        </div>
    </div>
</section> -->