/*import {Component, HostListener} from '@angular/core';


@Component({...})
export class MyComponent {

  @HostListener('document:keyup', ['$event'])
  onKeyUp(ev: KeyboardEvent) {
    // do something meaningful with it
  }
}*/

import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[appIframeCatch]'
})
export class PostMessageDirective {
  constructor() {}
  @HostListener('window:message', ['$event.data.data', '$event.data.type']) onMessage(msje, type) {
  }
}
/*import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appClickCatcher]'
})
export class PostMessageDirective {
  algo: string;
  constructor() {
  }
  @HostListener('change', ['$event.value']) onClick(hola) {
    this.algo = hola;
  }
}*/
