import { keysUniversal, StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from '../../services/data.service';
import { DataStoreService } from '../../services/data-store.service';
import { LanguageService } from '../language/language.service';
import { typeApp, environment } from 'src/environments/environment';
import { InitService } from '../../services/init.service';

@Component({
  selector: 'app-certificate-learn-more',
  templateUrl: './certificate-learn-more.component.html',
  styleUrls: ['./certificate-learn-more.component.css']
})
export class CertificateLearnMoreComponent implements OnInit {

  i;
  benefitsGroup = [];
  activateDeploy = false;

  systemLanguage = 'EN';
  currencyUniversal: any;

  get brand() {
    return this.dataStore.brand;
  }

  constructor(private dialog: MatDialog,
    private render: Renderer2,
    private dataService: DataService,
    private dataStore: DataStoreService,
    private languageService: LanguageService,
    private initService: InitService,
    private store: StoreServiceLibrary,
    private dialogRef: MatDialogRef<CertificateLearnMoreComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) {
    this.initService.isInitializedBrand.subscribe((initialized) => {
      if (initialized) {
        this.currencyUniversal = JSON.parse(
          this.store.getJustOneUniversalPropertie(
            keysUniversal.app_default_currency
          )
        );
      }
    });
    // cuando cambia el idioma del sistema
    this.languageService.systemLanguajeChange.subscribe(language => {
      if (language) {
        this.systemLanguage = language.toUpperCase();
      }
    });
  }

  ngOnInit(): void {
    this.benefitsGroup = this.data;
  }

  activateDeployItem(id1) {
    this.activateDeploy = !this.activateDeploy;
    let itemBenfits = document.getElementById(`${id1}item`);
    let itemBenfitsContent = document.getElementById(`${id1}collapsible`);
    if (this.activateDeploy) {
      this.render.setStyle(itemBenfits, 'max-height', '394px');
      this.render.addClass(itemBenfitsContent, 'active');
    } else {
      this.render.removeStyle(itemBenfits, 'max-height');
      this.render.removeClass(itemBenfitsContent, 'active');
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  showTermsAndConditionsDialog() {
    if (this.dataService.idApplication === String(typeApp.LIFEMILES_TRAVEL)) {
      const lang = this.store.getItemFromStorage('lang') || 'en';
      // const urlTerms = environment.brands[this.dataStore.brand].terminos_y_condiciones[lang.toUpperCase()];
      let urlTerms = this.store.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_en);

      if (lang) {
        if (lang == 'es') {
          urlTerms = this.store.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_es);
        }
      }
      window.open(urlTerms, '_blank');
    } else {
      this.dataService.openTermsAndConditionsDialog();
    }
  }

}
