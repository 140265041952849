export enum FilterGroupType {
  checkboxTextFG = 1,
  checkboxIconsFG = 2,
  sliderFG = 3,
  textAutocompleteFG = 4,
  textDropdownFG = 5
}

export enum FilterDataType {
  indistinctFilter = 0,
  momentFilter = 1,
  priceFilter = 2,
  iconFilter = 3,
  autocompleteFilter = 4,
  dropdownFilter = 5,

}

export interface IFilterParams {
  groupTitle: string;
  groupName: string;
  groupOrder?: number;
  groupType: FilterGroupType;
  optionValuePath: string;
  optionsIdsPath: string;
  optionType?: FilterDataType;
  includeText?: RegExp;
  excludeText?: RegExp;
  iconImg?: string;
  dictionary?: IDictionary[];
  currencyPath?: string;
  chequedValues?: any[];
  multipleOptions?: boolean;
}

export interface ISlidersGroupIds {
  filterName: string;
  prevSliderIdsArray: any[]
}

export interface TranslateValue {
  text: string; // path de la variable de traducción
  params: string; // parametros de la variable de traducción
}

export interface IDictionary {
  inValue: any;
  outValue: any; // muestra el texto tal cual en pantalla
  outValueTranslate?: TranslateValue; // muestra el texto desde una variable de traducción
}
