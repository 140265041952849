import { GalleryCarouselComponent } from './../gallery-carousel.component';
import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { SwiperComponent, SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  type = 'component';
  show = true;
  config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 6,
    mousewheel: false,
    scrollbar: false,
    height: 65,
    spaceBetween: 10
  };
  prev: number;
  next: number;
  mainImageIndex = 0;


  images = [
    {
      imgURL: './assets/images/empty_room.png',
      label: ''
    }
  ];

  slides: any[] = [];
  @ViewChild(SwiperComponent) swiper: SwiperComponent;
  @Output() clickedImgSrc = new EventEmitter();


  constructor(public galleryCarousel: GalleryCarouselComponent) { }

  ngOnInit() {
    this.galleryCarousel.images.forEach(img => {
      const found = this.slides.find(item => {
        const url1 = img.imgURL.split('?');
        const url2 = item.imgURL.split('?');
        if (url1[0] === url2[0]) {
          return item;
        }
      });
      if (found === undefined) {
        this.slides.push(img);
      }
    });
  }

  onIndexChange(ev) {
  }

  updateIndex (i) {
    this.mainImageIndex = i ;
    this.clickedImgSrc.emit(this.slides[this.mainImageIndex].imgURL);
    this.swiper.directiveRef.setIndex(this.mainImageIndex);
  }

  onErrorFn(index) {
    this.slides.splice(index, 1);
  }

  onNext() {
    if (this.mainImageIndex < this.slides.length - 1  ) {
      this.mainImageIndex += 1;
      this.clickedImgSrc.emit(this.slides[this.mainImageIndex].imgURL);
      this.swiper.directiveRef.setIndex(this.mainImageIndex);
    }
  }
  onPrev() {
    if (this.mainImageIndex > 0 ) {
      this.mainImageIndex -= 1;
      this.clickedImgSrc.emit(this.slides[this.mainImageIndex].imgURL);
      this.swiper.directiveRef.setIndex(this.mainImageIndex);
    }
  }
}
