<div class="title-meet">
    <div class="container">
        <h2 [innerHTML]="config?.title[this.translate.currentLang]"></h2>
    </div>

</div>

<section class="meet" *ngIf="showComponent">
    <div class="container">
        <div class="row">
            <div class="block" *ngFor="let meetItem of config['videos'][this.translate.currentLang]"
                (click)="openModal(meetItem?.url)">
                <div class="info">
                    <h3 [innerHTML]="meetItem?.title"></h3>
                    <p [innerHTML]="meetItem?.description"></p>
                    <span class="watch">Watch video</span>
                </div>
                <img [src]="meetItem?.img | srcSanitizer" alt="">
            </div>

        </div>
    </div>
</section>

<div class="bg" [ngClass]="{'open': open}">
    <div class="video">
        <!-- <span class="icon-play" (click)="playVideo()" *ngIf="!play"></span> -->
        <span class="icon-close" (click)="closeModal()"></span>
        <video [src]="video" controls #elementVideo poster="./assets/images/myrewards-miles/poster-meet.png"></video>
    </div>

</div>