import { RentacarFormatter } from './models/rentacar-formatter';
import { ProductHandlerService } from './../../services/product-handler.service';
import { RequestHandlerService } from './../../services/request-handler.service';
import { DataService } from './../../services/data.service';
import { SearchedService } from './../../component/searched/searched.service';
import { Injectable } from '@angular/core';
import { InitService } from 'src/app/services/init.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/component/language/language.service';
import { BehaviorSubject } from 'rxjs';
import { EProduct } from 'src/app/component/searched/models/searched.model';
import { HotelFormatter } from './models/hotel-formatter';
import { HotelAvailabilityOptions } from 'src/app/models/new-hotel/hotel-availability-response.model';
import { FiltersFormatter } from './models/filters-formatter';
import { Journey } from 'src/app/models/new-flight/journey.model';
import { RentacarAvailabilityOption } from 'src/app/models/new-rentacar/rentacar-availability-option.model';
import { ActivityFormatter } from './models/activity-formatter';
import { ActivityResult } from 'src/app/models/new-activity/activity-availability-response.model';
import { SearchErrorType } from 'src/app/models/search-error-type.model';

@Injectable({
  providedIn: 'root'
})
export class ResultsService {

  public isInitialized = new BehaviorSubject(false);

  public productList: HotelAvailabilityOptions[] | Journey[] | RentacarAvailabilityOption[] | ActivityResult[];
  public productListFormatter: () => {};

  public translationDate = [];
  public translationResults = [];
  public translationNoResults = [];

  get token() {
    return this.dataService.token;
  }

  get selectedProduct(): HotelAvailabilityOptions | Journey | RentacarAvailabilityOption | ActivityResult {
    return this.productHandlerService.selectedProduct;
  }

  get productType(): EProduct {
    return this.productHandlerService.productType;
  }


  constructor(
    private initService: InitService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private searchedService: SearchedService,
    private dataService: DataService,
    private requestHandlerService: RequestHandlerService,
    private productHandlerService: ProductHandlerService,
  ) {
    this.initService.isInitializedBrand.subscribe(async isInitialized => {
      if (isInitialized) {
        this.languageService.systemLanguajeChange.subscribe((language) => {
          if (language) {
            this.getTranslations().then(() => {
              if (!this.isInitialized.value) {
                this.isInitialized.next(true);
              }
            });
          }
        });
      }
    });
  }

  getTranslations() {
    return new Promise<any>((resolve) => {
      this.translateService.get([
        'CARD',
        'DATE',
        'RESULTS',
        'NO_RESULTS',
      ]).subscribe((trans) => {
        this.translationDate = trans['DATE'];
        this.translationResults = trans['RESULTS'];
        this.translationNoResults = trans['NO_RESULTS'];
        resolve(true);
      });
    });
  }

  getAvailability() {
    this.productList = [];
    this.requestHandlerService.setProductVariables(this.productType);
    this.requestHandlerService.availabilityRequest.language = this.languageService.systemLanguajeChange.value.toUpperCase();

    return new Promise<any>((resolve, reject) => {
      this.requestHandlerService.sendAvailabilityRequest().subscribe(res => {
        this.productList = this.formatProductList(res);
        this.productHandlerService.selectedProduct = this.productList[0];
        resolve(this.productList);
      }, error => {
        this.setNoResultsVars(this.productType);
        reject(error);
      });
    });
  }

  getContentData(products: any[]) {
    const codes = products.map(prod => prod.hotelCode).join(',');
    this.requestHandlerService.sendContentRequest(codes).subscribe(res => {
      res.forEach(item => {
        (this.productList as any).find(product => {
          if (product.hotelCode === item.hotelCode) {
            product.amenities = item.amenities;
            product.images = item.images;
          }
        });
      });
    }, err => { });
  }

  getBookingRules() {
    return new Promise<any>((resolve, reject) => {
      this.requestHandlerService.sendBookingRulesRequest(this.requestHandlerService.bookingRulesRequest).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  }

  formatProductList(availabilityResponse) {
    switch (this.productType) {
      case EProduct.HOTELS:
        const hotelFormatter = new HotelFormatter(
          availabilityResponse.hotelOptions,
          this.searchedService.getSearchedHotel());
        return hotelFormatter.formatData();
        break;
      case EProduct.FLIGHTS:
        return Journey.toArray(availabilityResponse.journeys);
        break;
      case EProduct.CARS:
        const rentacarFormatter = new RentacarFormatter(
          availabilityResponse.options,
          this.searchedService.getSearchedCar());
        return rentacarFormatter.formatData();
        break;
      case EProduct.ACTIVITIES:
        const activityFormatter = new ActivityFormatter(
          availabilityResponse.results,
          this.searchedService.getSearchedActivity());
        return activityFormatter.formatData();
        break;
    }
  }

  setNoResultsVars(productType) {
    switch (this.productType) {
      case EProduct.HOTELS:
        this.dataService.flightSearchError = this.translationNoResults['JUNIP1'] as SearchErrorType;
        break;
      case EProduct.FLIGHTS:
        this.dataService.flightSearchError = this.translationNoResults['CONS2'] as SearchErrorType;
        break;
      case EProduct.CARS:
        this.dataService.flightSearchError = this.translationNoResults['JUNIP2'] as SearchErrorType;
        break;
      case EProduct.ACTIVITIES:
        this.dataService.flightSearchError = this.translationNoResults['JUNIP3'] as SearchErrorType;
        break;
    }
  }

  // setNoResultsVars(error, productType ) {
  //   if (error.message && error.error.message) {
  //     const errMsg = error.error.message;
  //     if (errMsg.includes('close to departure')) {
  //       // TODO: Cambiar variables del DataService
  //       this.dataService.flightSearchError = this.translationNoResults['CONS1'] as SearchErrorType;
  //     } else {
  //       // TODO: Cambiar variables del DataService
  //       this.dataService.flightSearchError = this.translationNoResults['CONS2'] as SearchErrorType;
  //     }
  //   } else {
  //       // TODO: Cambiar variables del DataService
  //     this.dataService.flightSearchError = this.translationNoResults['CONS2'] as SearchErrorType;
  //   }
  // }

  getFiltersParamsList() {
    let flightLength = 0;
    if ((this.selectedProduct as Journey).journeyID) {
      flightLength = (this.selectedProduct as Journey).flights.length;
    }
    const filtersFormatter = new FiltersFormatter(this.translationResults, flightLength);
    return filtersFormatter.getFiltersParamsList(this.productType);
  }

  priceConversion(percent) {
    const conversionRate = this.dataService.conversionRate;
    const userPoints = this.dataService.userPointsSP;
    this.productList.forEach(product => {
      product.priceConversion(conversionRate, percent, userPoints);
    });
  }

}
