import { Directive, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'img[appFallBackImg]'
})
export class FallBackImgDirective {
  @Input() appFallBackImg: string;

  constructor(private eRef: ElementRef) { }

  @HostListener('error')
  loadFallBackOnError() {
    const element: HTMLImageElement = <HTMLImageElement>this.eRef.nativeElement;
    element.src = this.appFallBackImg;
  }
}
