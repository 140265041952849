<div class="traveling-passengers">
    <div class="passengers">
        <h3><b innerHTML="{{'PASSENGERS.passengers' | translate}}">Passengers</b></h3>
        <p innerHTML="{{'PASSENGERS.enterPassengerMinors' | translate}}">please, enter ages from minor passengers</p>
        <div class="line"></div>
        <h5>
            <span innerHTML="{{'PASSENGERS.total' | translate}}">Total passengers</span>
            <span class="number">{{totalPassenger}}</span> 
        </h5>
        <div class="bl-pasajeros">
            <div class="bl" innerHTML="# {{'PASSENGERS.minors' | translate}}">
                # Minors passengers
            </div>
            <select [(ngModel)]="totalPassengersSelect" (change)="getTotalPassengers()">
                <option *ngFor="let passengers of totalPassengers">{{passengers}}</option>
            </select>
        </div>
        <div class="error" *ngIf="existeError">{{msgError}}</div>
        <div class="bl-edad">
            <span innerHTML="{{'PASSENGERS.infantValid' | translate}}">Only 1 infant per adult (-2)</span>
            <div class="items-edad">
                <div *ngFor="let age of totalAges; let nPos = index;" class="box">
                    <span innerHTML="{{'PASSENGERS.age' | translate}}">Age</span>
                    <select [(ngModel)]="totalAgeSelect[nPos]">
                        <option *ngFor="let passengers of minorPassengers" [value]="passengers">{{passengers}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-btn" (click)="apply()"  innerHTML="{{'ACTIVITY_BODY.accept' | translate}}">Accept</div>
</div>
