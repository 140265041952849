<div class="price-container">
    <div class="">
        <p class="label">{{ priceLabel }}</p>
        <div class="price">
            <p>{{ layout.totalMilesPrice | milesFormat }} <span>{{ 'LAYOUT_TWO.miles' | translate }}</span></p>
            <p><span>{{ currency }}</span> {{ layout.totalMoneyPrice | milesFormat }}</p>
        </div>
    </div>
    <div class="button" *ngIf="options.hasDetail">
        <button class="" (click)="layout.productDetail()">{{ 'LAYOUT_TWO.view_detail' | translate }}</button>
    </div>
    <div class="bottom-info" *ngIf="showBottomInfo">
        <p class="ps-0 col-md-6 col-sm-6 col-xs-6" *ngIf="layout.nights">
            <i class="travel-icon icon-calendar" aria-hidden="true"></i>
            {{ layout.nights }} {{ 'LAYOUT_TWO.nights' | translate }}
        </p>
        <p class="ps-0 col-md-6 col-sm-6 col-xs-6" *ngIf="layout.travellers">
            <i class="travel-icon icon-people" aria-hidden="true" ></i>
            {{ layout.travellers }} {{ 'LAYOUT_TWO.guests' | translate }}
        </p>
    </div>
</div>