import { Component, OnInit, Input } from '@angular/core';
import { DataStoreService } from '../../../services/data-store.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-section-three',
  templateUrl: './section-three.component.html',
  styleUrls: ['./section-three.component.scss']
})
export class SectionThreeComponent implements OnInit {
  @Input() brand: string;
  urlsVideos: any;

  constructor(private dataStore: DataStoreService) { }

  ngOnInit() {
    this.urlsVideos = environment.brands[this.dataStore.brand].videosLanding.sectionThree;
  }

}
