import { InitService } from './services/init.service';
import { Component, OnInit, Inject, ViewEncapsulation, AfterContentInit, AfterViewInit, OnDestroy, HostListener, Renderer2 } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { DataService } from './services/data.service';
import { DataStoreService } from './services/data-store.service';
import { DOCUMENT } from '@angular/common';
import { StoreServiceLibrary, GeneralUtilService, keysUniversal, ScotiaImplementationService, UserData, SessionResponse, OnePocketService, AuthService, AuthGetAccount2Response, AccountSumaryDto, AlleCoreRest, BacCredomaticService, AccountResultDto, SecurityObject } from '@wearenovae/novae-core-services';
import { environment } from 'src/environments/environment';
import { LanguageService } from './component/language/language.service';
import { OcultadorService } from './pages/home/ocultador.service';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { Title } from '@angular/platform-browser';
import { SeoService } from './services/seo.service';
import { ActionEventService } from './services/action-event.service';
import { TranslateService } from '@ngx-translate/core';

/** aca - Test ScotiaBank */
import { GenerateService } from 'src/app/services/generate.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertErrorComponent } from './component/product-detail/alert-error/alert-error.component';
import { ScotiaCardsComponent } from './component/scotia-cards/scotia-cards.component';
import { keys } from './resources/keys.resources';
import { LoginService } from './container/shared/login/login.service';
import { SmartProfileService } from './services/smart-profile.service';
import { EProduct } from './component/searched/models/searched.model';
import { LoadingScreenService } from './services/loading-screen.service';
import { IGoogleAnalyticsSettings, NgxGoogleAnalyticsModule, NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN } from 'ngx-google-analytics';
import { GoogleAnalytics } from './resources/GoogleAnalytics.resource';
import { UtilsResource } from './resources/UtilsResource.resource';
import { Observable } from 'rxjs';

declare var window;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('visibilityChanged', [
      state('true', style({ opacity: 1 })),
      state('false', style({ opacity: 0 })),
      transition('0 => 1', animate('350ms ease-in'))
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterContentInit {

  ShowLoader: any;
  rutaActual: string;
  LPinit = false;
  // brand por default
  brand: string = 'miles';
  useJwtToken: boolean;
  countrysClass = 'country'



  /**
   * cuando el brand está cargado, mostramos el contenido HTML
   */

  isInitializedBrand = false;


  constructor(
    @Inject(DOCUMENT) private _DOCUMENT,
    @Inject(NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN) public gaConfig: IGoogleAnalyticsSettings,
    private title: Title,
    private seo: SeoService,
    public router: Router,
    public data: DataService,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    public dataStore: DataStoreService,
    private generalUtil: GeneralUtilService,
    private languageService: LanguageService,
    private storeLibrary: StoreServiceLibrary,
    private initService: InitService,
    private ocultadorService: OcultadorService,
    private actionEventService: ActionEventService,
    private loadingScreenService: LoadingScreenService,
    private translate: TranslateService,
    private scotiaServiceImplementation: ScotiaImplementationService,
    /** aca - Test ScotiaBank */
    private loginService: LoginService,
    private bacService: BacCredomaticService,
    private generalService: GeneralUtilService,
    private _general: GenerateService,
    private renderer2: Renderer2,
    private _authService: AuthService,
  ) {
    this.languageService.init();

  }

  async ngOnInit() {
    StoreServiceLibrary.securityDevice = 'browser';
    this.useJwtToken = this.data.getJwtUse();
    AlleCoreRest.isPlatformBrowser = true;
    this.initService.isInitializedBrand.subscribe(isInitialized => {
      if (isInitialized) {
        // llamados a funciones que dependen del brand
        this.setLanguage();
        this.loadBrand();
        this.setApplicationId();
        this.setCurrency();
        this.setFavicon();
        this.data.setIframeUrl();
        this.changeSubBrand();
        this.setTheme();
        this.setRegistrationType();
        this.getSSOSessionId();

        //
        //
        //
        /*
        this.getJWTTokenService.setJwtTokenAuth(true);
        this.getJWTTokenService.setJwtTokenCreditCard(true);
        //PETICIONAR EL SERVICIO PARA OBTENER LA IP
        
        this.getGlobalUser().subscribe(
          (info: any) => {
            this.storeLibrary.setIsUniversalLoad(true);
          }, (err) => {
            UtilsResource.NSMarkLogError('Error ip user', err);
            this.storeLibrary.setIsUniversalLoad(true);
          }
        );
        */
        //
        //
        //

        if (environment.production) {
          try {
            const objOribiAnalytics = this.storeLibrary.getJustOneUniversalPropertie(keys.ORIBI_ANALYTICS);
            if (objOribiAnalytics) {
              let objTransformOribir = JSON.parse(objOribiAnalytics);
              this.generalService.oribiAnalytics(objTransformOribir.TOKEN, objTransformOribir.LABEL);
            }
          } catch (err) {
          }
        }
        try {
          const hotJarFunction = this.storeLibrary.getJustOneUniversalPropertie(keys.HOTJAR);
          if (hotJarFunction) {
            const hotJarScript = document.createElement('script');
            hotJarScript.innerHTML = hotJarFunction;
            document.head.appendChild(hotJarScript);
          }

          const url = keysUniversal.ADD_KPI_WEB;
          const kpiWebText = this.storeLibrary.getJustOneUniversalPropertie(url);
          if (kpiWebText) {
            let objTransformkpiWeb = JSON.parse(kpiWebText);
            objTransformkpiWeb.forEach((item, index) => {
              if (item.script) {
                this._general.generateNewElement('script', item.script.text, this._DOCUMENT.head);
              }
              if (item.noscript) {
                this._general.generateNewElement('noscript', item.script.text, this._DOCUMENT.body, item.noscript.type, item.noscript.src);
              }
            });
            //this._general.generateNewElement();
          }
        } catch (err) {
        }

        this.languageService.systemLanguajeChange.subscribe((language) => {
          if (language) {
            this.loadChatBot(language);
            this.LPinit = true;
            const brandLP = this.dataStore.brand;
            if (brandLP !== 'scotia') {
              this.loadChatRouter();
            }
          }
        });

        // fin inicializar brand
        this.isInitializedBrand = true;

        this.scrollEvents();


        // this.trackingManager.initializeGoogleAnalytics(trackId);
        // this.gaConfig.trackingCode = trackId;

        GoogleAnalytics.evaluateCanInitFirebase();
        const configFirebase = this.storeLibrary.getJustOneUniversalPropertie(keys.CONFIG_FIREBASE);
        GoogleAnalytics.initFirebase(configFirebase);

        if (this.dataStore.brand === 'credomatic') {
          this.getCountryUser();
        }
      }
    });
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.rutaActual = evt.urlAfterRedirects.split('?')[0];
      }
    });
    this.languageService.systemLanguajeChange.subscribe((language) => {
      if (language && this.LPinit) {
        this.generalUtil.LPMonitorChangeSection(this.translateBrandForChatBot(language));
      }
    });
    const t = environment.brands[this.dataStore.brand].appName;

    this.title.setTitle(t);
    const book= 'Book on'
    const millions= 'and get millions of options in flights, hotels, car rentals at the best prices and earn miles rewards every time you book travel.'

    this.seo.generateTags({
      title: t,
      keyword: 'Miles Card, rewards, miles, travel, rewards, flights, hotels, car rentals, travel benefits',
      description: book.concat('', t, millions)
    });


    const noFirstLoad = localStorage.getItem('noFirstLoad');
    if (noFirstLoad) {
      localStorage.setItem('noFirstLoad', `${noFirstLoad}`);
    } else {
      localStorage.setItem('noFirstLoad', `${false}`);
    }
  }

  ngAfterContentInit() {
    //if (environment.production) {

    // }
  }

  /*
  getGlobalUser() {
    return new Observable((observer) => {
      this._authService.getGlobalInfoUser(this.useJwtToken).subscribe((info: any) => {
        let ip = '';
        if (info && info.ip) {
          ip = info.ip;
        }else{
          let ipUniversal = this.storeLibrary.getJustOneUniversalPropertie(keysUniversal.IP_UNIVERSAL);
          ip = ipUniversal;
        }
        SecurityObject.setIpGlobalUser(ip);
        observer.next(ip);
        observer.complete();
      }, (err) => {
        let ipUniversal = this.storeLibrary.getJustOneUniversalPropertie(keysUniversal.IP_UNIVERSAL);
        SecurityObject.setIpGlobalUser(ipUniversal);
        UtilsResource.NSMarkLogError('Error ip user', err);
        observer.error();
        observer.complete();
      });
    });
  }
  */

  setLanguage() {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['lang'] || params['language']) {
        let lang = environment.brands[this.dataStore.brand].defaultLanguaje;
        let haveTwoLanguage = environment.brands[this.dataStore.brand].config['hasTwoLanguages'];
        if (haveTwoLanguage) {
          let languageURL = params['lang'] || params['language'];
          this.translate.use(languageURL);
          this.storeLibrary.setItemInStorage('lang', languageURL);
          this.languageService.setSystemLanguaje(languageURL);
          this.actionEventService.changeSelectorLanguaje(languageURL);
        } else {
          this.translate.use(lang);
          this.storeLibrary.setItemInStorage('lang', lang);
          this.languageService.setSystemLanguaje(lang);
          this.actionEventService.changeSelectorLanguaje(lang);
        }
      }
    });
  }


  getSSOSessionId() {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['ssoSessionId']) {
        const ssoSessionId = params['ssoSessionId'];
        this.scotiaServiceImplementation.decrypSsoId(ssoSessionId, this.useJwtToken).subscribe((ssoId) => {
          if (ssoId) {
            this.sessionExternal(ssoId);
            // this.openGetCard();
          }
        }, (err) => {
          UtilsResource.NSMarkLogError('SSO SESSIONID ERROR', err);
        });
      }

    });

  }

  sessionExternal(ssoSessionId) {
    const channelId = this.storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_channel_id_mr) !== '' ? this.storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_channel_id_mr) : this.storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_channel_id);
    const authCode = '';
    // cambar al web
    const headerType = 'Online';
    const clienteId = this.storeLibrary.getJustOneUniversalPropertie(keysUniversal.scotia_auth_api_security_auth_sso_client_id_Web);
    let userData: UserData = this.storeLibrary.getUserData();

    // aca - scotiabank
    this.scotiaServiceImplementation.session(channelId, authCode, clienteId, ssoSessionId, headerType, this.useJwtToken).subscribe(
      (response: SessionResponse) => {
        if (response.operationResult && response.operationResult.code && response.operationResult.code === '200') {
          let authToken = response.authenticationToken;
          this.data.token = authToken;
          if (userData) {
            userData.authenticationToken = authToken;
            if (userData.accountSumaryDto) {
              userData.accountSumaryDto.firstName = response.firstName;
              userData.accountSumaryDto.lastName1 = response.lastName;
            } else {
              userData.accountSumaryDto = new AccountSumaryDto();
              userData.accountSumaryDto.firstName = response.firstName;
              userData.accountSumaryDto.lastName1 = response.lastName;
            }
          } else {
            userData = new UserData();
            userData.accountSumaryDto = new AccountSumaryDto();
            userData.authenticationToken = authToken;
            userData.accountSumaryDto.firstName = response.firstName;
            userData.accountSumaryDto.lastName1 = response.lastName;

          }


          let idAplication = environment.brands[this.dataStore.brand].applicationId;


          this.storeLibrary.setIdAplication(Number(idAplication));
          this.storeLibrary.setUserData(userData);
          this.data.saveToken(authToken);
          this.openGetCard(authToken);

        } else {
          this.errorModal(response.operationResult.message);
        }
      }, (err) => {
        let message;
        this.translate.get('ALERT.ERROR_BOOKING.boddy').subscribe(translate => message = translate);
        this.errorModal(message);
      });
  }

  openGetCard(authToken) {
    const dialogRef = this.dialog.open(ScotiaCardsComponent, {
      panelClass: 'onepocket-scotia',
      id: 'scotia-cards',
      disableClose: true
    });

    this.loadingScreenService.startLoading();

    dialogRef.afterClosed().subscribe(() => {
      this.data.token = authToken;
      this.actionEventService.sendLoguedUserNotify(true);
    });

  }

  getCountryUser() {
    let smartProfile = JSON.parse(sessionStorage.getItem('smartProfile'));
    let objectcountry = {
      code: 'CRI',
      name: 'Costa Rica',
      classCountry: `${this.countrysClass}-CRI`
    };
    let countryCode = '';
    let typeValidateCredomatic: Boolean;

    this.bacService.getCountryUser(this.useJwtToken).subscribe((resp) => {

      if (smartProfile) {
        if (smartProfile && smartProfile.accountResultDto && smartProfile.accountResultDto.isoCountyCard) {
          countryCode = smartProfile.accountResultDto.isoCountyCard;
          objectcountry.code = countryCode;
          objectcountry.classCountry = `${this.countrysClass}-${countryCode}`
          typeValidateCredomatic = false;
        } else {
          if (smartProfile.accountResultDto) {
            countryCode = smartProfile.accountResultDto.isoCountyCard;
            objectcountry.code = countryCode;
            objectcountry.classCountry = `${this.countrysClass}-${countryCode}`
            typeValidateCredomatic = false;
          } else {
            smartProfile.accountResultDto = new AccountResultDto();
            smartProfile.accountResultDto.isoCountyCard = resp['country_code'];
            countryCode = resp['country_code'];
            typeValidateCredomatic = true;
          }
        }
      } else {
        smartProfile = new AuthGetAccount2Response();
        smartProfile.accountResultDto = new AccountResultDto();
        smartProfile.accountResultDto.isoCountyCard = resp['country_code'];
        countryCode = resp['country_code'];
        typeValidateCredomatic = true;
      }

      const validateUserCountry = this.validateCountryUser(countryCode, typeValidateCredomatic);

      if (validateUserCountry.countryUserToPrint) {
        let countryUserConfigured = validateUserCountry.objectToPrint;
        smartProfile.accountResultDto.isoCountyCard = validateUserCountry.objectToPrint['code'];
        this.actionEventService.sendCountryCode(countryUserConfigured);
        this.data.saveSmartProfile(smartProfile);
        this.storeLibrary.setItemInStorage('CountrySelected', validateUserCountry.objectToPrint['code']);
        GoogleAnalytics.setCountryUser(validateUserCountry.objectToPrint['name']);
      } else {
        const objectcountryNone = {
          code: 'CRI',
          name: 'Costa Rica',
          classCountry: `${this.countrysClass}-CRI`
        };
        this.actionEventService.sendCountryCode(objectcountryNone);
        smartProfile.accountResultDto.isoCountyCard = objectcountryNone.code;
        this.data.saveSmartProfile(smartProfile);
        this.storeLibrary.setItemInStorage('CountrySelected', objectcountryNone.code);
        GoogleAnalytics.setCountryUser(objectcountryNone['name']);
      }
    }, (err) => {
      UtilsResource.NSMarkLogError('GET COUNTRY USER ERROR', err);
    });

  }

  validateCountryUser(countryUser, typeValidateCredomatic) {
    let jsonCountries: any;

    if (typeValidateCredomatic) {
      jsonCountries = this.storeLibrary.getJustOneUniversalPropertie('_JSON_COUNTRIES_ALLETRAVEL_GEO_PLUGIN');
    } else {
      jsonCountries = this.storeLibrary.getJustOneUniversalPropertie('_JSON_COUNTRIES_ALLETRAVEL');
    }

    if (jsonCountries) {
      let castCountries = JSON.parse(jsonCountries);


      let countries = castCountries['countries'];
      let countryUserToPrint: boolean = false;

      let objConfigurationsUser = {
        countryUserToPrint: countryUserToPrint,
        objectToPrint: {}
      };

      countries.map((country) => {
        if (country.code == countryUser) {
          country.code = country.codeCountryBac;
          let objectToPrint = country;
          countryUserToPrint = true;
          objConfigurationsUser.objectToPrint = objectToPrint;
          objConfigurationsUser.countryUserToPrint = countryUserToPrint;
          return true;
        }
      });
      if (countryUserToPrint) {
        return objConfigurationsUser;
      } else {
        return objConfigurationsUser;
      }
    }
  }




  errorModal(message) {
    this.loadingScreenService.stopLoading();
    const heightPadding = this.dataStore.isMobileDevice ? '0px' : '40px';
    const widthPadding = this.dataStore.isMobileDevice ? '0px' : '16vw';
    const dialogRef = this.dialog.open(AlertErrorComponent, {
      maxWidth: '50vw',
      width: 'calc(50vw - ' + widthPadding + ')',
      height: 'calc(50vh - ' + heightPadding + ')',
      minHeight: '59vh',
      data: {
        tittle: 'ALERT.ERROR_BOOKING.title',
        error: `${message}`
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      if (this.data.token) {
        this.loginService.logout();
      }
    });
  }
  /**
   * @method loadBrand configurar todo lo que se configura por el brand
   */
  loadBrand() {
    this.actionEventService.changeBrandDynamic.subscribe((brand) => {
      this.brand = brand;
      this.dataStore.brand = brand;
      this.setTheme();
      this.setApplicationId();
      this.setCurrency();
      this.setFavicon();
      this.data.setIframeUrl();
      this.setRegistrationType();
      let titleBrand = environment.brands[this.dataStore.brand].appName;
      this.title.setTitle(titleBrand);
      window.location.reload();
      // this.router.navigateByUrl('/home');
    });
  }

  /**
   * @method scrollEvents establece el home en la posicion inicial
   */

  scrollEvents() {
    this.actionEventService.redirectHome.subscribe((isHomeScroll) => {
      if (isHomeScroll) {
        window.scrollTo(0, 0);
      }
    });
  }

  /**
   * @method loadChatRouter cuando recibe true va a mostrar el chat de liveperson
   */
  loadChatRouter() {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params.openchat === 'true') {
        let element: HTMLElement = document.getElementsByClassName('LPMimage')[0] as HTMLElement;
        element.click();
      }
    });
  }


  /**
  //  * cargar chatbot (solo se hace en web y responsive), se oculta en el iframe
  //  */
  private loadChatBot(language: string) {
    setTimeout(() => {
      // esperamos un momento para ver si hay que mostrar o no el boton del chatbot
      if (!this.ocultadorService.ocultar && !this.router.url.includes('/travel-app')) {
        // TODO: add key univerlsa web and all applications key
        UtilsResource.ShowLog('*** AppComponent - loadChatBot', this.storeLibrary.getJustOneUniversalPropertie('_LIVE_PERSON_ACCOUNT_WEB'), language);
        this.generalUtil.initLivepersonMonitor(
          this.storeLibrary.getJustOneUniversalPropertie('_LIVE_PERSON_ACCOUNT_WEB'),
          this.translateBrandForChatBot(language)
        );
      }
    }, 1000);
  }

  /**
   * set la unidad de la plata
   */
  private setCurrency() {
    this.data.saveBrandTargetCurrency(environment.brands[this.dataStore.brand].targetCurrency);
  }

  /**
   * set app ID en el store library de novae
   */
  private setApplicationId() {
    const idAplication = environment.brands[this.dataStore.brand].applicationId;
    this.data.idApplication = idAplication;
    StoreServiceLibrary.application = this.data.idApplication;
    this.storeLibrary.setIdAplication(Number(idAplication));
  }

  private setFavicon() {
    const routeFavicon = environment.brands[this.dataStore.brand].favicon_route;
    this._DOCUMENT
      .getElementById('appFavicon')
      .setAttribute('href', routeFavicon);
  }

  private setRegistrationType() {
    // environment.registrationType[this.dataStore.brand];
    this.data.registrationType = environment.brands[this.dataStore.brand].registrationType;
  }

  /**
   * hoja de estilos dinámica según brand
   */
  private setTheme() {
    if (!this.dataStore.creditCardDefult) {
      this._DOCUMENT.getElementById('brand-theme').setAttribute('href', 'assets/brand/styles/' + this.dataStore.brand + '.css');
    } else {
      let isSubBrand = environment.brands[this.dataStore.brand].subBrand;
      if (isSubBrand) {
        this._DOCUMENT.getElementById('brand-theme').setAttribute('href', 'assets/brand/styles/' + this.dataStore.brand + '-'
          + this.dataStore.creditCardDefult
          + '.css');
      } else {
        this._DOCUMENT.getElementById('brand-theme').setAttribute('href', 'assets/brand/styles/' + this.dataStore.brand + '.css');
      }
    }
  }

  changeSubBrand() {
    this.actionEventService.changeClassAmexSubject.subscribe((subbrand: string) => {
      if (subbrand === 'default') {
        this._DOCUMENT.getElementById('brand-theme').setAttribute('href', 'assets/brand/styles/' + this.dataStore.brand + '.css');
      } else {
        this._DOCUMENT.getElementById('brand-theme')
          .setAttribute('href', 'assets/brand/styles/' + `${this.dataStore.brand}-${subbrand}` + '.css');
      }
    });
  }

  private translateBrandForChatBot(language: string): string {
    switch (this.dataStore.brand) {
      case 'ncb':
        return this.dataStore.brand + '-' + language;
      case 'firstcitizens':
        return 'fc-' + language;
      case 'credomatic':
        return 'bac';
      default:
        return this.dataStore.brand;
    }
  }
}
