import { CardLayoutThreeModel } from 'src/app/component/card/models/card-layout-three.model';
import { ActivityOption } from './activity-option.model';
import { CardInfo, CardImage } from 'src/app/component/card/models/card-layout-two.model';
import { CardPrice } from 'src/app/component/card/models/card-price.model';
import { Slider } from 'src/app/component/slider-op/model/slider';

export interface ActivityAvailabilityResponse {
    results: ActivityResult[];

}

export class ActivityResult implements CardLayoutThreeModel {
    constructor(
        public id: string | number,
        public name: string,
        public address?: string,
        public description?: string,
        public info?: CardInfo[],
        public images?: CardImage[],
        public price?: CardPrice,

        public startDate?: string,
        public endDate?: string,
        public options?: ActivityOption[],
        public selectedOption?: ActivityOption,

        public bookingRules?: BookingRules
    ) {  }

    static toArray(values: ActivityResult[]): ActivityResult[] {
        values.forEach((v, i) => {
            // Formatear data de habitaciones para mostrar en CardLayout
            v.options = ActivityOption.toArray(v.options, v.info);

            // Setear option[0] como preseleccionada
            v.selectedOption = v.options[0];
            v.price = v.options[0].price;

            values[i] = new ActivityResult(
                v.id,
                v.name,
                v.address,
                v.description,
                v.info,
                v.images,
                v.price,
                v.startDate,
                v.endDate,
                v.options,
                v.selectedOption
            );
        });
        return values;
    }

    nameSelected = () => ( this.selectedOption.name );
    subnameSelected = () => ( null ); // this.selectedOption.name
    getMoneyAmount = () => (
        (!this.selectedOption.selectedDate.price.calculatedPrice && this.selectedOption.selectedDate.price.pointsPrice)
        || this.selectedOption.selectedDate.price.calculatedPrice
        ? this.selectedOption.selectedDate.price.calculatedPrice : this.selectedOption.selectedDate.price.totalPrice )
    getPointAmount = () => ( this.selectedOption.selectedDate.price.pointsPrice ? +this.selectedOption.selectedDate.price.pointsPrice : 0 );
    refundable = () => (
        !this.selectedOption.cancellationPolicy ? null : this.selectedOption.cancellationPolicy.refundable === 'Y' ? true : false )

    priceConversion = (conversionRate, percent, userPoints) => {
        this.options = Slider.getPriceConversion(this.options, conversionRate, percent, userPoints);
    }

    get ratePlanCode() {
        return this.selectedOption.selectedDate.ratePlanCode;
    }
}

export interface BookingRules {
    warnings: Warning[];
    totalPrice: number;
    providerTotalPrice: string;
    bookingCode: string;
    serviceCode: string;
    startDate: string;
    meetingPoints: MeetingPoint[];
    cancellationPolicy: any;
}

export interface Warning {
    type: string;
    description: string;
}

export interface MeetingPoint {
    time: string;
    name: string;
    address: string;
    code: string;
}
