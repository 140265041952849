import { MainFormModule } from './../main-form/main-form.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card/card.component';
import { CardHeaderComponent } from './card/card-header/card-header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalComponent } from './modal/modal.component';
import { SrcSanitizerPipe } from './custom-pipes/src-sanitizer.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CardBodyComponent } from './card/card-body/card-body.component';
import { CardImageComponent } from './card/card-body/card-image/card-image.component';
import { CardDetailComponent } from './card/card-body/card-detail/card-detail.component';
import { CardPriceComponent } from './card/card-body/card-price/card-price.component';
import { SliderComponent } from '../../component/slider/slider.component';
import { NumberToArrPipe } from './custom-pipes/number-to-arr.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { AbstractCardListComponent } from './abstract-card-list/abstract-card-list.component';
import { AbstractCardComponent } from './abstract-card-list/abstract-card/abstract-card.component';
import { AbstractCardHeaderComponent } from './abstract-card-list/abstract-card/abstract-card-header/abstract-card-header.component';
import { AbstractCardBodyComponent } from './abstract-card-list/abstract-card/abstract-card-body/abstract-card-body.component';
import {
  AbstractCardImageComponent
} from './abstract-card-list/abstract-card/abstract-card-body/abstract-card-image/abstract-card-image.component';
import {
  AbstractCardDetailComponent
} from './abstract-card-list/abstract-card/abstract-card-body/abstract-card-detail/abstract-card-detail.component';
import {
  AbstractCardPriceComponent
} from './abstract-card-list/abstract-card/abstract-card-body/abstract-card-price/abstract-card-price.component';
import { FiltersModule } from './filters/filters.module';
import { KeyboardFillerComponent } from '../../component/keyboard-filler/keyboard-filler.component';
import { TermsAndConditionsComponent } from '../../component/terms-and-conditions/terms-and-conditions.component';
import { TranslateModule } from '@ngx-translate/core';
import { LoginComponent } from './login/login.component';
import { MaterialModule } from 'src/app/models/material';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AlertSessionComponent } from './alert-session/alert-session.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { BookingAlertComponent } from './booking-alert/booking-alert.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ScotiaCardsComponent } from '../../component/scotia-cards/scotia-cards.component';
import { WarningModalComponent } from '../../component/warning-modal/warning-modal.component';
import { CredomaticCardsComponent } from '../../component/credomatic-cards/credomatic-cards.component';
import { CertificateLearnMoreComponent } from '../../component/certificate-learn-more/certificate-learn-more.component';
import { ModalQuestionComponent } from '../../component/modal/modal-question/modal-question.component';
import { WebsiteComponent } from '../../component/website/website.component';
import { DetailsNewsComponent } from '../../component/news/details-news/details-news.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    NgSelectModule,
    FiltersModule,
    TranslateModule.forChild(),
    MaterialModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatRippleModule,
    MatIconModule,
    PipesModule,
    RouterModule,
    MainFormModule,
    NgxDaterangepickerMd.forRoot(),
  ],
  exports: [
    CardComponent,
    CardHeaderComponent,
    CardImageComponent,
    CardDetailComponent,
    CardPriceComponent,
    ModalComponent,
    SrcSanitizerPipe,
    SliderComponent,
    AbstractCardListComponent,
    KeyboardFillerComponent,
    ModalQuestionComponent,
    WebsiteComponent,
    DetailsNewsComponent
  ],
  declarations: [
    CardComponent,
    CardHeaderComponent,
    CardImageComponent,
    CardDetailComponent,
    CardPriceComponent,
    ModalComponent,
    CardBodyComponent,
    SrcSanitizerPipe,
    SliderComponent,
    NumberToArrPipe,
    AbstractCardListComponent,
    AbstractCardComponent,
    AbstractCardHeaderComponent,
    AbstractCardBodyComponent,
    AbstractCardImageComponent,
    AbstractCardDetailComponent,
    AbstractCardPriceComponent,
    KeyboardFillerComponent,
    TermsAndConditionsComponent,
    LoginComponent,
    AlertSessionComponent,
    BookingAlertComponent,
    ScotiaCardsComponent,
    WarningModalComponent,
    CredomaticCardsComponent,
    CertificateLearnMoreComponent,
    ModalQuestionComponent,
    WebsiteComponent,
    DetailsNewsComponent
  ],
  entryComponents: [
    TermsAndConditionsComponent,
    LoginComponent,
    AlertSessionComponent,
    BookingAlertComponent,
    ScotiaCardsComponent,
    WarningModalComponent,
    CredomaticCardsComponent,
    CertificateLearnMoreComponent,
    ModalQuestionComponent,
    WebsiteComponent,
    DetailsNewsComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
