import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationComponent } from './confirmation.component';
import { ConfirmationHotelComponent } from './confirmation-hotel/confirmation-hotel.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationFlightComponent } from './confirmation-flight/confirmation-flight.component';
import { ConfirmationBenefitsComponent } from './confirmation-benefits/confirmation-benefits.component';
import { ConfirmationCarComponent } from './confirmation-car/confirmation-car.component';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationActivityComponent } from './confirmation-activity/confirmation-activity.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { MaterialModule } from 'src/app/models/material';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    TranslateModule.forChild(),
    PipesModule,
    MaterialModule
  ],
  declarations: [
    ConfirmationComponent,
    ConfirmationHotelComponent,
    ConfirmationFlightComponent,
    ConfirmationBenefitsComponent,
    ConfirmationCarComponent,
    ConfirmationActivityComponent
  ],
  exports: [
    ConfirmationComponent
  ]
})
export class ConfirmationModule { }
