import { CardLayoutThreeComponent } from './../component/card/layouts/card-layout-three/card-layout-three.component';
import { CardLayoutTwoComponent } from './../component/card/layouts/card-layout-two/card-layout-two.component';
import { EProduct } from './../component/searched/models/searched.model';
import { CardLayoutOneComponent } from './../component/card/layouts/card-layout-one/card-layout-one.component';
import { Directive, ViewContainerRef, OnInit, Input, OnChanges,
        ComponentFactoryResolver, Output, EventEmitter, ComponentFactory, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appShowProduct]',
})
export class ShowProductDirective implements OnInit, OnChanges, OnDestroy {

  @Input() product: any;
  @Input() options: any;
  @Output() getProductDetail = new EventEmitter();
  @Output() layoutOptionRadio = new EventEmitter();
  @Output() layoutDropDown = new EventEmitter();

  componentRef: any;

  subscription: Subscription;
  subscriptionOption: Subscription;
  subscriptionSelect: Subscription;
  constructor(
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    this.setComponentToShow();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.componentRef && (changes.options || changes.product)) {
      (this.componentRef.instance as CardLayoutOneComponent | CardLayoutTwoComponent | CardLayoutThreeComponent).product = this.product;
      (this.componentRef.instance as CardLayoutOneComponent | CardLayoutTwoComponent | CardLayoutThreeComponent).options = this.options;
    }
  }

  setComponentToShow() {
    /**
     * Instaniar ComponentFactory segun el tipo de tarjeta a mostrar
    */
    let componentFactory: ComponentFactory<any>;
    switch (this.options.type) {
      case EProduct.FLIGHTS:
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(CardLayoutOneComponent);
        break;
      case 'layout-three':
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(CardLayoutThreeComponent);
        break;
      // case EProduct.CARS:
      //   // componentFactory = this.componentFactoryResolver.resolveComponentFactory(CardLayoutTwoComponent);
      //   break;
      default:
        componentFactory = this.componentFactoryResolver.resolveComponentFactory(CardLayoutTwoComponent);
        break;
    }

    // Crear componente y asignar valor del @Input()
    this.componentRef = this.viewContainerRef.createComponent(componentFactory);
    (this.componentRef.instance as CardLayoutOneComponent | CardLayoutTwoComponent | CardLayoutThreeComponent).product = this.product;
    (this.componentRef.instance as CardLayoutOneComponent | CardLayoutTwoComponent | CardLayoutThreeComponent).options = this.options;

    // Subscribirse al @Output() getProductDetail
    if (this.componentRef.instance.getProductDetail) {
      this.subscription = this.componentRef.instance.getProductDetail.subscribe(output => {
        this.getProductDetail.emit(output);
      });
    } else if (this.options.type === 'layout-three') {
      this.subscriptionOption = this.componentRef.instance.dropDown.subscribe(output => {
        this.layoutDropDown.emit(output);
      });
      this.subscriptionSelect = this.componentRef.instance.optionRadio.subscribe(output => {
        this.layoutOptionRadio.emit(output);
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }
}
