<div class="container">
    <div class="modal-form-container">
        <h1 class="title">
            {{'LOGIN.STATES.choose_password'|translate}}
        </h1>
        <form [formGroup]="formConfirmation" *ngIf="initialized">
            <div class="personal">
                <div class="input" *ngIf="!hasFirstName"
                    [class.error]="formConfirmation.get('firstName').touched && formConfirmation.get('firstName').invalid">
                    <input type="text" formControlName="firstName"
                        autocomplete="off"
                        placeholder="{{'LOGIN.name'|translate}}">
                </div>
                <div class="input" *ngIf="!hasLastName"
                    [class.error]="formConfirmation.get('lastName').touched && formConfirmation.get('lastName').invalid">
                    <input type="text" formControlName="lastName"
                        autocomplete="off"
                        placeholder="{{'LOGIN.last_name'|translate}}">
                </div>
                <div class="input" matTooltip="{{'LOGIN.password_format'|translate}}"
                    [class.error]="(formConfirmation.get('password').touched && formConfirmation.get('password').invalid) || this.formConfirmation.hasError('password_not_match')">
                    <mat-icon>lock</mat-icon>
                    <input #password type="password" formControlName="password"
                        autocomplete="off"
                        placeholder="{{'LOGIN.password'|translate}}">
                    <mat-icon (touchstart)="password.type='text'"
                        (touchend)="password.type='password'"
                        (mousedown)="password.type='text'"
                        (mouseup)="password.type='password'"
                        (mouseleave)="password.type='password'">
                        {{password.type=='text' ? 'visibility_off' : 'visibility'}}
                    </mat-icon>
                </div>
                <div *ngIf="this.formConfirmation.get('password').hasError('password_length')"
                    class="error">
                    {{'LOGIN.password_length'|translate}}
                </div>
                <div *ngIf="this.formConfirmation.get('password').hasError('password_safe')"
                    class="error">
                    {{'LOGIN.password_format'|translate}}
                </div>
                <div class="input"
                    [class.error]="(formConfirmation.get('password_confirm').touched && formConfirmation.get('password_confirm').invalid) || this.formConfirmation.hasError('password_not_match')">
                    <mat-icon>lock</mat-icon>
                    <input #password_confirm type="password"
                        formControlName="password_confirm" autocomplete="off"
                        placeholder="{{'LOGIN.password_confirm'|translate}}">
                    <mat-icon (touchstart)="password_confirm.type='text'"
                        (touchend)="password_confirm.type='password'"
                        (mousedown)="password_confirm.type='text'"
                        (mouseup)="password_confirm.type='password'"
                        (mouseleave)="password_confirm.type='password'">
                        {{password_confirm.type=='text' ? 'visibility_off' : 'visibility'}}
                    </mat-icon>
                </div>
                <div *ngIf="this.formConfirmation.hasError('password_not_match')"
                    class="error">
                    {{'LOGIN.password_not_match'|translate}}
                </div>
            </div>
            <div class="confirmar">
                <button mat-button (click)="next()"
                    [disabled]="nextButtonDisabled || loading">
                    {{'LOGIN.confirm'|translate}}
                </button>
            </div>
        </form>
    </div>

</div>