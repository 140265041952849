import { RentaCarBodyComponent } from './renta-car-body.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { MainFormModule } from '../main-form/main-form.module';
import { FiltersModule } from '../shared/filters/filters.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    RentaCarBodyComponent
  ],
  imports: [
    TranslateModule.forChild(),
    CommonModule,
    SharedModule,
    MainFormModule,
    FiltersModule,
  ],
  exports: [
    RentaCarBodyComponent,
  ]
})
export class RentaCarModule { }
