import { InitService } from './../../../services/init.service';
import { DataStoreService } from 'src/app/services/data-store.service';
import { DataService } from 'src/app/services/data.service';
import { Component, OnInit, Input } from '@angular/core';
import { StoreServiceLibrary, ProductOfferService, AccountSumaryDto } from '@wearenovae/novae-core-services';
import { formatDate } from '@angular/common';
import { locale } from 'moment';
import { UtilsResource } from 'src/app/resources/UtilsResource.resource';

@Component({
  selector: 'app-active-benefit',
  templateUrl: './active-benefit.component.html',
  styleUrls: ['./active-benefit.component.scss']
})
export class ActiveBenefitComponent implements OnInit {

  @Input() user: AccountSumaryDto;
  public benefit: any;

  get startDate() {
    return formatDate(this.benefit.insurance.startDate, 'dd/MM/yyyy', locale()).toString();
  }

  get expirationDate() {
    return formatDate(this.benefit.insurance.expirationDate, 'dd/MM/yyyy', locale()).toString();
  }

  constructor(
    private _storeLibrary: StoreServiceLibrary,
    private _productOfferService: ProductOfferService,
    public dataStore: DataStoreService,
    private initService: InitService,
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.initService.isInitializedBrand.subscribe(isInitialized => {
      if (isInitialized) {
        this.getUserBenefit();
      }
    });
  }

  getUserBenefit() {
    if (this.dataService.smartProfile
      && this.dataService.smartProfile.accountResultDto
      && this.dataService.smartProfile.accountResultDto.accountSumaryDto) {
      const userData = this._storeLibrary.getUserData();
      userData.accountSumaryDto = this.dataService.smartProfile.accountResultDto.accountSumaryDto;
      userData.authenticationToken = this.dataService.token;
      this._storeLibrary.setUserData(userData);
    }
    this.user = this.dataService.smartProfile.accountResultDto.accountSumaryDto;
    this.dataService.sendRequestProductByUser().subscribe(userProduct => {
      this.benefit = userProduct;
    }, error => {
      UtilsResource.NSMarkLogError('GET USER BENEFIT ERROR', error);
    });
  }

}
