import { Component, OnInit } from '@angular/core';

/**
 * agrega un espacio (height) a la aplicación, para compenzar el tamaño del teclado cuando este aparece en pantalla
 * de esta manera el usuario puede scrollear en la pantalla para que la aplicación no sea
 * tapada por el teclado.
 */
@Component({
  selector: 'app-keyboard-filler',
  templateUrl: './keyboard-filler.component.html',
  styleUrls: ['./keyboard-filler.component.scss']
})
export class KeyboardFillerComponent implements OnInit {

  /**
   * alto del viewport al momento de renderizar el componente
   * (osea cuando el teclado está cerrado)
   */
  private _initClientHeight = 0;
  keyboardHeightCalculated = 0;

  constructor() { }

  ngOnInit() {
    this._initClientHeight = document.documentElement.clientHeight;
  }

  onResize() {
    // si cambia el clientHeight, se asume que es porque el teclado aparece en pantalla
    // en este caso se aumenta el height del componente para compenzar el tamaño
    // ocupado por el teclado
    this.keyboardHeightCalculated = this._initClientHeight - document.documentElement.clientHeight;
  }
}
