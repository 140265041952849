<div id="segment-detail" class="segment-detail" [class.show]="show">

    <app-detail-header
        [index] = "index"
        [flight]="flight">
    </app-detail-header>

    <div class="">
        <div class="general">

            <div class="item-left">
                <app-detail-date
                    [flight]="flight">
                </app-detail-date>
            </div>

            <div class="item-right">
                <app-detail-route
                    *ngFor="let segment of flight.segments; let i = index; let l = last;"
                    [segment] = "segment"
                    [isLastSegment]="l">
                </app-detail-route>
            </div>

        </div>
    </div>

</div>