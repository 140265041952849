import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopUpDetailComponent } from '../pop-up-detail/pop-up-detail.component';
import { DataStoreService } from '../../../services/data-store.service';
import { IBenefitResponse } from 'src/app/models/benefitResponce-model';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-benefit-item',
  templateUrl: './benefit-item.component.html',
  styleUrls: ['./benefit-item.component.scss']
})
export class BenefitItemComponent implements OnInit {

  @Input() item: IBenefitResponse;

  // fechaInicioBeneficiarios: string;
  // fechaFinBeneficiarios: string;

  constructor(
    public dialog: MatDialog,
    // private dataStore: DataStoreService,
    public data: DataService
  ) { }

  openBenefitDialog(): void {
    const dialogRef = this.dialog.open(PopUpDetailComponent, {
      data: this.item
    });

    dialogRef.afterClosed().subscribe(result => { });
  }

  ngOnInit() {
    // this.fechaInicioBeneficiarios = this.dataStore.searchBenefits.fechaInicioBeneficiarios;
    // this.fechaFinBeneficiarios = this.dataStore.searchBenefits.fechaFinBeneficiarios;
  }
}
