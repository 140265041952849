import { Component, OnInit } from '@angular/core';
import { AbstractCardComponent } from '../../abstract-card.component';
import { GenericCard } from '../../../../../../models/genericCard/generic-card';
import { RentacarAvailabilityOption } from 'src/app/models/rentacar/response/rentacar-availability-option';
import { LanguageService } from 'src/app/component/language/language.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-abstract-card-detail',
  templateUrl: './abstract-card-detail.component.html',
  styleUrls: ['./abstract-card-detail.component.scss']
})
export class AbstractCardDetailComponent implements OnInit {


  options: any;
  product: GenericCard;

  private carDetailsTranslation: string[];

  constructor(private languageService: LanguageService,
    private translateService: TranslateService,
    public cardCmp: AbstractCardComponent
  ) { }

  ngOnInit() {
    this.options = this.cardCmp.options;
    this.product = this.cardCmp.product;
    this.languageService.systemLanguajeChange.subscribe((language) => {
      if (language) {
        this.getTranslations();
      }
    });
  }

  getACRISSValues(): string[] {
    const carClassification = (this.product as RentacarAvailabilityOption).carClassification;
    const keys = Object.keys(carClassification);
    let result: string[] = [];
    keys.forEach(key => {
        if ( typeof carClassification[key] === "boolean" ) {
          if (carClassification[key]) {
            if (key === "air") {
              result.push(this.carDetailsTranslation[key]);
            }
          }
        } else {
          result.push(this.carDetailsTranslation[key] + ': ' + carClassification[key]);
        }
      } 
    );
    return result;
  }

  private getTranslations() {
    return new Promise<any>((resolve, reject) => {
      this.translateService.get([
        'CARD',
        'CARD.CAR_DETAILS'
      ]).subscribe((trans) => {
        this.carDetailsTranslation = trans['CARD.CAR_DETAILS'];
        resolve(true);
      });
    });
  }

}
