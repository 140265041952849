
import firebase from 'firebase/app';
import 'firebase/analytics';
import { environment } from '../../environments/environment';
import { UtilsResource } from './UtilsResource.resource';

export class GoogleAnalytics {

    static canAnalytics: boolean = false;

    static evaluateCanInitFirebase() {
        const brand = sessionStorage.getItem('brand');
        const hasFirebase = environment.brands[brand]['config']['hasFirebase'];
        if (hasFirebase) {
            this.canAnalytics = true;
        }
    }

    static initFirebase(configFirebase) {
        if (this.canAnalytics) {
            let firebaseConfig = {};

            if (configFirebase) {
                firebaseConfig = JSON.parse(configFirebase);
            }

            // Initialize Firebase

            const objFirebase = firebase.initializeApp(firebaseConfig);
            firebase.analytics();
            return objFirebase;
        }
    }


    static sendEventFirebase(eventName: string, category: string, actionGtm: string, label: string) {
        if (this.canAnalytics) {
            firebase.analytics().logEvent(eventName, {
                eventGtm: eventName,
                categoryGtm: category,
                actionGtm: actionGtm,
                labelGtm: label
            });
        }
    }

    static setCurrentScreen(namePage: string) {
        if (this.canAnalytics) {
            firebase.analytics().setCurrentScreen(namePage);
        }
    }

    static setCountryUser(country: string) {
        if (this.canAnalytics) {
            firebase.analytics().setUserProperties({ Country: country });
        }
    }

    static clearLocationToAnalytics(tmpLocation: any) {
        try {
            let tmpLoc: any = {};

            tmpLoc.ancestorOrigins = tmpLocation.ancestorOrigins;
            tmpLoc.href = tmpLocation.href;
            tmpLoc.origin = tmpLocation.origin;
            tmpLoc.host = tmpLocation.host;
            tmpLoc.pathname = tmpLocation.pathname;
            tmpLoc.search = tmpLocation.search;

            return tmpLoc;
        } catch (error) {
            UtilsResource.NSMarkLogError('Error clearLocationToAnalytics', error);
        }
    }

}
