import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BenefitCardComponent } from './benefit-card.component';
import { BenefitItemComponent } from './benefit-item/benefit-item.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatGridListModule } from '@angular/material/grid-list';
import { PostMessageDirective } from '../../services/post-message.directive';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '../shared/shared.module';

import { PopUpDetailComponent } from './pop-up-detail/pop-up-detail.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSliderModule } from "@angular/material/slider";
import { FiltersModule } from '../shared/filters/filters.module';
import { TranslateModule } from '@ngx-translate/core';
import { ActiveBenefitComponent } from './active-benefit/active-benefit.component';
import { BenefitDetailComponent } from './active-benefit/benefit-detail/benefit-detail.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
@NgModule({
  declarations: [
    PostMessageDirective,
    BenefitCardComponent,
    BenefitItemComponent,
    PopUpDetailComponent,
    ActiveBenefitComponent,
    BenefitDetailComponent,
  ],
  exports: [
    ActiveBenefitComponent,
  ],
  imports: [
    CommonModule,
    MatRadioModule,
    MatGridListModule,
    BrowserAnimationsModule,
    SharedModule,
    FormsModule,
    MatInputModule,
    MatDialogModule,
    MatExpansionModule,
    MatSliderModule,
    FiltersModule,
    TranslateModule.forChild(),
    PipesModule
  ],
  entryComponents: [
    PopUpDetailComponent
  ]
})
export class BenefitModule { }
