import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-hotel-gallery',
  templateUrl: './hotel-gallery.component.html',
  styleUrls: ['./hotel-gallery.component.scss']
})
export class HotelGalleryComponent implements OnInit {
  @Input() images = [];
  mainImageSrc: string;
  constructor() { }

  ngOnInit() {
    if (this.images === null || this.images.length === 0) {
      this.images = [
        {
          imgURL: './assets/images/empty_room.png',
          label: ''
        }
      ];
    }
    this.mainImageSrc = this.images[0].imgURL;
  }
  clickedImgSrcListener(src) {
    this.mainImageSrc = src;
  }

  onErrorFn() {
    this.mainImageSrc = this.images[0].imgURL;
  }


}
