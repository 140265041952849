import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy, HostListener, Output } from '@angular/core';
import { DataService } from '../../services/data.service';
import { DataStoreService } from '../../services/data-store.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../container/shared/login/login.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../../container/shared/login/login.component';
import { ActionEventService } from '../../services/action-event.service';
import { EProduct } from 'src/app/component/searched/models/searched.model';
import { AlertErrorComponent } from '../../component/product-detail/alert-error/alert-error.component';
import { AuthService, ScotiaImplementationService, StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { environment } from 'src/environments/environment';
import { keysPostMessage } from 'src/app/resources/keys.resources';
import { InitService } from '../../services/init.service';
import { EventEmitter } from 'events';
import { SmartProfileService } from '../../services/smart-profile.service';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalytics } from '../../resources/GoogleAnalytics.resource';
import { ExpireMilesComponent } from '../../component/expire-miles/expire-miles.component';

@Component({
  selector: 'app-landing-my-profile',
  templateUrl: './landing-my-profile.component.html',
  styleUrls: ['./landing-my-profile.component.scss']
})
export class LandingMyProfileComponent implements OnInit, OnDestroy {

  @ViewChild('alle') alleIframe: ElementRef;
  @Output() outputEmiter = new EventEmitter();

  footerCredomatic: boolean;

  public loaded = false;
  private iframeBaseUrl: string;
  private iframeURL: string;
  appName = '';

  brand: string;
  brandEnvironment: string;
  public fixed: boolean;
  useJwtToken: any;
  public paymentStatus: boolean;

  constructor(
    public data: DataService,
    public dataStore: DataStoreService,
    private storeServiceLibrary: StoreServiceLibrary,
    private router: Router,
    private loginService: LoginService,
    private dialog: MatDialog,
    private actionEventService: ActionEventService,
    private activateRouter: ActivatedRoute,
    private initService: InitService,
    private smartProfile: SmartProfileService,
    private translateService: TranslateService,
    private scotiaServiceImplementation: ScotiaImplementationService,
  ) {
    this.initService.isInitializedBrand.subscribe((isInitialized) => {
      if (isInitialized) {
        this.getAccount();
        this.changeLanguage();
      }
    });
  }


  ngOnInit() {
    this.loadIframeAccount();
    this.getBrandRoute();
    this.appName = environment.brands[this.dataStore.brand].appName;
    this.validateNameApp();
    this.brandEnvironment = this.dataStore.brand;
    this.useJwtToken = this.data.getJwtUse();
  }

  loadIframeAccount() {
    this.loaded = false;
    this.iframeURL = this.dataStore.iframeUrl;
    const protocol = window.location.protocol;
    const host = window.location.host;
    this.iframeBaseUrl = protocol + '//' + host;
  }

  // addEventListener support for IE8
  @HostListener('window:message', ['$event'])
  receiveMessage(event) {
    if (event.data.typeMessage === 'USER_LOG_OUT') {
      this.loginService.logout();
    } else if (event.data.typeMessage === 'GO_TO_HOME') {
      // CVP 12 mayo 2021 - Se redirecciona a /travel solo para credomatic cuando OP envie el GO_TO_HOME
      if (this.dataStore.brand == 'credomatic') {
        this.router.navigate(['/travel']);
      } else {
        this.router.navigate(['/home']);
      }
    } else if (event.data.typeMessage === 'REFRESH_USER_POINT') {
      this.getAccount();
      if (this.dataStore.brand === 'scotia') {
        this.data.getInfoCard();
      }
    } else if (event.data.typeMessage === 'SHOW_ERROR_IN_ALLETRAVEL') {
      const idError = event.data.idError;
      let message;
      this.translateService.get('ALERT.ERROR_BOOKING.boddy').subscribe(translate => message = translate);
      this.errorModal(`${message} ${idError}`);
    } else if (event.data.typeMessage === 'TOKEN_SESSION_USER_ERROR') {
      // this.router.navigate(['/home']);
      this.loginService.logout();
      if (this.dataStore.brand !== 'scotia') {
        if (this.dataStore.brand == 'credomatic') {
          this.router.navigate(['/travel']);
        }
        this.dialog.open(LoginComponent, {
          panelClass: 'login-dialog'
        });
      }
    } else if (event.data.typeMessage === 'INIT_MY_ACCOUNT_PAGE') {
      const iframe = document.getElementById('alleIframe');
      const message = { typeMessage: keysPostMessage.tokenMyAccount, token: this.data.token };
      this.sendPostMessage(iframe, message, this.iframeBaseUrl);
      this.loaded = true;
    } else if (event.data.typeMessage === 'TYPE_CREDIT_CARD_DEFUALT') {
      if (this.dataStore.brand === 'scotia') {
        if (event.data.franquice === 3) {
          this.actionEventService.setSubBrand('amex');
          this.data.saveCreditCardDefult('amex');
          this.actionEventService.setChangeFooter(true);
        } else {
          this.actionEventService.setSubBrand('default');
          this.data.saveCreditCardDefult('');
          this.actionEventService.setChangeFooter(true);
        }
      }
    } else if (event.data.typeMessage === 'PAYMENT_RESPONSE_ONEPOCKET') {
      /* POSTMESSAGGE AL COMPLETAR PROCESO DE PAGO DE ONEPOCKET */
      this.onePocketPaymentResponse(event.data);
      if (this.dataStore.brand === 'scotia') {
        this.data.getInfoCard();
      }
    } else if (this.iframeURL && event.data.typeMessage === 'CARD_STORAGE_SAVE_DATA') {
      const dataCard = event.data.objectCard;
      this.data.setDataCard(dataCard);
      this.data.getInfoCard();
    } else if (event.data.typeMessage === 'OPEN_EXTERNAL_URL') {
      this.data.openUrlExternal(event.data.url, event.data.target, event.data.features);
    } else if (event.data.typeMessage === 'TRACKING_DATA') {
      let objTrackingId = {
        category: '',
        action: '',
        label: ''
      }
      if (event.data.objectTracking) {
        objTrackingId = event.data.objectTracking;
      }
      GoogleAnalytics.sendEventFirebase('ga_event', objTrackingId.category, objTrackingId.action, objTrackingId.label);
    } else if (event.data.typeMessage == 'SHOW_MODAL_EXPIRES_MILES') {
      this.dialog.open(ExpireMilesComponent, {
        panelClass: 'login-dialog'
      });
    }
  }

  @HostListener('body:scroll', ['$event'])
  onScrollingEvent($event): void {
    let scrollOffset = $event.target.scrollTop;
    if (scrollOffset > 0) {
      this.fixed = true;
    } else {
      this.fixed = false;
    }
  }



  /*
* ENVIAR RESPUESTA DEL PAGO
*/
  onePocketPaymentResponse(data: any) {
    this.paymentStatus = data.paymentSuccess;
    this.smartProfile.getAccountMethod(this.data.token);
    this.outputEmiter.emit(data);
  }



  validateNameApp() {
    switch (this.dataStore.brand) {
      case 'ncb':
        this.brand = 'app NCB Miles';
        break;
      case 'scotia':
        this.brand = 'app Scotia Rewards';
        break;
      case 'credomatic':
        this.brand = 'Viajes Bac Credomatic';
        break;
      case 'cibc':
        this.brand = 'My Rewards';
        break;
      default:
        this.brand = this.dataStore.brand;
        break;

    }
  }

  errorModal(message) {
    const heightPadding = this.dataStore.isMobileDevice ? '0px' : '40px';
    const widthPadding = this.dataStore.isMobileDevice ? '0px' : '16vw';
    const dialogRef = this.dialog.open(AlertErrorComponent, {
      maxWidth: '50vw',
      width: 'calc(50vw - ' + widthPadding + ')',
      height: 'calc(50vh - ' + heightPadding + ')',
      minHeight: '59vh',
      data: {
        tittle: 'ALERT.ERROR_BOOKING.title',
        error: `${message}`
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      /**
       * Aca se haria la logica para desplguear
       */
      this.data.token = '';
      this.router.navigateByUrl('home');
      this.data.saveCreditCardDefult('');
      this.actionEventService.setSubBrand('default');
      this.actionEventService.setChangeFooter(true);
      this.storeServiceLibrary.clearCardScotiaPointsSelected();
      this.logout();
    });
  }

  async logout() {
    if (this.dataStore.brand === 'scotia') {
      this.data.saveCreditCardDefult('');
      this.actionEventService.setSubBrand('default');
      this.actionEventService.setChangeFooter(true);
      this.storeServiceLibrary.clearCardScotiaPointsSelected();
    }
    await this.loginService.logout();
  }

  changeLanguage() {
    this.translateService.onLangChange.subscribe((data) => {
      const iframe = document.getElementById('alleIframe');
      const message = { typeMessage: 'LANGUAGE_CHANGE', language: data['lang'] };
      this.data.sendPostMessage(message, '*', iframe);
    }, error => {
    });
  }

  getBrandRoute() {
    this.activateRouter.queryParams.subscribe((params: any) => {
      if (params.brand) {
        this.data.saveBrand(params.brand);
        this.actionEventService.sendBrandDynamic(params.brand);
        this.data.productSelected = EProduct.FLIGHTS;
        this.dataStore.preloaderImg = EProduct.FLIGHTS;
      } else {
        this.activateRouter.url.subscribe(resp => {
          if (resp[0].path === EProduct.TRAVEL) {
            this.data.productSelected = EProduct.FLIGHTS;
            this.dataStore.preloaderImg = EProduct.FLIGHTS;
          }
        });
      }
    });
  }

  sendPostMessage(iframe: any, message: any, targetOrigin: string) {
    const iWindow = (<HTMLIFrameElement>iframe).contentWindow;
    iWindow.postMessage(message, '*');
  }


  ngOnDestroy(): void {
    window.removeEventListener('message', event => this.receiveMessage(event), false);
  }

  getAccount() {
    const token = this.data.token;
    this.loginService.setSmartProfileData(token).then((resp) => {
    }, (err) => {
      this.router.navigate(['/home']);
      this.loginService.logout();
      this.dialog.open(LoginComponent, {
        panelClass: 'login-dialog'
      });
    });
  }

}
