import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgSelectModule} from "@ng-select/ng-select";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from "@angular/material/input";
import {NgxDaterangepickerMd} from "ngx-daterangepicker-material";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatExpansionModule} from "@angular/material/expansion";
import {BuyerModule} from "./buyer/buyer.module";
import {TargetInputComponent} from "./target-input/target-input.component";
import {DateInputComponent} from "./date-input/date-input.component";
import {SearchTabComponent} from "./search-tab/search-tab.component";
import {BuyerComponent} from "./buyer/buyer.component";
import {PassengersComponent} from "./buyer/passengers/passengers.component";
import {GuestsComponent} from "./buyer/guests/guests.component";
import {TranslateModule} from "@ngx-translate/core";
import {AppModule} from "../../app.module";
import {InputNumberComponent} from "../input-number/input-number.component";
import { MenuSearchComponent } from './menu-search/menu-search.component';



@NgModule({
  declarations: [
    DateInputComponent,
    SearchTabComponent,
    TargetInputComponent,
    BuyerComponent,
    PassengersComponent,
    GuestsComponent,
    InputNumberComponent,
    MenuSearchComponent
  ],
  exports: [
    TargetInputComponent,
    DateInputComponent,
    BuyerComponent,
    MenuSearchComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // Angular Material Modules
    MatCheckboxModule,
    MatExpansionModule,
    MatInputModule,
    MatSelectModule,

    // external components modules
    NgSelectModule,
    NgxDaterangepickerMd.forRoot(),
    TranslateModule,
    BuyerModule,
  ]
})
export class HomeSearchModule { }
