import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { DataStoreService } from '../../../services/data-store.service';
import { OcultadorService } from '../ocultador.service';
import { SmartProfileService } from '../../../services/smart-profile.service';
import { EProduct } from 'src/app/component/searched/models/searched.model';
import { InitService } from '../../../services/init.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { StoreServiceLibrary, keys, UserData } from '@wearenovae/novae-core-services';
import data from '../../../resources/screenAndEventsTracking.json';
import { TranslateService } from '@ngx-translate/core';
import { ActionEventService } from '../../../services/action-event.service';
import { keysPostMessage, keysUniversal } from '../../../resources/keys.resources';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../../../container/shared/login/login.component';
import { GoogleAnalytics } from '../../../resources/GoogleAnalytics.resource';
import { MilesFormatPipe } from '../../../pipes/miles-format.pipe';
import { LoadingScreenService } from '../../../services/loading-screen.service';
import { Observable } from 'rxjs';
import { UtilsResource } from 'src/app/resources/UtilsResource.resource';

declare var Localize;

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {

  EProduct = EProduct;
  iframeScrollHeight = '100%';
  classSearchContent = 'buscador-flotante';
  flagUseRedemtionBuyMiles = false;
  useSearchIframe: any;
  /**
   * Para hacer pruebas
   */
  // urlSearchIframe = environment.brands[this.dataStore.brand].urlSearchIframe;
  urlSearchIframe: any;

  urlTravelDefault: any;

  urlJuniperLoad: any;

  private messageListenerFn = (event) => this.receiveMessage(event);

  purchaceId = 0;
  selectedTaxes = 0;
  percentageUser: any;
  locationLoader: string;
  reedemtionPointsDefault: any;
  msjReservationInfo: string;
  haveSearchData: boolean = true;

  get tabSelect(): string {
    return this.dataService.productSelected;
  }

  set tabSelect(value: string) {
    this.dataService.productSelected = value as EProduct;
    this.dataStore.preloaderImg = value;
  }

  public hasBenefits = false;
  public hasActivities = true;
  public flagJuniperIframeIsLoad = false;

  languageSend: string;
  messageNeedToken: string;
  onePocketUrl: string;
  hidenIframeTravel: boolean = true;

  bookingBenefitsTextBanner: {};
  htmlBenefits: string = '';

  constructor(
    private dataService: DataService,
    private dataStore: DataStoreService,
    public ocultadorService: OcultadorService,
    private smartProfileService: SmartProfileService,
    private initService: InitService,
    private translate: TranslateService,
    private router: Router,
    private storeServiceLibrary: StoreServiceLibrary,
    private actionsEventService: ActionEventService,
    private dialog: MatDialog,
    private milesFormat: MilesFormatPipe,
    private loadingScreenService: LoadingScreenService
  ) {
    // this.onPostMessageListener();
    this.initService.isInitializedBrand.subscribe((isInitialized) => {
      if (isInitialized) {
        this.useSearchIframe = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.USE_SEARCH_IFRAME) || false;
        this.urlJuniperLoad = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.URL_TRAVEL_JUNIPER) || '';
        this.urlTravelDefault = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.URL_JUNIPER_DEFAULT) || '';
        this.onePocketUrl = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.onePocket_url) || '';
        // this.loadUrlIframe();
      }
    });
  }

  ngOnInit() {
    const that = this;
    this.initService.isInitializedBrand.subscribe(isInitialized => {
      if (isInitialized) {
        this.loadUrlIframe().subscribe((observer) => {
          this.actionsEventService.setLoadUrlTravelIframe(true);
        });
        this.hasBenefits = environment.brands[this.dataStore.brand].config.hasBenefits;

        if (environment.brands[this.dataStore.brand].config.hasActivities) {
          this.hasActivities = environment.brands[this.dataStore.brand].config.hasActivities;
        }
        // if (this.dataStore.brand !== 'miles'
        //   && this.dataStore.brand !== 'firstcitizens'
        //   && this.dataStore.brand !== 'bancopopular'
        //   && this.dataStore.brand !== 'bancoindustrial'
        //   && this.dataStore.brand !== 'ncb'
        // ) {
        //   this.hasBenefits = true;
        // }
        this.languajeChange();
        setTimeout(() => {
          that.translate.get([`LANDING.${this.dataStore.brand}.ONEPOCKETPAY.booking_benefits_title`, `LANDING.${this.dataStore.brand}.ONEPOCKETPAY.booking_benefits_desc`, `LANDING.${this.dataStore.brand}.ONEPOCKETPAY.booking_benefits_btn`]).subscribe((resp) => {
            that.bookingBenefitsTextBanner = {
              'title': resp[`LANDING.${this.dataStore.brand}.ONEPOCKETPAY.booking_benefits_title`],
              'desc': resp[`LANDING.${this.dataStore.brand}.ONEPOCKETPAY.booking_benefits_desc`],
              'button': resp[`LANDING.${this.dataStore.brand}.ONEPOCKETPAY.booking_benefits_btn`]
            }
          })
          that.htmlBenefits = '';
          const benefits = document.getElementById('benefits')
          if (benefits) {
            that.htmlBenefits = benefits.outerHTML;
          }
        });
      }
    });
    this.tabSelect = this.dataService.productSelected;
    if (this.dataService.token && this.dataService.token !== '') {
      this.smartProfileService.setSmartProfilePreferences();
    }

    this.languageSend = this.translate.currentLang;
    this.activeListeners();
  }


  loadUrlIframe() {
    return new Observable((observer) => {
      if (this.useSearchIframe && this.urlJuniperLoad) {
        this.useSearchIframe = true;
        this.classSearchContent = 'buscador-iframe';
        this.urlSearchIframe = `${this.urlJuniperLoad}?idioma=${this.translate.currentLang}`;

        this.actionsEventService.getOtherUrl.subscribe(url => {
          this.hidenIframeTravel = true;
          this.urlSearchIframe = url;
          this.loadingScreenService.startLoading();
        });
        observer.next(true);

      }
    });
  }

  activeListeners() {
    this.actionsEventService.ifChangeLogin.subscribe(successfull => {
      if (successfull) {
        this.sendPostMessageTokenUserToJuniper();
      }
    });
  }

  ngOnDestroy(): void {
    // window.removeEventListener('message', this.messageListenerFn, false);
  }

  // onPostMessageListener() {
  // if (window.addEventListener) {
  //   window.addEventListener('message', this.messageListenerFn, false);
  // }
  // }

  @HostListener('window:message', ['$event'])
  receiveMessage(event: MessageEvent) {
    const iframe = document.getElementById('iframe_onepocket');

    //UtilsResource.ShowLog('*** SearchComponent - receiveMessage - event', event);

    /*     Localize.initialize({
          key: 'KWzn6jjKWmFpD',
          rememberLanguage: true,
          disableWidget: true,
          // other options go here, separated by commas
        });
     */
    if (event.data) {
      let dataEvent: any;
      try {
        dataEvent = JSON.parse(event.data);
      } catch (error) {
        dataEvent = event.data;
      }

      if (typeof dataEvent === 'object' && dataEvent.scrollHeight) {
        // iframeContainer.style.height = dataEvent.scrollHeight + 'px';
        if (this.haveSearchData) {
          this.iframeScrollHeight = dataEvent.scrollHeight + 'px';
        }
        // iframe.scrollIntoView({ behavior: 'smooth' });

      } else if (typeof dataEvent === 'object' && dataEvent.type && dataEvent.type === 'ID_PURCHASE') {
        this.purchaceId = dataEvent.purchaseId;
        this.selectedTaxes = dataEvent.selectedTaxes;
        this.percentageUser = dataEvent.userPorcentage;

        this.goToOnepocketPay();

      } else if (dataEvent === 'getUrlWidgetPortal') {
        const message = {
          0: 'urlWidgetPortal',
          1: window.location.origin
        };
        this.dataService.sendPostMessage(message, '*', iframe);

      } else if (dataEvent === 'getUrlPortal') {
        const message = {
          0: 'urlPortal',
          1: window.location.origin
        };
        this.dataService.sendPostMessage(message, '*', iframe);

      } else if (dataEvent.type === 'UP_LOGIN_MODAL') {
        this.openLogin();
      } else if (typeof dataEvent === 'object' && dataEvent.typeMessage && dataEvent.typeMessage === 'JUNIPER_PAGE_IS_LOAD') {
        this.flagJuniperIframeIsLoad = true;
        this.sendPostMessageInitDataToJuniper();
        const location = dataEvent.location;

        let href = dataEvent.location.href;
        if (!href || href == undefined || href == 'undefined') {
          href = null;
        }

        const tmpLocation = GoogleAnalytics.clearLocationToAnalytics(location);

        GoogleAnalytics.sendEventFirebase('ga_event', 'TRAVEL_SEARCH', href, JSON.stringify(tmpLocation));

        this.getOribiData();
        if (this.dataService.token && this.dataService.token !== '') {
          this.sendPostMessageTokenUserToJuniper();
        }
      } else if (dataEvent.typeMessage === 'WIDGET_IS_LOADED') {
        this.hidenIframeTravel = false;
        this.loadingScreenService.stopLoading();
      } else if (dataEvent.typeMessage === 'TRACKING_DATA') {
        let objTrackingId = {
          category: '',
          action: '',
          label: ''
        }
        if (dataEvent.objectTracking) {
          objTrackingId = dataEvent.objectTracking;
        }
        GoogleAnalytics.sendEventFirebase('ga_event', objTrackingId.category, objTrackingId.action, objTrackingId.label);
      } else if (dataEvent.type === '_NOT_FIND_SEARCH') {
        //this.iframeScrollHeight = '78vh';        
        this.iframeScrollHeight = 'calc(100vh - 80px)';
        this.actionsEventService.setHideItemsPage(false);
        this.haveSearchData = false;
      } else if (dataEvent.type === '_FIND_SEARCH') {
        this.haveSearchData = true;
        this.actionsEventService.setHideItemsPage(true);
      }

      /*   else if (event.data.to !== undefined) {
          Localize.setLanguage(event.data.to);
        } */
      // else if (dataEvent === 'getUrlAlleChannel') {
      // // TODO: toca sacar la url desde Universal para enviar aqui.
      // const message = {
      //   0: 'urlAlleChannel',
      //   1: 'https://purchasews.cibcfcibmiles.com/'
      // };
      // this.dataService.sendPostMessage(message, '*', iframe);
      // }
    }
  }

  sendPostMessageInitDataToJuniper() {
    const iframe = document.getElementById('iframe_onepocket');
    const universalProperties = localStorage.getItem(keys.universalProperties);
    this.reedemtionPointsDefault = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.DEFAULT_CONVERSION_FACTOR);

    let milesGiftFormat = environment.brands[this.dataStore.brand].milesGiftDefault;
    let messageDescSlider: string;

    if (milesGiftFormat) {
      milesGiftFormat = this.milesFormat.transform(milesGiftFormat);
    }

    this.messageNeedToken = this.getTranslate('SEARCH.needTokenPoints', { milesGift: milesGiftFormat });
    this.msjReservationInfo = this.getTranslate('SEARCH.htmlReservationInfo', {});

    if (this.dataStore.brand === 'lifeMilesTravel') {
      this.flagUseRedemtionBuyMiles = true;
    } else {
      this.flagUseRedemtionBuyMiles = false;
    }

    const cardSelected:any = this.storeServiceLibrary.getCardScotiaPointsSelected();
    let strCardSelected:string = '';

    if (cardSelected) {
      strCardSelected = JSON.stringify(cardSelected);
    }

    messageDescSlider = this.getTranslate('SEARCH.htmlDescSlider', {});
    this.locationLoaderSlider();
    this.dataService.sendPostMessage(
      {
        "typeMessage": keysPostMessage.initData,
        "applicationId": environment.brands[this.dataStore.brand].applicationId,
        "iframeSliderUrl": this.dataStore.iframeSliderUrl,
        "language": this.languageSend,
        "messageNeedToken": this.messageNeedToken,
        "messageDescSlider": messageDescSlider,
        "locationLoader": this.locationLoader,
        "defaultConversionFactor": this.reedemtionPointsDefault,
        "withoutToken": true,
        "flagRenderIframe": true,
        "flagUseRedemtionBuyMiles": this.flagUseRedemtionBuyMiles,
        "universalProperties": universalProperties,
        "htmlReservationInfo": this.msjReservationInfo,
        "htmlBenefits": btoa(this.htmlBenefits),
        "bookingBenefitsTextBanner": this.bookingBenefitsTextBanner,
        "strCardSelected": btoa(strCardSelected)
      }, '*', iframe
    );
  }

  locationLoaderSlider() {
    let preload = `${this.onePocketUrl}assets/img/loaders`;
    if (this.dataStore.brand === 'credomatic') {
      preload += `/bac/tutorial-SlideOP_${this.languageSend.toUpperCase()}.gif`;
    } else {
      preload += `/tutorial-SlideOP_${this.languageSend.toUpperCase()}.gif`;
    }
    this.locationLoader = preload;
  }

  sendPostMessageTokenUserToJuniper() {
    const iframe = document.getElementById('iframe_onepocket');
    const universalProperties = localStorage.getItem(keys.universalProperties);
    let userData = this.storeServiceLibrary.getUserData();

    if (this.dataStore.brand === 'lifeMilesTravel') {
      this.flagUseRedemtionBuyMiles = true;
    } else {
      this.flagUseRedemtionBuyMiles = false;
    }
    this.msjReservationInfo = this.getTranslate('SEARCH.htmlReservationInfo', {});

    this.dataService.sendPostMessage(
      {
        "typeMessage": keysPostMessage.tokenUser,
        "userData": userData,
        "token": this.dataService.token,
        "flagRenderIframe": true,
        "applicationId": environment.brands[this.dataStore.brand].applicationId,
        "language": this.languageSend,
        "withoutToken": true,
        "flagUseRedemtionBuyMiles": this.flagUseRedemtionBuyMiles,
        "universalProperties": universalProperties,
        "htmlReservationInfo": this.msjReservationInfo
      }, '*', iframe
    );
  }

  getOribiData() {
    const iframe = document.getElementById('iframe_onepocket');
    const objOribiAnalytics = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.ORIBI_ANALYTICS);
    if (objOribiAnalytics) {
      this.dataService.sendPostMessage({
        "typeMessage": "GET_ORIBI_TRACKING",
        "oribiAnalytics": objOribiAnalytics
      }, '*', iframe);
    }

  }

  goToOnepocketPay() {
    this.router.navigateByUrl('onepocket-pay', { state: { purchaceId: this.purchaceId, selectedTaxes: this.selectedTaxes, userPorcentage: this.percentageUser } });
  }

  tabSelected(tipo: string) {
    this.dataService.productSelected = tipo as EProduct;
    this.tabSelect = this.dataService.productSelected;

    const tipoUpperCase = this.tabSelect.replace(/\b\w/g, (l) => l.toUpperCase());
    const actionName = 'Web_' + tipoUpperCase + '_Header';
    // this.trackingManager.trackEventString(actionName, data.WELCOME.welcomeScreen);
    GoogleAnalytics.sendEventFirebase('ga_event', data.WELCOME.welcomeScreen, actionName, '');
  }

  getTranslate(tag, params) {
    let messageDataFind: string;
    this.translate.get(`${tag}`, params).subscribe((value) => messageDataFind = value);
    return messageDataFind;
  }

  openLogin() {
    const dialogRef = this.dialog.open(LoginComponent, {
      panelClass: 'login-dialog'
    });

    dialogRef.afterClosed().subscribe(successful => {
      if (successful) {
        this.sendPostMessageTokenUserToJuniper();
      }
    });
  }

  languajeChange() {
    const that = this;
    this.translate.onLangChange.subscribe((data) => {
      if (this.urlSearchIframe.includes('?idioma=en')) {
        const newSearch = this.urlSearchIframe.replace('?idioma=en', '');
        this.urlSearchIframe = `${newSearch}?idioma=${data['lang']}`;
      } else if (this.urlSearchIframe.includes('?idioma=es')) {
        const newSearch = this.urlSearchIframe.replace('?idioma=es', '');
        this.urlSearchIframe = `${newSearch}?idioma=${data['lang']}`;
      } else {
        this.urlSearchIframe = `${this.urlSearchIframe}?idioma=${data['lang']}`;
      }
      setTimeout(() => {
        that.translate.get([`LANDING.${this.dataStore.brand}.ONEPOCKETPAY.booking_benefits_title`, `LANDING.${this.dataStore.brand}.ONEPOCKETPAY.booking_benefits_desc`, `LANDING.${this.dataStore.brand}.ONEPOCKETPAY.booking_benefits_btn`]).subscribe((resp) => {
          that.bookingBenefitsTextBanner = {
            'title': resp[`LANDING.${this.dataStore.brand}.ONEPOCKETPAY.booking_benefits_title`],
            'desc': resp[`LANDING.${this.dataStore.brand}.ONEPOCKETPAY.booking_benefits_desc`],
            'button': resp[`LANDING.${this.dataStore.brand}.ONEPOCKETPAY.booking_benefits_btn`]
          }
        })
        that.htmlBenefits = undefined;
        const configOptionsTravel = this.dataService.getConfigPropertiesTravel();
        if (configOptionsTravel) {
          if (configOptionsTravel.showBenefitsInfo) {
            const benefits = document.getElementById('benefits')
            if (benefits) {
              that.htmlBenefits = benefits.outerHTML;
            }
          }
        }
      });
    });
  }

}
