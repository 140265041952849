import { Component, OnInit } from '@angular/core';
import { AbstractCardComponent } from '../../abstract-card.component';
import { GenericCard } from '../../../../../../models/genericCard/generic-card';
import { GenericCardPrice } from '../../../../../../models/genericCard/generic-card-price';
import { getCurrencySymbol } from '@angular/common';

@Component({
  selector: 'app-abstract-card-price',
  templateUrl: './abstract-card-price.component.html',
  styleUrls: ['./abstract-card-price.component.scss']
})
export class AbstractCardPriceComponent implements OnInit {

  product: GenericCard;
  options: any;
  price: GenericCardPrice;

  constructor(
    public cardCmp: AbstractCardComponent
  ) { }

  get currency() {
    return this.price.currency + ' ' + getCurrencySymbol(this.price.currency, 'narrow');
  }

  ngOnInit() {
    this.options = this.cardCmp.options;
    this.product = this.cardCmp.product;
    this.price = this.cardCmp.product.price;
  }
  selectDetail() {
    /*this.cardCmp.productDetailSelected.emit(this.product);*/
  }

}
