import { TranslateService } from '@ngx-translate/core';
import { ActivationCardService } from './../../services/activation-card.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, Renderer2, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InitService } from 'src/app/services/init.service';
import { environment } from 'src/environments/environment';
import { DataStoreService } from 'src/app/services/data-store.service';

@Component({
  selector: 'app-activate-phisical-card',
  templateUrl: './activate-phisical-card.component.html',
  styleUrls: ['./activate-phisical-card.component.css']
})
export class ActivatePhisicalCardComponent implements OnInit, AfterViewInit {
  activateForm: any;

  appName = '';
  newPwd = '';
  confirmPwd = '';
  message: string;
  expreg = new RegExp('[a-zA-ZñÑ0-9.!#$%&\'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*');
  respuesta: any;
  token: string;
  userId: string;
  lang: string;
  showForm: boolean = true;
  errorServiceMessage: boolean = false;

  classPwd1 = false;
  classPwd2 = false;
  channel = '';


  constructor(private render: Renderer2,
    private router: ActivatedRoute,
    private activeCardService: ActivationCardService,
    private translate: TranslateService,
    private initService: InitService,
    private storeService: DataStoreService) { }

  ngOnInit(): void {
    this.getParams();
    this.createRow();
    this.channel = '';
    this.initService.isInitializedBrand.subscribe((isInit) => {
      if (isInit) {
        this.appName = environment.brands[this.storeService.brand].appName;
      }
    });
  }


  ngAfterViewInit() {
    this.getNabHeader();
  }

  getParams() {
    this.router.queryParams.subscribe((params) => {
      if (params['channel']) {
        this.channel = params['channel'];
      }

      if (params['lang']) {
        this.lang = params['lang'];
      }

    });
  }


  async activateCard() {
    this.activeCardService.getJWT().subscribe(resp => {
      const token = resp;
      this.activeCardService.activateCard(token, this.activateForm.value.email, this.activateForm.value.bin).subscribe((data: any) => {
        this.respuesta = data;
        if (data.operationResult.code == '200') {
          // this.router.navigate(['./v1/respuesta/', data.email]);
        } else {
          this.errorServiceMessage = true;
          this.message = data.operationResult.message;
        }
      }, (err) => {
        this.showForm = true;
        this.errorServiceMessage = true;
        if (err.code === '100') {
          this.message = err.message;
        } else {
          this.errorService();
        }
      });
    });
  }

  async errorService() {
    this.message = await this.translate.get('matchPass').toPromise();
  }

  createRow() {
    this.activateForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(this.expreg)]),
      bin: new FormControl('', [Validators.required, Validators.maxLength(4)])
    });
  }

  getNabHeader() {
    const elementRef = document.getElementById('appNav');
    this.render.setStyle(elementRef, 'display', 'none');
  }

  cleanErros() {
    this.classPwd1 = false;
    this.classPwd2 = false;
    this.message = '';
  }


  ngOnDestroy(): void {
    const elementRef = document.getElementById('appNav');
    this.render.setStyle(elementRef, 'display', 'block');
  }


}
