<ng-container *ngIf="section">
    <div class="container promociones">
        <div class="cover-container" *ngIf="brand !== 'credomatic'" >
            <div class="title"><strong>{{section.title|translate}}</strong></div>
            <div class="description">{{section.description|translate}}</div>
        </div>
        <div *ngFor="let row of section.rows" class="row product-container">
            <div class="product">
                <div class="line"></div>
                <div class="text">
                    <strong>{{('EProduct.' + row.product)|translate}}</strong>
                </div>
                <div class="line"></div>
            </div>
            <swiper class="slider-promociones" [config]="swiperConfig">
                <app-promocion *ngFor="let promotion of row.promotions" [promotion]="promotion" [product]="row.product" [showDetail]="showDetail" (click)="search(row.product, promotion.search); $event.stopPropagation()">
                </app-promocion>
            </swiper>
        </div>
    </div>
</ng-container>
