<div class="content-cabin" *ngIf="activateInfoCabin">
  <div class="bg-cabin">
    <p innerHTML="{{'LANDING.'+brand+'.RESULTS.results_for' | translate }}"></p>
    <p>{{countPassengers}} {{ 'LANDING.lifeMilesTravel.RESULTS.passengers' | translate }} - {{ cabinTypeValidate }}</p>
  </div>
</div>


<iframe allow="clipboard-read; clipboard-write" id="iframe_juniper_lm" [src]="urlSearchIframe | srcSanitizer"
  *ngIf="useSearchIframe" class="iframe-content"
  [ngStyle]="{height: iframeScrollHeight, width: iframeScrollWidth, border: border}">
</iframe>
<app-footer-all [brand]="brand"></app-footer-all>