import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor( private meta: Meta ) { }

  generateTags(config) {
    config = {
      tittle: 'Ng Seo',
      keywords: '',
      description: 'Mi Angular Website',
      image: '',
      ...config

    };
    this.meta.updateTag({name: 'title', content: config.title});
    this.meta.updateTag({name: 'keywords', content: config.keyword });
    this.meta.updateTag({name: 'description', content: config.description});


    this.meta.updateTag({ name: 'twitter:title', content: config.title });
    this.meta.updateTag({ name: 'twitter:description', content: config.description });
    this.meta.updateTag({ name: 'twitter:image', content: config.image });

    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({ property: 'og:description', content: config.description });
    this.meta.updateTag({ property: 'og:image', content: config.image });
  }
}

