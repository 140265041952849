import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { SmartProfileService } from '../../services/smart-profile.service';
import { SwiperComponent, SwiperDirective, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { DataStoreService } from '../../services/data-store.service';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { GoogleAnalyticsAction } from '../../models/GoogleAnalyticsEvent';


@Component({
  selector: 'app-landing-allo',
  templateUrl: './landing-allo.component.html',
  styleUrls: ['./landing-allo.component.scss']
})
export class LandingAlloComponent implements OnInit {
  status = false;
  public link_ANDROID_URL_STORE: string;
  public link_IOS_URL_STORE: string;
  public loaded = false;
  public show = true;
  public type = 'component';
  public disabled = false;
  public brand = 'NOVAE';

  public config: SwiperConfigInterface = {
    direction: 'vertical',
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      type: 'bullets'
    },
    // loop:true,
    slidesPerView: 1,
    scrollbar: false,
    navigation: true,
    loop: true,
    autoHeight: true,
    setWrapperSize: true,
    mousewheel: {
      forceToAxis: true,
      releaseOnEdges: true,
      invert: true
    }
  };

  @ViewChild(SwiperComponent) SwiperComponent;
  @ViewChild(SwiperDirective) SwiperDirective;
  @ViewChild('loadingVideo') loadingVideo;
  @ViewChild('alle') alleIframe: ElementRef;

  clickEvent() {
    this.status = !this.status;
  }

  constructor(
    private router: Router,
    public data: DataService,
    public dataStore: DataStoreService,
    private smartProfile: SmartProfileService,
    private titleService: Title,
    protected $gaService: GoogleAnalyticsService
  ) { }

  async ngOnInit() {
    this.dataStore.preloaderImg = 'general';
    this.brand = (this.dataStore.brand + '').toUpperCase();
    this.titleService.setTitle(this.brand);
    await this.getLinksAppDownloadUrls();
    this.loaded = false;
  }

  /**
   * bindea los links de descarga de las apps, con los botones del html
   */
  async getLinksAppDownloadUrls() {
    // TODO: NO LLAMAR A "getUniversalAppDownloadUrls" tomar las propiedades del storeLibrary
    // return await this.data.getUniversalAppDownloadUrls().then((data: any) => {
    //   if (data &&
    //     data.getAllPropertiesByApplicationIdResponse &&
    //     data.getAllPropertiesByApplicationIdResponse.propertyDtoList) {
    //     const links = data.getAllPropertiesByApplicationIdResponse.propertyDtoList;
    //     // link para descargar la app de ANDROID
    //     const obj_ANDROID_URL_STORE = links.find((val) => val.key.indexOf('ANDROID_URL_STORE') !== -1);
    //     if (obj_ANDROID_URL_STORE && obj_ANDROID_URL_STORE.value) {
    //       this.link_ANDROID_URL_STORE = obj_ANDROID_URL_STORE.value;
    //     }
    //     // link para descargar la app de IOS
    //     const obj_IOS_URL_STORE = links.find((val) => val.key.indexOf('IOS_URL_STORE') !== -1);
    //     if (obj_IOS_URL_STORE && obj_IOS_URL_STORE.value) {
    //       this.link_IOS_URL_STORE = obj_IOS_URL_STORE.value;
    //     }
    //   } else {
    //     throw new Error('Links de descarga no encontrados');
    //   }
    // }).catch((e) => {
    //   // links de descarga no encontrados
    
    // });
  }

  @HostListener('window:message', ['$event'])
  async receiveMessage(event: any) {
    if (event.data) {
      if (event.data.typeMessage === 'TOKEN_LOGIN') {
        // si el el login dio success
        this.$gaService.event(
          'Status',
          GoogleAnalyticsAction.Login,
          'Success'
        );
        this.data.saveToken(event.data.token);
        await this.smartProfile.getAccountMethod(this.data.token);
        this.router.navigate(['home']);
      }
      if (event.data.typeMessage === 'INIT_SIGNUP_PAGE') {
        this.loaded = true;
      }
    }
  }
}
