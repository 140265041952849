<div #guestCmp class="bl-guests">
  <div class="guests">
    <form [formGroup]="buyerForm" (ngSubmit)="submitG()">

      <mat-accordion>
        <div formArrayName="rooms"
             *ngFor="let room of roomsArray.controls; let i = index">
          <div [formGroupName]="i">
            <mat-expansion-panel class="roomsRow">
              <mat-expansion-panel-header>
                <mat-panel-title
                  innerHTML="{{'GUESTS.room'|translate:'{value:\'' + (i+1) + '\'}'}}">
                  Room {{ i + 1}}
                </mat-panel-title>
                <!--<mat-panel-description>
                  {{qAdultosText(room.get('qAdultos').value)}}
                  <span *ngIf="qMenoresD(i) !== 'no'"> {{qMenoresD(i)}}</span>
                </mat-panel-description>-->
              </mat-expansion-panel-header>
              <div class="persons">
                <div class="item">
                  <span
                    innerHTML="{{'GUESTS.adults'|translate:'{value:\'+18\'}'}}">
                    Adults (+18)
                  </span>
                  <div class="range-age">
                    <app-input-number formControlName="adults" [min]="0" [max]="8"></app-input-number>
                  </div>
                </div>
              </div>
              <div class="persons">
                <div class="item">
                  <span
                    innerHTML="{{'GUESTS.minors'|translate:'{value:\'0-18\'}'}}">
                    Minors (0-18)
                  </span>
                  <div class="range-age bl-kid">
                    <a class="menos" (click)="removeLastGuest(i)"></a>
                    <!--<input type="number" readonly [value]="guestsArray(room).length">-->
                    <a class="mas" (click)="addNextGuest(i)"></a>
                  </div>
                </div>
              </div>
              <div class="age-kids">
                <div formArrayName="guests" class="item-kids"
                     *ngFor="let guest of guestsArray(room).controls; let j = index">
                  <div [formGroupName]="j" class="guestRow">
                    <mat-form-field>
                      <mat-label innerHTML="{{'GUESTS.age'|translate}}">
                        Age
                      </mat-label>
                      <mat-select formControlName="age">
                        <mat-option *ngFor="let age of ages" [value]="age">
                          {{age}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="validatorH"
                                 innerHTML="{{'GUESTS.select_age'}}">
                        Select age
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
      </mat-accordion>

      <div class="rowBtn b-hab">
        <span (click)="addNextRoom()" class="roomBtn"
              innerHTML="{{'GUESTS.btn_room'|translate:'{dir:\'+\'}'}}">
          + Room
        </span>
        <span (click)="removeLastRoom()" class="roomBtn"
              innerHTML="{{'GUESTS.btn_room'|translate:'{dir:\'-\'}'}}">
          - Room
        </span>
      </div>
    </form>
  </div>
  <div class="bg-btn"></div>
  <div class="btn-aplicar" (click)="submitG()"
       innerHTML="{{'GUESTS.apply'|translate}}">
    Apply
  </div>
</div>
