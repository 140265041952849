import {Component, Input, OnInit} from '@angular/core';
import { HotelCardComponent } from '../hotel-card.component';

@Component({
  selector: 'app-hotel-image',
  templateUrl: './hotel-image.component.html',
  styleUrls: ['./hotel-image.component.scss']
})
export class HotelImageComponent implements OnInit {

  image;

  constructor(private cardComponent: HotelCardComponent) {
  }

  ngOnInit() {
    this.image = this.cardComponent.hotel.Images[0];
  }

}
