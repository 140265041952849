import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

interface RegionAutocomplete {
  region: any[]
}

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  public BaseUrl = 'https://api.bitacora.travel/api/';
  //public BaseUrl = 'http://testapi.bitacora.travel/api/';
  //public BaseUrl = 'http://localhost:11049/api';

  constructor(
    private httpClient: HttpClient
  ) { }


  GetAutocomplete (String) {
    return this.httpClient.get<RegionAutocomplete>(this.BaseUrl+'/Region/RegionAutocomplete?String='+String);
  }
}
