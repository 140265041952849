import { SrcSanitizerPipe } from './src-sanitizer.pipe';
import { NumberToArrPipe } from './number-to-arr.pipe';
import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { TruncatePipe } from './truncate.pipe';
import { FilterPipe } from './filter.pipe';
import { MilesFormatPipe } from './miles-format.pipe';
import { milesFormatEnPipe } from './miles-en.pipe';
import { DomSecurityPipe } from './dom-security.pipe';

const pipes = [
  TruncatePipe,
  FilterPipe,
  MilesFormatPipe,
  NumberToArrPipe,
  SrcSanitizerPipe,
  milesFormatEnPipe,
  DomSecurityPipe
];

@NgModule({
  declarations: pipes,
  exports: pipes,
  imports: [
    CommonModule
  ],
  providers: [...pipes, DecimalPipe],
})
export class PipesModule { }
