<div class="bl-step">

  <div class="container" id="main_form_component">
    <div class="row stepper">
      <div class="col-md-10 offset-md-1 col-12" #MainSteps>

        <div class="after-container step-div text-center" [ngClass]="{ active: true }">
          <div class="step-img" routerLink="/travel">
            <i class="travel-icon icon-stepper-case-outline"></i>
            <div class="col-8 offset-2 step-label">
              <p innerHTML="{{'MAIN_FORM.my_trip'|translate}}">Mi viaje</p>
            </div>
          </div>
        </div>

        <div class="after-container step-div text-center" [ngClass]="{ active: true }">
          <div class="step-img" [ngClass]="class" routerLink="/{{mainFormDinamicText.dinamicRoute}}/results/">
            <i class="travel-icon {{mainFormDinamicText.dinamicIcon}}"></i>
            <div class="col-8 offset-2 step-label">
              <p>{{ mainFormDinamicText.dinamicText}}</p>
            </div>
          </div>
        </div>

        <div class="after-container step-div text-center" [ngClass]="{ active: activeData }">
          <div class="step-img" routerLink="/{{mainFormDinamicText.dinamicRoute}}/registry/">
            <i class="travel-icon icon-people before"></i>
            <div class="clearfix"></div>
            <div class="col-8 offset-2 step-label">
              <p innerHTML="{{'MAIN_FORM.data'|translate}}">Datos</p>
            </div>
          </div>
        </div>

        <div class="after-container step-div text-center" [ngClass]="{active: activePay }">
          <div class="step-img" [ngClass]="class" routerLink="/{{mainFormDinamicText.dinamicRoute}}/registry">
            <i class="travel-icon icon-stepper-card-outline"></i>
            <div class="clearfix"></div>
            <div class="col-8 offset-2 step-label">
              <p innerHTML="{{'MAIN_FORM.payment'|translate}}">Pago</p>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="col-md-12 hidden-xs resume">
      <app-search-resume-bar></app-search-resume-bar>
      <!-- <div class="col-xs-12 col-md-12 ps-0 bl-items"
        *ngIf="mainFormDinamicText && mainFormDinamicText.searchDetailsTexts">
        <p class="segments left-margin">
          {{ mainFormDinamicText.searchDetailsTexts.originDestinyIata }}</p>
        <p class="left-margin">
          {{ mainFormDinamicText.searchDetailsTexts.departureArrivalDates }}</p>
        <p class="left-margin item-none">
          {{ mainFormDinamicText.searchDetailsTexts.qNights }}</p>
        <p class="left-margin item-none">
          {{ mainFormDinamicText.searchDetailsTexts.qPasangers }}</p>
      </div> -->
    </div>
    <div class="clearfix"></div>

  </div>
</div>
