import { Component, HostListener, OnInit } from '@angular/core';
import { EProduct } from '../../component/searched/models/searched.model';
import { UserData, StoreServiceLibrary, keys, keysFixedRates } from '@wearenovae/novae-core-services';
import { Router, ActivatedRoute } from '@angular/router';
import { InitService } from '../../services/init.service';
import { TranslateService } from '@ngx-translate/core';
import { ActionEventService } from '../../services/action-event.service';
import { DataStoreService } from '../../services/data-store.service';
import { DataService } from '../../services/data.service';
import { SmartProfileService } from '../../services/smart-profile.service';
import { LoadingScreenService } from '../../services/loading-screen.service';
import { environment } from '../../../environments/environment';
import { keysPostMessage, keysUniversal, typeService } from 'src/app/resources/keys.resources';
import { JuniperService } from '../../services/juniper.service';
import { GoogleAnalytics } from '../../resources/GoogleAnalytics.resource';
import { MilesFormatPipe } from '../../pipes/miles-format.pipe';
import { UtilsResource } from 'src/app/resources/UtilsResource.resource';
import { BenefitsComponent } from 'src/app/component/benefits/benefits.component';
import { MatDialog } from '@angular/material/dialog';
import { LanguageService } from 'src/app/component/language/language.service';

declare var androidProxy: any;
declare var window: any;

@Component({
  selector: 'app-travel-app',
  templateUrl: './travel-app.component.html',
  styleUrls: ['./travel-app.component.css']
})
export class TravelAppComponent implements OnInit {

  get brand() {
    return this.dataStore.brand;
  }


  EProduct = EProduct;
  iframeScrollHeight = '100%';
  iframeScrollWidth = '100%';
  border = '0';
  classSearchContent = 'buscador-flotante';

  typeExternalUrls = [];
  selectedTab = typeService.flights;
  configOptionsTravel: any;

  user: UserData;
  flagUseRedemtionBuyMiles = false;
  reedemtionPointsDefault: any;

  useSearchIframe: any;
  purchaceId = 0;
  urlSearchIframe: any;

  urlSearchIframeUniversal: any;

  urlTravelDefault: any;
  tokenUser: string;
  languageSend: string;
  messageNeedToken: string;

  public hasBenefits = false;
  public hasActivities = true;
  public flagJuniperIframeIsLoad = false;
  locationLoader: string;
  onePocketUrl: string;

  activateCard = false;
  objDataFixedRates: any;
  percentageUser: any;
  msjReservationInfo: any;
  strCardSelected: any;
  showPromotions: boolean = false;
  haveSearchData: boolean = true;
  showTabs: boolean = false;

  showBenefits: boolean = false;

  bookingBenefitsTextBanner: {};
  htmlBenefits: string = undefined;

  isFixedRates: Boolean = false;

  objActivatesTabs = {
    activateFligths: true,
    activateHotels: false,
    activateCars: false,
    activateHouseVacations: false
  };

  paymentMethod: string = '';
  productSearchStr: string = '';

  get tabSelect(): string {
    return this.dataService.productSelected;
  }

  set tabSelect(value: string) {
    this.selectedTab = value;
    this.dataService.productSelected = value as EProduct;
    this.dataStore.preloaderImg = value;
  }

  constructor(
    private storeServiceLibrary: StoreServiceLibrary,
    private initService: InitService,
    private translate: TranslateService,
    private actionsEventService: ActionEventService,
    private dataStore: DataStoreService,
    private dataService: DataService,
    private smartProfileService: SmartProfileService,
    private loadingScreenService: LoadingScreenService,
    private router: Router,
    private activateRouter: ActivatedRoute,
    private juniperService: JuniperService,
    private milesFormat: MilesFormatPipe,
    private dialog: MatDialog,
    private languageService:LanguageService
  ) {
    this.tabSelect = typeService.flights;
    this.initService.isInitializedBrand.subscribe((isInitialized) => {
      if (isInitialized) {
        let externalUrls = JSON.parse(this.storeServiceLibrary.getJustOneUniversalPropertie('_TYPE_URLS_EXTERNAL_TRAVEL') || 'false');
        if (externalUrls) {
          externalUrls = externalUrls.filter(typeService => typeService?.active == true);
          this.typeExternalUrls = externalUrls;
          if (externalUrls.length >= 0) {
            this.tabSelect = externalUrls[0].name;
          }
          UtilsResource.NSMarkLog('CONFIGURATIONS TO SHOW: ', this.typeExternalUrls);
        }

        let configOptionsTravel = this.dataService.getConfigPropertiesTravel();
        UtilsResource.NSMarkLog('CONFIGURATIONS TO SHOW: ', configOptionsTravel);

        if (configOptionsTravel) {
          this.configOptionsTravel = configOptionsTravel;
          this.showTabs = this.configOptionsTravel.showTabs;
          this.showPromotions = this.configOptionsTravel.showHighlights;
          this.showBenefits = this.configOptionsTravel.showBenefitsInfo;
        }

        this.useSearchIframe = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.USE_SEARCH_IFRAME) || false;
        this.urlSearchIframeUniversal = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.URL_TRAVEL_JUNIPER) || '';
        this.urlTravelDefault = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.URL_JUNIPER_DEFAULT) || '';
        this.languageSend = this.translate.currentLang;
        this.onePocketUrl = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.onePocket_url) || '';
        this.setUrlIframe();
      }
    });
  }

  ngOnInit() {
    this.initService.isInitializedBrand.subscribe((isInitialized) => {
      if (isInitialized) {
        this.definirURLTravelSearchIframe(this.translate.currentLang);
        this.getRouter();

        this.hasBenefits = environment.brands[this.dataStore.brand].config.hasBenefits;
        if (environment.brands[this.dataStore.brand].config.hasActivities) {
          this.hasActivities = environment.brands[this.dataStore.brand].config.hasActivities;
        }
        
        if (this.dataService.token && this.dataService.token !== '') {
          this.smartProfileService.setSmartProfilePreferences();
        }
      }
    });

    this.activeListeners();
  }

  activeListeners() {
    this.translate.onLangChange.subscribe(dataService => {
      this.definirURLTravelSearchIframe(dataService.lang);
    });
  }

  definirURLTravelSearchIframe(lang: string) {
    let finalUrl = '';
    const langAux = this.languageService.getValidLanguage(lang);
        
    if (this.useSearchIframe) {
      finalUrl = `${this.urlSearchIframeUniversal}`;
    } else {
      finalUrl = `${this.urlTravelDefault}`;
    }

    let url = this.construirUrl(finalUrl, langAux, this.productSearchStr, this.paymentMethod);
    this.urlSearchIframe = url;
  }

  construirUrl(url: string, language?: string, productSearch?: string, paymentMethod?: string) {
    var lastChar = url.substr(url.length - 1);
    if (lastChar != '/') {
      url += url + "/";
    }
    let urlBuild = `${url}${productSearch}/`;
    if (language) {
      let seperator = this.getSeparator(urlBuild);
      urlBuild += `${seperator}idioma=${language}`;
    }
    if (paymentMethod) {
      let seperator = this.getSeparator(urlBuild);
      urlBuild += `${seperator}PaymentMethod=${paymentMethod}`;
    }
    return urlBuild;
  }

  getSeparator(url: string) {
    return url.includes('?') ? '&' : '?';
  }

  starLoading() {
    this.loadingScreenService.startLoading();
  }

  stopLoading() {
    this.loadingScreenService.stopLoading();
  }

  getRouter() {
    const iframe = document.getElementById('iframe_juniper_app');
    this.activateRouter.queryParams.subscribe((params) => {
      this.productSearchStr;
      if (params.productSearch) {
        this.productSearchStr = params.productSearch;
        if (this.productSearchStr == EProduct.FLIGHTS) {
          this.changeLoadIframe(typeService.flights);
        }
        if (this.productSearchStr == EProduct.HOTELS) {
          this.changeLoadIframe(typeService.hotels);
        }
        if (this.productSearchStr == EProduct.CARS) {
          this.changeLoadIframe(typeService.cars);
        }
      } else {
        this.changeLoadIframe(this.tabSelect);
      }

      if (params.strCardSelected) {
        this.strCardSelected = '';

        let strCardSelectedTemp = atob(params.strCardSelected);

        if (typeof strCardSelectedTemp != 'undefined' && strCardSelectedTemp != 'undefined') {
          try {
            this.strCardSelected = JSON.parse(strCardSelectedTemp);
          } catch (error) {
            UtilsResource.NSMarkLogError('ERROR, no se pudo parear a JSON strCardSelected: ', this.strCardSelected);
          }
        }

        this.storeServiceLibrary.setCardScotiaPointsSelected(this.strCardSelected);
        UtilsResource.NSMarkLog('Card Selected', this.strCardSelected);

        let typeZone;
        if (this.strCardSelected && this.strCardSelected.typeZone) {
          typeZone = this.strCardSelected.typeZone;
        } else {
          typeZone = keysFixedRates.DR;
        }

        this.validateTypeZone(typeZone);
      }

      if (params.tokenAuth) {
        if (params.b) {
          this.tokenUser = atob(params.tokenAuth);
        } else {
          let tokenAuth = params.tokenAuth.replace(/ /g, '+');
          this.tokenUser = encodeURI(tokenAuth);
        }
        this.getAccount(this.tokenUser);
      }

      if (params.language) {
        const paramLanguage = this.languageService.getValidLanguage(params.language);
        this.languageSend = paramLanguage;
      }

      this.paymentMethod = '';
      if (params.PaymentMethod) {
        this.paymentMethod = params.PaymentMethod;
      }

      this.definirURLTravelSearchIframe(this.languageSend);

      if (params.objectSearchDeep) {
        let objectSearch = atob(params.objectSearchDeep);
        setTimeout(() => {
          let urlSearchIframeDeep = this.juniperService.searchFlightsJuniperScotia('searchflights', JSON.parse(objectSearch));
          this.actionsEventService.setChangeUrlJuniper(urlSearchIframeDeep, false);
          this.showTabs = false;
        }, 200);
      }

      if (params.objectFixedRates) {
        let objDataFixedRates = atob(params.objectFixedRates);
        this.objDataFixedRates = JSON.parse(objDataFixedRates);
        this.isFixedRates = true;
      }
    });
  }

  @HostListener('window:message', ['$event'])
  receiveMessage(event) {
    const iframe = document.getElementById('iframe_juniper_app');
    if (event.data) {
      let dataEvent: any;
      try {
        dataEvent = JSON.parse(event.data);
      } catch (error) {
        dataEvent = event.data;
      }

      if (typeof dataEvent === 'object' && dataEvent.scrollHeight) {
        if (this.haveSearchData) {
          this.iframeScrollHeight = dataEvent.scrollHeight + 'px';
        }
      } else if (dataEvent === 'getUrlWidgetPortal') {

        const message = {
          0: 'urlWidgetPortal',
          1: window.location.origin
        };
        this.dataService.sendPostMessage(message, '*', iframe);

      } else if (typeof dataEvent === 'object' && dataEvent.type && dataEvent.type === 'ID_PURCHASE') {

        this.purchaceId = dataEvent.purchaseId;
        this.percentageUser = dataEvent.userPorcentage;
        this.sendPurchaceToApp(this.purchaceId);

      } else if (typeof dataEvent === 'object' && dataEvent.typeMessage && dataEvent.typeMessage === 'JUNIPER_PAGE_IS_LOAD') {
        this.flagJuniperIframeIsLoad = true;
        this.sendPostMessageHideContent();
        this.sendPostMessageInitDataToJuniper();

        const location = dataEvent.location;
        let href = dataEvent.location.href;
        if (!href || href == undefined || href == 'undefined') {
          href = null;
        }

        const tmpLocation = GoogleAnalytics.clearLocationToAnalytics(location);
        GoogleAnalytics.sendEventFirebase('ga_event', 'TRAVEL_SEARCH', href, JSON.stringify(tmpLocation));
        if (this.tokenUser) {
          this.getAccount(this.tokenUser);
        }

        if (this.dataStore.brand != 'scotia') {
          this.sendPostMessageShowExtraElements();
        }
      } else if (dataEvent.typeMessage === 'TRACKING_DATA') {
        let objTrackingId = {
          category: '',
          action: '',
          label: ''
        }
        if (dataEvent.objectTracking) {
          objTrackingId = dataEvent.objectTracking;
        }
        GoogleAnalytics.sendEventFirebase('ga_event', objTrackingId.category, objTrackingId.action, objTrackingId.label);
      } else if (dataEvent.type === '_NOT_FIND_SEARCH') {
        this.iframeScrollHeight = '90vh';
        // this.actionsEventService.setHideItemsPage(false);
        this.haveSearchData = false;
        this.showPromotions = false;
        this.showBenefits = false;
      } else if (dataEvent.type === '_FIND_SEARCH') {
        this.haveSearchData = true;
        let configOptionsTravel = this.dataService.getConfigPropertiesTravel();
        UtilsResource.NSMarkLog('CONFIGURATIONS TO SHOW: ', configOptionsTravel);
        if (configOptionsTravel) {
          this.configOptionsTravel = configOptionsTravel;
          if (this.configOptionsTravel.showHighlights) {
            this.showPromotions = true;
          } else {
            this.showPromotions = false;
          }
          if (this.configOptionsTravel.showBenefitsInfo) {
            this.showBenefits = true;
          } else {
            this.showBenefits = false;
          }
        }

        // this.actionsEventService.setHideItemsPage(true);
      }

    }
  }

  sendPostMessageTokenUserToJuniper() {
    const iframe = document.getElementById('iframe_juniper_app');
    const userData = this.storeServiceLibrary.getUserData();
    const universalProperties = localStorage.getItem(keys.universalProperties);

    if (this.dataStore.brand === 'lifeMilesTravel') {
      this.flagUseRedemtionBuyMiles = true;
    } else {
      this.flagUseRedemtionBuyMiles = false;
    }

    this.msjReservationInfo = this.getTranslate('SEARCH.htmlReservationInfo', {});
    this.dataService.sendPostMessage(
      {
        "typeMessage": keysPostMessage.tokenUser,
        "userData": userData,
        "token": userData.authenticationToken,
        "flagRenderIframe": true,
        "applicationId": environment.brands[this.dataStore.brand].applicationId,
        "language": this.languageSend,
        "withoutToken": true,
        "flagUseRedemtionBuyMiles": this.flagUseRedemtionBuyMiles,
        "universalProperties": universalProperties,
        "htmlReservationInfo": this.msjReservationInfo
      }, '*', iframe
    );
  }

  sendPostMessageShowExtraElements() {
    const iframe = document.getElementById('iframe_juniper_app');
    this.dataService.sendPostMessage(
      {
        "typeMessage": keysPostMessage.showJuniperExtraElements,
      }, '*', iframe
    );
  }

  sendPostMessageInitDataToJuniper() {
    let isFixedRates = false;
    const iframe = document.getElementById('iframe_juniper_app');
    const universalProperties = localStorage.getItem(keys.universalProperties);

    this.reedemtionPointsDefault = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.DEFAULT_CONVERSION_FACTOR);
    this.msjReservationInfo = this.getTranslate('SEARCH.htmlReservationInfo', {});

    if (this.dataStore.brand === 'lifeMilesTravel') {
      this.flagUseRedemtionBuyMiles = true;
    } else {
      this.flagUseRedemtionBuyMiles = false;
    }

    let milesGiftFormat = environment.brands[this.dataStore.brand].milesGiftDefault;

    if (milesGiftFormat) {
      milesGiftFormat = this.milesFormat.transform(milesGiftFormat);
    }

    this.translate.get([`LANDING.${this.dataStore.brand}.ONEPOCKETPAY.booking_benefits_title`, `LANDING.${this.dataStore.brand}.ONEPOCKETPAY.booking_benefits_desc`, `LANDING.${this.dataStore.brand}.ONEPOCKETPAY.booking_benefits_btn`]).subscribe((resp) => {
      this.bookingBenefitsTextBanner = {
        'title': resp[`LANDING.${this.dataStore.brand}.ONEPOCKETPAY.booking_benefits_title`],
        'desc': resp[`LANDING.${this.dataStore.brand}.ONEPOCKETPAY.booking_benefits_desc`],
        'button': resp[`LANDING.${this.dataStore.brand}.ONEPOCKETPAY.booking_benefits_btn`]
      }
    })

    this.htmlBenefits = undefined;
    const configOptionsTravel = this.dataService.getConfigPropertiesTravel();
    if (configOptionsTravel) {
      if (configOptionsTravel.showBenefitsInfo) {
        const benefits = document.getElementById('benefitsTravelApp');
        if (benefits) {
          this.htmlBenefits = benefits.outerHTML;
        }
      }
    }

    this.messageNeedToken = this.getTranslate('SEARCH.needTokenPoints', { milesGift: milesGiftFormat });
    this.locationLoaderSlider();

    if (this.dataStore.brand === 'scotia') {
      isFixedRates = true;      
    }
    
    const cardSelected:any = this.storeServiceLibrary.getCardScotiaPointsSelected();
    let strCardSelected:string = '';
    
    if (cardSelected) {
      strCardSelected = JSON.stringify(cardSelected);
    }
    
    const message = {
      typeMessage: 'extraDataJson',
      extraDataJSON: this.objDataFixedRates
    };
    
    this.dataService.sendPostMessage(message, '*', iframe);
    this.dataService.sendPostMessage(
      {
        "typeMessage": keysPostMessage.initData,
        "applicationId": environment.brands[this.dataStore.brand].applicationId,
        "iframeSliderUrl": this.dataStore.iframeSliderUrl,
        "language": this.languageSend,
        "messageNeedToken": this.messageNeedToken,
        "locationLoader": this.locationLoader,
        "fixedRates": isFixedRates,
        "fixedRatesData": this.objDataFixedRates,
        "defaultConversionFactor": this.reedemtionPointsDefault,
        "withoutToken": true,
        "flagRenderIframe": true,
        "flagUseRedemtionBuyMiles": this.flagUseRedemtionBuyMiles,
        "universalProperties": universalProperties,
        "htmlReservationInfo": this.msjReservationInfo,
        "htmlBenefits": btoa(this.htmlBenefits),
        "bookingBenefitsTextBanner": this.bookingBenefitsTextBanner,
        "strCardSelected": btoa(strCardSelected)
      }, '*', iframe
      );
      if (this.dataStore.brand === 'cryptmi') {
        this.dataService.sendPostMessage({'typeMessage': 'USER_LOADED_ONEPOCKET'}, '*', iframe);
      }
    }
    
  locationLoaderSlider() {
    let preload = `${this.onePocketUrl}assets/img/loaders`;
    if (this.dataStore.brand === 'credomatic') {
      preload += `/bac/tutorial-SlideOP_${this.languageSend.toUpperCase()}.gif`;
    } else {
      preload += `/tutorial-SlideOP_${this.languageSend.toUpperCase()}.gif`;
    }
    this.locationLoader = preload;
  }

  sendPurchaceToApp(idpurchace) {
    let message = {
      type: keysPostMessage.idPurchase,
      purchaseId: idpurchace,
      idPurchase: idpurchace,
      userPorcentage: this.percentageUser
    };

    if (this.dataStore.brand == 'credomatic') {
      if (this.storeServiceLibrary.getItemFromStorage('CountrySelected')) {
        message['countryCredomatic'] = this.storeServiceLibrary.getItemFromStorage('CountrySelected');
      }
    }

    if (typeof androidProxy !== 'undefined') { // Si es android
      androidProxy.postMessage(JSON.stringify(message));
    } else if (window.webkit !== undefined && window.webkit !== 'undefined' && window.webkit.messageHandlers.native !== undefined && window.webkit.messageHandlers.native !== 'undefined') { // Si es iOS
      window.webkit.messageHandlers.native.postMessage(JSON.stringify(message));
    } else { // Si es Web
      this.goToOnepocketPay(idpurchace);
    }
  }

  goToOnepocketPay(purchaceId) {
    this.router.navigateByUrl('onepocket-pay', { state: { purchaceId: purchaceId, userPorcentage: this.percentageUser, product: this.objDataFixedRates } });
  }

  getAccount(token: string) {
    let userData = this.storeServiceLibrary.getUserData();
    userData.authenticationToken = token;
    this.storeServiceLibrary.setUserData(userData);
    this.smartProfileService.getAccountMethod(token).then((resp) => {
      this.sendPostMessageTokenUserToJuniper();
    }, (err) => {
      UtilsResource.NSMarkLogError('GET ACCOUNT ERROR', err);
    });
  }

  getTranslate(tag, params) {
    let messageDataFind: string;
    this.translate.get(`${tag}`, params).subscribe((value) => messageDataFind = value);
    return messageDataFind;
  }

  setUrlIframe() {
    this.actionsEventService.getOtherUrl.subscribe(url => {
      this.urlSearchIframe = url;
    });
  }

  changeLoadIframe(item) {
    const urlJuniper = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.URL_TRAVEL_JUNIPER);
    this.tabSelect = item;
    switch (item) {
      case typeService.flights: {
        // let urlToSend = `${urlJuniper}flights/?idioma=${this.translate.currentLang}`;
        // this.actionsEventService.setChangeUrlJuniper(urlToSend, false);
        this.productSearchStr = 'flights';
        this.definirURLTravelSearchIframe(this.translate.currentLang);
        this.actionsEventService.setChangeUrlJuniper(this.urlSearchIframe, false);

        this.objActivatesTabs.activateFligths = true;
        this.objActivatesTabs.activateHotels = false;
        this.objActivatesTabs.activateCars = false;
        this.objActivatesTabs.activateHouseVacations = false;


        if (this.dataStore.brand === 'credomatic') {
          this.actionsEventService.setItemActiveTab(EProduct.FLIGHTS);
          this.dataService.productSelected = EProduct.FLIGHTS;
          this.dataStore.preloaderImg = EProduct.FLIGHTS;
        } else {
          this.actionsEventService.setItemActiveTab(EProduct.TRAVEL);
          this.dataService.productSelected = EProduct.TRAVEL;
          this.dataStore.preloaderImg = EProduct.TRAVEL;
        }

        break;
      }
      case typeService.hotels: {
        // let urlToSend = `${urlJuniper}hotels/?idioma=${this.translate.currentLang}`;
        // this.actionsEventService.setChangeUrlJuniper(urlToSend, false);
        this.productSearchStr = 'hotels';
        this.definirURLTravelSearchIframe(this.translate.currentLang);
        this.actionsEventService.setChangeUrlJuniper(this.urlSearchIframe, false);

        this.objActivatesTabs.activateFligths = false;
        this.objActivatesTabs.activateHotels = true;
        this.objActivatesTabs.activateCars = false;
        this.objActivatesTabs.activateHouseVacations = false;

        if (this.dataStore.brand === 'credomatic') {
          this.actionsEventService.setItemActiveTab(EProduct.HOTELS);
          this.dataService.productSelected = EProduct.HOTELS;
          this.dataStore.preloaderImg = EProduct.HOTELS;
        } else {
          this.actionsEventService.setItemActiveTab(EProduct.TRAVEL);
          this.dataService.productSelected = EProduct.TRAVEL;
          this.dataStore.preloaderImg = EProduct.TRAVEL;
        }

        break;
      }
      case typeService.vacationHomes: {
        // let urlToSend = `${urlJuniper}homes/homes/homes.aspx?idioma=${this.translate.currentLang}`;
        // this.actionsEventService.setChangeUrlJuniper(urlToSend, false);
        this.productSearchStr = 'homes/homes/homes.aspx';
        this.definirURLTravelSearchIframe(this.translate.currentLang);
        this.actionsEventService.setChangeUrlJuniper(this.urlSearchIframe, false);

        this.objActivatesTabs.activateFligths = false;
        this.objActivatesTabs.activateHotels = false;
        this.objActivatesTabs.activateCars = false;
        this.objActivatesTabs.activateHouseVacations = true;

        if (this.dataStore.brand === 'credomatic') {
          this.actionsEventService.setItemActiveTab(EProduct.HOMEVACATIONS);
          this.dataService.productSelected = EProduct.HOMEVACATIONS;
          this.dataStore.preloaderImg = EProduct.HOMEVACATIONS;
        } else {
          this.actionsEventService.setItemActiveTab(EProduct.TRAVEL);
          this.dataService.productSelected = EProduct.TRAVEL;
          this.dataStore.preloaderImg = EProduct.TRAVEL;
        }

        break;
      }
      case typeService.cars: {
        // let urlToSend = `${urlJuniper}cars/?idioma=${this.translate.currentLang}`;
        // this.actionsEventService.setChangeUrlJuniper(urlToSend, false);
        this.productSearchStr = 'cars';
        this.definirURLTravelSearchIframe(this.translate.currentLang);
        this.actionsEventService.setChangeUrlJuniper(this.urlSearchIframe, false);

        this.objActivatesTabs.activateFligths = false;
        this.objActivatesTabs.activateHotels = false;
        this.objActivatesTabs.activateCars = true;
        this.objActivatesTabs.activateHouseVacations = false;

        if (this.dataStore.brand === 'credomatic') {
          this.actionsEventService.setItemActiveTab(EProduct.CARS);
          this.dataService.productSelected = EProduct.CARS;
          this.dataStore.preloaderImg = EProduct.CARS;
        } else {
          this.actionsEventService.setItemActiveTab(EProduct.TRAVEL);
          this.dataService.productSelected = EProduct.TRAVEL;
          this.dataStore.preloaderImg = EProduct.TRAVEL;
        }

        break;
      }
      default:
        break;
    }
  }

  sendPostMessageHideContent() {
    setTimeout(() => {
      const iframe = document.getElementById('iframe_juniper_app');
      this.dataService.sendPostMessage(
        {
          typeMessage: 'HIDE_CONTENT_FOOTER',
        }, '*', iframe
      );
    }, 1);
  }

  validateTypeZone(typeZone) {
    switch (typeZone) {
      case keysFixedRates.ECR_AMEX:
        this.actionsEventService.setSubBrand('amex');
        this.dataService.saveCreditCardDefult('amex');
        this.actionsEventService.setChangeFooter(true);
        break;
      case keysFixedRates.DR_AMEX:
        this.actionsEventService.setSubBrand('amex');
        this.dataService.saveCreditCardDefult('amex');
        this.actionsEventService.setChangeFooter(true);
        break;
      case keysFixedRates.ECR:
        this.actionsEventService.setSubBrand('default');
        this.dataService.saveCreditCardDefult('');
        this.actionsEventService.setChangeFooter(false);
        break;
      case keysFixedRates.DR:
        this.actionsEventService.setSubBrand('default');
        this.dataService.saveCreditCardDefult('');
        this.actionsEventService.setChangeFooter(false);
        break;
      default:
        break;
    }
  }

  showBenefitsButton() {
    const dialogRef = this.dialog.open(BenefitsComponent, {
      panelClass: 'modal-benefits',
      id: 'benefitsModal',
      disableClose: false,
      data: {
        showTermsAndConditions: false
      }
    });
  }

}

