import { Journey } from './../../../../models/new-flight/journey.model';
import { Component, OnInit, Input } from '@angular/core';
import { CardLayoutOneService } from './card-layout-one.service';

@Component({
  selector: 'app-card-layout-one',
  templateUrl: './card-layout-one.component.html',
  styleUrls: ['./card-layout-one.component.scss']
})
export class CardLayoutOneComponent implements OnInit {

  @Input() product: Journey;
  @Input() options: any;

  constructor(private cardLayoutOneService: CardLayoutOneService) { }

  ngOnInit() {
  }

}
