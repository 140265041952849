<style>
    @media (max-width: 540px) {
        section.gray.s-flex .items-number p{
            text-align: left !important;
            margin: 0;
            margin-top: 0 !important;
        }
    }
</style>
<section class="gray s-flex questions" *ngIf="brand ==='scotia'">
    <div class="container">
        <div class="bl-row">
            <div class="bl-left">
                <h2 class="text-gradient" innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_TOPS.tops_title' | translate }}"></h2>
                <ul class="items-number">
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.1' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_TOPS.tops_list_1' | translate}}"></p>
                    </li>
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.2' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_TOPS.tops_list_2' | translate}}"></p>
                    </li>
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.3' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_TOPS.tops_list_3' | translate}}"></p>
                    </li>
                </ul>
            </div>

            <div class="bl-right">
                <img class="imagen-video" [src]="iphoneTops" alt="">
            </div>
        </div>
    </div>
</section>




<section class="safe" *ngIf="brand === 'scotia'">
    <div class="container">
        <div class="bl-row align-top">
            <div class="bl-center">
                <h1 class="text-gradient" innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_SAFE.safe_title_1' | translate}}"></h1>
                <p innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.SECTION_SAFE.safe_list_1' | translate}}"></p>
            </div>
        </div>

    </div>
</section>

<section class="descargas">
    <div class="download">
        <h2 class="text-gradient" innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.text_download' | translate }}"> </h2>
        <div class="store">
            <ul>
                <app-download-store [class]="'logo'"></app-download-store>
            </ul>
        </div>
    </div>
</section>