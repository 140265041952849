import { CardPrice } from 'src/app/component/card/models/card-price.model';
import { Slider } from 'src/app/component/slider-op/model/slider';

export class Journey {

    static toArray(values: Journey[]): Journey[] {
        values.forEach((v, i) => {
            values[i] = new Journey(
                v.flights,
                v.validatingCompany,
                v.queryID,
                v.flightsIDS,
                v.info,
                v.journeyID,
                v.price,
                v.id,
                v.travelFare,
                v.basePrice,
                v.charges,
                v.currency,
                v.discount,
                v.passengerType,
                v.quantity,
                v.taxes,
                v.totalPrice
            );
        });
        return values;
    }

    getMoneyAmount = () => (
        (!this.price.calculatedPrice && this.price.pointsPrice)
        || this.price.calculatedPrice
        ? this.price.calculatedPrice : this.price.totalPrice )
    priceConversion = (conversionRate, percent, userPoints) => {
        Slider.getPriceConversion([{price: this.price}], conversionRate, percent, userPoints);
    }

    constructor(
        public flights?: Flight[],
        public validatingCompany?: Company,
        public queryID?: string,
        public flightsIDS?: string[],
        public info?: Info,
        public journeyID?: string,
        public price?: CardPrice,
        public id?: string,
        public travelFare?: TravelFare[] | TravelFare,
        public basePrice?: string,
        public charges?: string,
        public currency?: string,
        public discount?: string,
        public passengerType?: string,
        public quantity?: string,
        public taxes?: string,
        public totalPrice?: string
    ) {}

}

export interface Flight {
    arrivalDate: string;
    arrivalHour: string;
    departureDate: string;
    departureHour: string;
    company: Company;
    destination: Destination;
    origin: Origin;
    flightDuration: string;
    segmentIatas: string[];
    segments: Segment[];
}

export interface Company {
    iata?: string;
    icao?: string;
    shortName?: string;
    longName?: string;
}

export interface Destination {
    cityName?: string;
    country?: string;
    countryName?: string;
    iata: string;
    id?: number;
    longName?: string;
    shortName?: string;
    terminal?: string;
    timeZone?: string;
    type?: string;
}

export interface Origin {
    cityName?: string;
    country?: string;
    countryName?: string;
    iata: string;
    id?: number;
    longName?: string;
    shortName?: string;
    terminal?: string;
    timeZone?: string;
    type?: string;
}


export interface Segment {
    departureDate?: string;
    arrivalDate?: string;
    departureHour?: string;
    arrivalHour?: string;
    flightDuration?: string;
    flightNumber?: string;
    stopDuration?: string;
    origin?: Origin;
    destination?: Destination;
    company?: Company;
    info?: Info;
    scale?: Scale;
}


export interface Info {
    cabin?: Cabin|string;
    baggage?: string; // Baggage|string
    refundable?: string|Refundable;
}

export interface Scale {
    cityName: string;
    iata: string;
    scaleDuration: string;
}

export interface Cabin {
    longName: string;
}

export interface Refundable {
    isRefundable: string;
}

export interface TravelFare {
    basePrice?: string;
    charges?: string;
    currency?: string;
    discount?: string;
    passengerType?: string;
    quantity?: string;
    taxes?: string;
    totalPrice?: string;
}
