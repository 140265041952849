import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GenericCard } from '../../../models/genericCard/generic-card';
import { RentaCarBodyComponent } from '../../renta-car-body/renta-car-body.component';

@Component({
  selector: 'app-card-abstract-list',
  templateUrl: './abstract-card-list.component.html',
  styleUrls: ['./abstract-card-list.component.scss']
})
export class AbstractCardListComponent implements OnInit {
  @Input() data: GenericCard[];
  @Input() options: any;
  @Output() selectProduct = new EventEmitter();
  @Input() selectedProductId: any;
  qtyResultShow = 5;
  get filteredID() {
    return this.carCmp.filteredID;
  }
  constructor(private carCmp: RentaCarBodyComponent) { }


  ngOnInit() {
  }
  AddResults() {
    if (this.qtyResultShow < this.data.length) {
      this.qtyResultShow += 5;
    }
  }

}
