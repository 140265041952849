<div class="search-resume-bar">
    <ng-container [ngSwitch]="productSelected">

        <!-- FLIGHTS -->
        <ng-container *ngSwitchCase="EProduct.FLIGHTS">
            <div class="resume-col">
                <span matTooltip="{{searchedFlight.origin.name}}">
                    {{searchedFlight.origin.name|truncate:16}}&nbsp;-&nbsp;
                </span>
                <span matTooltip="{{searchedFlight.destination.name}}">
                    {{searchedFlight.destination.name|truncate:16}}
                </span>
            </div>
            <div class="resume-col">
                {{searchedFlight.journeyToHumanReadable}}
            </div>
            <ng-container *ngIf="searchedFlight.journey === 'oneWay'">
                <div class="resume-col">
                    {{searchedFlight.dateToHumanReadable.start}}
                </div>
            </ng-container>
            <ng-container *ngIf="searchedFlight.journey === 'idaVuelta'">
                <div class="resume-col">
                    {{searchedFlight.dateToHumanReadable.start}}&nbsp;-&nbsp;{{searchedFlight.dateToHumanReadable.end}}
                </div>
                <div class="resume-col">
                    {{searchedFlight.qDaysToString}}
                </div>
            </ng-container>
            <div *ngIf="searchedFlight.quantity.adults" class="resume-col">
                {{searchedFlight.qAdultsToString}}
            </div>
            <div *ngIf="searchedFlight.quantity.ninos" class="resume-col">
                {{searchedFlight.qNinosToString}}
            </div>
            <div *ngIf="searchedFlight.quantity.babes" class="resume-col">
                {{searchedFlight.qBebesToString}}
            </div>
        </ng-container>

        <!-- HOTELS -->
        <ng-container *ngSwitchCase="EProduct.HOTELS">
            <div class="resume-col">
                {{searchedHotel.destination.name}}
            </div>
            <div class="resume-col">
                {{'ISearched.SearchedHotel.check_in'|translate}}&nbsp;
                {{searchedHotel.dateToHumanReadable.start}}
                &nbsp;-&nbsp;
                {{'ISearched.SearchedHotel.check_out'|translate}}&nbsp;
                {{searchedHotel.dateToHumanReadable.end}}
            </div>
            <div class="resume-col">
                {{searchedHotel.qRoomsToString}},&nbsp;{{searchedHotel.qGuestToString}}
            </div>
            <div class="resume-col">
                {{searchedHotel.qNightsToString}}
            </div>
        </ng-container>

        <!-- CARS -->
        <ng-container *ngSwitchCase="EProduct.CARS">
            <div class="resume-col"  matTooltip="{{searchedCar.withdrawal.name}}">
                {{'ISearched.SearchedCar.withdrawal'|translate}}&nbsp;
                {{searchedCar.withdrawal.name|truncate:40}}
            </div>
            <div class="resume-col">
                {{'ISearched.SearchedCar.return'|translate}}&nbsp;
                {{searchedCar.return.name|truncate:40}}
            </div>
            <div class="resume-col">
                {{searchedCar.dateToHumanReadable.start}}
                {{searchedCar.timeToHumanReadable.start}}&nbsp;hs
                &nbsp;-&nbsp;
                {{searchedCar.dateToHumanReadable.end}}
                {{searchedCar.timeToHumanReadable.end}}&nbsp;hs
            </div>
            <div class="resume-col">
                {{searchedCar.qDaysToString}}
            </div>
        </ng-container>

        <!-- ACTIVITIES -->
        <ng-container *ngSwitchCase="EProduct.ACTIVITIES">
            <div class="resume-col">
                {{searchedActivity.destination.name}}
            </div>
            <div class="resume-col">
                {{searchedActivity.dateToHumanReadable.start}}
                &nbsp;-&nbsp;
                {{searchedActivity.dateToHumanReadable.end}}
            </div>
            <div class="resume-col">
                {{searchedActivity.qPassengerToString}}
            </div>
        </ng-container>

        <!-- BENEFITS -->
        <ng-container *ngSwitchCase="EProduct.BENEFITS">
        </ng-container>

    </ng-container>
</div>
