import { Component, OnInit } from '@angular/core';
import {CardComponent} from '../card.component';

@Component({
  selector: 'app-card-header',
  templateUrl: './card-header.component.html',
  styleUrls: ['./card-header.component.scss']
})
export class CardHeaderComponent implements OnInit {

  product;
  starsArr = [];
  constructor(private cardComponent: CardComponent) {
  }

  ngOnInit() {
    this.product = this.cardComponent.product;
    const stars = parseInt(this.product.category);
    if (!isNaN(stars)) {
      this.starsArr =  Array(stars).fill(0);
    }

  }

}
