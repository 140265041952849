<div class="container container-product">
  <div class="row">
    <p class="product-name">{{item.productName}}</p>
  </div>
  <div class="detail-container d-flex align-items-center">
    <div class="item-container">
      <ng-container
        *ngFor="let benefitGroup of item.benefitGroups; let i = index; let l = last;">
        <div class="item">
          <h6>{{ item.benefitGroups[i].groupName }}</h6>
          <ul>
            <ng-container
              *ngFor="let benefit of benefitGroup.benefits; let i = index;">
              <li *ngIf="i < 2 && benefit.benefitName !== ''">
                <div class="benefit-box">
                  <span class="description">{{benefit.benefitName}}</span>
                  <!-- <strong class="price">{{benefit.config}}</strong> -->
                  <strong class="price">{{benefit.limit}}</strong>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
        <div class="bar" *ngIf="i%2 == 0 && !l"></div>
      </ng-container>

    </div>
  </div>
  <div class="product-bottom">
    <div *ngIf="item.productName === 'Standard'" class="col-8 forma-de-pago">
      <small class="label">{{'BENEFIT_ITEM.annual_price'|translate}}: </small>
      <span class="point-price">{{'BENEFIT_ITEM.free'|translate}}</span>
    </div>
    <div *ngIf="item.productName !== 'Standard'" class="forma-de-pago">
      <small class="label"><i class="travel-icon icon-stepper-card"></i>
        {{'BENEFIT_ITEM.annual_price'|translate}}:&nbsp;</small>
      <span class="point-price">
        {{ item.price.pointsPrice | milesFormat }}
        {{'BENEFIT_ITEM.miles'|translate}}&nbsp;
        <small class="money-price">+
          {{data.getCurrencySymbol(item.productCurrency,'narrow' )}}
          {{item.price.calculatedPrice | milesFormat}}</small>
      </span>
    </div>
    <div class="button-container-benefit">
      <button
        (click)="openBenefitDialog()">{{'BENEFIT_ITEM.details'|translate}}</button>
    </div>
  </div>
</div>