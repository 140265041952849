import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromocionesComponent } from './promociones.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { TranslateModule } from '@ngx-translate/core';
import { PromocionComponent } from './promocion/promocion.component';



@NgModule({
  declarations: [
    PromocionesComponent,
    PromocionComponent
  ],
  imports: [
    CommonModule,
    SwiperModule,
    TranslateModule.forChild()
  ],
  exports: [
    PromocionesComponent
  ]
})
export class PromocionesModule { }
