<div class="description">
    <!-- <ng-container *ngIf="options.type !== EProduct.HOTELS else hotel"></ng-container> -->
    <span class="name">{{ layout.nameSelected }}</span>
    <span class="subname" *ngIf="layout.subnameSelected">
        <i class="travel-icon icon-stepper-acommodation" aria-hidden="true"></i>
        {{ layout.subnameSelected }}
    </span>
    <div class="description" *ngIf="product.description">
        <div [innerHtml]="product.description | truncate: 130"></div>
    </div>
    <div class="refundable" *ngIf="options.hasRefundable">
        <i class="travel-icon icon-alert-rounded" aria-hidden="true"></i>
        <span>{{ refundable }}</span>
    </div>

    <!-- SE MUESTRA SOLO EN HOTELES -->
    <div class="amenities" *ngIf="showAmenities">
        <span *ngIf="breakfast" class="amenity" title="Desayuno incluido">
            <i class="travel-icon icon-breakfast" aria-hidden="true"></i>
        </span>
        <span *ngIf="wifi" class="amenity" title="Wifi">
            <i class="travel-icon icon-wifi" aria-hidden="true"></i>
        </span>
    </div>
    <!-- SE MUESTRA SOLO EN HOTELES -->

    <!-- SE MUESTRA SOLO EN HABITACIONES -->
    <div class="night-guest" *ngIf="showNightGuest">
        <span class="" *ngIf="layout.nights">
            <i class="travel-icon icon-calendar" aria-hidden="true"></i>
            {{ layout.nights }} {{ 'LAYOUT_TWO.nights' | translate }}
        </span>
        <span class="" *ngIf="layout.travellers">
            <i class="travel-icon icon-people" aria-hidden="true" ></i>
            {{ layout.travellers }} {{ 'LAYOUT_TWO.guests' | translate }}
        </span>
    </div>
    <!-- SE MUESTRA SOLO EN HABITACIONES -->

    <!-- SE MUESTRA SOLO EN AUTOS -->
    <div *ngIf="showCarACRISS">
        <ul *ngIf="ACRISS">
            <ng-container *ngFor="let acrissdetail of ACRISS.value">
                <li class="acriss">
                    <strong>{{ 'LAYOUT_TWO.'+acrissdetail.type | translate}}:</strong> {{acrissdetail.value}}
                </li>
            </ng-container>
        </ul>
    </div>
    <!-- SE MUESTRA SOLO EN AUTOS -->
</div>

<!-- <ng-template #hotel>
    <span class="room">{{ layout.nameSelected }}</span>
    <span class="board">
        <i class="travel-icon icon-stepper-acommodation" aria-hidden="true"></i>
        {{ layout.subnameSelected }}
    </span>
    <div class="services description" *ngIf="product.description !== null">
        <div [innerHtml]="product.description"></div>
    </div>
    <div class="refundable">
        <i class="travel-icon icon-alert-rounded" aria-hidden="true"></i>
        <span>{{ refundable }}</span>
    </div>

    <div class="amenities">
        <span *ngIf="breakfast" class="amenity" title="Desayuno incluido">
            <i class="travel-icon icon-breakfast" aria-hidden="true"></i>
        </span>
        <span *ngIf="wifi" class="amenity" title="Wifi">
            <i class="travel-icon icon-wifi" aria-hidden="true"></i>
        </span>
    </div>
</ng-template> -->