import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
  HostListener
} from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';
import { DataStoreService } from '../../services/data-store.service';
import { DebounceCall } from 'src/app/decorators/debounce.decorator';
import { getCurrencySymbol } from '@angular/common';
import { Router } from '@angular/router';
import { LoginService } from '../../container/shared/login/login.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../../container/shared/login/login.component';
import { ActionEventService } from '../../services/action-event.service';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { keysPostMessage } from 'src/app/resources/keys.resources';
import { UtilsResource } from 'src/app/resources/UtilsResource.resource';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, OnDestroy {

  @ViewChild('onePocketIframe') iframe;
  @ViewChild('loadingVideo') loadingVideo;

  @Input() iframeURL: string;
  @Input() token: string;
  @Input() enabled = false;

  contador: number;

  // @Input()
  public loaded = false;
  public moneyAmount = 0;
  public pointsAmount = 0;
  public getCurrencySymbol = getCurrencySymbol;

  private initiated = false;
  private totalCostValue = 0;

  @Input()
  set totalCost(cost: any) {
    if (!this.enabled || (this.enabled && !(+this.data.smartProfile.accountResultDto.balance))) {
      this.moneyAmount = cost;
    }
    this.totalCostValue = cost;
    this.refreshValuePurchase();
  }

  @Input()
  public moneyCurrency = '';

  @Output()
  public getPorcentaje = new EventEmitter(true);

  private iframeBaseUrl: string;

  constructor(
    private data: DataService,
    public dataStore: DataStoreService,
    private router: Router,
    private loginService: LoginService,
    private dialog: MatDialog,
    private storeServiceLibrary: StoreServiceLibrary

  ) { }

  async ngOnInit() {
    this.initiated = true;
    this.loaded = false;
    this.iframeURL = this.dataStore.iframeSliderUrl;

    const pathArray = this.iframeURL.split('/');
    const protocol = pathArray[0];
    const host = pathArray[2];
    this.iframeBaseUrl = protocol + '//' + host;

    let price: any = {};
    price.valuePayment = this.totalCostValue;
    price.valuePoints = 0;
    price.currency = this.moneyCurrency;
    this.data.saveLatestPrice(price);
  }

  async playVideo() {
    await this.loadingVideo.nativeElement.play();
  }

  @HostListener('window:message', ['$event'])
  async messageListener(event: MessageEvent) {

    if (event.origin === this.iframeBaseUrl && event.data.typeMessage === 'INIT_PREFERENCE_PAYMENT_PAGE') {
      this.loaded = true;
      this.sendToken();
      this.refreshValuePurchase();
    } else if (event.origin === this.iframeBaseUrl && event.data.typeMessage === 'SLIDER_DRAGEND_PAYMENT_PAGE') {
      let price: any = {};
      price.valuePayment = event.data.valuePayment;
      price.valuePoints = event.data.valuePoints;
      price.currency = this.moneyCurrency;
      this.data.saveLatestPrice(price);
      this.onePocketSliderResponse(event.data);
    } else if (event.origin === this.iframeBaseUrl && event.data.typeMessage === 'FINISHED_PROCESSING_LAST_MESSAGE') {
      this.contador++;
    } else if (event.origin === this.iframeBaseUrl && event.data.typeMessage === 'TOKEN_SESSION_USER_ERROR') {
      this.router.navigate(['/home']);
      this.loginService.logout();
      this.dialog.open(LoginComponent, {
        panelClass: 'login-dialog'
      });
    } else if (event.origin === this.iframeBaseUrl && event.data.typeMessage === 'ERROR_RECEIVED_POST_MESSAGE') {
      UtilsResource.NSMarkLogError('ERROR_RECEIVED_POST_MESSAGE', event.data);
    } else {
      if (environment.production) {
      }
    }
  }

  sendToken() {
    if (this.token !== undefined) {
      const iframe = document.getElementById('iframe_onepocket_slider');
      const message = {
        typeMessage: keysPostMessage.tokenPreferencePayment,
        infoPayment: {
          valuePurchase: this.totalCostValue
        },
        token: this.token
      };
      this.sendPostMessage(iframe, message, this.iframeBaseUrl);
    }
  }

  refreshValuePurchase() {
    if (!this.initiated || !this.loaded) {
      return;
    }
    const iframe = document.getElementById('iframe_onepocket_slider');
    const message = { typeMessage: keysPostMessage.updateInfoPreferencePayment, infoPayment: { valuePurchase: this.totalCostValue } };
    this.sendPostMessage(iframe, message, this.iframeBaseUrl);
  }

  sendPostMessage(iframe: any, message: any, targetOrigin: string) {
    if (iframe === null) {
      return;
    }
    const iWindow = (<HTMLIFrameElement>iframe).contentWindow;
    iWindow.postMessage(message, targetOrigin);
  }

  @DebounceCall(50)
  private onePocketSliderResponse(data: any) {
    this.pointsAmount = +data.valuePoints;
    this.moneyAmount = +data.valuePayment;
    const percentage = data.porcentagePayment;
    this.storeServiceLibrary.setItemInStorage('percentage', percentage);
    this.getPorcentaje.emit(data.porcentagePayment);
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', event => this.messageListener(event), false);
  }
}
