<div class="container">
    <div class="row" *ngFor="let item of cars">
        <div class="col-12 text-center info-provider">
            <!-- <p><span innerHTML="{{ 'CONFIRMATION.CAR.provider_name' | translate }}">Nombre del Proveedor: </span> {{ item.pickUp.locationName }}</p>
      <p><span innerHTML="{{ 'CONFIRMATION.CAR.address' | translate }}">Dirección: </span> {{ item.pickUp.address }}</p>
      <p><span innerHTML="{{ 'CONFIRMATION.CAR.phone' | translate }}">Teléfono: </span> {{ item.pickUp.phoneNumbers.join(', ') }}</p> -->
        </div>
        <div class="info-book">
            <div class="">
                <span innerHTML="{{ 'CONFIRMATION.CAR.car' | translate }}">Auto:</span>
                <p>{{ item.name }}</p>
            </div>
            <div class="locator">
                <span innerHTML="{{ 'CONFIRMATION.CAR.locator' | translate }}">Localizador:</span>
                <p>{{ confirmationData.bookingData.purchaseId }}</p>
            </div>
        </div>

        <div class="title-info">
            <!--*ngIf="confirmationData.type === 'benefits'"-->
            {{ 'CONFIRMATION.CAR.more_information' | translate}}
        </div>

        <div class="datos-col row">
            <div class="col-30">
                <strong innerHTML="{{ 'CONFIRMATION.CAR.supplier_name' | translate }}"> </strong> {{ item.supplier }}</div>
        </div>
        <div class="datos-col row">
            <div class="col-6">
                <strong innerHTML="{{ 'CONFIRMATION.CAR.pick_up' | translate }}"></strong> {{ item.puckUp }}</div>
            <div class="col-6">
                <strong innerHTML="{{ 'CONFIRMATION.CAR.drop_off' | translate }}"></strong> {{ item.dropOff }}</div>
            <div class="col-6">
                <strong innerHTML="{{ 'CONFIRMATION.CAR.pick_up_hour' | translate }}"></strong>{{getPickUpDate(item.pickUpDate)}} {{ item.pickUpHour }}</div>
            <div class="col-6">
                <strong innerHTML="{{ 'CONFIRMATION.CAR.drop_off_hour' | translate }}"></strong> {{getDropOffDate(item.dropOffDate)}} {{item.dropOffHour}}</div>
        </div>


    </div>
</div>