<!-- IFRAME ADDCREDITCARD POINTS OR SELECTC CREDITCARDPOINTS -->
<div class="slider-container" [style.display]='!loaded ? "none" : "block"'>

  <div class="text-center">
    <iframe class="iframeClass"  [src]="iframeURL | srcSanitizer" frameborder="0" #onePocketIframe
      id="iframe_onepocket_points" allow="clipboard-read; clipboard-write" [ngStyle]="{height: iframeScrollHeight, width: iframeScrollWidth, 'min-height': iframeMinHeigth }"></iframe>
  </div>


</div>
