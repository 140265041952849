import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HotelBodyComponent } from './container/hotel-body/hotel-body.component';
import { HomeComponent } from './pages/home/home.component';
import { NoResultsComponent } from './container/flight-body/no-results/no-results.component';
import { LandingMyProfileComponent } from './pages/landing-my-profile/landing-my-profile.component';
import { RentaCarBodyComponent } from './container/renta-car-body/renta-car-body.component';
import { BenefitCardComponent } from './container/benefit-card/benefit-card.component';
import { HomeSearchComponent } from './component/home-search/home-search.component';
import { AuthGuard } from './authentication/auth.guard';
import { RegistryConfirmationComponent } from './component/registry-confirmation/registry-confirmation.component';
import { LandingFcComponent } from './pages/landing-fc/landing-fc.component';
import { LandingAboutComponent } from './pages/landing-about/landing-about.component';

import { HomeGuard } from './guard/home.guard';
import { AboutGuard } from './guard/about/about.guard';
import { LandingTravelComponent } from './pages/landing-travel/landing-travel/landing-travel.component';
import { OpFromIframeComponent } from './component/op-from-iframe/op-from-iframe.component';
import { FixedRatesComponent } from './pages/fixed-rates/fixed-rates.component';
import { FixedRatesGuard } from './guard/fixed-rates/fixed-rates.guard';
import { TravelAppComponent } from './pages/travel-app/travel-app.component';
import { MyrewardsSalesdeckComponent } from './pages/myrewards-salesdeck/myrewards-salesdeck.component';
import { LandingLifeMilesComponent } from './pages/landing-lifemiles/landing-lifemiles.component';
import { TravelGuard } from './guard/travel/travel.guard';
import { RouterRedirectGuard } from './guard/router-redirect/router-redirect.guard';
import { RedirectComponent } from './component/redirect/redirect.component';

import { NewsComponent } from './pages/news/news.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ActivatePhisicalCardComponent } from './pages/activate-phisical-card/activate-phisical-card.component';
import { LandingUpperclubComponent } from './pages/landing-upperclub/landing-upperclub.component';
import { LandingHelpComponent } from './pages/landing-help/landing-help.component';

import { ErrorPageComponent } from './component/errors/error-page/error-page.component';
import { AppNotFoundComponent } from './component/errors/app-not-found/app-not-found.component';
import { OnepocketBenefitsComponent } from './pages/onepocket-benefits/onepocket-benefits.component';


const routes: Routes = [

  {
    path: 'nuevo',
    component: HomeSearchComponent,
  },
  {
    path: 'benefits',
    component: BenefitCardComponent,
    canActivate: [AuthGuard]
  },
  {
    /* RUTA DE ACCESO DESDE APP MOBILE */
    path: 'flights-mobile',
    loadChildren: () => import('./container/flight-body/flights.module').then(m => m.FlightsModule)
  },
  {
    /* RUTA DE ACCESO DESDE APP MOBILE */
    path: 'hotels-mobile',
    component: HotelBodyComponent
  },
  {
    path: 'flights',
    children: [
      { path: 'results', loadChildren: () => import('./container/results/results.module').then(m => m.ResultsModule) },
      { path: 'registry', loadChildren: () => import('./pages/registration/registration.module').then(m => m.RegistrationModule), canActivate: [AuthGuard] },
      { path: 'noResults', component: NoResultsComponent },
    ]
  },
  {
    path: 'activities',
    children: [
      { path: 'results', loadChildren: () => import('./container/results/results.module').then(m => m.ResultsModule) },
      { path: 'registry', loadChildren: () => import('./pages/registration/registration.module').then(m => m.RegistrationModule), canActivate: [AuthGuard] },
      { path: 'noResults', component: NoResultsComponent },
    ]
  },
  {
    path: 'cars',
    children: [
      { path: 'results', loadChildren: () => import('./container/results/results.module').then(m => m.ResultsModule) },
      { path: 'registry', loadChildren: () => import('./pages/registration/registration.module').then(m => m.RegistrationModule), canActivate: [AuthGuard] },
      { path: 'noResults', component: NoResultsComponent },
    ],
  },
  {
    path: 'cars-mobile',
    component: RentaCarBodyComponent
  },
  {
    path: 'hotels',
    children: [
      { path: 'results', loadChildren: () => import('./container/results/results.module').then(m => m.ResultsModule) },
      { path: 'registry', loadChildren: () => import('./pages/registration/registration.module').then(m => m.RegistrationModule), canActivate: [AuthGuard] },
      { path: 'noResults', component: NoResultsComponent },
    ]
  },
  {
    path: 'benefits',
    children: [
      { path: 'results', component: BenefitCardComponent },
      { path: 'registry', loadChildren: () => import('./pages/registration/registration.module').then(m => m.RegistrationModule), canActivate: [AuthGuard] },
      { path: 'noResults', component: NoResultsComponent },
    ]
  },
  {
    path: 'landing',
    component: HomeComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [HomeGuard],
  },
  {
    path: 'travel',
    component: LandingTravelComponent,
    canActivate: [TravelGuard]
  },
  {
    path: 'travel/:service',
    component: LandingTravelComponent
  },
  {
    path: 'about',
    canActivate: [AboutGuard],
    component: LandingAboutComponent,
  },
  {
    path: 'landingfc',
    component: LandingFcComponent,
  },
  {
    path: 'myRewards',
    component: HomeComponent,
  },
  {
    path: 'myProfile',
    component: LandingMyProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'registry-confirmation',
    component: RegistryConfirmationComponent
  },
  {
    path: 'results/noResults',
    component: NoResultsComponent
  },
  {
    path: 'lifemiles-travel',
    component: LandingLifeMilesComponent
  },
  {
    path: 'onepocket-pay',
    component: OpFromIframeComponent
  },
  {
    path: 'travel-app',
    component: TravelAppComponent
  },
  {
    path: 'onepocket-benefits',
    component: OnepocketBenefitsComponent
  },
  {
    path: 'fixed-rates',
    component: FixedRatesComponent,
    canActivate: [FixedRatesGuard]
  },
  {
    path: 'myrewards-salesdeck',
    component: MyrewardsSalesdeckComponent
  },
  {
    path: 'default',
    component: RedirectComponent,
    canActivate: [RouterRedirectGuard]
  },
  {
    path: 'register',
    component: LandingTravelComponent,
    data: { openRegister: true }
  },
  {
    path: 'news',
    component: NewsComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'activate-phisical-card',
    component: ActivatePhisicalCardComponent
  },
  {
    path: 'tutorials',
    component: LandingUpperclubComponent
  },
  {
    path: 'error-page',
    component: ErrorPageComponent
  },
  {
    path: 'error-app-not-found',
    component: AppNotFoundComponent
  },
  {
    path: 'help',
    component: LandingHelpComponent
  },
  {
    path: '',
    redirectTo: 'default',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'default',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
