<div class="installed">
  <div class="icono">
    <img src="../assets/images/scotia/icon-alert.svg" alt="">
  </div>
  <div class="tittle">
    <h1 innerHtml="{{app_name | translate}}">App</h1>
    <p innerHtml="{{'LANDING_ALLO.is_not_installed' | translate}}">is not installed</p>
  </div>
  
  <div class="download-installed">
    <div class="container">
      <span innerHtml="{{'LANDING_ALLO.enjoying_benefits' | translate}}">You are very close to enjoying the benefits of Scotia Rewards!</span>

      <div class="download">
        <p innerHtml="{{'LANDING_ALLO.download_now_message' | translate}}">Download <strong>now</strong></p>
        <div class="stores">
          <a>
            <img src="../assets/images/scotia/app-store.png" alt="app-store" (click)="goToApp('ios')">
          </a>
          <a>
            <img src="../assets/images/scotia/play-store.png" alt="app-store" (click)="goToApp('android')">
          </a>
        </div>
      </div>
    </div>
  </div>
  <p class="text" innerHtml="{{'LANDING_ALLO.chatting_with_alle' | translate}}">Also answer your questions by chatting with alle, our smart agent.</p>
  <div class="buttons">
    <button (click)="goHome()" innerHtml="{{'LANDING_ALLO.go-to-home' | translate}}">Go to home</button>
  </div>
</div>