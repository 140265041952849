import { keys } from 'src/app/resources/keys.resources';
import { Component, OnInit, ViewChild, AfterContentInit, AfterViewInit } from '@angular/core';
import { SwiperComponent, SwiperDirective, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { LanguageService } from 'src/app/component/language/language.service';
import { DataStoreService } from '../../../services/data-store.service';
import { GenerateService } from '../../../services/generate.service';
import { environment } from '../../../../environments/environment';
import { ActionEventService } from '../../../services/action-event.service';
import { InitService } from '../../../services/init.service';
import { DataService } from '../../../services/data.service';
import { Router } from '@angular/router';
import { keysUniversal, StoreServiceLibrary } from '@wearenovae/novae-core-services';

declare var $;

@Component({
  selector: 'app-slider-home',
  templateUrl: './slider-home.component.html',
  styleUrls: ['./slider-home.component.scss']
})

export class SliderHomeComponent implements OnInit {

  public show = false;
  public type = 'component';
  public disabled = false;
  brand;
  uri = environment.uriSlider;
  isBACSlider = false;

  config: SwiperConfigInterface;
  translations = [];

  // @ViewChild(SwiperComponent, { static: false }) SwiperComponent;
  // @ViewChild(SwiperDirective, { static: false }) SwiperDirective;
  sliders = [];
  sliderLength: number;
  sliderEnd;
  country: string;
  cursorType = [];
  sliderJson: any;
  sliderJsonFromDb: any;
  loadSliderDb: boolean;

  constructor(
    private dataStore: DataStoreService,
    private languageService: LanguageService,
    private _general: GenerateService,
    private actionsEventService: ActionEventService,
    private initService: InitService,
    private data: DataService,
    public router: Router,
    private _storeLibrary: StoreServiceLibrary,
    private actionEventService: ActionEventService,
  ) {
    this.initService.isInitializedBrand.subscribe((isInit) => {
      if (isInit) {
        this.verifyFormLoadSlider();
        this.countryCodeChange();
      }
    });
  }

  ngOnInit() {
  }


  countryCodeChange() {
    this.actionsEventService.countryCodeChange.subscribe((countryCodeChange) => {
      this.country = countryCodeChange.code;
      this.loadDataSlider();
      // this.transformSliders();
    });
  }

  verifyFormLoadSlider() {
    this.loadSliderDb = environment.brands[this.dataStore.brand].config['loadSliderDB'];
    if (this.loadSliderDb) {
      this.sliderJsonFromDb = JSON.parse(this._storeLibrary.getJustOneUniversalPropertie(keys.JSON_SLIDERS_BANNER));
      if (typeof this.country === 'undefined') {
        this.country = this._storeLibrary.getItemFromStorage('CountrySelected');
      }
      this.sliderJson = this.sliderJsonFromDb;
      this.brand = this.dataStore.brand;
      this.show = true;
      this.initSliders();
      this.loadSliderAmex();
    } else {
      this.brand = this.dataStore.brand;
      this.show = true;
      this.initSliders();
      this.loadSliderAmex();
      if (typeof this.country === 'undefined') {
        this.country = this._storeLibrary.getItemFromStorage('CountrySelected');
      }
    }
  }

  initSliders() {
    if (this.loadSliderDb) {
      this.loadDataSlider();
    } else {
      this._general.readJson(this.uri).subscribe(data => {
        this.sliderJson = data;
        this.loadDataSlider();
      });

    }
  }

  loadSliderAmex() {
    this.actionsEventService.changeClassAmexSubject.subscribe((amex) => {
      this.loadDataSlider();
      // this.initSwiper();
    });
  }

  loadDataSlider() {
    if (this.sliderJson === null || typeof this.sliderJson === 'undefined') {
      return;
    }
    if (this.brand === 'scotia') {
      if (this.dataStore.creditCardDefult === 'amex') {
        this.sliders = this.sliderJson['scotia-amex'].sliders;
      } else {
        this.sliders = this.sliderJson[this.dataStore.brand].sliders;
      }
    } else {
      if (this.brand === 'credomatic') {
        if (this.country === null || typeof this.country === 'undefined') {
          return;
        }
        this.isBACSlider = true;
        // this.actionsEventService.countryCodeChange.subscribe((countryCodeChange) => {
        this.sliders = this.sliderJson[this.brand][this.country].sliders;
        // })
      } else {
        this.sliders = this.sliderJson[this.dataStore.brand].sliders;
      }
    }
    this.transformSliders();
    this.initSwiper();
  }



  transformSliders() {
    this.sliders.map((item, index) => {
      if (item['country'] && item['country'] !== this.country) {
        this.sliders.splice(index, 1);
      }
    });
  }


  initSwiper() {
    this.config = {
      direction: 'vertical',
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        type: 'bullets',
        currentClass: 'slider-counter',
        renderFraction: function (currentClass) {
          return '<span class="' + currentClass + '"></span>';
        }
      },
      simulateTouch: false,
      allowTouchMove: false,
      autoplay: {
        delay: 30000,
      },
      slidesPerView: 1,
      scrollbar: false,
      navigation: false,
      loop: true,
      breakpoints: {
        768: {
          slidesPerView: 1,
          direction: 'horizontal',
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            type: 'bullets',
            currentClass: 'swiper-pagination-current'
          },
        }
      }
    };
  }

  openWindow(item) {
    if (item['action'] && item['action'].includes('routing')) {
      if (item['section']) {
        this.router.navigateByUrl(item['route'], { state: { section: item['section'] }});
      } else {
        this.router.navigate([item['route']]);
      }

    } else if (item['action'] && item['action'].includes('whatsapp')) {
      const urlWhatsappWelcome = this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.app_url_whatsapp_welcome);
      this.data.openUrlExternal(urlWhatsappWelcome, '_blank');
    } else {
      this.data.openUrlExternal(item['action'], '_blank');
    }
  }
}



