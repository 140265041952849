
<div class="datos-col row">
    <div class="col-12 datos-destacado">
      <div class="destacado">{{ hotel.name }}</div>
      <div>{{ nights }}</div>
    </div>
  </div>
  <div class="datos-col row">
    <div class="col-6"><strong innerHTML="{{ 'CONFIRMATION.HOTEL.address' | translate }}">Dirección:</strong> {{ hotel.address }}</div>
    <div class="col-6"><strong>ID Purchase:</strong> # {{ confirmationData.transactionData.transactionNumber }}</div>
    <div class="col-6">
      <strong innerHTML="{{ 'CONFIRMATION.HOTEL.rooms' | translate }}">Habitación(s):</strong>
      {{ qrooms }}
    </div>
    <div class="col-6"><strong innerHTML="{{ 'CONFIRMATION.HOTEL.type' | translate }}">Tipo:</strong> {{ roomName }}</div>
    <div class="col-6"><strong innerHTML="{{ 'CONFIRMATION.HOTEL.guests' | translate }}">Huésped(s):</strong> {{ travellers }}</div>
    <div class="col-6"><strong innerHTML="{{ 'CONFIRMATION.HOTEL.board' | translate }}">Régimen de comida</strong> {{hotel.selectedOption.board}}</div>
    <div class="col-6"><strong innerHTML="{{ 'CONFIRMATION.HOTEL.checkin' | translate }}">Check in:</strong> {{ checkIn }}</div>
    <div class="col-6"><strong innerHTML="{{ 'CONFIRMATION.HOTEL.checkout' | translate }}">Check out:</strong> {{ checkOut }}</div>
    <div class="col-12"><i class="travel-icon icon-reembolsable"></i> {{refundable}}</div>
  </div>
