import { Component, OnDestroy, OnInit, AfterViewInit, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingScreenService } from '../../services/loading-screen.service';
import { DataStoreService } from '../../services/data-store.service';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../language/language.service';
import { EProduct } from '../searched/models/searched.model';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})

export class LoadingScreenComponent implements OnInit, OnDestroy, AfterViewInit {

  EProduct = EProduct;

  public translations = {};
  public textos: string;
  public textos2: string;
  public textos3: string;
  showMessage = false;

  get source(): string {
    this.textos = this.dataStoreService.preloaderImg;
    if (this._DOCUMENT.location.host.indexOf('registry') >= 0) {
      this.textos = '';
      this.textos2 = this.translations['LOADING_SCREEN.procesing'];
      this.textos3 = this.translations['LOADING_SCREEN.information'];
    } else {

      if (this.textos === 'procesing') {
        this.textos = '';
        this.textos2 = this.translations['LOADING_SCREEN.procesing'];
        this.textos3 = this.translations['LOADING_SCREEN.information'];
      }

      if (this.textos === 'general') {
        this.textos = '';
        this.textos2 = this.translations['LOADING_SCREEN.opening'];
        this.textos3 = this.translations['LOADING_SCREEN.travel_profile'];
      }

      if (this.textos === EProduct.FLIGHTS) {
        if (this._DOCUMENT.location.hash.indexOf('results') >= 0) {
          this.showMessage = true;
          this.textos = this.translations['LOADING_SCREEN.flight'];
          this.textos2 = this.translations['LOADING_SCREEN.searching'];
          this.textos3 = this.translations['LOADING_SCREEN.best_option_of'];
        } else if (this._DOCUMENT.location.hash.indexOf('registry') >= 0) {
          this.showMessage = true;
          this.textos2 = this.translations['LOADING_SCREEN.checking'];
          this.textos3 = this.translations['LOADING_SCREEN.Information2'];
        } else {
          this.showMessage = false;
        }
      }

      if (this.textos === EProduct.HOTELS) {
        if (this._DOCUMENT.location.hash.indexOf('results') >= 0) {
          this.textos = this.translations['LOADING_SCREEN.accommodation'];
          this.textos2 = this.translations['LOADING_SCREEN.searching'];
          this.textos3 = this.translations['LOADING_SCREEN.best_option_of'];
        } else if (this._DOCUMENT.location.hash.indexOf('registry') >= 0) {
          this.showMessage = true;
          this.textos2 = this.translations['LOADING_SCREEN.checking'];
          this.textos3 = this.translations['LOADING_SCREEN.Information2'];
        } else {
          this.showMessage = false;
        }
      }

      if (this.textos === EProduct.CARS) {
        if (this._DOCUMENT.location.hash.indexOf('results') >= 0) {
          this.textos = this.translations['LOADING_SCREEN.cars'];
          this.textos2 = this.translations['LOADING_SCREEN.searching'];
          this.textos3 = this.translations['LOADING_SCREEN.best_option_of'];
        } else if (this._DOCUMENT.location.hash.indexOf('registry') >= 0) {
          this.showMessage = true;
          this.textos2 = this.translations['LOADING_SCREEN.checking'];
          this.textos3 = this.translations['LOADING_SCREEN.Information2'];
        } else {
          this.showMessage = false;
        }
      }

      if (this.textos === EProduct.ACTIVITIES) {
        if (this._DOCUMENT.location.hash.indexOf('results') >= 0) {
          this.textos = this.translations['LOADING_SCREEN.activities'];
          this.textos2 = this.translations['LOADING_SCREEN.searching'];
          this.textos3 = this.translations['LOADING_SCREEN.best_option_of'];
        } else if (this._DOCUMENT.location.hash.indexOf('registry') >= 0) {
          this.showMessage = true;
          this.textos2 = this.translations['LOADING_SCREEN.checking'];
          this.textos3 = this.translations['LOADING_SCREEN.Information2'];
        } else {
          this.showMessage = false;
        }
      }

      if (this.textos === EProduct.BENEFITS) {
        if (this._DOCUMENT.location.hash.indexOf('results') >= 0) {
          this.textos = this.translations['LOADING_SCREEN.benefits'];
          this.textos2 = this.translations['LOADING_SCREEN.searching'];
          this.textos3 = this.translations['LOADING_SCREEN.best_option_of'];
        } else if (this._DOCUMENT.location.hash.indexOf('registry') >= 0) {
          this.showMessage = true;
          this.textos2 = this.translations['LOADING_SCREEN.checking'];
          this.textos3 = this.translations['LOADING_SCREEN.Information2'];
        } else {
          this.showMessage = false;
        }
      }
      if (this.textos === EProduct.ABOUT) {
        this.showMessage = false;
        this.textos = '';
        this.textos2 = this.translations['LOADING_SCREEN.opening'];
        this.textos3 = this.translations['LOADING_SCREEN.about'];
      }
    }

    if (this.dataStoreService
      && this.dataStoreService.brand
      && this.dataStoreService.brand.trim().length > 0) {
      return this.dataStoreService.preloaderImg;
    }

    return '';
  }

  loading = true;
  loadingSubscription: Subscription;

  constructor(
    private loadingScreenService: LoadingScreenService,
    private dataStoreService: DataStoreService,
    @Inject(DOCUMENT) private _DOCUMENT,
    private languageService: LanguageService,
    private translateService: TranslateService) {
    this.getTranslations();
  }

  ngOnInit() { }

  ngAfterViewInit() {
    this.loadingSubscription = this.loadingScreenService.loadingStatus.subscribe((value) => {
      this.loading = value;
    });
  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }

  getTranslations() {
    this.languageService.systemLanguajeChange.subscribe(language => {
      if (language) {
        this.translateService.get([
          'LOADING_SCREEN.fulltext',
          'LOADING_SCREEN.procesing',
          'LOADING_SCREEN.opening',
          'LOADING_SCREEN.information',
          'LOADING_SCREEN.searching',
          'LOADING_SCREEN.travel_profile',
          'LOADING_SCREEN.best_option_of',

          'LOADING_SCREEN.accommodation',
          'LOADING_SCREEN.flight',
          'LOADING_SCREEN.cars',
          'LOADING_SCREEN.activities',

          'LOADING_SCREEN.benefits',

          'LOADING_SCREEN.checking',
          'LOADING_SCREEN.Information2',

          'LOADING_SCREEN.about',

        ]).subscribe((trns) => {
          this.translations = trns;
        });
      }
    });
  }

}
