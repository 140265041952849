import { PurchaseSummaryService } from './../purchase-summary.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input } from '@angular/core';
import { Journey } from 'src/app/models/new-flight/journey.model';

@Component({
  selector: 'app-flight-summary',
  templateUrl: './flight-summary.component.html',
  styleUrls: ['./flight-summary.component.scss']
})
export class FlightSummaryComponent implements OnInit {

  translations = false;
  translationsFlight = [];
  translationDate = [];
  translationDataService = [];

  constructor(
    public translateService: TranslateService,
    public purchaseSummaryService: PurchaseSummaryService,
  ) {
    this.translationsFlight = purchaseSummaryService.translationsFlight;
    this.translationDate = purchaseSummaryService.translationDate;
    this.translationDataService = purchaseSummaryService.translationDataService;
  }

  get journey() {
    return this.purchaseSummaryService.journey as Journey;
  }
  get getBaggage() {
    const baggage = this.journey.info.baggage;
    let label = '';
    if (baggage.indexOf('PC') > -1) {
      label = ' ' + this.translationDataService['baggage_pieces'];
    } else if (baggage.indexOf('K') > -1) {
      label = this.translationDataService['baggage_kilograms'];
    }
    const qty = parseInt(baggage);
    if (qty === 1) {
      return this.translationDataService['baggage_one_piece'];
    } else if (qty > 1) {
      return qty + label;
    } else {
      return this.translationDataService['baggage_hand_baggage'];
    }
  }

  get locale() {
    return this.purchaseSummaryService.locale;
  }

  ngOnInit() {
  }

}
