import { Component, OnInit, Input } from '@angular/core';
import { IPromotion } from '../promociones.model';
import { EProduct } from '../../searched/models/searched.model';

@Component({
  selector: 'app-promocion',
  templateUrl: './promocion.component.html',
  styleUrls: ['./promocion.component.scss']
})
export class PromocionComponent implements OnInit {

  @Input() promotion: IPromotion;
  @Input() product: EProduct;

  @Input() showDetail:boolean = true;

  constructor() { }

  ngOnInit() {
  }

}
