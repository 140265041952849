import { Component, OnInit, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InitService } from '../../services/init.service';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';

@Component({
  selector: 'app-meet-myrewards',
  templateUrl: './meet-myrewards.component.html',
  styleUrls: ['./meet-myrewards.component.scss']
})
export class MeetMyrewardsComponent implements OnInit {

  config: any;
  showComponent = false;

  public video: string;
  public open: boolean;
  public play: boolean;


  constructor(private el: ElementRef, private init: InitService, private store: StoreServiceLibrary,
    public translate: TranslateService) { }

  ngOnInit(): void {
    this.init.isInitializedBrand.subscribe((init) => {
      if (init) {
        let configToRender = this.store.getItemFromStorage('CONFIG-HELP') || false;
        if (configToRender) {
          let MeetCenter = configToRender['meet-myrewards'] || false;
          if (MeetCenter) {
            this.config = configToRender['meet-myrewards'];
            this.showComponent = true;
          } else {
            this.showComponent = false;
          }
        } else {
          this.showComponent = false;
        }
      }
    });
  }

  openModal(video) {
    this.open = true;
    this.video = video;
    const elementVideo = this.el.nativeElement.querySelector('video');
    setTimeout(function(){
      elementVideo.play();
    }, 500)
  }

  closeModal() {
    this.open = false;
    const elementVideo = this.el.nativeElement.querySelector('video');
    elementVideo.pause();
    elementVideo.currentTime = 0;
    this.play = false;
  }

  playVideo() {
    this.play = true;
    const elementVideo = this.el.nativeElement.querySelector('video');
    elementVideo.play();
  }

}
