<div [class]="'detail ' + dataStore.brand">
    <div class="header">
        <app-detail-header></app-detail-header>
        <button (click)="close()" type="button" class="close"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="body b-hoteles-modales">
        <div class="row bl-seccion pb-3">
            <div class="col-12 col-md-7">
                <app-detail-gallery [images]="activity.images">
                </app-detail-gallery>
            </div>
            <div class="col-12 col-md-5">
                <app-detail-selection></app-detail-selection>
            </div>
        </div>

        <div class="row"
            *ngIf="activity !== undefined && activity.options !== undefined">
            <div class="col-12 col-md-7 cards-container">
                <div class="detalle-gral">
                    <h6>{{'HOTEL_DETAIL.general_information'|translate}}</h6>
                    <p>
                        {{activity.description|truncate:(isDescriptionShowingMore ? activity.description.length : 135)}}
                        <button class="see-more"
                            (click)="isDescriptionShowingMore=!isDescriptionShowingMore">
                            {{('HOTEL_DETAIL.see_' + ((isDescriptionShowingMore) ? 'less' : 'more'))|translate}}
                        </button>
                    </p>
                </div>
                <div *ngFor="let option of activity.options; let j = index; let f2 = first;"
                    class="option">
                    <app-activity-detail-card [isSelected]="isSelected(option)"
                        [option]="option" (selected)="optionListener($event)">
                    </app-activity-detail-card>
                </div>
            </div>
            <div class="col-12 col-md-5">
                <div class="mb-2 button-container mt-1 ps-0">
                    <button class="btn-travel"
                        [disabled]="hasOptionMeetingPoint != isSelectedMeetingPoint"
                        innerHTML="{{ 'HOTEL_DETAIL.continue' | translate }}"
                        (click)="continue()">Continue to register</button>
                </div>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
    <div class="footer mt-2">

    </div>
</div>