/**
 * formateador para mostrar las millas
 */
import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { LanguageService } from '../component/language/language.service';
import { environment } from '../../environments/environment';

@Pipe({
    name: 'milesFormat',
    pure: false
})
export class MilesFormatPipe implements PipeTransform {

    transform(value: number | string): any {
        return this.decimalPipe.transform(
            value,
            environment.pipeMilesFormat,
            this.languageService.systemLanguajeChange.value
        );
    }

    constructor(
        private languageService: LanguageService,
        private decimalPipe: DecimalPipe
    ) { }
}
