import { CardLayoutThreeComponent } from '../card-layout-three.component';
import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { LanguageService } from 'src/app/component/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { locale } from 'moment';

@Component({
  selector: 'app-dropdown-option',
  templateUrl: './dropdown-option.component.html',
  styleUrls: ['./dropdown-option.component.scss']
})
export class DropdownOptionComponent implements OnInit {

  translations = [];

  constructor(
    private languageService: LanguageService,
    private translateService: TranslateService,
    private layout: CardLayoutThreeComponent
  ) { }

  get product() {
    return this.layout.product;
  }

  get dropdownOptions() {
    return this.layout.product.dropdownOptions;
  }

  get selected() {
    return JSON.stringify(this.layout.options.selectedDropdownOption);
  }

  ngOnInit() {
    this.getTranslations();
  }

  getTranslations() {
    return new Promise<any>((resolve, response) => {
      this.languageService.systemLanguajeChange.subscribe(language => {
        if (language) {
          this.translateService.get([
            'DATE'
          ]).subscribe((trns) => {
            this.translations = trns;
            resolve(true);
          });
        }
      });
    });
  }

  formatTime(time) {
    try {
      return formatDate(
        time,
        this.translations['DATE']['format_weekday'],
        locale(this.languageService.systemLanguajeChange.value)
      ).toString();
    } catch (e) {
      return time;
    }
  }

  selectOption(ev) {
    ev = JSON.parse(ev);
    this.layout.dropDown.emit({
      product: this.product,
      option: ev
    });
  }

  parseValue(value) {
    return JSON.stringify(value);
  }
}
