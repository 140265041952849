import { UniversalService, AccountSumaryDto } from '@wearenovae/novae-core-services';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { DataStoreService } from '../../services/data-store.service';
import { DataService } from '../../services/data.service';

@Injectable({
  providedIn: 'root'
})
export class TravellerFormService {

  universalUrlBase = environment.brands[this.dataStore.brand].novaeUniversalUrlBase;

  universalJwtUrl: string;
  universalJwtUN: string;
  universalJwtPW: string;
  useJwtToken: boolean;

  constructor(
    private universalService: UniversalService,
    private dataStore: DataStoreService,
    private dataService: DataService
  ) {
    this.universalJwtUrl = environment.brands[this.dataStore.brand].UNIVERSAL_GET_JWTTOKEN_URL || '';
    this.universalJwtUN = environment.brands[this.dataStore.brand].UNIVERSAL_GET_JWTTOKEN_UN || '';
    this.universalJwtPW = environment.brands[this.dataStore.brand].UNIVERSAL_GET_JWTTOKEN_PW || '';
    this.useJwtToken = this.dataService.getJwtUse();
  }

  getCountries() {
    return new Promise<any>((resolve, reject) => {
      this.universalService.getCountries(this.universalUrlBase, atob(this.universalJwtUrl),
        atob(this.universalJwtUN),
        atob(this.universalJwtPW),
        this.useJwtToken).subscribe(res => {
          resolve(res);
        }, err => {
          reject();
        });
    });
  }

  getCities(country) {
    return new Promise<any>((resolve, reject) => {
      if (country) {
        this.universalService.getCityByCountryId(country, this.universalUrlBase, atob(this.universalJwtUrl),
          atob(this.universalJwtUN),
          atob(this.universalJwtPW),
          this.useJwtToken).subscribe(res => {
            resolve(res.cityDtoList);
          }, err => {
            reject();
          });
      }
    });
  }

  setTravellerData(accountSumaryDto?: AccountSumaryDto) {
    if (accountSumaryDto) {
      return {
        firstname: (accountSumaryDto.firstName) ? accountSumaryDto.firstName : '',
        lastname: (accountSumaryDto.lastName1) ? accountSumaryDto.lastName1 : '',
        birthDate: (accountSumaryDto.dateBirthday) ? accountSumaryDto.dateBirthday : '',
        isoCountry: (accountSumaryDto.addtionalInformationDto && accountSumaryDto.addtionalInformationDto.isoCountry) ? accountSumaryDto.addtionalInformationDto.isoCountry : '0',
        city: (accountSumaryDto.addtionalInformationDto && accountSumaryDto.addtionalInformationDto.cityName) ? accountSumaryDto.addtionalInformationDto.cityName : '0',
        documentTYpe: (accountSumaryDto.identificationDto && accountSumaryDto.identificationDto.typeId) ? accountSumaryDto.identificationDto.typeId : '',
        documentNumber: (accountSumaryDto.identificationDto && accountSumaryDto.identificationDto.number) ? accountSumaryDto.identificationDto.number : '',
        address: (accountSumaryDto.streetAddress) ? accountSumaryDto.streetAddress : '',
        email: (accountSumaryDto.emailaddress) ? accountSumaryDto.emailaddress : '',
        phone: (accountSumaryDto.addtionalInformationDto && accountSumaryDto.addtionalInformationDto.phoneNumber) ? accountSumaryDto.addtionalInformationDto.phoneNumber : '',
        phoneCountry: '',
        phoneArea: accountSumaryDto.indicativeCode || '',
        phoneNumber: (accountSumaryDto.addtionalInformationDto && accountSumaryDto.addtionalInformationDto.phoneNumber) ? accountSumaryDto.addtionalInformationDto.phoneNumber : '',
        title: (accountSumaryDto.title) ? accountSumaryDto.title : ''
      };
    } else {
      return {
        firstname: '',
        lastname: '',
        birthDate: '',
        isoCountry: '0',
        city: '0',
        documentNumber: '',
        email: '',
        phoneCountry: '',
        phoneArea: '',
        phoneNumber: '',
        address: '',
        documentTYpe: '',
        phone: '',
        title: ''
      };
    }
  }
}
