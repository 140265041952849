<div id="hotels" name="search-hotels" class="filtro-flotanteH tab-panel">
    <form [formGroup]="hotelSearchForm" novalidate class="hotelFormG" (ngSubmit)="searchH()">
        <div class="bl-form">
            <div class="city-date" (click)="searchFormInvalid = false">
                <div class="city">
                    <ng-select formControlName="destino" class="autocompleteSearch" id="destino" [items]="allCities" bindLabel="name" groupBy="type" dropdownPosition="bottom" [searchFn]="returnTrue" placeholder="{{'SEARCH_HOTELS.placeholder_destination'|translate}}" [virtualScroll]="true"
                        (search)="onSearch($event)" (change)="onChangeH($event)" [selectOnTab]="true" [notFoundText]="notFoundText">
                        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                            <div>
                                <span>{{item.name}}</span>
                            </div>
                        </ng-template>
                    </ng-select>
                    <div *ngIf="destino.invalid && searchFormInvalid" class="alert_red" innerHTML="{{'SEARCH_HOTELS.please_indicate_destination'|translate}}">
                        Please indicate the destination of your trip
                    </div>
                </div>
                <div class="fechasHotel">
                    <label innerHTML="{{'SEARCH_HOTELS.date_of_stay'|translate}}">
            Date of stay
          </label>

                    <div class="fecha-inputs" *ngIf="refresh">
                        <div class="bl-input-icon">
                            <input #dateStart [(ngModel)]="selected" class="dateSelect form-control" (openedChange)="openedChangeStart($event)" type="text" appCalendarSearch readonly formControlName="dateSelect" emptyWeekRowClass="empty-week" placeholder="{{'SEARCH_HOTELS.select_date' | translate}}"
                                [linkedCalendars]="true " [showDropdowns]="true " [autoApply]="true " [locale]="locale " [singleDatePicker]="true " [minDate]="minDate " [dateShowTogether]="false " [isStart]="true " />
                        </div>
                        <div class="bl-input-icon icon-last">
                            <input #dateEnd [(ngModel)]="selected" class="dateSelect form-control" (openedChange)="openedChangeEnd($event)" type="text" appCalendarSearch readonly formControlName="dateSelect" emptyWeekRowClass="empty-week" placeholder="{{'SEARCH_HOTELS.select_date' | translate}}"
                                [linkedCalendars]="true " [showDropdowns]="true " [autoApply]="true " [locale]="locale " [singleDatePicker]="true " [minDate]="minDate " [dateShowTogether]="false " [isEnd]="true " />
                        </div>
                    </div>
                </div>
            </div>
            <div class="guests " (click)="showGuestFormFn() ">
                <label innerHTML="{{ 'SEARCH_HOTELS.guests'|translate}} ">
          Guests
        </label>
                <input readonly formControlName="passenger" class="guestsForm " [placeholder]="placeholderGuests " attr-open-app-guest="true ">
                <app-guests [ngbCollapse]="showGuestForm "></app-guests>
                <div *ngIf="placeholderGuests==='' && searchFormInvalid " class="alert_red " innerHTML="{{ 'SEARCH_HOTELS.guest_information'|translate}} ">
                    Complete guest information
                </div>
            </div>
        </div>
        <div class="buscar " (click)="searchH() ">
            <span innerHTML="{{ 'SEARCH_HOTELS.search'|translate}} ">
        Search
      </span>
        </div>
    </form>
</div>