import { Component, OnInit } from '@angular/core';
import { AbstractCardComponent } from '../abstract-card.component';
import { GenericCard } from '../../../../../models/genericCard/generic-card';
import { AbstractCardListComponent } from '../../abstract-card-list.component';

@Component({
  selector: 'app-abstract-card-header',
  templateUrl: './abstract-card-header.component.html',
  styleUrls: ['./abstract-card-header.component.scss']
})
export class AbstractCardHeaderComponent implements OnInit {

  product: GenericCard;
  options: any;
  constructor(
    public cardCmp: AbstractCardComponent,
    public listCmp: AbstractCardListComponent
  ) {
  }

  ngOnInit() {
    this.product = this.cardCmp.product;
    this.options = this.listCmp.options;
  }

}
