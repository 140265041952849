import { ITraveller } from 'src/app/component/traveller-form/models/traveller.model';
import { TravellerFormService } from './traveller-form.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Inject, Renderer2, ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, FormControl, FormsModule } from '@angular/forms';
import { FormConfig, TravellerData } from './models/form-config.model';
import { TravellerType } from './enums/traveller-type';
import { FormType } from './enums/form-type';
import moment from 'moment';
import { locale } from 'moment';
import { formatDate } from '@angular/common';
import { CountryDto, keysUniversal, StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/component/language/language.service';
import { ActionEventService } from '../../services/action-event.service';
import { EProduct } from 'src/app/component/searched/models/searched.model';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-traveller-form',
  templateUrl: './traveller-form.component.html',
  styleUrls: ['./traveller-form.component.scss']
})
export class TravellerFormComponent implements OnInit {

  /**
   * true cuando el form esta inicializado
   */
  isFormInitialized = false;
  selected: any;
  codeCountry = [];
  isOpen = false;
  regexValidatorFirst: any;
  regexValidatorLast: any;
  style: any = { display: 'none' };

  @Output() formIsOk = new EventEmitter();
  @Input() formConfig: FormConfig = {
    formType: FormType.FLIGHT,
    travellerTypes: [TravellerType.ADT],
    travellerAges: [18],
    travelDate: '02-01-2020',
  };

  minDate: moment.Moment = moment().subtract(100, 'years');
  maxDate: moment.Moment = moment();

  get travellerQty() {
    return this.formConfig.travellerTypes.length;
  }

  get travellerAge() {
    try {
      return this.formConfig.travellerAges;
    } catch (e) {
      return null;
    }
  }

  get travellerType() {
    try {
      return this.formConfig.travellerTypes;
    } catch (e) {
      return null;
    }
  }

  get momentDate() {
    return moment();
  }

  get formType(): string {
    return this.formConfig.formType;
  }
  get travelDate() {
    return this.formConfig.travelDate;
  }

  get defaultTravellerData(): TravellerData {
    return {
      firstname: '',
      lastname: '',
      birthDate: '',
      isoCountry: '0',
      city: '0',
      documentNumber: '',
      email: '',
      phoneCountry: '',
      phoneArea: '',
      phoneNumber: '',
      address: '',
      documentTYpe: '0',
      phone: '',
      title: ''
    };
  }

  travellerForm: FormGroup;
  travellerData: TravellerData[] = [{
    firstname: '',
    lastname: '',
    birthDate: '',
    isoCountry: '',
    city: '',
    documentNumber: '',
    email: '',
    phoneCountry: '',
    phoneArea: '',
    phoneNumber: '',
    address: '',
    documentTYpe: '',
    phone: '',
    title: ''
  }];

  // Traducciones
  translations = [];
  translations_date = [];
  translations_genders = [];
  translations_doctype = [];

  // Listas
  countryList: CountryDto[] = [];
  cityList = []; // Array CityDto[]

  /**
   * refrescar campos fecha cuando cambian el idioma
   */
  refresherDates = true;

  /**
   * como mostrar fechas
   */
  locale: any = {
    format: ''
  };

  /**
   * form de hostel que se está mostrando
   */
  selectedFormIndex = 0;

  get formArray() {
    return this.travellerForm.get('formArray') as FormArray;
  }

  /**
   * listado de generos
   */
  get titleTypeList() {
    return this.translations_genders;
  }

  get documentTypes() {
    return this.translations_doctype;
  }




  /**
   * true si el form control name is valid
   */
  isFormControlValid(control: FormControl): boolean {
    return control.valid;
  }

  /**
   * true si el form control name is valid
   */
  isFormControlInvalid(control: FormControl): boolean {
    return control.invalid && control.touched;
  }

  // tslint:disable-next-line:member-ordering
  private stateClassRef = {
    normal: 'visible',
    collapsed: 'hidden'
  };
  // tslint:disable-next-line:member-ordering
  private state = 'normal'; // normal or collapsed

  constructor(
    private el: ElementRef,
    private rd: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private builder: FormBuilder,
    private travellerFormService: TravellerFormService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private actionEventService: ActionEventService,
    private storeLibrary: StoreServiceLibrary,
    private router: Router
  ) {
    this.travellerForm = this.builder.group({
      formArray: this.builder.array([])
    });
  }

  ngOnInit() {
    this.actionEventService.sendActiveItemMain('data');
    this.languageService.systemLanguajeChange.subscribe((language) => {
      if (language) {
        locale(language);
        this.getTranslations().then(() => {
          this.travellerData = (this.formConfig.travellerData) ? this.formConfig.travellerData : this.travellerData;
          this.fillFormArray();
          this.dateInputsRefresh();
        });
      }
    });

    this.regexValidatorFirst = this.storeLibrary.getJustOneUniversalPropertie(`_APP_REGEX_FIRST_NAME`);
    this.regexValidatorLast = this.storeLibrary.getJustOneUniversalPropertie('_APP_REGEX_LAST_NAME');

    this.travellerForm.statusChanges.subscribe(
      result => {
        if (result === 'VALID') {
          this.formatData();
        } else {
          this.formIsOk.emit(false);
        }
      });
    // cargar listado de paises
    this.loadCountries();
    this.codeCountry = JSON.parse(this.storeLibrary.getJustOneUniversalPropertie(keysUniversal._app_preferences_country_code));

    try {
      const codesPhone = JSON.parse(this.storeLibrary.getJustOneUniversalPropertie(keysUniversal._app_preferences_country_code));
      this.validateCodesPhone(codesPhone);
    } catch (error) { }
  }

  openedChange(opened: boolean) {
    this.style = { display: 'none' };
    this.isOpen = opened;
    this.state = this.state === 'normal' ? 'collapsed' : 'normal';
    this.el.nativeElement.closest('body').className = this.stateClassRef[this.state];

    let arrayTravellers: Array<any> = this.travellerForm.value.formArray;

    for (let index = 0; index < arrayTravellers.length; index++) {
      let codePhoneUsers: any = arrayTravellers[this.selectedFormIndex];
      if (codePhoneUsers['phoneArea'].length > 1) {
        this.selected = codePhoneUsers.phoneArea;
      } else {
        this.selected = '';
      }
    }
  }

  validateCamps() {
    let arrayTravellers: Array<any> = this.travellerForm.value.formArray;

    for (let index = 0; index < arrayTravellers.length; index++) {
      let codePhoneUsers: any = arrayTravellers[this.selectedFormIndex];
      if (codePhoneUsers['phoneArea'].length > 1) {
        this.selected = codePhoneUsers.phoneArea;
      } else {
        this.selected = '';
      }
    }
  }


  setStep(phoneArea: any) {
    this.isOpen = true;
    this.style = { display: 'block' };
    phoneArea.open();
  }




  validateCodesPhone(codes: Array<any>) {
    codes.map((data) => {
      if (data.language === this.translateService.currentLang) {
        data.codes.map((code) => {
          code.areaCode = `${code.areaCode}`;
        });
        this.codeCountry = data.codes;
      }
    });
  }



  formatData() {
    const travellersData: ITraveller[] = [];
    this.travellerForm.value.formArray.forEach((element, index) => {
      travellersData.push({
        id: index + 1,
        number: index + 1,
        type: element.userType,
        firstName: element.firstName,
        lastName: element.lastName,
        name: element.firstName,
        surname: element.lastName,
        documentType: element.documentType,
        documentNumber: element.documentNumber,
        nationality: element.country,
        city: element.city,
        address: element.address,
        gender: element.gender,
        dateOfBirth: formatDate(
          element.dateOfBirth.startDate ? element.dateOfBirth.startDate : element.dateOfBirth,
          'MM/dd/yyyy',
          moment.locale(this.translateService.getDefaultLang())
        ).toString(),
        age: moment().diff(element.dateOfBirth.startDate, 'years'),
        email: element.email,
        telephone: element.phone,
        telephoneCountry: element.phone.substring(0, 1),
        telephoneArea: element.phoneArea,
        requiredInformations: []
      });
    });
    this.emitFormResult(travellersData);
  }

  fillFormArray() {
    if (!this.isFormInitialized) {
      const ages = (this.formConfig.travellerAges) ? this.formConfig.travellerAges : [];
      const paxes = (this.formConfig.travellerTypes) ? this.formConfig.travellerTypes
        : (this.formConfig.travellerAges) ? this.formConfig.travellerAges
          : [];
      paxes.forEach((traveller, i) => {
        (this.travellerForm.controls.formArray as FormArray).push(this.createRow(traveller, ages[i], i));
      });

      this.isFormInitialized = true;
    }
  }

  createRow(travellerType, age, paxPosition): FormGroup {
    // {
    //   startDate: (age) ? moment().subtract(+age, 'years') : moment(),
    //   endDate: (age) ? moment().subtract(+age, 'years') : moment()
    // }
    const pax = (this.travellerData[paxPosition]) ? this.travellerData[paxPosition]
      : this.defaultTravellerData;
    let birthDate = undefined;
    if (pax.birthDate && pax.birthDate.length > 0 && pax.birthDate !== 'null') {
      const dateSegments = pax.birthDate.split(' ')[0].split('-');
      birthDate = new Date(+dateSegments[0], +dateSegments[1] - 1, +dateSegments[2], 12);
    }
    const abstractFormGroup = this.builder.group({
      userType: travellerType,
      userTypeName: this.getUserType(travellerType),
      firstName: [pax.firstname, [
        Validators.required,
        Validators.pattern(this.regexValidatorFirst)
      ]],
      lastName: [pax.lastname, [
        Validators.required,
        Validators.pattern(this.regexValidatorLast)
      ]],
      gender: [pax.title ? this.getTitleBySPId(pax.title) : '0', [
        Validators.required,
        Validators.pattern('[a-zA-Z]*'),
        Validators.pattern('^((?!0).)*$')
      ]],
      dateOfBirth: [{ startDate: this.setBirthDateDefault(birthDate, paxPosition), endDate: this.setBirthDateDefault(birthDate, paxPosition) }, [
        Validators.required,
      ]],
      documentType: [pax.documentTYpe, [
        Validators.required,
        Validators.pattern('[a-zA-Z]*'),
        Validators.pattern('^((?!0).)*$')
      ]],
      documentNumber: [pax.documentNumber, [
        Validators.required,
        Validators.pattern('[a-zA-Z0-9-/]+')
      ]],
      country: [pax.isoCountry, [
        Validators.required,
        Validators.pattern('[a-zA-Z]*'),
        Validators.pattern('^((?!0).)*$')
      ]],
      city: [pax.city, [
        Validators.required,
        Validators.pattern('^((?!0).)*$')
      ]],
      address: [pax.address, [
        Validators.required,
        Validators.pattern('^.{1,}$')
      ]],
      phoneArea: [pax.phoneArea, [
        Validators.required
      ]],
      phone: [pax.phoneNumber, [
        Validators.required,
        Validators.pattern('[0-9]*')
      ]],
      email: [pax.email, [
        Validators.required,
        Validators.pattern('[a-zA-ZñÑ0-9.!#$%&\'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*')
      ]],
    });
    this.selected = pax.phoneArea;
    abstractFormGroup.updateValueAndValidity();
    return abstractFormGroup;
  }

  getUserType(travellerType) {
    switch (travellerType) {
      case 'ADT':
        return this.translations['adult'];
      case 'CHD':
        return this.translations['child'];
      case 'INF':
        return this.translations['infant'];
      default:
        return '';
    }
  }

  emitFormResult(formData) {
    this.formIsOk.emit(formData);
  }

  private getTranslations() {
    return new Promise<any>((resolve, reject) => {
      this.translateService.get([
        'TRAVELLER_FORM',
        'DATE',
        'GENDERS',
        'LOGIN.IDtypes'
      ]).subscribe((trans) => {
        this.locale.daysOfWeek = moment.weekdaysMin();
        this.locale.monthNames = moment.monthsShort();
        this.translations = trans['TRAVELLER_FORM'];
        this.translations_doctype = trans['LOGIN.IDtypes'];
        this.translations_date = trans['DATE'];
        this.translations_genders = trans['GENDERS'];
        resolve(true);
      });
    });
  }

  /**
   * refrescar los inputs de fechas para que muestren bien la fecha
   */
  private dateInputsRefresh() {
    this.refresherDates = false;
    this.locale.format = this.translations_date['format'];
    setTimeout(() => {
      this.refresherDates = true;
    }, 10);
  }

  setMinDate = i => {
    if (this.travellerAge && this.travellerAge[i]) {
      return moment(this.travelDate).subtract(+this.travellerAge[i] + 1, 'years');
      // return moment().subtract(100, 'years');
    } else {
      switch (this.formType) {
        case EProduct.FLIGHTS:
          if (this.travellerType[i] === 'ADT') {
            return moment(this.travelDate).subtract(100, 'years');
          } else if (this.travellerType[i] === 'CHD') {
            return moment(this.travelDate).subtract(+11, 'years');
          } else if (this.travellerType[i] === 'INF') {
            return moment(this.travelDate).subtract(+2, 'years');
          }
          return moment(this.travelDate).subtract(100, 'years');

        case EProduct.HOTELS:
          if (this.travellerType[i] === 'ADT') {
            return moment(this.travelDate).subtract(100, 'years');
          } else if (this.travellerType[i] === 'CHD') {
            return moment(this.travelDate).subtract(+18, 'years');
          }
          return moment(this.travelDate).subtract(100, 'years');
        case EProduct.CARS:
          return moment(this.travelDate).subtract(100, 'years');
      }
    }
  }

  setMaxDate = i => {
    if (this.travellerAge && this.travellerAge[i]) {
      return moment(this.travelDate).subtract(+this.travellerAge[i], 'years');
    } else {
      switch (this.formType) {
        case EProduct.FLIGHTS:
          if (this.travellerType[i] === 'ADT') {
            return moment(this.travelDate).subtract(+11, 'years');
          } else if (this.travellerType[i] === 'CHD') {
            return moment(this.travelDate).subtract(+2, 'years');
          } else if (this.travellerType[i] === 'INF') {
            return moment(this.travelDate).subtract(+0, 'years');
          }
          return moment(this.travelDate).subtract(100, 'years');

        case EProduct.HOTELS:
          if (this.travellerType[i] === 'ADT') {
            return moment(this.travelDate).subtract(+18, 'years');
          } else if (this.travellerType[i] === 'CHD') {
            return moment(this.travelDate).subtract(+0, 'years');
          }
          return moment(this.travelDate).subtract(100, 'years');
        case EProduct.CARS:
          return moment(this.travelDate).subtract(+18, 'years');
      }
    }
  }

  isInvalidPhoneNumberMessage(row) {
    if (row.get('contactPhoneCountry').invalid && row.get('contactPhoneCountry').touched) {
      return this.translations['phone_invalid'];
    } else if (row.get('contactPhoneArea').invalid && row.get('contactPhoneArea').touched) {
      return this.translations['phone_invalid'];
    } else if (row.get('contactPhone').invalid && row.get('contactPhone').touched) {
      return this.translations['phone_invalid'];
    } else {
      return false;
    }
  }

  /**
   * Obtener listado de paises
   */
  private loadCountries() {
    this.countryList = [];
    this.travellerFormService.getCountries().then((res) => {
      this.countryList = res.countryDtoList;
      this.fillCitiesList(0);
    });
  }

  /**
   * Obtener listado de Ciiudades
   */
  fillCitiesList(i) {
    const pax = this.formArray.value[i];
    if (pax && pax.country !== '0') {
      const country = this.countryList.find(item => {
        return item.iso === pax.country;
      });
      this.travellerFormService.getCities(country.id).then(cities => {
        this.cityList[i] = (cities && cities.length > 0) ? cities
          : [{
            active: true,
            countryId: 315,
            id: 1253,
            indicative: '0',
            name: 'Otro'
          }];

        const formGroup = (this.formArray.controls[i] as FormGroup);
        if (this.cityList[i] != null) {
          const validCity = this.checkIsValidCity(this.formArray.value[i].city, this.cityList[i]);
          if (validCity) {
            formGroup.get('city').setValue(validCity.name);
          }
        } else if (!this.cityList[i]) {
          formGroup.controls.city.disable();
        }
      });
    } else {
      this.cityList[i] = [];
      const formGroup = (this.formArray.controls[i] as FormGroup);
      formGroup.controls[i]['city'].disable();
    }
  }

  checkIsValidCity(city, cityList): any {
    const isInList = cityList.find(item => {
      return item.name.toString().toUpperCase() === city.toUpperCase();
    });
    return isInList;
  }

  getTitleBySPId(spId: string) {
    const title = this.titleTypeList.find(title => {
      return title.spId.toString() === spId;
    });
    if (title) {
      return title.id;
    } else {
      return '0';
    }
  }

  validateRequireds(formValidated: FormGroup) {
    const urlService = this.router.url.split('/')[1];
    switch (urlService) {
      case 'flights':
        formValidated.controls['country'] = new FormControl(formValidated.controls['country'].value, []);
        formValidated.controls['city'] = new FormControl(formValidated.controls['city'].value, []);
        formValidated.controls['address'] = new FormControl(formValidated.controls['address'].value, []);
        formValidated.controls['phoneArea'] = new FormControl(formValidated.controls['phoneArea'].value, []);
        formValidated.controls['phone'] = new FormControl(formValidated.controls['phone'].value, []);
        formValidated.updateValueAndValidity();
        break;
      case 'hotels':
        let birthDateControl: any = formValidated.controls['dateOfBirth'].value;
        let birthDateForm = undefined;

        if (birthDateControl && birthDateControl.length > 0 && birthDateControl !== 'null') {
          const dateSegments = birthDateControl.split(' ')[0].split('-');
          birthDateForm = new Date(+dateSegments[0], +dateSegments[1] - 1, +dateSegments[2], 12);
        }

        formValidated.controls['gender'] = new FormControl(formValidated.controls['gender'].value, []);
        /*         formValidated.controls['dateOfBirth'] = new FormControl(
                  [birthDateForm ? { startDate: moment(birthDateForm), endDate: moment(birthDateForm) } : '', []]); */
        formValidated.controls['documentType'] = new FormControl(formValidated.controls['documentType'].value, []);
        formValidated.controls['documentNumber'] = new FormControl(formValidated.controls['documentNumber'].value, []);
        formValidated.controls['country'] = new FormControl(formValidated.controls['country'].value, []);
        formValidated.controls['city'] = new FormControl(formValidated.controls['city'].value, []);
        formValidated.controls['address'] = new FormControl(formValidated.controls['address'].value, []);
        formValidated.controls['phoneArea'] = new FormControl(formValidated.controls['phoneArea'].value, []);
        formValidated.controls['phone'] = new FormControl(formValidated.controls['phone'].value, []);
        formValidated.updateValueAndValidity();
        break;
      case 'cars':
        formValidated.controls['city'] = new FormControl(formValidated.controls['city'].value, []);
        formValidated.controls['address'] = new FormControl(formValidated.controls['address'].value, []);
        formValidated.updateValueAndValidity();
        break;
      case 'activities':
        let birthDateControlAct: any = formValidated.controls['dateOfBirth'].value;
        let birthDateFormAct = undefined;

        if (birthDateControlAct && birthDateControlAct.length > 0 && birthDateControlAct !== 'null') {
          const dateSegments = birthDateControlAct.split(' ')[0].split('-');
          birthDateFormAct = new Date(+dateSegments[0], +dateSegments[1] - 1, +dateSegments[2], 12);
        }

        formValidated.controls['gender'] = new FormControl(formValidated.controls['gender'].value, []);
        /*         formValidated.controls['dateOfBirth'] = new FormControl(
                  [birthDateForm ? { startDate: moment(birthDateForm), endDate: moment(birthDateForm) } : '', []]); */
        formValidated.controls['documentType'] = new FormControl(formValidated.controls['documentType'].value, []);
        formValidated.controls['documentNumber'] = new FormControl(formValidated.controls['documentNumber'].value, []);
        formValidated.controls['country'] = new FormControl(formValidated.controls['country'].value, []);
        formValidated.controls['city'] = new FormControl(formValidated.controls['city'].value, []);
        formValidated.controls['address'] = new FormControl(formValidated.controls['address'].value, []);
        formValidated.controls['phoneArea'] = new FormControl(formValidated.controls['phoneArea'].value, []);
        formValidated.controls['phone'] = new FormControl(formValidated.controls['phone'].value, []);
        break;
      default:
        break;
    }


  }
  setBirthDateDefault(birthDate, i) {

    if (birthDate === undefined) {
      return moment(this.travelDate).subtract(+18, 'years');
    } else {
      switch (this.formType) {
        case EProduct.FLIGHTS:
          if (this.travellerType[i] === 'ADT' && moment().diff(birthDate, 'years') < 11) {
            return moment(formatDate(this.travelDate, 'MM/dd/yyyy', moment.locale(this.translateService.getDefaultLang()))
              .toString()).subtract(+11, 'years');
          } else if (this.travellerType[i] === 'CHD' && (moment().diff(birthDate, 'years') > 10 || moment().diff(birthDate, 'years') < 2)) {
            return moment(formatDate(this.travelDate, 'MM/dd/yyyy', moment.locale(this.translateService.getDefaultLang()))
              .toString()).subtract(+2, 'years');
          } else if (this.travellerType[i] === 'INF' && moment().diff(birthDate, 'years') > 1) {
            return moment(formatDate(this.travelDate, 'MM/dd/yyyy', moment.locale(this.translateService.getDefaultLang()))
              .toString()).subtract(+0, 'years');
          } else {
            return moment(birthDate, 'MM/dd/yyyy');
          }
        case EProduct.HOTELS:
          if (this.travellerType[i] === 'ADT' && moment().diff(birthDate, 'years') < 18) {
            return moment(formatDate(this.travelDate, 'MM/dd/yyyy', moment.locale(this.translateService.getDefaultLang()))
              .toString()).subtract(+18, 'years');
          } else if (this.travellerType[i] === 'CHD' && moment().diff(birthDate, 'years') > 17) {
            return moment(formatDate(this.travelDate, 'MM/dd/yyyy', moment.locale(this.translateService.getDefaultLang()))
              .toString()).subtract(+0, 'years');
          } else {
            return moment(birthDate, 'MM/dd/yyyy');
          }
        case EProduct.CARS:
          if (moment().diff(birthDate, 'years') < 18) {
            return moment(formatDate(this.travelDate, 'MM/dd/yyyy', moment.locale(this.translateService.getDefaultLang()))
              .toString()).subtract(+18, 'years');
          } else {
            return moment(birthDate, 'MM/dd/yyyy');
          }
        default:
          return moment(birthDate, 'MM/dd/yyyy');

      }
    }
  }
}
