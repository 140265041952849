import {Component, OnInit} from '@angular/core';
import {CardComponent} from '../../card.component';
import { getCurrencySymbol } from '@angular/common';

@Component({
  selector: 'app-card-price',
  templateUrl: './card-price.component.html',
  styleUrls: ['./card-price.component.scss']
})
export class CardPriceComponent implements OnInit {

  product: any;
  options: any;
  price: any;

  constructor(
    public card: CardComponent
  ) { }

  get currency() {
    return this.price.currency + ' ' + getCurrencySymbol(this.price.currency, 'narrow');
  }

  ngOnInit() {
    this.options = this.card.options;
    this.product = this.card.product;
    this.price = this.card.product.price;
  }
  selectDetail() {
    this.card.productDetailSelected.emit(this.product);
  }

}

