import { Component, OnInit, Input, Output, EventEmitter, } from '@angular/core';
import { CardLayoutTwoModel } from '../../models/card-layout-two.model';
import { IOptions } from '../../models/options.model';
import { InitService } from 'src/app/services/init.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/component/language/language.service';

@Component({
  selector: 'app-card-layout-two',
  templateUrl: './card-layout-two.component.html',
  styleUrls: ['./card-layout-two.component.scss']
})
export class CardLayoutTwoComponent implements OnInit {

  @Input() product: CardLayoutTwoModel;
  @Input() options: IOptions;
  @Output() getProductDetail = new EventEmitter();

  isHidden = true;

  public translations;
  get totalMoneyPrice() {
    return this.product.getMoneyAmount();
  }
  get totalMilesPrice() {
    return this.product.getPointAmount();
  }
  get currency() {
    return this.product.price.currency;
  }
  get nights(): number {
    if (!this.product.info || this.product.info.length === 0) { return 0; }
    try {
      const nights = this.product.info.find( item => item.type === 'night' ).value;
      return +nights;
    } catch {
      return 0;
    }
  }
  get travellers(): number {
    if (!this.product.info || this.product.info.length === 0) { return 0; } 
    try {
      const travellers = this.product.info.find( item => item.type === 'traveller' ).value;
      return +travellers;
    } catch {
      return 0;
    }
  }
  get nameSelected() {
    return this.product.nameSelected();
  }
  get subnameSelected() {
    return this.product.subnameSelected();
  }

  constructor(
    private initService: InitService,
    private translateService: TranslateService,
    private languageService: LanguageService,
  ) { }

  ngOnInit() {
    this.initService.isInitializedBrand.subscribe(async isInitialized => {
      if (isInitialized) {
        this.languageService.systemLanguajeChange.subscribe((language) => {
          if (language) {
            this.getTranslations().then(() => {
            });
          }
        });
      }
    });
    if (!this.product.images || this.product.images === null || this.product.images.length === 0) {
      this.isHidden = !this.options.isViewDetail;
    }
  }

  getTranslations() {
    return new Promise<any>((resolve) => {
      this.translateService.get([
        'LAYOUT_TWO',
      ]).subscribe(trans => {
        this.translations = trans['LAYOUT_TWO'];
        resolve(true);
      });
    });
  }

  productDetail() {
    this.getProductDetail.emit(this.product);
  }

}
