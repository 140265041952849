import { LanguageService } from './../../language/language.service';
import { Component, OnInit, Input } from '@angular/core';
import { PurchaseSummaryService } from '../purchase-summary.service';
import { SearchedService } from '../../searched/searched.service';
import { SearchedCar } from '../../searched/models/searched-car.model';
import { getCurrencySymbol, formatDate } from '@angular/common';
import { locale } from 'moment';
import { RentacarAvailabilityOption } from 'src/app/models/new-rentacar/rentacar-availability-option.model';

@Component({
  selector: 'app-car-summary',
  templateUrl: './car-summary.component.html',
  styleUrls: ['./car-summary.component.scss']
})
export class CarSummaryComponent implements OnInit {

  /**
   * modelo con los parámetros de búsqueda
   */
  searchedCar: SearchedCar;
  tranlationsCar = [];
  translationDate = [];

  @Input() car: RentacarAvailabilityOption;

  get isSamePlaceWithdrawalAndReturn() {
    return this.searchedCar.isSamePlaceWithdrawalAndReturn;
  }

  get pickUpDate() {
    return this.searchedCar.dateToHumanReadable.start;
  }

  get pickUpHour() {
    return this.searchedCar.timeToHumanReadable.start;
  }

  get dropOffDate() {
    return this.searchedCar.dateToHumanReadable.end;
  }

  get dropOffHour() {
    return this.searchedCar.timeToHumanReadable.end;
  }

  get cancellationPolicies() {
    if (this.car.bookingRules.cancellationPolicy) {
      return this.car.bookingRules.cancellationPolicy;
    } else {
      return null;
    }
  }

  get ACRISS() {
    try {
      const info = this.car.info.find(item => item.type === 'ACRISS');
      return info;
    } catch(e) {
      return undefined;
    }
  }

  get currency() {
    const currency = this.purchaseSummaryService.activity.selectedOption.selectedDate.price.currency;
    return currency + ' ' + getCurrencySymbol(currency, 'narrow');
  }

  fromDate(value) {
    return formatDate(
      value.from,
      this.translationDate['pipe_date'],
      locale(this.languageService.systemLanguajeChange.value));
  }

  constructor(
    private purchaseSummaryService: PurchaseSummaryService,
    private searchedService: SearchedService,
    private languageService: LanguageService
  ) {
    this.tranlationsCar = this.purchaseSummaryService.translationsCar;
    this.translationDate = this.purchaseSummaryService.translationDate;
    this.searchedCar = this.searchedService.getModel(SearchedCar);
  }

  ngOnInit() {
    this.car.address;
  }

}
