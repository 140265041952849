import { ActivityDetailComponent } from './../activity-detail.component';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detail-header',
  templateUrl: './detail-header.component.html',
  styleUrls: ['./detail-header.component.scss']
})
export class DetailHeaderComponent implements OnInit {

  constructor(
    public activity: ActivityDetailComponent
  ) { }

  ngOnInit() {
  }

}
