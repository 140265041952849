import { CardLayoutOneService } from './../card-layout-one.service';
import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Flight } from 'src/app/models/new-flight/journey.model';


@Component({
  selector: 'app-segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.scss']
})
export class SegmentComponent implements OnInit {

  @Input() index;
  @Input() flight: Flight;

  show = false;
  init = false;
  translations: any;
  get stops() {
    const stops = this.flight.segments.length - 1;
    switch (stops) {
      case 0:
        return this.translations['direct'];
        break;
      case 1:
        return stops + ' ' + this.translations['stop'];
        break;
      case 2:
        return stops + ' ' + this.translations['stops'];
        break;
      default:
        break;
    }
  }

  constructor(private cardLayoutOneService: CardLayoutOneService) { }

  ngOnInit() {
    this.cardLayoutOneService.getTranslations().then(() => {
      this.init = true;
      this.translations = this.cardLayoutOneService.translations['LAYOUT_ONE'];
    });
  }

}
