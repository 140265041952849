import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FiltersComponent} from "./filters.component";
import { FilterGroupComponent } from './filter-group/filter-group.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {Ng5SliderModule} from "ng5-slider";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {NumberToArrPipeBis} from "./number-to-arr.bis.pipe";
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    FiltersComponent,
    FilterGroupComponent,
    NumberToArrPipeBis,
  ],
  exports: [
    FiltersComponent
  ],
  imports: [
    CommonModule,
    MatCheckboxModule,
    FormsModule,
    NgSelectModule,
    Ng5SliderModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
  ]
})
export class FiltersModule { }
