import { Journey } from './../../../models/new-flight/journey.model';
import { EProduct } from '../../../component/searched/models/searched.model';
import { formatDate } from '@angular/common';
import { locale } from 'moment';
import { environment } from 'src/environments/environment';
import { SearchedHotel } from 'src/app/component/searched/models/searched-hotel.model';
import { SearchedCar } from 'src/app/component/searched/models/searched-car.model';
import { SearchedFlight } from 'src/app/component/searched/models/searched-flight.model';
import { SearchedActivity } from 'src/app/component/searched/models/searched-activity.model';
import { SearchedBenefit } from 'src/app/component/searched/models/searched-benefit.model';
import {
    Trayecto, FlightInformation, DetailFlight,
    BusinessInformation, DetailsProductJson,
    AddPurchaseRequest, SecurityObject, MerchanRequest,
    PurchaseRequest, ReferenceBase, Reference,
    ProductRequest, BodyAddPurchase, DetailStay
} from '@wearenovae/novae-core-services';
import { IActivityResult } from 'src/app/models/activities/response/activity-availability.model';
import { IBenefitResponse } from 'src/app/models/benefitResponce-model';
import { HotelAvailabilityOptions } from 'src/app/models/new-hotel/hotel-availability-response.model';
import { RentacarAvailabilityOption } from 'src/app/models/new-rentacar/rentacar-availability-option.model';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { keysUniversal } from 'src/app/resources/keys.resources';

export class PurchaseProductInfo {

    private postSilenciosoURL: string;

    constructor(
        public product: Journey | HotelAvailabilityOptions | RentacarAvailabilityOption | IActivityResult | IBenefitResponse,
        public productType: EProduct,
        public isoLanguage = 'ES',
        public brand = 'novae',
        public channelId = 3,
        public applicationId = 27,
        public searchedData?: SearchedHotel | SearchedCar | SearchedFlight | SearchedActivity | SearchedBenefit,
        public urlPostUri?: string
    ) {
        this.postSilenciosoURL = urlPostUri;
    }

    get description() {
        const product = this.product;
        switch (this.productType) {
            case EProduct.FLIGHTS:
                const cities = (product as Journey).flights.map(item => {
                    return item.origin.cityName;
                }).join(' - ');
                return 'Vuelo: ' + cities;
                break;
            case EProduct.HOTELS:
                const hotel = (product as HotelAvailabilityOptions).name;
                const rooms = (product as HotelAvailabilityOptions).selectedOption.rooms.map(item => {
                    return item.name;
                }).join(', ');
                return 'Alojamiento en ' + hotel + '. Habitación(es): ' + rooms;
                break;
            case EProduct.ACTIVITIES:
                const activityPlace = (this.product as IActivityResult).name;
                const activity = (this.product as IActivityResult).selectedOption.name;
                return 'Compra de Actividad'; // 'Actividad: ' + activity;
                break;
            case EProduct.CARS:
                const car = (this.product as RentacarAvailabilityOption).name;
                return 'Auto: ' + car;
                break;
            case EProduct.BENEFITS:
                const benefit = (this.product as IBenefitResponse).productName;
                return benefit;
                break;
            default:
                return '-';
                break;
        }
    }

    get purchaseName() {
        const product = this.product as Journey;
        switch (this.productType) {
            case EProduct.FLIGHTS:
                return 'Pago de reserva de vuelo';
                break;
            default:
                return '-';
                break;
        }
    }

    get price() {
        switch (this.productType) {
            case EProduct.FLIGHTS:
                return +(this.product as Journey).price.totalPrice;
                break;
            case EProduct.HOTELS:
                return +(this.product as HotelAvailabilityOptions).selectedOption.price.totalPrice;
                break;
            case EProduct.ACTIVITIES:
                return +(this.product as IActivityResult).selectedOption.selectedDate.price.totalPrice;
                break;
            case EProduct.CARS:
                return +(this.product as RentacarAvailabilityOption).price.totalPrice;
                break;
            case EProduct.BENEFITS:
                return +(this.product as IBenefitResponse).price.totalPrice;
                break;
            default:
                return 0;
                break;
        }
    }

    get quantiy() {
        return 1;
    }

    get categoryName() {
        switch (this.productType) {
            case EProduct.FLIGHTS:
                return 'vuelo';
                break;
            default:
                return '0';
                break;
        }
    }

    get validatingCompany() {
        return (this.product as Journey).validatingCompany.longName;
    }

    get accountId() {
        switch (this.productType) {
            case EProduct.FLIGHTS:
                return environment.brands[this.brand].merchantAccountId['flight'];
            case EProduct.HOTELS:
                return environment.brands[this.brand].merchantAccountId['hotel'];
            case EProduct.CARS:
                return environment.brands[this.brand].merchantAccountId['cars'];
                break;
            case EProduct.ACTIVITIES:
                return environment.brands[this.brand].merchantAccountId['activities'];
            case EProduct.BENEFITS:
                return environment.brands[this.brand].merchantAccountId['benefits'];
            default:
                break;
        }
    }

    get merchantName() {
        return this.brand + ' Shoping';
    }

    get purchaseCurrency() {
        const product = this.product;
        switch (this.productType) {
            case EProduct.FLIGHTS:
                return (this.product as Journey).price.currency;
                break;
            case EProduct.HOTELS:
                return (this.product as HotelAvailabilityOptions).selectedOption.price.currency;
                break;
            case EProduct.ACTIVITIES:
                return (this.product as IActivityResult).price.currency;
                break;
            case EProduct.CARS:
                return (this.product as RentacarAvailabilityOption).price.currency;
                break;
            case EProduct.BENEFITS:
                return (this.product as IBenefitResponse).productCurrency;
                break;
            default:
                return '-';
                break;
        }
    }

    get merchantRequest() {
        // Merchant
        const merchant = new MerchanRequest();
        merchant.accountID = this.accountId;
        merchant.name = this.merchantName;
        merchant.postURL = this.postSilenciosoURL + 'booking/makeBooking/'; // TODO: CAMBIAR A ENDPOINT DE BOOKING
        merchant.answerURL = 'http://answer.com';
        merchant.merchantID = 1;
        merchant.authenticationMerchant = '1';

        return merchant;
    }

    get referenceRequest() {
        // Reference Base
        const referenceBase = new ReferenceBase();
        referenceBase.channelID = this.channelId;
        referenceBase.applicationID = this.applicationId;
        referenceBase.payMethodID = 1;
        referenceBase.portalID = 1;

        // Reference
        const reference = new Reference();
        reference.reference = this.merchantName;
        reference.referenceBase = referenceBase;

        return reference;
    }

    get productRequest() {
        // product
        const productRequest = new ProductRequest();
        productRequest.itemName = this.purchaseName;
        productRequest.itemValue = this.price + '';
        productRequest.itemQuantity = this.quantiy;
        productRequest.categoryName = this.categoryName;
        return productRequest;
    }

    get purchaseRequest() {
        // Purchase
        let purchase = new PurchaseRequest();

        switch (this.productType) {
            case EProduct.ACTIVITIES:
                // GUARDAR DETALLES DE LA ACTIVIDAD
                purchase = this.detailActivityData();
                break;
            case EProduct.CARS:
                // GUARDAR DETALLES DEL AUTO
                purchase = this.detailCarData();
                break;
            default:
                purchase.description = this.description;
                purchase.value = this.price;
                purchase.currencyISO = this.purchaseCurrency;
                break;
        }


        return purchase;
    }

    get addPurchaseRequest(): BodyAddPurchase {

        const detailsProductJson = new DetailsProductJson();
        const addPurchaseRequest = new AddPurchaseRequest();
        addPurchaseRequest.security = SecurityObject.getSecurityObject();
        addPurchaseRequest.isoLanguage = this.isoLanguage;

        detailsProductJson.businessInformation = new BusinessInformation();

        switch (this.productType) {
            case EProduct.FLIGHTS:
                // GUARDAR DETALLES DEL VUELO
                const detailFlight = this.detailFlightData();
                detailsProductJson.type = EProduct.FLIGHTS;
                detailsProductJson.businessInformation.detailFlight = detailFlight;
                break;
            case EProduct.HOTELS:
                // GUARDAR DETALLES DEL HOTEL
                const detailHotel = this.detailHotelData();
                detailsProductJson.type = EProduct.HOTELS;
                detailsProductJson.businessInformation.detailStay = detailHotel;
                break;
            case EProduct.ACTIVITIES:
                detailsProductJson.type = EProduct.ACTIVITIES;
                break;
            case EProduct.CARS:
                detailsProductJson.type = EProduct.CARS;
                break;
            case EProduct.BENEFITS:
                detailsProductJson.type = EProduct.BENEFITS;
                break;
            default:
                break;
        }

        const purchase = this.purchaseRequest;
        purchase.detailsProductJson = detailsProductJson;
        addPurchaseRequest.purchase = purchase;

        const merchant = this.merchantRequest;
        addPurchaseRequest.merchant = merchant;

        const reference = this.referenceRequest;
        addPurchaseRequest.reference = reference;

        const productRequest = this.productRequest;
        const listProduct = [];
        listProduct[0] = productRequest;
        addPurchaseRequest.product = listProduct;

        if (this.productType !== EProduct.BENEFITS) {
            addPurchaseRequest.type = 'ADJUSTMENT_REDEMPTION';
        }

        const request = new BodyAddPurchase();
        request.addPurchaseRequest = addPurchaseRequest;
        return request;
    }

    detailHotelData(): DetailStay {
        const detailStay = new DetailStay();
        const searchedHotel = (this.searchedData as SearchedHotel);

        detailStay.numDays = searchedHotel.qNights;
        detailStay.numNights = searchedHotel.qNights;
        detailStay.city = searchedHotel.destination.name;
        detailStay.hotelName = (this.product as HotelAvailabilityOptions).name;
        detailStay.reservationCode = '';
        return detailStay;
    }

    detailActivityData(): PurchaseRequest {
        const purchase = new PurchaseRequest();
        purchase.description = this.description;
        purchase.value = this.price;
        purchase.currencyISO = this.purchaseCurrency;
        purchase.tax = '0';
        purchase.basisDevolution = '0';
        purchase.basisTaxDevolution = '0';
        purchase.tips = '0';
        purchase.discountValue = '0';
        purchase.iceValue = '0';
        purchase.additionalValue = '0';
        purchase.shippingValue = '0';
        purchase.service = '0';
        return purchase;
    }

    detailCarData(): PurchaseRequest {
        const purchase = new PurchaseRequest();
        purchase.description = this.description;
        purchase.value = this.price;
        purchase.currencyISO = this.purchaseCurrency;
        purchase.tax = '0';
        purchase.basisDevolution = '0';
        purchase.basisTaxDevolution = '0';
        purchase.tips = '0';
        purchase.discountValue = '0';
        purchase.iceValue = '0';
        purchase.additionalValue = '0';
        purchase.shippingValue = '0';
        purchase.service = '0';
        return purchase;
    }

    detailFlightData(): DetailFlight {
        const flightLength = (this.product as Journey).flights.length;

        const detailFlight = new DetailFlight();
        detailFlight.aerline = this.validatingCompany;
        detailFlight.PNRCode = 'pending'; // TODO: Consultar dato a enviar aca D:
        detailFlight.outBoundFlight = this.getFlightData(0);

        if (flightLength > 0) {
            detailFlight.returnFlight = this.getFlightData(flightLength - 1);
        }

        return detailFlight;
    }

    getFlightData(i): FlightInformation {
        const flight = new FlightInformation();
        flight.fecha = this.flightDate(i);
        flight.duracion = this.flightDuration(i);
        flight.clase = this.flightCabinType();
        flight.numEquipajes = this.flightBaggageInfo();
        flight.trayectos = this.flightTrayectos(i);
        return flight;
    }

    flightDate(i) {
        return formatDate((this.product as Journey).flights[i].departureDate, 'dd/MM/yyyy', locale()).toString();
    }

    flightDuration(i) {
        return (this.product as Journey).flights[i].flightDuration;
    }

    flightCabinType() {
        return this.cabinType((this.product as Journey).info.cabin);
    }

    flightBaggageInfo() {
        return parseInt((this.product as Journey).info.baggage);
    }

    flightTrayectos(i): Trayecto[] {
        return (this.product as Journey).flights[i].segments.map(segment => {
            // trayectos: DETALLE DEL SEGMENT
            const trayectos = new Trayecto();
            trayectos.cityOrigin = segment.origin.cityName;
            trayectos.cityOriginCod = segment.origin.iata;
            trayectos.cityDestiny = segment.destination.cityName;
            trayectos.cityDestinyCod = segment.destination.iata;
            trayectos.flightNumber = segment.flightNumber;
            return trayectos;
        });
    }

    cabinType(cabin) {
        switch (cabin) {
            case 'Y':
                return 'economy_cabin';
            case 'B':
                return 'bussines_cabin';
            case 'F':
                return 'first_class_cabin';
            case 'P':
                return 'premium_economy_cabin';
            case 'M':
                return 'combined_cabin';
            default:
                break;
        }
    }

    baggageInfo(baggage) {
        let label = '';
        if (baggage.indexOf('PC') > -1) {
            label = ' ' + 'DATA_SERVICE.baggage_pieces';
        } else if (baggage.indexOf('K') > -1) {
            label = 'DATA_SERVICE.baggage_kilograms';
        }
        const qty = parseInt(baggage);
        if (qty === 1) {
            return 'DATA_SERVICE.baggage_one_piece';
        } else if (qty > 1) {
            return qty + label;
        } else {
            return 'DATA_SERVICE.baggage_hand_baggage';
        }
    }



}
