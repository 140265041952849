import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { DataStoreService } from '../../../services/data-store.service';
import { keysUniversal, StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-section-four-bac',
  templateUrl: './section-four-bac.component.html',
  styleUrls: ['./section-four-bac.component.scss']
})
export class SectionFourBacComponent implements OnInit {

  @Input() brand: string;
  urlsVideos: any = environment.brands[this.dataStore.brand].videosLandingEN.sectionThree;
  iphoneTops = `./assets/images/${this.dataStore.brand}-miles/iphone-tops.png`;
  urlData: string;

  constructor(private dataStore: DataStoreService,
    private store: StoreServiceLibrary,
    private translate: TranslateService,
    private el: ElementRef,
    private router: Router) { }

  ngOnInit() {
    this.changeLanguaje();
  }

  changeLanguaje() {
    this.translate.onLangChange.subscribe((data) => {
      if (data.lang === 'es') {
        this.iphoneTops = `./assets/images/${this.dataStore.brand}-miles/es/iphone-tops.png`;
        //this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingES.sectionFour;
      } else {
        this.iphoneTops = `./assets/images/${this.dataStore.brand}-miles/iphone-tops.png`;
        //this.urlsVideos = environment.brands[this.dataStore.brand].videosLandingEN.sectionFour;
      }
    });
  }

  goToTravel() {
    this.router.navigateByUrl('travel');
    document.body.scroll({
      top: 0,
      behavior: 'smooth'
    });
  }

  goToTyC() {
    const lang = this.store.getItemFromStorage('lang') || 'en';
    // const urlTerms = environment.brands[this.dataStore.brand].terminos_y_condiciones[lang.toUpperCase()];
    let urlTerms = this.store.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_en);

    if (lang) {
      if (lang == 'es') {
        urlTerms = this.store.getJustOneUniversalPropertie(keysUniversal.web_url_terms_and_conditions_es);
      }
    }
    if (!this.dataStore.isAlleMobile) {
      this.urlData = urlTerms;
    } else {
      this.urlData = `${urlTerms}`;
    }
    window.open(this.urlData, '_blank');
  }
}
