import { Component, OnInit, Renderer2, ViewChild, ElementRef, Input } from '@angular/core';
import { InitService } from '../../services/init.service';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss']
})
export class HelpCenterComponent implements OnInit {

  config: any;
  showComponent = false;

  public video: string;
  public open: boolean;
  public play: boolean;

  @ViewChild("video") elementVideo: ElementRef;

  constructor(private renderer: Renderer2, private el: ElementRef,
    private init: InitService, private store: StoreServiceLibrary,
    public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.init.isInitializedBrand.subscribe((init) => {
      if (init) {
        let configToRender = this.store.getItemFromStorage('CONFIG-HELP') || false;
        if (configToRender) {
          let helpCenter = configToRender['help-center'] || false;
          if (helpCenter) {
            this.config = configToRender['help-center'];
            this.showComponent = true;
          } else {
            this.showComponent = false;
          }
        } else {
          this.showComponent = false;
        }

      }
    });
  }

  openModal(video) {
    this.open = true;
    this.video = video;
    const elementVideo = this.el.nativeElement.querySelector('.elementVideo');
    setTimeout(function(){
      elementVideo.play();
    }, 500)
    
  }

  closeModal() {
    this.open = false;
    const elementVideo = this.el.nativeElement.querySelector('video');
    elementVideo.pause();
    elementVideo.currentTime = 0;
    this.play = false;
  }

  playVideo() {
    this.play = true;
    const elementVideo = this.el.nativeElement.querySelector('video');
    elementVideo.play();
  }
}
