<section class="block-video">
	<div class="container">
		<div class="bl-row">
            <div class="bl-left">
                <div class="bloque-video">
                    <div class="iphone-video">
                        <video class="video-vimeo" loop muted playsinline autoplay oncanplay="this.play()" onloadedmetadata="this.muted=true">
                                <source [src]="urlsVideos.one.url | srcSanitizer" type="video/mp4">
                            </video>
                    </div>
                    <img class="imagen-video" src="./assets/images/{{brand}}-miles/iphone-bac.png" alt="">
                </div>
            </div>
            <div class="bl-right">
                <h3><strong>Combina tus <span class="italic">Miles</span></strong><br> con las tarjetas <br><strong>BAC Credomatic y <br>vive el viaje de tus sueños.</strong></h3>
                <div id="ProgramsLoyalty"></div>
            </div>
        </div>
        <div class="bl-row">
            <div class="bl-left">
            	<h3>Encuentra todos tus programas de lealtad de <strong>Viajes BAC Credomatic en un mismo lugar.</strong></h3>
            </div>
            <div class="bl-right wl-50">
                <div class="bloque-video">
                    <div class="iphone-video">
                        <video class="video-vimeo" loop muted playsinline autoplay oncanplay="this.play()" onloadedmetadata="this.muted=true">
                                <source [src]="urlsVideos.two.url | srcSanitizer" type="video/mp4">
                            </video>
                    </div>
                    <img class="imagen-video" src="./assets/images/{{brand}}-miles/iphone-bac.png" alt="">
                </div>
            </div>
        </div>
        <div class="bl-row">
            <div class="bl-left">
                <div class="bloque-video">
                    <div class="iphone-video">
                        <video class="video-vimeo" loop muted playsinline autoplay oncanplay="this.play()" onloadedmetadata="this.muted=true">
                                <source [src]="urlsVideos.three.url | srcSanitizer" type="video/mp4">
                            </video>
                    </div>
                    <img class="imagen-video" src="./assets/images/{{brand}}-miles/iphone-bac.png" alt="">
                </div>
            </div>
            <div class="bl-right">
                <h3>Con<br> <strong>Viajes BAC Credomatic</strong> tienes la oportunidad de pagar tus viajes en cuotas con 0% de interés.</h3>
                <a (click)="goToTyC()">Aplican Términos y Condiciones.</a>
            </div>
        </div>
	</div>
	<button class="reserve"  (click)="goToTravel()">Reserva ahora</button>
</section>

<section class="gray guarantee">
	<div class="container">
		<h3>Si encuentras la misma oferta a un menor <br> precio y con todos los beneficios que te da <br> <strong>upperMiles, te regresamos la diferencia.</strong> </h3>
		<h3><strong>Eso es garantía de verdad.</strong></h3>
        <a (click)="goToTyC()">Aplican Términos y Condiciones</a>

		<img src="../../../../assets/images/{{brand}}-miles/guarantee.png" alt="">
	</div>
</section>

<section class="alle">
	<div class="container">
		<button class="reserve" (click)="goToTravel()">Reserva ahora</button>
		<h2>¿Preguntas? <span class="italic">alle</span> te ayuda siempre y de inmediato</h2>
		<p>Sin importar la hora o el lugar, <strong><span class="italic">alle,</span> nuestro agente inteligente,</strong> te ayuda siempre y de inmediato. </p>
		<div class="bl-row">
            <div class="bl-left">
            	<div class="item active">
            		<p>Habla con <span class="italic white">alle</span> a través de<br> <strong>Whatsapp, Facebook</strong> o <br> <strong>iMessage.</strong></p>
            	</div>
                <div class="item protection">
                	<img  alt="" src="../../../../assets/images/credomatic-miles/icon-protection.svg">
                	<p >¿Te enfermaste durante el <br> viaje? <strong><span class="italic">alle</span></strong> te conecta con <br> una red abierta de <br> médicos y hospitales en <br> cualquier parte del mundo.</p>
                </div>
            </div>
            <div class="bl-right">
                <div class="item">
                	<img  alt="" src="../../../../assets/images/credomatic-miles/icon-booking.svg">
                	<p >¿Cambio de planes?  <br><strong><span class="italic">alle</span></strong> te ayuda a <br> modificar tu reserva. </p>
                </div>
                <div class="item">
                	<img  alt="" src="../../../../assets/images/credomatic-miles/icon-bag.svg">
                	<p >¿Se retrasó o se perdió tu <br> maleta? <strong>Recibe <br> compensación al <br> instante.</strong></p>
                </div>
            </div>
        </div>
        <div class="experience">
           <img src="../../../../assets/images/credomatic-miles/experience.png" alt="">
           <div>
               <h4>Vive una <br> experiencia <br> <strong>100% digital</strong></h4>
               <p>Lleva todo lo que necesitas para tu viaje en tu teléfono y siempre al alcance de tus manos. <strong>Sin necesidad de imprimir documentos.</strong> </p>
           </div>
        </div>
        <button class="reserve"  (click)="goToTravel()">Reserva ahora</button>
	</div>
</section>

<section class="s-enough">
    <div class="container">
        <h2 class="text-gradient" innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_EMILES.enough_miles_title' | translate}}"></h2>
        <div class="bl-row">
            <div class="bl-left">
                <ul class="items-number">
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.1' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_EMILES.list_paying_1' | translate}}"></p>
                    </li>
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.2' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_EMILES.list_paying_2' | translate}}"></p>
                    </li>
                    <li><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.3' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_EMILES.list_paying_3' | translate}}"></p>
                    </li>
                    <li *ngIf="brand === 'credomatic'"><span>{{'LANDING.'+brand+'.SECTION_FIRST.SECTION1.4' | translate}}</span>
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_EMILES.list_paying_4' | translate}}"></p>
                    </li>
                    <li *ngIf="brand === 'credomatic'">
                        <p innerHTML="{{'LANDING.'+brand+'.SECTION_THREE.SECTION_EMILES.list_paying_5' | translate}}"></p>
                    </li>
                </ul>
            </div>

            <div class="bl-right wl-50">

            	<div class="bloque-video">
                    <div class="iphone-video">
                        <video class="video-vimeo" loop muted playsinline autoplay oncanplay="this.play()" onloadedmetadata="this.muted=true">
                                <source [src]="urlsVideos.four.url | srcSanitizer" type="video/mp4">
                            </video>
                    </div>
                    <img class="imagen-video" src="./assets/images/{{brand}}-miles/iphone-bac.png" alt="">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="descargas">
    <div class="download">
        <h2 class="text-gradient" innerHTML="{{'LANDING.'+brand+'.SECTION_FOUR.text_download' | translate }}"> </h2>
        <div class="store">
            <ul>
                <app-download-store [class]="'logo'"></app-download-store>
            </ul>
        </div>
    </div>
</section>
