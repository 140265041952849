import { Component, OnInit, Input } from '@angular/core';
import { Confirmation } from '../confirmation.model';
import { DataStoreService } from 'src/app/services/data-store.service';
import { SearchedBenefit } from '../../searched/models/searched-benefit.model';
import { SearchedService } from '../../searched/searched.service';

@Component({
  selector: 'app-confirmation-benefits',
  templateUrl: './confirmation-benefits.component.html',
  styleUrls: ['./confirmation-benefits.component.scss']
})
export class ConfirmationBenefitsComponent implements OnInit {

  @Input() confirmationData: Confirmation;
  searchedBenefit: SearchedBenefit;

  get startDate() {
    return this.searchedBenefit.dateToHumanReadable.start;
  }

  get endDate() {
    return this.searchedBenefit.dateToHumanReadable.end;
  }

  constructor(
    public dataStore: DataStoreService,
    private searchedService: SearchedService
  ) {
    this.searchedBenefit = this.searchedService.getModel(SearchedBenefit);
  }

  ngOnInit() {
  }

}
