<footer class="content-footer">
    <div id="containerNav" class="container" #containerNav *ngIf="brand !== 'lifeMilesTravel'">
        <!-- <ul *ngIf="!isAlleMobile">
            <h6>{{ 'FOOTER.About_Scotia_rewards' | translate }}</h6>
            <li>
                <a (click)="openModalLogin()" href="javascript:void(0)">{{ 'FOOTER.activate_your_account' | translate }}</a>
            </li>
            <li>
                <a (click)="tabSelected(EProduct.FLIGHTS, containerNav)" routerLink="/travel">{{  'FOOTER.start_reedeming' | translate}}</a>
            </li>
            <li>
                <a routerLink="/">{{  'FOOTER.need_help' | translate}}</a>
            </li>
        </ul> -->
        <!-- <ul *ngIf="!isAlleMobile">
            <h6>{{ 'FOOTER.voyage' | translate }}</h6>
            <li>
                <a (click)="tabSelected(EProduct.FLIGHTS, containerNav)" routerLink="/travel" innerHTML="{{'FOOTER.flight'|translate}}"></a>
            </li>
            <li>
                <a (click)="tabSelected(EProduct.HOTELS, containerNav)" routerLink="/travel" innerHTML="{{'FOOTER.hotel'|translate}}"></a>
            </li>
            <li>
                <a (click)="tabSelected(EProduct.CARS, containerNav)" routerLink="/travel" innerHTML="{{'FOOTER.cars'|translate}}"></a>
            </li>
            <li>
                <a (click)="tabSelected(EProduct.ACTIVITIES, containerNav)" routerLink="/travel" *ngIf="hasActivities" innerHTML="{{'FOOTER.activities'|translate}}"></a>
            </li>
            <li>
                <a (click)="tabSelected(EProduct.BENEFITS, containerNav)" routerLink="/travel" *ngIf="hasBenefits" innerHTML="{{'FOOTER.benefits'|translate}}"></a>
            </li>
        </ul> -->
        <ul *ngIf="!isAlleMobile">
            <h6> {{ 'FOOTER.improve_your_expirence' | translate }}</h6>
            <ul>
                <app-download-store></app-download-store>
            </ul>
        </ul>
        <ul>
            <h6>
                <a (click)="showTermsAndConditionsDialog()" innerHTML="{{'FOOTER.terms_conditions'|translate}}"></a>
            </h6>
        </ul>
        <ul>
            <li>©&nbsp;{{YEAR}}</li>
        </ul>
    </div>
    <div id="containerNav" class="container" #containerNav *ngIf="brand === 'lifeMilesTravel'">
        <ul>
            <li>
                <p>
                    <a (click)="showTermsAndConditionsDialog()" innerHTML="{{'FOOTER.terms_conditions'|translate}}"></a>
                </p>
            </li>
        </ul>
    </div>
</footer>