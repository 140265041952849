import { ActivityService } from './activity.service';
import { ActivityDetailComponent } from './activity-detail/activity-detail.component';
import { DataStoreService } from 'src/app/services/data-store.service';
import { DataService } from 'src/app/services/data.service';
import { Component, OnInit } from '@angular/core';
import { IActivityResult } from 'src/app/models/activities/response/activity-availability.model';
import { Card } from '../shared/card/model/card';
import { IFilterParams, FilterGroupType, FilterDataType } from '../shared/filters/filterParams';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../component/language/language.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-activity-body',
  templateUrl: './activity-body.component.html',
  styleUrls: ['./activity-body.component.scss']
})
export class ActivityBodyComponent implements OnInit {

  translations = {};
  showFilters = false;
  showPriceSlider = false;
  activityList: any[] = [];
  activityDetail: any;
  openModal = false;
  mapView = false;
  porcentajeConversion = 100;
  filtersParamsList: IFilterParams[];
  optionsCard = {
    type: 'activity'
  };
  filteredID: any = {
    type: 'activity',
    values: []
  };
  constructor(
    private activityService: ActivityService,
    public dataService: DataService,
    public dataStore: DataStoreService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    public dialog: MatDialog,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.getTranslations().then(() => {
      this.getActivityList();
    });
  }

  getActivityList() {
    this.dataService.sendRequestAvailabilityActivities(
      this.dataService.activityRequestParameters
    ).subscribe(
      (response: any) => {
        if (!response.error && (response.results && response.results.length > 0)) {
          this.activityList = this.formatCardData(response.results);
          this.actualizarPorcentaje(100);
          this.setFilterParamList();
          this.dataStore.selectedActivity = this.activityList[0];
        } else {
          this.dataService.flightSearchError = this.translations['NO_RESULTS.JUNIP3'];
          this.router.navigate(['benefits/noResults']);
        }
      },
      (err) => {
        this.dataService.flightSearchError = this.translations['NO_RESULTS.JUNIP3'];
        this.router.navigate(['benefits/noResults']);
      });
  }

  formatCardData(activitiesList: any[]) {
    if (activitiesList) {
      const list = activitiesList.map((activity, i) => {

        activity.options.forEach((option, j) => {
          option.id = j;
        });

        // PRESELECCIONAR LA PRIMERA OPCION DE FECHA
        activity.options[0].selectedDate = activity.options[0].dates[0];

        if (activity.images && activity.images.length > 0) {
          activity.images.forEach((item: any) => {
            item.imgURL = item.url;
            item.label = item.type;
          });
        }
        const totalTravellers = this.countPassengers(this.dataService.activityRequestParameters);
        const info: any[] = [
          {
            type: 'refundable',
            value: 'Y' // this.translations['CARD.check_refundable']
          },
          // {
          //   type: 'night',
          //   value: totalNights
          // },
          {
            type: 'traveller',
            value: totalTravellers
          }
        ];
        const toReturn: IActivityResult = {
          id: i,
          name: activity.serviceName,
          description: activity.serviceDescription,
          startDate: activity.startDate,
          endDate: activity.endDate,
          price: activity.options[0].dates[0].price,
          options: activity.options,
          selectedOption: activity.options[0],
          info: info,
          activityObj: activity,
          images: (activity.images === null) ? [] : activity.images,
        }
        return toReturn;
      });
      return list;
    } else {
      return [];
    }
  }

  countPassengers(obj) {
    let qty = 0;
    obj.roomDistribution.forEach(item => {
      qty += item.paxes.length;
    });
    return qty;
  }

  /*
  * ABRIR MODAL DE DETALLE DE ACTIVIDAD
  * */
  activityDetailListener(ev) {

    this.dataStore.selectedActivity.options = this.activityService.updateActivityOptionsPrice(
      this.dataStore.selectedActivity.options,
      this.dataService.conversionRate,
      this.porcentajeConversion,
      this.dataService.userPointsSP
    );

    const dialogRef = this.dialog.open(ActivityDetailComponent, {
      panelClass: 'detail-dialog',
      data: this.dataStore.selectedActivity
    });
  }

  actualizarPorcentaje(evt) {
    const conversionRate = this.dataService.conversionRate;
    const userPointsSP = this.dataService.userPointsSP;
    this.dataStore.porcentajeConversion = evt;

    this.dataService.getPriceConversion(this.activityList, conversionRate, this.dataStore.porcentajeConversion, userPointsSP);
    this.porcentajeConversion = evt;
  }

  setFilterParamList() {
    this.filtersParamsList = [
      {
        groupTitle: this.translations['ACTIVITY_BODY.name'],
        groupName: 'Nombre',
        groupType: FilterGroupType.textAutocompleteFG,
        optionValuePath: 'data.name',
        optionsIdsPath: 'data.id',
        optionType: FilterDataType.autocompleteFilter,
        //Deben ser expresiones regulares
        includeText: undefined,
        excludeText: undefined,
        multipleOptions: false
      },
      {
        groupTitle: this.translations['ACTIVITY_BODY.price'],
        groupName: 'price',
        groupType: FilterGroupType.sliderFG,
        optionValuePath: 'data.price.totalPrice',
        optionsIdsPath: 'data.id',
        optionType: FilterDataType.priceFilter,
        //Deben ser expresiones regulares
        includeText: undefined,
        excludeText: undefined,
        currencyPath: 'data.price.currency',
        multipleOptions: false
      },
    ];
  }

  filterListener(filteredIDs) {
    this.filteredID = {
      type: 'activity',
      values: filteredIDs.IDs,
      isFiltered: filteredIDs.isFiltered
    };
    if (this.filteredID.values.length > 0) {
      // const activity = this.activityList.find(item => {
      //   return item.id === this.filteredID.values[0];
      // });
      // this.dataService.saveSelectedRentaCar(car);
    }
  }

  getTranslations() {
    return new Promise<any>((resolve, reject) => {
      this.languageService.systemLanguajeChange.subscribe(language => {
        if (language) {
          this.translateService.get([
            'ACTIVITY_BODY.price',
            'ACTIVITY_BODY.name',
            'NO_RESULTS.JUNIP3'
          ]).subscribe((trns) => {
            this.translations = trns;
            resolve(true);
          });
        }
      }, err => {
        reject();
      });
    });
  }
}
