<app-landing-travel *ngIf="!isNewHome"></app-landing-travel>

<app-landing-mr *ngIf="isNewHome && brand ==='firstcitizens'"></app-landing-mr>
<app-landing-mr *ngIf="isNewHome && brand ==='myrewards'"></app-landing-mr>
<app-landing-mr *ngIf="isNewHome && brand ==='cibc'"></app-landing-mr>
<app-landing-mr *ngIf="isNewHome && brand ==='apap'"></app-landing-mr>

<app-landing-mr *ngIf="isNewHome && brand ==='bancoindustrial'"></app-landing-mr>
<app-landing-scotia *ngIf="isNewHome && brand ==='scotia'"></app-landing-scotia>
<app-landing-bac *ngIf="isNewHome && brand ==='credomatic'"></app-landing-bac>

<app-landing-about *ngIf="isNewHome && brand ==='ncb' && !this.route.url.includes('/myRewards')"></app-landing-about>
<app-landing-mr *ngIf="isNewHome && brand ==='ncb' && this.route.url.includes('/myRewards')"></app-landing-mr>