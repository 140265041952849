<div [ngClass]="{'hidden-parameter': ocultadorService.ocultar}">
  <app-slider-home id="slider" *ngIf="showSlider"></app-slider-home>
  <app-ctf-slider id="slider" *ngIf="useContentful"></app-ctf-slider>
  <div class="menu-nav" id="menuNav" *ngIf="showTabs">
    <div class="container">
      <div [id]="i+'service'" *ngFor="let itemTab of typeExternalUrls; let i = index" class="item"
        [ngClass]="{'active': itemTab.name == selectedTab }" (click)="changeLoadIframe(itemTab.name)">{{itemTab.lang[language]}}
      </div>
    </div>
  </div>
  <app-search *ngIf="showForm"></app-search>
  <app-promociones id="promotions" *ngIf="showPromotions && brand != 'credomatic'"></app-promociones>
  <app-promociones-ctf id="promotions" *ngIf="showPromotions && brand == 'credomatic'"></app-promociones-ctf>
  <app-benefits id="benefits" [ngStyle]="{display: showBenefits? 'inline' : 'none'}"></app-benefits>
  <app-footer-all [brand]="brand" *ngIf="showFooter"></app-footer-all>
  <div class="bg-ncb2"><img src="../assets/images/bg-ncb.png" alt=""></div>
</div>