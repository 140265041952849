import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { DataStoreService } from '../../../services/data-store.service';
import { Card } from '../../shared/card/model/card';
import { HotelContentResponse } from '../../../models/hotel/response/hotel-content-response.model';

@Component({
  selector: 'app-hotel-list',
  templateUrl: './hotel-list.component.html',
  styleUrls: ['./hotel-list.component.scss']
})
export class HotelListComponent implements OnInit, OnChanges {
  @Input() public filteredID: any = {
    type: '',
    values: []
  };
  @Input() hotelList: Card[];

  @Input() style = 1;
  @Output() hotelDetail = new EventEmitter();
  qtyResultShow = 5;
  cardOptions = {
    type: 'hotel',
    style: this.style,
  };

  constructor(
    public dataService: DataService,
    public dataStore: DataStoreService,
  ) { }

  ngOnInit() {
    /*this.hotelContent(this.hotelList);*/
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filteredID) {
      const param = (this.filteredID.values.length > 0) ? this.filteredID.values : null;
      this.hotelContent(this.hotelList,
        param);
    }
    this.cardOptions.style = this.style;
  }

  AddResults() {
    this.hotelContent(this.hotelList);
    this.qtyResultShow += 5;
  }

  detailCardListener(ev) {
    this.dataService.saveSelectedHotel(ev.hotelObj);
    this.hotelDetail.emit(ev);
    /*this.hotelList.find(hotel => {
      if (!hotel.hotelObj.selectedRoomOption) {
        hotel.hotelObj.selectedRoomOption = hotel.hotelObj.options[0];
      }
      return (hotel.id === ev);
    })*/
  }

  selectProductListener(ev: Card) {
    this.dataService.saveSelectedHotel(ev.hotelObj);
  }

  hotelContent(list, filteredID = null) {
    if (filteredID !== null) {
      filteredID = filteredID.filter(item => {
        return item !== undefined;
      });
    }
    let i = 0;
    let hotels: any = list.filter((hotel) => {
      if ((filteredID === null || filteredID.indexOf(hotel.id) > -1)
        && (hotel.images === null || hotel.images.length === 0) && i < 10) {
        i++;
        return hotel.hotelObj.hotelCode;
      }
    });
    hotels = hotels.map(hotel => {
      return hotel.hotelObj.hotelCode;
    });
    this.getHotelContent(hotels);
  }

  getHotelContent(ids: string[]) {
    this.dataService.sendContentHotelRequest(ids.join(',')).subscribe((response: HotelContentResponse[]) => {
      response.forEach(item => {
        this.hotelList.find(hotel => {
          if (hotel.hotelObj.hotelCode === item.hotelCode) {
            hotel.hotelObj.amenities = item.amenities;
            hotel.images = item.images;
          }
        });
      });
    });
  }
}
