import { Component, OnInit } from '@angular/core';
import { AuthGetAccount2Response, keysUniversal, StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { environment } from 'src/environments/environment';
import { DataStoreService } from 'src/app/services/data-store.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../language/language.service';
import { GoogleAnalytics } from 'src/app/resources/GoogleAnalytics.resource';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from 'src/app/container/shared/login/login.component';
import data from '../../../resources/screenAndEventsTracking.json';
import { DataService } from 'src/app/services/data.service';
import { ActionEventService } from 'src/app/services/action-event.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-section-first',
  templateUrl: './section-first.component.html',
  styleUrls: ['./section-first.component.scss']
})
export class SectionFirstComponent implements OnInit {
  brand = '';
  appName = '';

  profile: AuthGetAccount2Response;

  constructor(
    private _storeLibrary: StoreServiceLibrary,
    private dataStoreService: DataStoreService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private store: StoreServiceLibrary,
    private dialog: MatDialog,
    private dataService: DataService,
    private actionEventService: ActionEventService,
    private router: Router,
  ) { }

  ngOnInit() {

    this.translate.use(environment.brands[this.dataStoreService.brand].defaultLanguaje);
    this.translate.setDefaultLang(environment.brands[this.dataStoreService.brand].defaultLanguaje);
    this.languageService.setSystemLanguaje(environment.brands[this.dataStoreService.brand].defaultLanguaje);
    this.store.setItemInStorage('lang', environment.brands[this.dataStoreService.brand].defaultLanguaje);

    this.brand = this.dataStoreService.brand.toUpperCase();
    this.appName = environment.brands[this.dataStoreService.brand].appName;
  }

  openLogin() {
    // this.trackingManager.trackEventString(data.WALKTHROUGH.signInWalk, data.WALKTHROUGH.walkthroughScreen);
    GoogleAnalytics.sendEventFirebase('ga_event', data.WALKTHROUGH.walkthroughScreen, data.WALKTHROUGH.signInWalk, '');
    const dialogRef = this.dialog.open(LoginComponent, {
      panelClass: 'login-dialog'
    });

    dialogRef.afterClosed().subscribe(successful => {
      if (successful) {
        // si ingreso y/o se registró correctamente
        this.profile = this.dataService.smartProfile;
        this.actionEventService.sendRedirectTo(true);
        this.router.navigateByUrl('/home');
      }
    });
  }
}
