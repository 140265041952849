import { ActionEventService } from './../../services/action-event.service';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Renderer2, HostListener } from '@angular/core';
import { keysUniversal, keysPostMessage } from 'src/app/resources/keys.resources';
import { getCurrencySymbol } from '@angular/common';
import { DataService } from '../../services/data.service';
import { InitService } from '../../services/init.service';
import { DataStoreService } from '../../services/data-store.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingScreenService } from '../../services/loading-screen.service';
import { environment } from '../../../environments/environment';
import { EProduct } from '../searched/models/searched.model';
import { AlertErrorComponent } from '../product-detail/alert-error/alert-error.component';
import { LanguageService } from '../language/language.service';

@Component({
  selector: 'app-credomatic-cards',
  templateUrl: './credomatic-cards.component.html',
  styleUrls: ['./credomatic-cards.component.scss']
})
export class CredomaticCardsComponent implements OnInit {

  iframeScrollHeight = '100%';
  iframeScrollWidth = '100%';
  classSearchContent = 'buscador-flotante';
  loadButtonContinue = false;

  @ViewChild('onePocketIframe') iframe;
  @ViewChild('loadingVideo') loadingVideo;

  elementCardCredomatic: any;

  @Input() iframeURL: string;
  @Input() token: string;
  @Input() enabled;

  contador: number;
  public loaderSlider: string;
  onePocketUrl = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.onePocket_url) || '';
  // onePocketUrl = 'http://localhost:8100/';

  public loaded = false;
  public moneyAmount = 0;
  public pointsAmount = 0;
  public getCurrencySymbol = getCurrencySymbol;

  @Input()
  public moneyCurrency = '';

  @Output()
  public getPorcentaje = new EventEmitter(true);

  useJwtToken: boolean;
  iframeBaseUrl: string;
  initiated = false;

  private systemLanguage = 'ES';

  get brand() {
    return this.dataStore.brand;
  }

  constructor(private dataService: DataService,
    private initService: InitService,
    public dataStore: DataStoreService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private storeServiceLibrary: StoreServiceLibrary,
    private loadingScreenService: LoadingScreenService,
    private dialogRef: MatDialogRef<CredomaticCardsComponent>,
    private eventService: ActionEventService,
    private render: Renderer2,
    private languageService: LanguageService,
  ) {
  }

  ngOnInit(): void {
    this.enabled = true;
    this.elementCardCredomatic = document.getElementById('credomatic-cards');
    this.render.setStyle(this.elementCardCredomatic, 'display', 'none');
    this.useJwtToken = this.dataService.getJwtUse();
    this.initService.isInitializedBrand.subscribe(isInitialized => {
      if (isInitialized) {
        this.initiated = true;
        this.loaded = false;
        
        this.definirUrlIframe();

        const pathArray = this.iframeURL.split('/');
        const protocol = pathArray[0];
        const host = pathArray[2];
        this.iframeBaseUrl = protocol + '//' + host;

        this.languageService.systemLanguajeChange.subscribe((language) => {
          if (language) {
            this.systemLanguage = language.toUpperCase();
            this.definirUrlIframe();
          }
        });
      }
    });
    this.definedLangLoader();
  }

  definirUrlIframe() {
    //Definir url con lenguaje
    this.iframeURL = `${this.onePocketUrl}#/onepocket-points`;
    let applicationId = environment.brands[this.dataStore.brand].applicationId
    let paramsUrl = `applicationID=${applicationId}`;
    paramsUrl += `&language=${this.systemLanguage}`;
    this.iframeURL = `${this.iframeURL}?${paramsUrl}`;
    //Definir url con lenguaje
  }

  @HostListener('window:message', ['$event'])
  messageListener(event: MessageEvent) {
    if (this.iframeURL && event.data.typeMessage === 'INIT_ONEPOCKET_PAGE') {
      this.starLoading();
      this.loaded = true;
      this.dataService.productSelected = EProduct.HOME;
      this.dataStore.preloaderImg = EProduct.GENERAL;
      this.sendTokenAuth();
    } else if (this.iframeURL && event.data.typeMessage === 'COMPLETE_SAVE_DATA_USER') {
      this.dataService.productSelected = EProduct.HOME;
      this.dataStore.preloaderImg = EProduct.GENERAL;
      this.stopLoading();
    } else if (this.iframeURL && event.data.typeMessage === 'CARD_SCOTIA_SHOW_MODAL') {
      const showModal = event.data.showModal;
      if (showModal) {
        this.render.setStyle(this.elementCardCredomatic, 'display', 'block');
        this.stopLoading();
      } else {
        this.render.setStyle(this.elementCardCredomatic, 'display', 'none');
        this.starLoading();
      }
    } else if (this.iframeURL && event.data.typeMessage === 'CARD_STORAGE_SAVE_DATA') {
      const dataCard = event.data.objectCard;
      this.dialogRef.close(true);
      this.stopLoading();
    } else if (this.iframeURL && event.data.typeMessage === 'SHOW_ERROR_IN_ALLETRAVEL') {
      const idError = event.data.idError;
      let message;
      this.translate.get('ALERT.ERROR_BOOKING.boddy').subscribe(translate => message = translate);
      this.errorModal(`${message} ${idError}`);
    } else if (event.data.typeMessage === 'TOKEN_SESSION_USER_ERROR') {
      const idError = event.data.idError;
      let message;
      this.translate.get('ALERT.ERROR_BOOKING.boddy').subscribe(translate => message = translate);
      this.errorModal(`${message} ${idError}`);
      this.stopLoading();
    } else if (this.iframeURL && event.data.typeMessage === 'ADD_CREDIT_CARD_SUCCESS_ONE') {
      this.loadButtonContinue = true;
      const dataCard = event.data.objectCard;
      const balanceUser = event.data.balance;

      let smartProfile = this.dataService.smartProfile;
      if (smartProfile && smartProfile.accountResultDto && smartProfile.accountResultDto.balance) {
        smartProfile.accountResultDto.balance = `${balanceUser}`;
        this.dataService.saveSmartProfile(smartProfile);
      }

    }
  }

  sendTokenAuth() {
    const iframe = document.getElementById('iframe_onepocket_points_credomatic');
    const userData = this.storeServiceLibrary.getUserData();
    const message = {
      typeMessage: keysPostMessage.tokenAuth,
      user: userData
    };
    this.sendPostMessage(iframe, message, '*');
  }

  definedLangLoader() {
    this.loaderSlider = this.dataService.definedLangLoader();
  }

  starLoading() {
    this.loadingScreenService.startLoading();
  }

  close() {
    this.dialogRef.close(true);
  }

  stopLoading() {
    this.loadingScreenService.stopLoading();
  }


  async playVideo() {
    await this.loadingVideo.nativeElement.play();
  }


  errorModal(message) {
    const heightPadding = this.dataStore.isMobileDevice ? '0px' : '40px';
    const widthPadding = this.dataStore.isMobileDevice ? '0px' : '16vw';
    const dialogRef = this.dialog.open(AlertErrorComponent, {
      maxWidth: '50vw',
      width: 'calc(50vw - ' + widthPadding + ')',
      height: 'calc(50vh - ' + heightPadding + ')',
      minHeight: '59vh',
      data: {
        tittle: 'ALERT.ERROR_BOOKING.title',
        error: `${message}`
      }
    });

    dialogRef.afterClosed().subscribe(() => {
    });

  }

  sendPostMessage(iframe: any, message: any, targetOrigin: string) {
    const iWindow = (<HTMLIFrameElement>iframe).contentWindow;
    iWindow.postMessage(message, '*');
  }


  ngOnDestroy(): void {
    window.removeEventListener('message', event => this.messageListener(event), false);
  }

}
