
<section class="section-cero">
    <div class="container">
        <div class="bl-coming">
            <div class="bl-center">

                <h1 class="gradient" innerHTML="{{ 'LANDING_ABOUT.SECTION_FIRST.SECTION1.title_1' | translate:'{appName:\'' + appName + '\'}' }}"></h1>

                <div class="logo-miles"></div>

                <h3 innerHTML="{{ 'LANDING_ABOUT.SECTION_FIRST.SECTION1.parrafo_default1' | translate:'{brand:\'' + brand + '\'}' }}"></h3>

                <h3 innerHTML="{{ 'LANDING_ABOUT.SECTION_FIRST.SECTION1.parrafo_default2' | translate:'{appName:\'' + appName + '\', brand:\''+ brand + '\'}' }}"></h3>

                <p class="letra-pequenia" innerHTML="{{ 'LANDING_ABOUT.letra_pequenia' | translate:'{brand:\'' + brand + '\'}' }}"></p>

                <img class="img-coming" src="../../../../assets/images/ncb-miles/bg-mobiles.png" alt="mr">

                <button (click)="openLogin()">Sign in!</button>

                <div class="store">
                    <span innerHTML="{{ 'LANDING_ABOUT.text_download'| translate:'{appName:\'' + appName + '\'}' }}"></span>
                    <app-download-store [class]="'bl-stores'"></app-download-store>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="seccion-1">
    <div class="container">
        <div class="row">
            <div class="col-md-5">

                <h1 class="gradient" innerHTML="{{ 'LANDING_ABOUT.SECTION_FIRST.SECTION1.title_1' | translate:'{appName:\'' + appName + '\'}' }}"></h1>
                <p class="parrafo-default" innerHTML="{{ 'LANDING_ABOUT.SECTION_FIRST.SECTION1.parrafo_default1' | translate:'{brand:\'' + brand + '\'}' }}"></p>
                <p class="parrafo-default" innerHTML="{{ 'LANDING_ABOUT.SECTION_FIRST.SECTION1.parrafo_default2' | translate:'{appName:\'' + appName + '\', brand:\''+ brand + '\'}' }}"></p>
                <p class="letra-pequenia" innerHTML="{{ 'LANDING_ABOUT.letra_pequenia' | translate:'{brand:\'' + brand + '\'}' }}"></p>
                <span class="text-download parrafo-logos" innerHTML="{{ 'LANDING_ABOUT.text_download'| translate:'{appName:\'' + appName + '\'}' }}"></span>
                <app-download-store [class]="'bl-stores'"></app-download-store>
            </div>
            <div class="col-md-7">
                <img class="imagen-seccion1" src="../../../../assets/images/about/element-s1.png" alt="">
            </div>
        </div>
    </div>

</section> -->

<section class="seccion-2">
    <div class="container">
        <div class="row">
            <div class="col-md-5 align-right">
                <h1 class="gradient" innerHTML="{{ 'LANDING_ABOUT.SECTION_FIRST.SECTION2.title'| translate }}"></h1>
                <p class="parrafo-default-24 padding-bottom-p padding-left-p" innerHTML="{{ 'LANDING_ABOUT.SECTION_FIRST.SECTION2.parrafo_default1'| translate:'{appName:\'' + appName + '\'}' }}"> </p>
                <p class="parrafo-default-24 padding-left-p" innerHTML="{{ 'LANDING_ABOUT.SECTION_FIRST.SECTION2.parrafo_default2' | translate:'{appName:\'' + appName + '\', brand:\''+ brand + '\'}' }}"></p>
                <p class="parrafo-default-24 padding-left-p" innerHTML="{{ 'LANDING_ABOUT.SECTION_FIRST.SECTION2.parrafo_default3' | translate:'{appName:\'' + appName + '\', brand:\''+ brand + '\'}' }}"></p>
            </div>
            <div class="col-md-7">
                <img class="imagen-seccion2" src="../../../../assets/images/about/en/phone-s2.png" alt="">
            </div>

        </div>
    </div>
</section>
<!-- <section class="seccion-2-1">
    <div class="container-full">
        <div class="row reverso">
            <div class="col-md-6 imagen-seccion-mano">
                <img src="../../../../assets/images/about/en/element-s2-1.png" alt="">
            </div>
            <div class="col-md-5 col-pay">
                <p class="parrafo-default-24" innerHTML="{{ 'LANDING_ABOUT.SECTION_FIRST.SECTION2.parrafo_default3' | translate :'{appName:\'' + appName + '\', brand:\''+ brand + '\'}' }}"></p>
                <div class="logos-pay">
                    <img src="../../../../assets/images/about/logo-pay.png" alt="">
                    <img src="../../../../assets/images/about/logo-gpay.png" alt="">
                    <img src="../../../../assets/images/about/logo-paypal.png" alt="">
                    <img src="../../../../assets/images/about/logo-visa.png" alt="">
                    <img src="../../../../assets/images/about/logo-samsung.png" alt="">
                    <img src="../../../../assets/images/about/logo-amazon.png" alt="">
                    
                </div>
            </div>
        </div>
    </div>
</section> -->
<section class="seccion-2-2">
    <div class="container">
        <div class="row column">
            <p class="parrafo-default centrar" innerHTML="{{ 'LANDING_ABOUT.SECTION_FIRST.SECTION2.parrafo_default_central1' | translate }}"></p>

            <p class="parrafo-default bold centrar text-download" innerHTML="{{ 'LANDING_ABOUT.text_download'| translate }}"></p>

            <app-download-store [class]="'logo'"></app-download-store>

            <span class="letra-pequenia light" innerHTML="{{'LANDING_ABOUT.letra_pequenia2' | translate:'{brand:\'' + brand + '\'}' }}"></span>


        </div>
    </div>
</section>