
import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { DataStoreService } from '../../services/data-store.service';
import { SmartProfileService } from '../../services/smart-profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '../../component/language/language.service';
import { OcultadorService } from '../home/ocultador.service';
import { InitService } from '../../services/init.service';
import { ActionEventService } from '../../services/action-event.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EProduct } from '../../component/searched/models/searched.model';
import { environment } from '../../../environments/environment';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import data from '../../resources/screenAndEventsTracking.json';
import { LoginComponent } from 'src/app/container/shared/login/login.component';
import { LoginService } from '../../container/shared/login/login.service';
import { GoogleAnalytics } from '../../resources/GoogleAnalytics.resource';



@Component({
  selector: 'app-landing-about',
  templateUrl: './landing-about.component.html',
  styleUrls: ['./landing-about.component.scss']
})
export class LandingAboutComponent implements OnInit {


  // get optiene el brand del storage

  get brand() {
    return this.dataStoreService.brand;
  }

  constructor(
    private dataService: DataService,
    private dataStoreService: DataStoreService,
    private smartProfile: SmartProfileService,
    private route: ActivatedRoute,
    private languageService: LanguageService,
    public ocultadorService: OcultadorService,
    private initService: InitService,
    private actionEventService: ActionEventService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private store: StoreServiceLibrary,
    private router: Router,
    private loginService: LoginService
  ) { }

  ngOnInit() {
    // this.trackingManager.setCurrentScreen('/home', data.WELCOME.welcomeScreen);

    this.getBrandRoute();
    this.aboutLoadNav();
    // this.dataService.productSelected = EProduct.FLIGHTS;
    // this.dataStoreService.preloaderImg = EProduct.FLIGHTS;
    this.initService.isInitializedBrand.subscribe((isInitialized) => {
      if (isInitialized) {

        this.translate.use(environment.brands[this.dataStoreService.brand].defaultLanguaje);
        this.translate.setDefaultLang(environment.brands[this.dataStoreService.brand].defaultLanguaje);
        this.languageService.setSystemLanguaje(environment.brands[this.dataStoreService.brand].defaultLanguaje);
        this.store.setItemInStorage('lang', environment.brands[this.dataStoreService.brand].defaultLanguaje);
        this.validateRegisterParams();
        setTimeout(() => {
          this.checkGetUserOnUrl();
        });
        if (this.dataService.token) {
          this.smartProfile.getAccountMethod(this.dataService.token);
        }
      }
      this.dataService.productSelected = EProduct.HOME;
      this.dataStoreService.preloaderImg = EProduct.GENERAL;
      this.actionEventService.setItemActiveTab(EProduct.HOME);
      GoogleAnalytics.setCurrentScreen('/home');
    });
  }

  /**
   * verificar si el usuario llegó en la url
   */
  private checkGetUserOnUrl() {
    this.route.queryParams.subscribe((params) => {
      if (params.tokenAuth) {
        this.actionEventService.sendCollapseApp(true);
        const tokn = params.tokenAuth.replace(/ /g, '+');
        this.dataService.token = encodeURI(tokn);
        this.dataService.idApplication = params.idApplication;
        this.dataStoreService.channelID = params.idChannel;
        this.languageService.setSystemLanguaje(params.language);
        this.smartProfile.getAccountMethod(this.dataService.token);

        // como abrieron bitacora, ya logueados
        this.ocultadorService.ocultar = true;
        this.router.navigateByUrl('travel');
      }
    });
  }

  /**
   * @method getBrandRoute optiene el brand por parametro para su configuracion
   */

  getBrandRoute() {
    this.route.queryParams.subscribe((params: any) => {
      if (params.brand) {
        this.dataService.saveBrand(params.brand);
        this.actionEventService.sendBrandDynamic(params.brand);
        this.dataService.productSelected = EProduct.HOME;
        this.dataStoreService.preloaderImg = EProduct.GENERAL;
      }
    });
  }

  /**
   * @param EProduct.HOME es para colocar el active en el producto seleccionado
   * @param EProduct.GENERAL imagen que se le settea a el loaderService
   */
  aboutLoadNav() {
    this.actionEventService.isAboutLoad.subscribe((isAbout) => {
      this.dataService.productSelected = EProduct.HOME;
      this.dataStoreService.preloaderImg = EProduct.GENERAL;
      this.actionEventService.setItemActiveTab(EProduct.HOME);
    });
  }

  /**
   * @method validateRegisterParams
   * le envia el obj de registro a LoginComponent para su registro.
   */

  validateRegisterParams() {
    this.loginService.validateRegisterParams();
  }

}
