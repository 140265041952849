<div class="container">
  <p class="title" innerHTML="{{ 'HOTEL_DETAIL.reservation' | translate }}">Tu
    reserva</p>
</div>
<div class="container">
  <small *ngIf="travellersQty > 1; else elseBlock"
    innerHTML="{{ 'HOTEL_DETAIL.total_price_person' | translate:'{value:\'' +travellersQty + '\'}' }}">
    Total price
  </small>
  <ng-template #elseBlock>
    <small innerHTML="{{ 'HOTEL_DETAIL.total_price' | translate }}">Total
      price</small>
  </ng-template>
  <div class="prices">
    <p class="new_price">
      {{getPointsPrice() | milesFormat}} <span
        innerHTML="{{ 'HOTEL_DETAIL.miles' | translate }}"></span>
    </p>
    <p class="new_price">+ {{getMoneyPrice}} <span>{{getCurrency()}}</span></p>
  </div>
  <p class="selection">{{getSelectedRoom()}}.</p>
  <p class="selection board">{{getSelectedBoard()}}.</p>
  <div class="mt-4 info">
    <p>
      <i class="travel-icon icon-calendar"></i>
      {{nights}}
    </p>
    <p>
      <i class="travel-icon icon-people"></i>
      {{travellers}}
    </p>
    <p>
      <i class="travel-icon icon-stepper-acommodation"></i>
      {{getRoomQty()}}
    </p>
    <p *ngIf="wifi !== undefined && wifi">
      <i class="travel-icon icon-wifi"></i>
      <span innerHTML="{{ 'HOTEL_DETAIL.wifi' | translate }}">Wifi</span>
    </p>
    <p *ngIf="hasBreakfast()">
      <i class="travel-icon icon-breakfast"></i>
      <span innerHTML="{{ 'HOTEL_DETAIL.breakfast' | translate }}">Desayuno
        incluido</span>
    </p>

  </div>
</div>