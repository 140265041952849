import { SearchedCar } from 'src/app/component/searched/models/searched-car.model';
import { RentacarAvailabilityOption } from 'src/app/models/new-rentacar/rentacar-availability-option.model';
import { CardInfo } from 'src/app/component/card/models/card-layout-two.model';
export class RentacarFormatter {

    constructor(
        public rentacarData: any,
        private searchedData: SearchedCar
    ) { }

    formatData() {
        const rentacarData = this.rentacarData;
        if (rentacarData) {
            const list = rentacarData.filter((rentacar, i) => {
                const info: CardInfo[] = [{ type: 'ACRISS', value: this.getACRISSSDescriptions(rentacar.carClassification,
                     rentacar.supplier.supplierName) }];

                rentacar.id = i;
                rentacar.info = info;
                return rentacar;
            });
            return RentacarAvailabilityOption.toArray(list);
        }
    }

    /**
     * GEnerar el listado de datos ACRISS
     */
    getACRISSSDescriptions(carClassification, supplier): CardInfo[] {
        const result: { type, value }[] = [];
        if (carClassification) {
            const keys = Object.keys(carClassification);
            keys.forEach(key => {
                const info = { type: key, value: carClassification[key] };
                result.push(info);
                // if ( typeof carClassification[key] === "boolean" ) {
                // if (carClassification[key]) {
                //     if (key === "air") {
                //     result.push(this.carDetailsTranslation[key]);
                //     }
                // }
                // } else {
            }
            );
        }
        if (supplier) {
            const info = { type: 'supplier', value: supplier };
            result.push(info);
        }
        return result;
    }

}
