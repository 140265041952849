import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/component/language/language.service';
import { DataService } from 'src/app/services/data.service';
import { DebounceCall } from 'src/app/decorators/debounce.decorator';
import { DataStoreService } from 'src/app/services/data-store.service';
import { Router } from '@angular/router';
import { IActivityRequestParameters } from 'src/app/models/activities/response/activity-availability.model';
import { Subscription } from 'rxjs';
import { locale } from 'moment';
import { SearchedService } from 'src/app/component/searched/searched.service';
import { SearchedActivity } from 'src/app/component/searched/models/searched-activity.model';

import data from 'src/app/resources/screenAndEventsTracking.json';
import { GoogleAnalytics } from '../../../../resources/GoogleAnalytics.resource';
import { InitService } from '../../../../services/init.service';

@Component({
  selector: 'app-search-activities',
  templateUrl: './search-activities.component.html',
  styleUrls: ['./search-activities.component.scss']
})
export class SearchActivitiesComponent implements OnInit {
  destinyNotSelected: boolean;
  dateNotSelected: boolean;
  passengerNotSelected: boolean;
  form = this.fb.group({
    destinationZone: [null, Validators.required],
    selectDate: ['', Validators.required],
    paxes: [null, Validators.required]
  });
  minDate = moment(new Date());
  translations_search_activities: any = [];
  translations_date: any = [];
  locale: any = {
    format: '',
    autoclose: true
  };
  refresherDates = true;
  cities = [];
  searchSbs: Subscription;

  @ViewChild('dateStart') dateStart;
  @ViewChild('dateEnd') dateEnd;

  minDateEnd;
  selected = {
    startDate: moment(new Date()),
    endDate: moment(new Date())
  };

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private dataService: DataService,
    public dataStoreService: DataStoreService,
    private router: Router,
    private searchedService: SearchedService,
    private initService: InitService
  ) { }

  ngOnInit() {
    // this.trackingManager.setCurrentScreen('/activities', data.ACTIVITIES.activitiesHomeScreen);
    this.destinyNotSelected = false;
    this.dateNotSelected = false;
    this.passengerNotSelected = false;
    this.languageService.systemLanguajeChange.subscribe((language) => {
      if (language) {
        this.getTranslations();
        this.dateInputsRefresh();
      }
    });

    this.initService.isInitializedBrand.subscribe((isInit) => {
      if (isInit) {
        GoogleAnalytics.setCurrentScreen('/activities');
      }
    });
  }

  private getTranslations() {
    this.translateService.get([
      'SEARCH_ACTIVITIES',
      'DATE'
    ]).subscribe((trans) => {

      // local para los calendarios
      locale(this.languageService.systemLanguajeChange.value);
      this.locale.daysOfWeek = moment.weekdaysMin();
      this.locale.monthNames = moment.monthsShort();

      this.translations_search_activities = trans['SEARCH_ACTIVITIES'];
      this.translations_date = trans['DATE'];
    });
  }

  /**
   * refrescar los inputs de fechas para que muestren bien la fecha
   */
  private dateInputsRefresh() {
    this.refresherDates = false;
    this.locale.format = this.translations_date['format'];
    setTimeout(() => {
      this.refresherDates = true;
    }, 10);
  }

  /**
   * Usuario ingresa texto en el buscador de destino
   * entonces filtramos las ciudades
   */
  @DebounceCall(250)
  onDestinationSearch(event) {
    if (this.searchSbs && !this.searchSbs.closed) {
      // si la busqueda anterior no terminó, la cancelamos
      this.searchSbs.unsubscribe();
    }

    this.cities = [];
    const searched = event.term;
    if (searched.length < 3) {
      return;
    }
    this.destinyNotSelected = false;
    // si la busqueda es de al menos 3 letras
    this.searchSbs = this.dataService.getCities(event.term).subscribe(rspCities => {
      this.cities = rspCities.filter(city => this.isCity(city.type));
      this.searchSbs.unsubscribe();
    });
  }

  /**
   * hamemos que el ng-select retorne siempre true en el search
   * porque lo que filtramos es cambar el array de items (onSearch)
   * y el componente esta pensado para tener en el array todo cargado
   * y buscar mostrando y ocutlando sobre esos items
   */
  returnTrue = () => true;

  /**
   * remover de la búsqueda los aeropuertos, para retornar solo ciudades
   */
  isCity(type) {
    return !(<string>type).toLowerCase().match(this.translations_search_activities['airports'].toLowerCase());
  }

  /**
   * cambia el valor del input de paxes
   */
  onApplyAgesPassengers(event: FormArray) {
    if (event) {
      // actualiza valor del form array de paxes
      this.form.get('paxes').setValue(event.value);
    }
    this.passengerNotSelected = false;
  }

  updateDateValidator() {
    this.dateNotSelected = false;
  }

  /**
   * setear parametros de búsqueda
   */
  search() {
    if (this.form.valid) {
      // parametros para el request de actividades
      const desdeDateString = (<moment.Moment>(this.form.get('selectDate').value).startDate).format('YYYY-MM-DD');
      const hastaDateString = (<moment.Moment>(this.form.get('selectDate').value).endDate).format('YYYY-MM-DD');
      const requestParameters = {
        destinationZone: this.form.get('destinationZone').value.id,
        startDate: desdeDateString,
        endDate: hastaDateString,
        roomDistribution: [{}],
        language: (<string>this.languageService.systemLanguajeChange.value).toUpperCase(),
        targetCurrency: this.dataStoreService.brandTargetCurrency
      } as IActivityRequestParameters;

      // formatear paxes
      requestParameters.roomDistribution[0]['paxes'] = this.form.get('paxes').value.map((paxe: any, index) => {
        return {
          id: ((+index) + 1),
          age: +paxe.age
        };
      });

      // valores que fueron buscados
      this.searchedService.setModel(<SearchedActivity>{
        destination: this.form.get('destinationZone').value,
        date: {
          start: this.form.get('selectDate').value.startDate,
          end: this.form.get('selectDate').value.endDate
        },
        paxes: this.form.get('paxes').value
      });

      this.dataService.activityRequestParameters = requestParameters;
      this.router.navigate(['/activities/results']);
    } else {
      if (!this.form.controls['destinationZone'].valid) {
        this.destinyNotSelected = true;
      }
      if (!this.form.controls['selectDate'].valid) {
        this.dateNotSelected = true;
      }
      if (!this.form.controls['paxes'].valid) {
        this.passengerNotSelected = true;
      }
    }
    // this.trackingManager.trackEventString(data.ACTIVITIES.searchActivitiesButton, data.ACTIVITIES.activitiesHomeScreen, '', JSON.stringify(this.dataService.activityRequestParameters));
    GoogleAnalytics.sendEventFirebase('ga_event', data.ACTIVITIES.activitiesHomeScreen, data.ACTIVITIES.searchActivitiesButton, JSON.stringify(this.dataService.activityRequestParameters));
  }

  openedChangeStart(opened: any) {
    // if (!opened) {
    this.selected = this.form.value.selectDate;
    this.dateStart.nativeElement.value = this.form.value.selectDate.startDate.format('MM/DD/YYYY');
    this.dateEnd.nativeElement.value = this.form.value.selectDate.endDate.format('MM/DD/YYYY');
    this.minDateEnd = this.form.value.selectDate.startDate;

  }

  openedChangeEnd(opened: any) {
    // if (!opened) {
    this.dateStart.nativeElement.value = this.form.value.selectDate.startDate.format('MM/DD/YYYY');
    this.dateEnd.nativeElement.value = this.form.value.selectDate.endDate.format('MM/DD/YYYY');
    this.selected = this.form.value.selectDate;

    // }
  }
}
