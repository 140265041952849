import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataStoreService } from './data-store.service';
import { InitService } from './init.service';
import { StoreServiceLibrary } from '@wearenovae/novae-core-services';
import { keysUniversal } from '../resources/keys.resources';
import { GenerateService } from './generate.service';


@Injectable({
  providedIn: 'root'
})
export class JuniperService {

  urlSearchIframe = ``;
  urlSearchIframeFixedRates = ``;

  constructor(
    private translate: TranslateService,
    private initService: InitService,
    private storeServiceLibrary: StoreServiceLibrary,
    private generateService:GenerateService
  ) {
    this.initService.isInitializedBrand.subscribe((isInitialized) => {
      if (isInitialized) {
        this.urlSearchIframe = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.URL_TRAVEL_JUNIPER) || '';
        this.urlSearchIframeFixedRates = this.storeServiceLibrary.getJustOneUniversalPropertie(keysUniversal.URL_TRAVEL_FIXED_RATES) || '';
      }
    });
  }

  searchHotelsJuniper(typeService: string, search: object) {
    let url = `${this.urlSearchIframe}hotels/results.aspx`;
    url += `?accion=${typeService}&idioma=${this.translate.currentLang}&destinationID=${search['destinyCode']}&startDate=${search['checkIn']}&endDate=${search['checkOut']}&paxs=10&secondarySearch=false`;
    return url;
  }

  searchHotelsJuniperAllParameters(typeService: string, search: object) {
    let url = `${this.urlSearchIframe}hotels/results.aspx?accion=${typeService}&idioma=${this.translate.currentLang}`;

    if (search['destinationID']) {
      url += `&destinationID=${search['destinationID']}`;
    } else {
      url += `&destinationIATA=${search['destinationIATA']}`;
    }
    if (search['ages']) {
      url += `&startDate=${search['startDate']}&endDate=${search['endDate']}&paxs=${search['adt']}${search['chd']}&ages=${search['ages']}&secondarySearch=false`;
    } else {
      url += `&startDate=${search['startDate']}&endDate=${search['endDate']}&paxs=${search['adt']}${search['chd']}&secondarySearch=false`;
    }

    return url;
  }

  searchHomeVacationsJuniperAllParameters(typeService: string, search: object) {
    let url = `${this.urlSearchIframe}hotels/results.aspx?accion=${typeService}&idioma=${this.translate.currentLang}`;
    if (search['destinationID']) {
      url += `&destinationID=${search['destinationID']}`;
    } else {
      url += `&destinationIATA=${search['destinationIATA']}`;
    }
    if (search['ages']) {
      url += `&startDate=${search['startDate']}&endDate=${search['endDate']}&paxs=${search['adt']}${search['chd']}&ages=${search['ages']}`;
    } else {
      url += `&startDate=${search['startDate']}&endDate=${search['endDate']}&paxs=${search['adt']}${search['chd']}`;
    }

    return url;
  }

  searchCarsJuniper(typeService: string, search: object) {
    let url = `${this.urlSearchIframe}cars/results.aspx?accion=${typeService}&idioma=${this.translate.currentLang}&startHour=${search['startHour']}&nationality=${search['nationality']}&originID=${search['originID']}&startDate=${search['startDate']}&endDate=${search['endDate']}&dirverAge=${search['dirverAge']}&endHour=${search['endHour']}`;
    return url;
  }

  searchFlightsJuniper(typeService: string, search: object) {
    let url = `${this.urlSearchIframe}flights/results.aspx?accion=${typeService}&idioma=${this.translate.currentLang}&roundtrip=roundtrip&discount=0&searchtype=groupedByPrice&appendHashParams=discount%3d0`;
    url += `&originID=${search['departureAirportCityCode']}&destinationID=${search['arrivalAirportCityCode']}&startDate=${search['startDate']}&endDate=${search['endDate']}&paxs=10`;
    return url;
  }

  searchFlightsJuniperLifeMiles(typeService: string, search: object) {
    let url = `${this.urlSearchIframe}flights/results.aspx?accion=${typeService}&roundtrip=${search['itineraryType']}&discount=0&searchtype=groupedByPrice`;
    if (search['ages']) {
      url += `&originIATA=${search['originIATA']}&destinationIATA=${search['destinationIATA']}&startDate=${search['startDate']}&endDate=${search['endDate']}&paxs=${search['adt']}${search['chd']}&ages=${search['ages']}&flightClass=${search['flightClass']}`;
    } else {
      url += `&originIATA=${search['originIATA']}&destinationIATA=${search['destinationIATA']}&startDate=${search['startDate']}&endDate=${search['endDate']}&paxs=${search['adt']}${search['chd']}&flightClass=${search['flightClass']}`;
    }
    // Los infantes no se deben tener en cuenta para el total de pasajeros que se cobran
    const countPassengers = Number(search['adt']) + Number(search['chd']) - Number(search['inf']);
    search['appendHashParams'] = this.generateService.appendHashParams(search['flightClass'], countPassengers);
    url += `&${search['appendHashParams']}`;
    return url;
  }

  searchFlightsJuniperAllParameters(typeService: string, search: object) {
    let url = `${this.urlSearchIframe}flights/results.aspx?accion=${typeService}&roundtrip=${search['itineraryType']}&discount=0&searchtype=groupedByPrice&appendHashParams=discount%3d0`;
    if (search['originID'] || search['destinationID']) {
      url += `&originID=${search['originID']}&destinationID=${search['destinationID']}`;
    } else {
      url += `&originIATA=${search['originIATA']}&destinationIATA=${search['destinationIATA']}`;
    }
    if (search['ages']) {
      url += `&startDate=${search['startDate']}&endDate=${search['endDate']}&paxs=${search['adt']}${search['chd']}&ages=${search['ages']}&flightClass=${search['flightClass']}`;
    } else {
      url += `&startDate=${search['startDate']}&endDate=${search['endDate']}&paxs=${search['adt']}${search['chd']}&flightClass=${search['flightClass']}`;
    }
    return url;
  }

  searchFlightsJuniperScotia(typeService: string, search: object) {
    let url = `${this.urlSearchIframeFixedRates}/flights/results.aspx?accion=${typeService}&discount=0&searchtype=groupedByPrice&idioma=${this.translate.currentLang}`;
    if (search['ages']) {
      url += `&originIATA=${search['originIATA']}&destinationIATA=${search['destinationIATA']}&startDate=${search['startDate']}&endDate=${search['endDate']}&paxs=${search['adt']}${search['chd']}&ages=${search['ages']}`;
    } else {
      url += `&originIATA=${search['originIATA']}&destinationIATA=${search['destinationIATA']}&startDate=${search['startDate']}&endDate=${search['endDate']}&paxs=${search['adt']}${search['chd']}`;
    }
    // Los infantes no se deben tener en cuenta para el total de pasajeros que se cobran
    const countPassengers = Number(search['adt']) + Number(search['chd']) - Number(search['inf']);
    search['appendHashParams'] = this.generateService.appendHashParamsToFixedRates(search['flightClass'], countPassengers, search['subtotal']);
    url += `&${search['appendHashParams']}`;
    return url;
  }

  searchFlightsJuniperMultiDestino(typeService: string, search: object) {
    let url = `${this.urlSearchIframe}flights/results.aspx?accion=${typeService}&roundtrip=${search['itineraryType']}&discount=0&searchtype=groupedByPrice`;
    if (search['ages']) {
      url += `&routes=${search['urlMultiDestino']}&paxs=${search['adt']}${search['chd']}&ages=${search['ages']}&flightClass=${search['flightClass']}`;
    } else {
      url += `&routes=${search['urlMultiDestino']}&paxs=${search['adt']}${search['chd']}&flightClass=${search['flightClass']}`;
    }
    // Los infantes no se deben tener en cuenta para el total de pasajeros que se cobran
    const countPassengers = Number(search['adt']) + Number(search['chd']) - Number(search['inf']);
    search['appendHashParams'] = this.generateService.appendHashParams(search['flightClass'], countPassengers);
    url += `&${search['appendHashParams']}`;
    return url;
  }
}
