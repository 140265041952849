import { Component, OnInit, Input } from '@angular/core';
import { StoreServiceLibrary, keysUniversal } from '@wearenovae/novae-core-services';

import data from '../../../resources/screenAndEventsTracking.json';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalytics } from '../../../resources/GoogleAnalytics.resource';

@Component({
  selector: 'app-download-store',
  templateUrl: './download-store.component.html',
  styleUrls: ['./download-store.component.scss']
})
export class DownloadStoreComponent implements OnInit {

  @Input() class: string;

  isLangEs: boolean = false;

  constructor(private _storeLibrary: StoreServiceLibrary,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.takeLoadLang();
  }

  goToStore(store) {
    let urlWhatsappWelcome = '';
    if (store === 'ios') {
      // this.trackingManager.trackEventString(data.WALKTHROUGH.appStore, data.WALKTHROUGH.walkthroughScreen);
      GoogleAnalytics.sendEventFirebase('ga_event', data.WALKTHROUGH.walkthroughScreen, data.WALKTHROUGH.appStore, '');
      urlWhatsappWelcome = this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.ios_url_store);
    } else {
      // this.trackingManager.trackEventString(data.WALKTHROUGH.playStore, data.WALKTHROUGH.walkthroughScreen);
      GoogleAnalytics.sendEventFirebase('ga_event', data.WALKTHROUGH.walkthroughScreen, data.WALKTHROUGH.playStore, '');
      urlWhatsappWelcome = this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.android_url_store);
    }
    window.open(urlWhatsappWelcome, '_system', 'location=yes');
  }

  takeLoadLang() {
    this.translateService.onLangChange.subscribe((data) => {
      if (data.lang === 'es') {
        this.isLangEs = true;
      } else {
        this.isLangEs = false;
      }
    });
  }
}
