import { Injectable, OnInit } from '@angular/core';
import {
  AuthService, StoreServiceLibrary, AuthGetAccount2Response,
  AuthGetAccount2Request, keysUniversal, SecurityObject
} from '@wearenovae/novae-core-services';
import { DataService } from './data.service';
import { DataStoreService } from './data-store.service';
import { AlertSessionComponent } from '../container/shared/alert-session/alert-session.component';
import { MatDialog } from '@angular/material/dialog';
import { LanguageService } from "../component/language/language.service";
import { toInteger } from "@ng-bootstrap/ng-bootstrap/util/util";
import { LoginService } from "../container/shared/login/login.service";
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SmartProfileService implements OnInit {

  useJwtToken: boolean;

  smartProfilePreferences: any;
  preferenceOriginFlight: string;
  preferenceOriginFlightId: number;
  preferenceDestinyFlight: string;
  preferenceDestinyFlightId: number;
  preferenceFlightClass: string;
  preferenceHotelCategory: number;
  preferenceHotelDes: string;
  preferenceHotelDesId: number;
  preferenceHotelDesIata: string;
  preferenceOriginFlightIata: string;
  preferenceDestinyFlightIata: string;

  profileDataLoadedChange = new BehaviorSubject<boolean>(this.profileLoaded());

  get idApplication() {
    return this.data.idApplication;
  }

  constructor(
    private _storeLibrary: StoreServiceLibrary,
    private _authService: AuthService,
    private data: DataService,
    private dataStore: DataStoreService,
    private dialog: MatDialog,
    private language: LanguageService,
  ) {
  }

  ngOnInit() {
  }

  async getAccountMethod(token: string) {
    const url = this._storeLibrary.getJustOneUniversalPropertie(keysUniversal.auth_api_url_get_account_2);
    const authGetAccount2Request = new AuthGetAccount2Request();
    authGetAccount2Request.channelId = this.dataStore.channelID; // Chanel quemado this.data.smartProfile.accountResultDto.channelId
    authGetAccount2Request.language = this.language.systemLanguajeChange.value; // Idioma actual de la pagina
    authGetAccount2Request.authenticationToken = token;
    authGetAccount2Request.security = SecurityObject.getSecurityObject();
    this.useJwtToken = this.data.getJwtUse();
    await this.data.saveSmartProfile(await this._authService.getAccount2V2(authGetAccount2Request, url, this.useJwtToken).toPromise().catch((err) => {
      // this.data.deleteToken();
      // this.sessionErrorAlert(err);
      return new AuthGetAccount2Response;
    }));
    this.setSmartProfilePreferences();
  }

  sessionErrorAlert(err) {
    const dialogRef = this.dialog.open(AlertSessionComponent, {
      panelClass: 'alert-dialog',
      data: { message: err }
    });
  }

  setSmartProfilePreferences() {
    const that = this;
    if (this.data.smartProfile && this.data.smartProfile.accountResultDto && 
      this.data.smartProfile.accountResultDto.accountSumaryDto && 
      this.data.smartProfile.accountResultDto.accountSumaryDto.merchantAccounts) {

      this.data.smartProfile.accountResultDto.accountSumaryDto.merchantAccounts.forEach(data => {
        if (data.merchantAccountId === 3) {
          data.preferences.forEach(preference => {
            if (preference.codeTypePreference === 'VUEORI') {
              that.preferenceOriginFlight = preference.valueText;
              that.preferenceOriginFlightId = preference.valueId;
            }
            if (preference.codeTypePreference === 'VUEDES') {
              that.preferenceDestinyFlight = preference.valueText;
              that.preferenceDestinyFlightId = preference.valueId;
            }
            if (preference.codeTypePreference === 'CLASS') {
              switch (preference.valueText) {
                case 'novaeTravelEconomy':
                  that.preferenceFlightClass = 'Y';
                  break;
                case 'novaeTravelBusiness':
                  that.preferenceFlightClass = 'B';
                  break;
                case 'novaeTravelFirst':
                  that.preferenceFlightClass = 'F';
                  break;
              }
            }
          })
        } else {
          if (data.merchantAccountId === 2) {
            data.preferences.forEach(preference => {
              if (preference.codeTypePreference === 'HOTELES') {
                that.preferenceHotelCategory = parseInt(preference.valueText);
              }
              if (preference.codeTypePreference === 'HOTELDES') {
                that.preferenceHotelDes = preference.valueText;
                that.preferenceHotelDesId = preference.valueId;
              }
            });
          }
        }
      });
      this.data.saveSmartProfilePreferences({
        flightOrigin: this.preferenceOriginFlight,
        flightOriginId: this.preferenceOriginFlightId,
        flightDestiny: this.preferenceDestinyFlight,
        flightDestinyId: this.preferenceDestinyFlightId,
        flightClass: this.preferenceFlightClass,
        hotelCategory: this.preferenceHotelCategory,
        hotelDestiny: this.preferenceHotelDes,
        hotelDestinyId: this.preferenceHotelDesId
      });
      this.profileDataLoadedChange.next(true);
    }
  }

  profileLoaded() {
    return !!this.dataStore.smartProfilePreferences;
  }

  async unloadSmartProfile() {
    await this.data.saveSmartProfile(new AuthGetAccount2Response());
    this.profileDataLoadedChange.next(false);
  }
}
